import React, { useState, useEffect } from "react";

import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import {
  getIOIDetails,
  getTotalAmountRaisedByStartup,
} from "../../../../endpoints/deal";

import { Box } from "@mui/material";

const DetailedCardFinancials = ({ deal }) => {
  const token = localStorage.getItem("token");
  const [totalAmountRaised, setTotalAmountRaised] = useState(0);

  const [ioiBids, setIOIBids] = useState([]);
  const getTotalAmountRaised = async () => {
    try {
      const response = await getTotalAmountRaisedByStartup({
        dealId: deal ? deal.DealID : "",
        token: token,
      });
      if (response.data) setTotalAmountRaised(response.data || 0);
    } catch (error) {}
  };
  const getIOIBids = async () => {
    // Call the API to get the IOI bids for the startup
    try {
      const response = await getIOIDetails({
        dealId: deal ? deal.DealID : "",
        token: token,
      });
      setIOIBids(response.data);
    } catch (error) {}
  };
  useEffect(() => {
    if (deal?.DealID) {
      getTotalAmountRaised();
      getIOIBids();
    }
  }, [deal]);
  return (
    <Box
      className="cdetails-div-18"
      style={{
        borderBottom: "0px",
        marginTop: "0px",
        paddingBottom: "0px",
      }}
    >
      <div className="cdetails-div-19">
        <div className="column-3">
          <div className="cdetails-div-20" style={{ gap: "54px" }}>
            <div className="finanicials" style={{ width: "25%" }}>
              <div className="DetailedCardFinancials-heading">Financials</div>
              <div className="DetailedCardFinancials-subheading">
                ({deal ? deal.FinancialSubHeading : ""})
              </div>
              <div className="cdetails-div-22" style={{ padding: "24px" }}>
                <div className="cdetails-div-23" style={{ padding: "0px" }}>
                  <div
                    className="cdetails-div-24"
                    style={{ paddingTop: "0px", paddingBottom: "12px" }}
                  >
                    <div className="DetailedCardFinancials-tableText">
                      Revenue
                    </div>
                    <div className="DetailedCardFinancials-tableText-value">
                      ₹{deal ? deal.Revenue : ""}
                    </div>
                  </div>
                  <div
                    className="cdetails-div-24"
                    style={{ padding: "12px 0px" }}
                  >
                    <div className="DetailedCardFinancials-tableText">
                      Gross Profit
                    </div>
                    <div className="DetailedCardFinancials-tableText-value">
                      ₹{deal ? deal.GrossProfit : ""}
                    </div>
                  </div>
                  <div
                    className="cdetails-div-24"
                    style={{ padding: "12px 0px" }}
                  >
                    <div className="DetailedCardFinancials-tableText">
                      EBITDA
                    </div>
                    <div className="DetailedCardFinancials-tableText-value">
                      ₹{deal ? deal.EBITDA : ""}
                    </div>
                  </div>
                  <div
                    className="cdetails-div-24"
                    style={{
                      borderBottom: "0px",
                      paddingTop: "12px",
                      paddingBottom: "0px",
                    }}
                  >
                    <div className="DetailedCardFinancials-tableText">
                      EBITDA %
                    </div>
                    <Box
                      className="DetailedCardFinancials-tableText-value"
                      sx={{
                        color:
                          deal &&
                          String(deal.EBITDA_Percentage).slice(0, 1) === "-"
                            ? "#D32F2F"
                            : "#00B844",
                      }}
                    >
                      {deal ? `${deal.EBITDA_Percentage}%` : ""}
                    </Box>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="cdetails-div-37"
              style={{ alignItems: "center", width: "30%" }}
            >
              <div
                className="DetailedCardFinancials-heading"
                style={{ textAlign: "center" }}
              >
                Current Round
              </div>
              <div className="current-scenario">
                <Box
                  className="cdetails-circular-progress-bar"
                  sx={{ width: "132px", marginTop: "16px" }}
                >
                  {/* <CircularProgressbarWithChildren
                    value={
                      deal
                        ? ((Number(deal.TotalFundingRaised) +
                            totalAmountRaised) /
                            Number(deal.FundingRequired)) *
                          100
                        : ""
                    }
                    circleRatio={0.75}
                    styles={buildStyles({
                      rotation: 1 / 2 + 1 / 8,
                      trailColor: "#eee",
                      pathColor: "#4EF79A",
                    })}
                  >
                    <div className="cdetails-circular-progress-bar2">
                      <CircularProgressbar
                        value={
                          deal
                            ? (Number(deal.TotalFundingRaised || 0) /
                                Number(deal.FundingRequired)) *
                              100
                            : ""
                        }
                        circleRatio={0.75}
                        styles={buildStyles({
                          rotation: 1 / 2 + 1 / 8,
                          trailColor: "transparent",
                          pathColor: "#00A6FB",
                        })}
                      />
                    </div>

                    <div className="progress-bar-div">
                      <div className="progress-bar-div-2">
                        ₹
                        {deal
                          ? Number(deal.TotalFundingRaised || 0) +
                            totalAmountRaised
                          : ""}
                        Cr
                      </div>
                      <div className="progress-bar-div-3">
                        {deal
                          ? (
                              ((Number(deal.TotalFundingRaised || 0) +
                                totalAmountRaised) /
                                Number(deal.FundingRequired || 1)) *
                              100
                            ).toFixed(2)
                          : ""}
                        % Raised
                      </div>
                    </div>
                    <p className="progress-bar-div-4 round-size">
                      ₹{deal ? deal.FundingRequired : ""}Cr
                    </p>
                    <p className="progress-bar-div-4 round-size-text">
                      Round Size
                    </p>
                  </CircularProgressbarWithChildren> */}
                  <CircularProgressbarWithChildren
                    value={
                      deal
                        ? ((Number(deal.TotalFundingRaised) +
                            totalAmountRaised) /
                            Number(deal.FundingRequired)) *
                          100
                        : ""
                    }
                    circleRatio={0.75}
                    styles={buildStyles({
                      rotation: 1 / 2 + 1 / 8,
                      trailColor: "#eee",
                      pathColor: "#00A6FB", // Set the color to #00A6FB
                    })}
                  >
                    <Box
                      className="progress-bar-div"
                      sx={{
                        width: "95px !important",
                        height: "95px !important",
                        top: "0px !important",
                        padding: "0px !important",
                      }}
                    >
                      <Box
                        className="progress-bar-div-2"
                        style={{
                          fontSize: "16px",
                        }}
                      >
                        ₹
                        {deal
                          ? Number(deal.TotalFundingRaised || 0) +
                            totalAmountRaised
                          : ""}
                        Cr
                      </Box>
                      <Box
                        className="progress-bar-div-3"
                        style={{
                          top: "0px",
                          fontSize: "14px",
                          whiteSpace: "nowrap",
                          textAlign: "center",
                        }}
                      >
                        {deal
                          ? (
                              ((Number(deal.TotalFundingRaised || 0) +
                                totalAmountRaised) /
                                Number(deal.FundingRequired || 1)) *
                              100
                            ).toFixed(0) + "% Raised"
                          : ""}
                      </Box>
                    </Box>
                    <p
                      className="progress-bar-div-4 round-size"
                      style={{ fontSize: "16px" }}
                    >
                      ₹{deal ? deal.FundingRequired : ""}Cr
                    </p>
                    <p
                      className="progress-bar-div-4 round-size-text"
                      style={{
                        fontSize: "14px",
                        color: "#696F79",
                        fontWeight: "300",
                      }}
                    >
                      Round Size
                    </p>
                  </CircularProgressbarWithChildren>
                </Box>
              </div>
              <Box
                sx={{
                  display: "flex",
                  width: "242px",
                  padding: "12px 24px",
                  alignItems: "flex-start",
                  gap: "10px",
                  borderRadius: "8px",
                  background: "#FAFAFA",
                  marginTop: "40px",
                  justifyContent: "space-around",
                }}
              >
                <Box>
                  <p
                    style={{
                      color: "#1A1A1A",
                      fontSize: "16px",
                      fontWeight: "600",
                    }}
                  >
                    {deal
                      ? JSON.parse(deal.RoundType)[
                          deal.RoundType === '"Debt"' ? 1 : 0
                        ]
                      : ""}
                  </p>
                  <p
                    style={{
                      color: "#696F79",
                      fontSize: "14px",
                      fontWeight: "300",
                    }}
                  >
                    Round Type
                  </p>
                </Box>
                <Box>
                  <p
                    style={{
                      color: "#1A1A1A",
                      fontSize: "16px",
                      fontWeight: "600",
                    }}
                  >
                    ₹{((deal?.Equity / deal?.StakeOffered) * 100).toFixed(1)}Cr
                  </p>
                  <p
                    style={{
                      color: "#696F79",
                      fontSize: "14px",
                      fontWeight: "300",
                    }}
                  >
                    Valuation
                  </p>
                </Box>
              </Box>
            </div>
            <div className="cdetails-div-45">
              <div className="cdetails-div-47">
                <div className="DetailedCardFinancials-heading">
                  Key Metrics
                </div>
                <Box
                  className="DetailedCardFinancials-subheading"
                  style={{ visibility: "hidden" }}
                >
                  Random
                </Box>
                <div
                  className="cdetails-div-66"
                  style={{
                    visibility: "hidden",
                  }}
                >
                  ({deal ? deal.FinancialSubHeading : ""})
                </div>
                <Box
                  className="cdetails-div-49"
                  sx={{ padding: "24px", marginTop: "0px" }}
                >
                  {Object.entries(deal?.KeyMetrics || {}).map(
                    ([key, value], index, array) => (
                      <div
                        key={index}
                        className="cdetails-div-27"
                        style={{
                          paddingTop: index === 0 && "0px",
                          paddingBottom:
                            index === array.length - 1 ? "0px" : "12px",
                          borderBottom:
                            index === array.length - 1 ? "0px" : undefined,
                        }}
                      >
                        <div className="DetailedCardFinancials-tableText">
                          {key}
                        </div>
                        <div className="DetailedCardFinancials-tableText-value">
                          {value}
                        </div>
                      </div>
                    )
                  )}
                </Box>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Box>
  );
};

export default DetailedCardFinancials;
