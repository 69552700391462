import React, { useState } from "react";
import Topbar from "./topbar";
import Sidebar from "./Sidebar";
import CompanyUploadedFiles from "../companyUploadedFiles";
import { useLocation } from "react-router-dom";

import DealDetailsForm from "./dealDetailsForm";
import FoundingTeamDetailsForm from "./foundingTeamDetailsForm";
import InvestorPanel from "../../Investor/Onboarding/InvestorPanel";
import DataroomUpload from "./DataroomUpload";
import AnalyticsMapper from "./AnalyticsMapper";

import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { Helmet } from "react-helmet";

const DealDetailsEdit = () => {
  const location = useLocation();
  const [cardType, setCardType] = useState("DealDetails");
  const { dealID, startupID } = location.state || {};
  const handleCardTypeChange = (event, newCardType) => {
    if (newCardType !== null) setCardType(newCardType);
  };
  let ComponentToRender;

  switch (cardType) {
    case "DealDetails":
      ComponentToRender = <DealDetailsForm />;
      break;

    case "FoundingTeamDetails":
      ComponentToRender = (
        <FoundingTeamDetailsForm startupID={startupID} dealID={dealID} />
      );
      break;
    case "InvestorPanel":
      ComponentToRender = (
        <InvestorPanel startupID={startupID} dealID={dealID} />
      );
      break;
    case "Files":
      ComponentToRender = <CompanyUploadedFiles />;
      break;
    case "Dataroom":
      ComponentToRender = (
        <DataroomUpload startupID={startupID} dealID={dealID} />
      );
      break;
    case "AnalyticsMapper":
      ComponentToRender = (
        <AnalyticsMapper startupID={startupID} dealID={dealID} />
      );
      break;
    default:
      ComponentToRender = <DealDetailsForm />;
  }
  return (
    <section className="dealDetailsEdit">
      <Helmet>
        <title>Admin Panel - deal details Edit</title>
      </Helmet>
      <Topbar />
      <Sidebar dealID={dealID} active={"dealDetails"} startupID={startupID} />
      <div className="dealDetailsEdit-box">
        <ToggleButtonGroup
          color="primary"
          value={cardType}
          exclusive
          onChange={handleCardTypeChange}
          aria-label="card-type"
          className="toggle-card-type-btn"
        >
          <ToggleButton
            value="DealDetails"
            sx={{
              textTransform: "capitalize",
            }}
          >
            Deal Details
          </ToggleButton>
          <ToggleButton
            value="FoundingTeamDetails"
            sx={{
              textTransform: "capitalize",
            }}
          >
            FoundingTeam Details
          </ToggleButton>
          <ToggleButton
            value="InvestorPanel"
            sx={{
              textTransform: "capitalize",
            }}
          >
            Choose Investors
          </ToggleButton>
          <ToggleButton
            value="Dataroom"
            sx={{
              textTransform: "capitalize",
            }}
          >
            Dataroom
          </ToggleButton>
          <ToggleButton
            value="Files"
            sx={{
              textTransform: "capitalize",
            }}
          >
            Files
          </ToggleButton>
          <ToggleButton
            value="AnalyticsMapper"
            sx={{
              textTransform: "capitalize",
            }}
          >
            Analytics Mapper
          </ToggleButton>
        </ToggleButtonGroup>
        {ComponentToRender}
      </div>
    </section>
  );
};

export default DealDetailsEdit;
