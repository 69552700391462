import React, { useState, useEffect } from "react";
import PasswordValidator from "../../Common/passwordValidator";
import Header from "../../LandingPage/header";
import "../../../CSS/Signup/investorRegister.css";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useLoader } from "../../Common/LoaderProvider";
import Toast from "../../Common/Toast";
import {
  sendOtpPasswordChange,
  updatePasswordRequest,
  verifyEmailPasswordChange,
} from "../../../endpoints/common";
import { startupMemberLogin } from "../../../endpoints/startup";
import { investorMemberLogin } from "../../../endpoints/investor";

import { Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import VerificationInput from "react-verification-input";
import RefreshIcon from "@mui/icons-material/Refresh";
import { ToastContainer } from "react-toastify";
import { Button, Checkbox, FormControl, FormLabel, Input } from "@mui/joy";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Visibility } from "@mui/icons-material";
import { useNavigate, Link, useLocation } from "react-router-dom";

import Verify from "../../../Assets/Images/signup/InvestorRegister/Verify.png";
import "../../../CSS/SignIn/style.css";

const InvestorSignInBlock = ({ startPoint }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { type, sentemail } = location.state || {};
  const [showPassword, setShowPassword] = useState({
    value1: false,
    value2: false,
    value3: false,
  });
  const [activeTab, setActiveTab] = useState(type || "Investor");
  const [firstopen, setfirstOpen] = useState(false);
  const [secondopen, setsecondOpen] = useState(false);
  const [thirdopen, setthirdOpen] = useState(false);
  const [fourthopen, setfourthOpen] = useState(false);
  const [codesent, setCodeSent] = useState(false);
  const [time, setTime] = useState(5 * 60);
  const [timeUp, setTimeUp] = useState(false);
  const [email, setEmail] = useState("");
  const [token, setToken] = useState();
  const [secondToken, setSecondToken] = useState();
  const [verified, setVerified] = useState(false);
  const [verificationCode, setVerificationCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [signInData, setSignInData] = useState({
    email: sentemail || "",
    password: "",
  });
  const loader = useLoader();
  const handleChange = (e) => {
    const { name, value } = e.target;
    setSignInData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const minutes = Math.floor(time / 60);
  const seconds = time % 60;

  const formattedTime = `${minutes < 10 ? "0" : ""}${minutes}:${
    seconds < 10 ? "0" : ""
  }${seconds}`;

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const resetPassword = params.get("resetPassword");
    if (resetPassword === "true") {
      setfirstOpen(true);
    }
  }, [location]);

  useEffect(() => {
    if (time === 0) {
      setTimeUp(true);
      return;
    }

    const timer = setInterval(() => {
      setTime((prevTime) => prevTime - 1);
    }, 1000);

    return () => clearInterval(timer);
  }, [time]);
  const handleClickFirstOpen = () => {
    setfirstOpen(true);
  };
  const handleClickSecondOpen = () => {
    sendOTP();
  };
  const handleClickThirdOpen = () => {
    verifyCode();
  };
  const handleClickFourthOpen = () => {
    updatePassword();
  };
  const handleFirstClose = () => {
    setfirstOpen(false);
  };
  const handleSecondClose = () => {
    setsecondOpen(false);
  };
  const handleThirdClose = () => {
    setthirdOpen(false);
  };
  const handleFourthClose = () => {
    setfourthOpen(false);
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSignInSubmit();
    }
  };
  const handleSignInSubmit = async () => {
    // In try block there will be signin flow but handling all the cases except server error which is being handled by catch block
    try {
      if (activeTab === "Investor") {
        loader.start("Signing In...");
        const responseData = await investorMemberLogin(signInData);
        loader.stop();
        if (responseData.data !== null) {
          const response = responseData.data;
          if (response.status === 200) {
            localStorage.setItem("token", response.data.data.token);
            localStorage.setItem("role", "investor");
            // localStorage.setItem(
            //   "isApplicationReviewed",
            //   response.data.data.isApplicationReviewed
            // );
            // localStorage.setItem(
            //   "onBoardingComplete",
            //   response.data.data.onBoardingComplete
            // );
          }
          if (startPoint === "noNameDeals") {
            navigate("/investor/deals");
          } else {
            navigate("/investor/newDeals", {
              state: {
                token: response.data.data.token,
              },
            });
            // } else {
            //   navigate("/investorDetails", {
            //     state: {
            //       investorEmail: signInData.email,
            //       investorName: response.data.data.name,
            //       tkn: response.data.data.token,
            //     },
            //   });
            // }
            Toast("Login Successful", "success", "signIn");
          }
        } else if (responseData.error.response.status === 401) {
          Toast("Invalid Credentials", "error", "signIn");
        }
      } else if (activeTab === "Startup") {
        loader.start("Signing In...");
        const responseData = await startupMemberLogin(signInData);
        loader.stop();
        if (responseData.data !== null) {
          const response = responseData.data;
          if (response.status === 200) {
            localStorage.setItem("token", response.data.data.token);
            localStorage.setItem("role", "company");

            localStorage.setItem("isOpen", response.data.data.isOpen);
            if (response.data.data.isOpen) {
              navigate("/company/fundraising", {
                state: {
                  token: response.data.data.token,
                },
              });
            } else if (response.data.data.isApproved) {
              navigate("/companyDetails", {
                state: {
                  companyEmail: signInData.email,
                  tkn: response.data.data.token,
                },
              });
            } else {
              // navigate("/companyDetails", {
              //   state: {
              //     companyEmail: signInData.email,
              //     tkn: response.data.data.token,
              //   },
              // })
              navigate("/companyWaitlistPage");
            }
          }
        } else if (responseData.error.response.status === 401) {
          Toast("Invalid Credentials", "error", "signIn");
        }
      }
    } catch {
      loader.stop();
      Toast("Internal server error", "error", "signIn");
    }
  };
  const sendOTP = async () => {
    try {
      loader.start("Sending OTP...");
      const responseData = await sendOtpPasswordChange(activeTab, email);
      loader.stop();
      if (responseData.data !== null) {
        const response = responseData.data;
        if (response.ok) {
          const responseData = await response.json();
          setCodeSent(true);
          setsecondOpen(true);
          setfirstOpen(false);
          setToken(responseData.verificationToken);
        }
        if (!response.ok) {
          Toast(
            "Did not find account associated with this email address",
            "error",
            "signIn"
          );
        }

        setTime(5 * 60);
        setTimeUp(false);
      } else {
        // Handle any errors, such as showing a message to the user
      }
    } catch (error) {
      loader.stop();
    }
  };
  const verifyCode = async () => {
    try {
      loader.start("Verifying OTP...");
      const responseData = await verifyEmailPasswordChange(
        token,
        email,
        verificationCode
      );
      loader.stop();
      if (responseData.data !== null) {
        const response = responseData.data;
        if (response.ok) {
          const responseData = await response.json();
          Toast("Verification Successful", "success", "signIn");
          setVerified(true);
          setthirdOpen(true);
          setsecondOpen(false);
          setSecondToken(responseData.verificationToken);
        }
        if (!response.ok) {
          Toast("Verification Unsucessful", "error", "signIn");
        }

        // const data = await response.json();
        // Handle successful verification
      } else {
        Toast("Verification Unsucessful", "error", "signIn");
        setVerificationCode("");
        // Handle error (e.g., show an error message)
      }
    } catch (error) {
      loader.stop();
    }
  };
  const updatePassword = async () => {
    if (newPassword !== confirmPassword) {
      Toast("Passwords do not match", "warning", "signIn");
    } else if (
      allStates.lowerValidated === false ||
      allStates.upperValidated === false ||
      allStates.numberValidated === false ||
      allStates.specialValidated === false ||
      allStates.lengthValidated === false
    ) {
      Toast("Password does not meet the requirements", "warning", "signIn");
      return;
    } else {
      try {
        loader.start("Updating Password...");
        const responseData = await updatePasswordRequest(
          secondToken,
          newPassword
        );
        loader.stop();
        if (responseData.data !== null) {
          const response = responseData.data;
          if (response.ok) {
            setfourthOpen(true);
            setthirdOpen(false);
          }
          if (!response.ok) {
            Toast("Failed to update password", "error", "signIn");
          }

          // const data = await response.json();
          // Handle successful verification
        } else {
          Toast("Failed to update password", "error", "signIn");
        }
      } catch (error) {
        loader.stop();
      }
    }
  };
  const handleSendAgain = () => {
    sendOTP();
    Toast("OTP sent again", "success", "signIn");
    setTime(5 * 60);
  };
  const updateSignInPassword = (newPassword) => {
    setNewPassword(newPassword);
  };
  const [allStates, setAllStates] = useState({
    lowerValidated: false,
    upperValidated: false,
    numberValidated: false,
    specialValidated: false,
    lengthValidated: false,
  });
  const receiveStatesFromChild = (states) => {
    setAllStates(states);
  };
  return (
    <div className="investorRegisterWithSignUpText">
      <div className="signInWithHeader">
        <Header color="black" type="investor" />
        <div className="investorRegister">
          <div className="section">
            <div className="goBack" onClick={() => navigate(-1)}>
              <ArrowBackIosIcon />
              <Typography className="back-text">Back</Typography>
            </div>
            <div className="title">
              <Typography variant="h6" className="heading">
                Welcome Back!
              </Typography>
              <Typography variant="subtitle1" color="grey" className="content">
                We are happy to have you back
              </Typography>
            </div>
            <div className="form">
              <div
                className="tabs-div"
                style={{ display: startPoint === "noNameDeals" && "none" }}
              >
                <div
                  className={`tabs-div-2 ${
                    activeTab === "Investor" ? "active-tab" : ""
                  }`}
                  onClick={() => setActiveTab("Investor")}
                >
                  Investor
                </div>
                <div
                  className={`tabs-div-3 ${
                    activeTab === "Startup" ? "active-tab" : ""
                  }`}
                  onClick={() => setActiveTab("Startup")}
                >
                  Company
                </div>
              </div>
              <FormControl
                className="form-control full-width-input"
                id="Id"
                required
                size="lg"
                color="primary"
              >
                <FormLabel className="label">Email Address</FormLabel>
                <Input
                  className="input"
                  placeholder="Enter email address"
                  color="neutral"
                  type="email"
                  name="email"
                  value={signInData.email}
                  onChange={handleChange}
                  onKeyDown={handleKeyDown}
                />
              </FormControl>
              <FormControl
                className="form-control full-width-input"
                id="Id"
                required
                size="lg"
                color="primary"
              >
                <FormLabel className="label">Password</FormLabel>
                <Input
                  endDecorator={
                    !showPassword.value1 ? (
                      <VisibilityOffIcon
                        onClick={() =>
                          setShowPassword((prevState) => ({
                            ...prevState,
                            value1: true,
                          }))
                        }
                      />
                    ) : (
                      <Visibility
                        onClick={() =>
                          setShowPassword((prevState) => ({
                            ...prevState,
                            value1: false,
                          }))
                        }
                      />
                    )
                  }
                  className="input"
                  placeholder="Enter password"
                  color="neutral"
                  type={showPassword.value1 ? "text" : "password"}
                  value={signInData.password}
                  name="password"
                  onChange={handleChange}
                  onKeyDown={handleKeyDown}
                />
                <p
                  className="forget-your-password"
                  onClick={handleClickFirstOpen}
                >
                  Forgot your password
                </p>
                <Dialog
                  open={firstopen}
                  //   onClose={handleSecondClose}
                  PaperProps={{
                    component: "form",
                    sx: {
                      width: "700px",
                      borderRadius: "20px",
                    },
                  }}
                >
                  <DialogTitle className="dialog-title">
                    Reset your password
                  </DialogTitle>
                  <IconButton
                    aria-label="close"
                    onClick={handleFirstClose}
                    sx={{
                      position: "absolute",
                      right: 8,
                      top: 8,
                      color: (theme) => theme.palette.grey[500],
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                  <DialogContent>
                    <div className="dialogcontent dialogcontent-forget-password">
                      <DialogContentText className="dialogcontenttext signIn-dialogcontenttext">
                        <p className="dialog-box-enter-email-text">
                          Enter your email address and we'll send you an OTP to
                          get back into your account.
                        </p>
                        <FormControl
                          className="form-control full-width-input"
                          id="Id"
                          size="lg"
                          color="primary"
                        >
                          <FormLabel className="email-address-label">
                            Email Address
                          </FormLabel>
                          <Input
                            className="email-address-input"
                            placeholder="Enter email address"
                            color="neutral"
                            type="email"
                            name="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </FormControl>
                        <Button
                          className="button proceed-btn"
                          fullWidth
                          onClick={handleClickSecondOpen}
                        >
                          Send OTP
                        </Button>
                      </DialogContentText>
                    </div>
                  </DialogContent>
                </Dialog>
                <Dialog
                  open={secondopen && codesent}
                  PaperProps={{
                    component: "form",
                    sx: {
                      width: "700px",
                      borderRadius: "20px",
                    },
                  }}
                >
                  <DialogTitle className="dialog-title">
                    Please enter the OTP shared on your email address
                  </DialogTitle>
                  <IconButton
                    aria-label="close"
                    onClick={handleSecondClose}
                    sx={{
                      position: "absolute",
                      right: 8,
                      top: 8,
                      color: (theme) => theme.palette.grey[500],
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                  <DialogContent>
                    <div className="dialogcontent dialogcontent-forget-password">
                      <DialogContentText className="dialogcontenttext signIn-dialogcontenttext">
                        <VerificationInput
                          length={6}
                          value={verificationCode}
                          onChange={(code) => setVerificationCode(code)}
                          placeholder=""
                          classNames={{
                            container: "code-container",
                            character: "code-character",
                            characterInactive: "character--inactive",
                            characterSelected: "character--selected",
                            characterFilled: "character--filled",
                          }}
                        />
                        <div className="time-up-send-again-box">
                          <Typography className="code-time">
                            {" "}
                            {timeUp ? "Time up" : formattedTime}
                          </Typography>
                          <div className="send-again">
                            <RefreshIcon />
                            <Typography
                              className="send-again-text"
                              onClick={handleSendAgain}
                            >
                              Send again
                            </Typography>
                          </div>
                        </div>
                        <Button
                          className="button proceed-btn"
                          fullWidth
                          onClick={handleClickThirdOpen}
                        >
                          Proceed
                        </Button>
                      </DialogContentText>
                    </div>
                  </DialogContent>
                </Dialog>
                <Dialog
                  open={thirdopen && verified}
                  PaperProps={{
                    component: "form",
                    sx: {
                      width: "700px",
                      borderRadius: "20px",
                    },
                  }}
                >
                  <DialogTitle className="dialog-title">
                    Please enter your new password below
                  </DialogTitle>
                  <IconButton
                    aria-label="close"
                    onClick={handleThirdClose}
                    sx={{
                      position: "absolute",
                      right: 8,
                      top: 8,
                      color: (theme) => theme.palette.grey[500],
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                  <DialogContent>
                    <div className="dialogcontent dialogcontent-forget-password">
                      <DialogContentText className="dialogcontenttext signIn-dialogcontenttext">
                        <PasswordValidator
                          updatePassword={updateSignInPassword}
                          sendStatesToParent={receiveStatesFromChild}
                          page="signin"
                        />
                        <FormControl
                          style={{
                            marginTop: "20px",
                            marginBottom: "20px",
                          }}
                          className="form-control"
                          id="Id"
                          required
                          size="lg"
                          color="primary"
                        >
                          <FormLabel className="password-label label">
                            Confirm Password
                          </FormLabel>
                          <Input
                            endDecorator={
                              !showPassword.value3 ? (
                                <VisibilityOffIcon
                                  onClick={() =>
                                    setShowPassword((prevState) => ({
                                      ...prevState,
                                      value3: true,
                                    }))
                                  }
                                />
                              ) : (
                                <Visibility
                                  onClick={() =>
                                    setShowPassword((prevState) => ({
                                      ...prevState,
                                      value3: false,
                                    }))
                                  }
                                />
                              )
                            }
                            className="email-address-input"
                            placeholder="Re-enter password"
                            color="neutral"
                            type={showPassword.value3 ? "text" : "password"}
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                          />
                        </FormControl>
                        <Button
                          className="button proceed-btn"
                          fullWidth
                          onClick={handleClickFourthOpen}
                        >
                          Reset Password
                        </Button>
                      </DialogContentText>
                    </div>
                  </DialogContent>
                </Dialog>
                <Dialog
                  open={fourthopen}
                  onClose={handleSecondClose}
                  PaperProps={{
                    component: "form",
                    sx: {
                      width: "560px",
                      borderRadius: "20px",
                    },
                  }}
                >
                  <IconButton
                    aria-label="close"
                    onClick={handleFourthClose}
                    sx={{
                      position: "absolute",
                      right: 8,
                      top: 8,
                      color: (theme) => theme.palette.grey[500],
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                  <DialogContent className="password-reset-success-box">
                    <img src={Verify} alt="verify"></img>
                    <p>Your password has been reset successfully!</p>
                  </DialogContent>
                </Dialog>
              </FormControl>

              <Button
                className="button proceed-btn"
                fullWidth
                onClick={handleSignInSubmit}
                style={{ textAlign: "center", maxWidth: "600px" }}
              >
                Sign In
              </Button>
            </div>
            <Typography className="text">
              <div className="textContent">
                Don't have an account?{" "}
                <Link
                  to={
                    startPoint === "noNameDeals"
                      ? "/investor/register"
                      : "/signup"
                  }
                  style={{ color: "rgba(0, 166, 251, 1)" }}
                >
                  Sign Up
                </Link>
              </div>
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvestorSignInBlock;
