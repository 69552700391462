import React, { useState } from "react";

const CustomBlackBtn = ({ type, text, color }) => {
  const [hover, setHover] = useState(false); // Manage hover state

  return (
    <button
      type={type ? type : "button"}
      onMouseEnter={() => setHover(true)} // Trigger hover effect
      onMouseLeave={() => setHover(false)} // Remove hover effect
      style={{
        width: "fit-content",
        display: "flex",
        height: "48px",
        padding: "12px 24px",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
        borderRadius: "100px",
        background:
          color === "#fff"
            ? hover
              ? "#fff"
              : "#fff"
            : hover
            ? "#00445E"
            : "#002132",
        boxShadow: hover
          ? "0px 6px 12px rgba(0, 0, 0, 0.2)"
          : "0px 4px 8px rgba(0, 0, 0, 0.1)",
        border: color === "#fff" ? "1px solid #002132" : "none",
        color: color === "#fff" ? "#002132" : "#fff",
        cursor: "pointer",
        transition: "background-color 0.3s ease, box-shadow 0.3s ease", // Smooth transition
      }}
    >
      {text}
    </button>
  );
};

export default CustomBlackBtn;
