import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Link,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import { useLoader } from "../../Common/LoaderProvider";
import { fetchStartupDetailsById } from "../../../endpoints/startup";
import { fetchBlobName, fetchFullFileName } from "../../Common/functions";
import FileImg from "../../../Assets/Images/signup/file.svg";
const CompanyUploadedFiles = () => {
  const loader = useLoader();
  const location = useLocation();
  const { startupID } = location.state || {};
  const token = localStorage.getItem("token");
  const [fileInfo, setFileInfo] = useState({});
  const [uploadedDocuments, setUploadedDocuments] = useState({});

  let stringFields = [
    "summary",
    "pitch",
    "businessPlan",
    "competitorAnalysis",
    "incomeStatement",
    "balanceSheet",
    "cashFlowStatement",
    "auditReport",
    "mis",
    "financialModel",
    "invoiceDetail",
    "salesPipelineMetrics",
    "marketingExpenses",
    "profitabilityMetrics",
    "otherMetrics",
    "directorsProfiles",
    "managementTeamProfile",
    "organizationChart",
    "memorandum",
    "certificateIncorporation",
    "taxIdentificationDocuments",
    "sharePurchaseAgreements",
    "debtAgreements",
    "capitalizationTable",
    "taxReturns",
    "employeeCensus",
    "employeeStockOptionPlan",
    "employmentAgreements",
    "productOverview",
    "productRoadmap",
    "recordedProductDemo",
    "trademarks",
    "patents",
    "keyCustomerContracts",
    "keyVendorContracts",
    "securityContracts",
    "otherMaterialAgreement",
  ];
  const documentsDictionary = {
    summary: "One Pager Summary",
    pitch: "Pitch deck",
    businessPlan: "Business Plan",
    competitorAnalysis: "Competitor Analysis",
    incomeStatement: "Monthly Income Statement (Last 3 years)",
    balanceSheet: "Monthly Balance Sheet (Last 3 years)",
    cashFlowStatement: "Monthly Cash Flow Statement (Last 3 Years)",
    auditReport: "Audit Report (Last 3 years)",
    mis: "Company MIS",
    financialModel: "Financial Model",
    invoiceDetail: "Invoice Detail",
    salesPipelineMetrics: "Historical Sales Pipeline Metrics",
    marketingExpenses: "Marketing Expenses / Performance",
    profitabilityMetrics: "Profitability Metrics",
    otherMetrics: "Any other Metrics",
    directorsProfiles: "Directors Profile",
    managementTeamProfile: "Management Team Profile",
    organizationChart: "Organization Chart",
    memorandum: "Memorandum & Articles of Association",
    certificateIncorporation: "Certificate of Incorporation",
    taxIdentificationDocuments: "Company ID - PAN/TAN",
    sharePurchaseAgreements: "Share Purchase Agreements",
    debtAgreements: "Debt Agreements (if any)",
    capitalizationTable: "Capitalization Table",
    taxReturns: "Tax Returns (Last 3 Years)",
    employeeCensus: "Employee Census",
    employeeStockOptionPlan: "Employee Stock Option Plan (if any)",
    employmentAgreements: "Employment Agreements",
    productOverview: "Product Overview",
    productRoadmap: "Product Roadmap",
    recordedProductDemo: "Recorded Product Demo",
    trademarks: "Trademarks",
    patents: "Granted & Filed Patents",
    keyCustomerContracts: "Key Customer Contracts",
    keyVendorContracts: "Key Vendor Contracts",
    securityContracts: "securityContracts",
    otherMaterialAgreement: "Any other material agreement",
  };
  const fetchDetails = async () => {
    if (!startupID) return;
    let response = null;
    try {
      loader.start();
      response = await fetchStartupDetailsById(startupID, token);
      loader.stop();
    } catch (error) {
      loader.stop();
    }
    if (response?.data !== null) {
      const data = response.data;
      const {
        summary,
        pitch,
        businessPlan,
        competitorAnalysis,
        incomeStatement,
        balanceSheet,
        cashFlowStatement,
        auditReport,
        mis,
        financialModel,
        invoiceDetail,
        salesPipelineMetrics,
        marketingExpenses,
        profitabilityMetrics,
        otherMetrics,
        directorsProfiles,
        managementTeamProfile,
        organizationChart,
        memorandum,
        certificateIncorporation,
        taxIdentificationDocuments,
        sharePurchaseAgreements,
        debtAgreements,
        capitalizationTable,
        taxReturns,
        employeeCensus,
        employeeStockOptionPlan,
        employmentAgreements,
        productOverview,
        productRoadmap,
        recordedProductDemo,
        trademarks,
        patents,
        keyCustomerContracts,
        keyVendorContracts,
        securityContracts,
        otherMaterialAgreement,
      } = data;
      if (data) {
        if (data) {
          const fields = [
            summary,
            pitch,
            businessPlan,
            competitorAnalysis,
            incomeStatement,
            balanceSheet,
            cashFlowStatement,
            auditReport,
            mis,
            financialModel,
            invoiceDetail,
            salesPipelineMetrics,
            marketingExpenses,
            profitabilityMetrics,
            otherMetrics,
            directorsProfiles,
            managementTeamProfile,
            organizationChart,
            memorandum,
            certificateIncorporation,
            taxIdentificationDocuments,
            sharePurchaseAgreements,
            debtAgreements,
            capitalizationTable,
            taxReturns,
            employeeCensus,
            employeeStockOptionPlan,
            employmentAgreements,
            productOverview,
            productRoadmap,
            recordedProductDemo,
            trademarks,
            patents,
            keyCustomerContracts,
            keyVendorContracts,
            securityContracts,
            otherMaterialAgreement,
          ];
          const newFileInfo = {};
          stringFields.forEach((field, index) => {
            if (fields[index]) {
              const urls = [];
              const fileUrls = [];

              fields[index].length > 0 &&
                fields[index].map((file, index) => {
                  const newFileInfoEntry = {
                    filename: fetchBlobName(file),
                    fullName: fetchFullFileName(file),
                    size: "NA",
                    fileURL: file,
                  };
                  urls.push(newFileInfoEntry);
                  fileUrls.push(file);

                  setUploadedDocuments((prev) => ({
                    ...prev,
                    [field]: urls,
                  }));
                });
              newFileInfo[field] = urls; // Store array of file info objects for each field
            }
          });
          setFileInfo((prevState) => ({
            ...prevState,
            ...newFileInfo,
          }));
        }
      }
    }
  };
  useEffect(() => {
    fetchDetails();
  }, [startupID]);
  return (
    <div className="documents">
      <p className="investorDetails-subheading">Documents</p>
      <div className="Documents-box">
        {stringFields.some(
          (field) => (fileInfo[field] && fileInfo[field].length) > 0
        ) ? (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      width: "40%",
                      fontFamily: "karla,sans-serif",
                      fontWeight: 600,
                    }}
                  >
                    Document Type
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "karla,sans-serif",
                      fontWeight: 600,
                    }}
                  >
                    Files
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {stringFields.map((field) => {
                  if (fileInfo[field] && fileInfo[field].length > 0) {
                    return (
                      <TableRow key={field}>
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{ fontFamily: "Karla, sans-serif" }}
                        >
                          {documentsDictionary[field]}
                        </TableCell>
                        <TableCell>
                          <div className="pitch-review-files">
                            {fileInfo[field].map((file, fileIndex) => (
                              <div
                                key={fileIndex}
                                className="fileUploads"
                                style={{ width: "fit-content" }}
                              >
                                <img
                                  src={FileImg}
                                  className="FileImg"
                                  alt="File Icon"
                                />
                                <div className="fileUploads-div-2">
                                  <div
                                    className="fileUploads-div-3"
                                    style={{
                                      width: "fit-content",
                                    }}
                                  >
                                    <a href={file.fileURL}>{file.filename}</a>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  }
                  return null;
                })}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <div>No Documents Attached</div>
        )}
      </div>
    </div>
  );
};

export default CompanyUploadedFiles;
