import React from "react";

const MSAAgreement = ({ setCardType, termsRefs }) => {
  const navigateToTerms = (ref, agreementRef) => {
    setCardType("TnC");
    setTimeout(() => {
      agreementRef.current.scrollTo({
        top: ref.current.offsetTop - agreementRef.current.offsetTop,
        behavior: "smooth",
      });
    }, 100);
  };
  return (
    <div className="mx-auto p-5 h-[100%] overflow-scroll border border-gray-300 rounded-[24px]">
      <p className="mb-4 text-[14px] text-[#696f79]">
        This Master Service Agreement (“Agreement”) is entered into by and
        between Fundrev (“Platform”) and the Fundraiser, collectively referred
        to as “Parties”. The Fundraiser agrees that this MSA, together with the
        Terms of Use for Fundraisers (“ToU”), collectively constitute the entire
        agreement and are binding upon the signatories. By continuing to use the
        Platform, the Fundraiser confirms acceptance of the following terms.
      </p>

      <h2 className="text-[18px] font-semibold mb-2">1. Acceptance of Terms</h2>
      <ul className="list-disc pl-6 mb-6 text-[14px] text-[#696f79]">
        <li>
          I confirm that I have read and understood the terms of this Agreement,
          the Master Service Agreement (MSA), and the Terms of Use. I agree that
          by clicking 'I Agree,' I am entering into a binding legal contract
          with Fundrev, voluntarily and with full understanding of its terms.
        </li>
      </ul>

      <h2
        onClick={() =>
          navigateToTerms(termsRefs.definitionsRef, termsRefs.agreementRef)
        }
        className="text-[18px] font-semibold mb-2 text-blue-500 hover:underline cursor-pointer"
      >
        2. Definitions
      </h2>
      <ul className="list-disc pl-6 mb-6 text-[14px] text-[#696f79]">
        <li>
          I acknowledge that 'Fundraiser' refers to any individual, company, or
          entity using the Platform to raise funds through primary or secondary
          financial transactions, including but not limited to equity, venture
          debt, M&A, or other financial instruments.
        </li>
        <li>
          I understand that a 'Transaction Fee' means a 2% fee payable on each
          fund transfer received from an Investor through the Platform, and this
          fee applies to all funding rounds and stages.
        </li>
        <li>
          I confirm that the term 'Investor' refers to any person or entity who
          invests or commits to investing in the Fundraiser via the Platform.
        </li>
      </ul>

      <h2
        onClick={() =>
          navigateToTerms(termsRefs.transactionFeeRef, termsRefs.agreementRef)
        }
        className="text-[18px] font-semibold mb-2 text-blue-500 hover:underline cursor-pointer"
      >
        3. Payment of Fees
      </h2>
      <ul className="list-disc pl-6 mb-6 text-[14px] text-[#696f79]">
        <li>
          I agree to pay a 2% fee on every transaction where funds are received
          from an Investor through the Platform. This fee applies to
          milestone-based payments, periodic payments, multiple-investor rounds,
          and M&A transactions.
        </li>
        <li>
          I understand that for milestone-based funding, the 2% fee applies to
          each payment received upon completion of the milestone.
        </li>
        <li>
          I understand that for periodic payments, the 2% fee applies to the
          entire committed amount upon the receipt of the first payment.
        </li>
        <li>
          I acknowledge that for multiple investors, the 2% fee applies to each
          round or stage of funding, including any subsequent rounds initiated
          by the Investor met on the Platform.
        </li>
      </ul>

      <h2
        onClick={() =>
          navigateToTerms(termsRefs.saleOfCompany, termsRefs.agreementRef)
        }
        className="text-[18px] font-semibold mb-2 text-blue-500 hover:underline cursor-pointer"
      >
        4. Applicability of Fees in Sale of Company (M&A)
      </h2>
      <ul className="list-disc pl-6 mb-6 text-[14px] text-[#696f79]">
        <li>
          I agree that if the transaction results in the sale or transfer of the
          company (M&A), the 2% fee applies to the total sale amount, and this
          includes all asset classes, such as equity, venture debt, or other
          financial instruments.
        </li>
      </ul>

      <h2
        onClick={() =>
          navigateToTerms(termsRefs.mandatoryDisclosure, termsRefs.agreementRef)
        }
        className="text-[18px] font-semibold mb-2 text-blue-500 hover:underline cursor-pointer"
      >
        5. Obligation to Disclose Information
      </h2>
      <ul className="list-disc pl-6 mb-6 text-[14px] text-[#696f79]">
        <li>
          I agree to disclose all necessary steps related to the funding
          process, from the initial stage to the culmination of the transaction.
          I understand that failure to provide accurate and complete information
          may result in termination of the Agreement or other remedies available
          to Fundrev.
        </li>
      </ul>

      <h2
        onClick={() =>
          navigateToTerms(termsRefs.nonSolicitationRef, termsRefs.agreementRef)
        }
        className="text-[18px] font-semibold mb-2 text-blue-500 hover:underline cursor-pointer"
      >
        6. Non-Solicitation
      </h2>
      <ul className="list-disc pl-6 mb-6 text-[14px] text-[#696f79]">
        <li>
          I agree not to solicit any Investor, employee, or advisor associated
          with Fundrev or introduced through the Platform for a period of 24
          months following the termination of this Agreement. I understand that
          violation of this clause may result in legal liability, including
          claims for damages.
        </li>
      </ul>

      <h2
        onClick={() =>
          navigateToTerms(termsRefs.confidentialityRef, termsRefs.agreementRef)
        }
        className="text-[18px] font-semibold mb-2 text-blue-500 hover:underline cursor-pointer"
      >
        7. Confidentiality
      </h2>
      <ul className="list-disc pl-6 mb-6 text-[14px] text-[#696f79]">
        <li>
          I agree to maintain the confidentiality of any non-public information
          obtained from the Platform, Fundrev, or the Investor during the use of
          the Platform. I understand that failure to comply with this obligation
          may result in termination and legal action.
        </li>
      </ul>

      <h2 className="text-[18px] font-semibold mb-2">8. Right of Refusal</h2>
      <ul className="list-disc pl-6 mb-6 text-[14px] text-[#696f79]">
        <li>
          I acknowledge that both Fundrev and I retain the right to refuse any
          negotiations or discussions with prospective investors or third
          parties without any liability or the need to provide an explanation.
        </li>
      </ul>

      <h2
        onClick={() =>
          navigateToTerms(
            termsRefs.disputeResolutionRef,
            termsRefs.agreementRef
          )
        }
        className="text-[18px] font-semibold mb-2 text-blue-500 hover:underline cursor-pointer"
      >
        9. Governing Law and Jurisdiction
      </h2>
      <ul className="list-disc pl-6 mb-6 text-[14px] text-[#696f79]">
        <li>
          I agree that this Agreement shall be governed by and construed in
          accordance with the laws of India.
        </li>
        <li>
          I agree that any disputes arising out of or in connection with this
          Agreement, including any question regarding its existence, validity,
          or termination, shall be referred to and finally resolved by
          arbitration in accordance with the Arbitration and Conciliation Act,
          1996, as amended from time to time.
        </li>
        <li>
          The seat and venue of arbitration shall be Bangalore, India, and the
          proceedings shall be conducted in English.
        </li>
        <li>
          I understand that the courts located in Bangalore, India, shall have
          exclusive jurisdiction over any matters that cannot be resolved
          through arbitration.
        </li>
      </ul>

      <h2
        onClick={() =>
          navigateToTerms(termsRefs.agreementRef, termsRefs.agreementRef)
        }
        className="text-[18px] font-semibold mb-2 text-blue-500 hover:underline cursor-pointer"
      >
        10. Entire Agreement
      </h2>
      <ul className="list-disc pl-6 mb-6 text-[14px] text-[#696f79]">
        <li>
          I confirm that this Agreement, along with the MSA and Terms of Use,
          collectively form the entire Agreement between Fundrev and me.
        </li>
      </ul>

      <h2
        onClick={() =>
          navigateToTerms(termsRefs.bindingNature, termsRefs.agreementRef)
        }
        className="text-[18px] font-semibold mb-2 text-blue-500 hover:underline cursor-pointer"
      >
        11. Binding Nature of Terms
      </h2>
      <ul className="list-disc pl-6 mb-6 text-[14px] text-[#696f79]">
        <li>
          I acknowledge that the terms of this agreement extend to any
          authorized non-signatories, agents, or representatives acting on my
          behalf or Fundrev's behalf. Both parties agree that agents are bound
          by the agreement's terms, and Fundrev retains the right to enforce
          these terms against non-signatories acting on behalf of either party.
        </li>
      </ul>

      <h2 className="text-[18px] font-semibold mb-2">
        12. MSA-TOU Integration Clause
      </h2>
      <ul className="list-disc pl-6 mb-6 text-[14px] text-[#696f79]">
        <li>
          I agree that the Master Service Agreement (MSA) and Terms of Use
          collectively form a legally binding agreement. These documents govern
          the entirety of my relationship with Fundrev as a Fundraiser. The MSA
          outlines core obligations, rights, and responsibilities, while the
          Terms of Use, which are uploaded on Fundrev's website, detail the
          operational guidelines and legal standards for platform participation.
          Together, these documents create a complete and binding contract,
          enforceable under the law.
        </li>
      </ul>
    </div>
  );
};

export default MSAAgreement;
