import { atom, useResetRecoilState } from "recoil";
import { useCallback } from "react";

const loadState = () => {
  try {
    const serializedState = localStorage.getItem("investorState");
    if (serializedState === null) {
      return {
        name: "",
        activeBtn: 1,
        investorID: "",
        notificationBarOpen: false,
        ProfileBoxOpen: false,
        wishListOpen: false,
        profileCompleted: false,
      };
    }
    const state = JSON.parse(serializedState);
    return {
      ...state,
      notificationBarOpen:
        state.notificationBarOpen !== undefined
          ? state.notificationBarOpen
          : false,
      ProfileBoxOpen:
        state.ProfileBoxOpen !== undefined ? state.ProfileBoxOpen : false,
      wishListOpen:
        state.wishListOpen !== undefined ? state.wishListOpen : false,
      profileCompleted:
        state.profileCompleted !== undefined ? state.profileCompleted : false,
    };
  } catch (err) {
    return {
      name: "",
      activeBtn: "",
      investorID: "",
      notificationBarOpen: false,
      ProfileBoxOpen: false,
      wishListOpen: true,
      profileCompleted: false,
    };
  }
};

export const investorState = atom({
  key: "investorState",
  default: loadState(),
});

export const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem("investorState", serializedState);
  } catch (err) {
    // Ignore write errors.
  }
};

export const interactedDealsState = atom({
  key: "interactedDealsState", // unique ID (with respect to other atoms/selectors)
  default: [], // default value (aka initial value)
});

export const useInvestorLogout = () => {
  const resetCompanyState = useResetRecoilState(investorState);
  const resetInteractedDealsState = useResetRecoilState(interactedDealsState);

  return useCallback(() => {
    localStorage.removeItem("investorState");

    // Reset Recoil state
    resetCompanyState();
    resetInteractedDealsState();
  }, [resetCompanyState, resetInteractedDealsState]);
};
