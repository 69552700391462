import axios from "axios";
import Toast from "../Components/Common/Toast";

const backend_url = process.env.REACT_APP_BACKEND_URL;

export const prodVMHealthCheck = async () => {
  try {
    const response = await axios.get(`https://api.fundrev.ai/healthz`);
    return response;
  } catch (error) {
    return { data: null, error: error };
  }
};

export const dbHealthCheck = async () => {
  try {
    const response = await axios.get(
      `${backend_url}/fundrevAnalyst/mongoHealthz`
    );
    return response;
  } catch (error) {
    return { data: null, error: error };
  }
};

export const devVMHealthCheck = async () => {
  try {
    const response = await axios.get(`https://api.dev.fundrev.ai/healthz`);
    return response;
  } catch (error) {
    return { data: null, error: error };
  }
};

export const adminLogin = async (signInData, token) => {
  try {
    const response = await axios.post(
      `${backend_url}/auth/admin-signin`,
      signInData,
      {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    );
    return { data: response.data, error: null };
  } catch (error) {
    console.error("Error uploading file:", error);
    return { data: null, error: error };
  }
};

export const fetchStartupData = async (token) => {
  try {
    const response = await axios.get(
      `${backend_url}/startup/getStartupsData`,
      {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
      //   "http://localhost:3000/startup/getStartupsData"
    );
    return { data: response.data.message.startups, error: null };
  } catch (error) {
    console.error("Error fetching data:", error);
    return { data: null, error: error };
  }
};

export const setToggleApproval = async (id, DealID, token) => {
  try {
    const response = await fetch(
      `${backend_url}/startup/toggleapproval/${id}/${DealID}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error toggling approval:", error);
  }
};
export const setToggleApprovalInvestor = async (id, token) => {
  try {
    const response = await fetch(
      `${backend_url}/investor/toggleapproval/${id}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
  } catch (error) {
    console.error("Error toggling approval:", error);
  }
};

export const checkDealExist = async (startupId, token) => {
  try {
    const response = await axios.get(
      `${backend_url}/deals/existDeal/${startupId}`,
      {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
      // `http://localhost:3000/deals/existDeal/${startupId}`
    );
    return { data: response.data, error: null };
  } catch (error) {
    return { data: null, error: error };
  }
};

export const setDealToInvestor = async (investorId, dealId, type, token) => {
  try {
    const response = await axios.post(
      `${backend_url}/deals/setDealToInvestor/`,
      // "http://localhost:3000/deals/setDealToInvestor/",
      {
        investorId,
        dealId, // Assuming `deal` is the current deal object
        type,
      },
      {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    );
    return { data: response.data, error: null };
  } catch (error) {
    return { data: null, error: error };
  }
};

export const getInvestorIdIndustryVerticals = async (dealId, token) => {
  try {
    const response = await axios.get(
      `${backend_url}/deals/getInvestorIndustry/${dealId}`,
      {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
      // `http://localhost:3000/deals/getInvestorIndustry/${dealId}`
    );
    return { data: response.data, error: null };
  } catch (error) {
    return { data: null, error: error };
  }
};

export const clearDealIdIndustryVerticals = async (
  investorId,
  dealId,
  token
) => {
  try {
    const response = await axios.post(
      `${backend_url}/deals/clearDealIndustry/`,
      // "http://localhost:3000/deals/clearDealIndustry/",
      {
        investorId,
        dealId, // Assuming `deal` is the current deal object
      },
      {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    );
    return { data: response.data, error: null };
  } catch (error) {
    return { data: null, error: error };
  }
};

export const uploadCustomerTape = async (formData, token) => {
  try {
    const response = await axios.post(
      // `${backend_url}/fundrevAnalyst/uploadCustomerTape/`,
      `${backend_url}/fundrevAnalyst/uploadCustomerTape/`,
      formData,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return { data: response.data, error: null };
  } catch (error) {
    console.error("Error uploading file:", error);
    Toast("Error in uploading file.", "error", "analyticsMapper");
    return { data: null, error: error };
  }
};

export const uploadCashFlow = async (formData, token) => {
  try {
    const response = await axios.post(
      // `${backend_url}/fundrevAnalyst/uploadCashFlowStatement/`,
      `${backend_url}/fundrevAnalyst/uploadCashFlowStatement/`,
      formData,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return { data: response.data, error: null };
  } catch (error) {
    console.error("Error uploading file:", error);
    Toast("Error in uploading file.", "error", "analyticsMapper");
    return { data: null, error: error };
  }
};
export const uploadBalanceSheet = async (formData, token) => {
  try {
    const response = await axios.post(
      // `${backend_url}/fundrevAnalyst/uploadBalanceSheet/`,
      `${backend_url}/fundrevAnalyst/uploadBalanceSheet/`,
      formData,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return { data: response.data, error: null };
  } catch (error) {
    console.error("Error uploading file:", error);
    Toast("Error in uploading file.", "error", "analyticsMapper");
    return { data: null, error: error };
  }
};

export const uploadFinancialStatement = async (formData, token) => {
  try {
    const response = await axios.post(
      // `${backend_url}/fundrevAnalyst/uploadFinancialStatement/`,
      `${backend_url}/fundrevAnalyst/uploadFinancialStatement/`,
      formData,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return { data: response.data, error: null };
  } catch (error) {
    console.error("Error uploading file:", error);
    Toast("Error in uploading file.", "error", "analyticsMapper");
    return { data: null, error: error };
  }
};

export const getCompanyMapper = async (dealId) => {
  try {
    const response = await axios.get(
      `${backend_url}/analytics/getCompanyMapper/${dealId}`
      // `${backend_url}/analytics/getCompanyMapper/${dealId}`
    );
    return { data: response.data.data, error: null };
  } catch (error) {
    console.error("Error fetching company mapper:", error);
    return { data: null, error: error };
  }
};

export const updateCompanyMapper = async (dealId, updatedMapper) => {
  try {
    const response = await axios.post(
      `${backend_url}/analytics/updateCompanyMapper/${dealId}`,
      // `${backend_url}/analytics/updateCompanyMapper/${dealId}`,
      updatedMapper
    );
    return { data: response.data.data, error: null };
  } catch (error) {
    console.error("Error updating company mapper:", error);
    return { data: null, error: error };
  }
};

export const searchUnifiedInvestors = async (query) => {
  try {
    const response = await axios.get(
      // `${backend_url}/fundrevAnalyst/searchUnifiedInvestor?investor=${query}`
      `${backend_url}/fundrevAnalyst/searchUnifiedInvestor?investor=${query}`
    );
    return { data: response.data, error: null };
  } catch (error) {
    console.error("Error searching unified investors:", error);
    return { data: null, error: error };
  }
};
