import React, { useEffect, useState } from "react";
import Sidebar from "./Components/sidebar";
import Topbar from "./Components/topbar";
import OnGoingDealCard from "./Components/onGoingDealCard";
import Card from "../../Common/components/Card";
import { getDealsByInvestorID, getDealByStage } from "../../../endpoints/deal";
import { fetchInvestorMemberDetails } from "../../../endpoints/investor";
import { reducedStages } from "../../Common/functions";

import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import moment from "moment-timezone";

import onGoingDealsBg2 from "../../../Assets/Images/InvestorPage/Dashboard/onGoingDealsBg2.svg";
import onGoingDealsBg3 from "../../../Assets/Images/InvestorPage/Dashboard/onGoingDealsBg3.svg";
import staytuned from "../../../Assets/Images/InvestorPage/Dashboard/stayTuned.svg";

import "../../../CSS/InvestorPage/Dashboard/onGoingDeals.css";

import {
  formatDistanceToNow,
  parseISO,
  isWithinInterval,
  subDays,
} from "date-fns";
import { useLoader } from "../../Common/LoaderProvider";

const OngoingDeals = () => {
  const loader = useLoader();
  const Navigate = useNavigate();
  const token = localStorage.getItem("token");
  const [name, setName] = useState("");
  const [investorId, setInvestorId] = useState("");
  const [deals, setDeals] = useState([]);
  const [dealDetails, setDealDetails] = useState({});
  const [selectedTab, setSelectedTab] = useState("currentDeals");
  // const open = localStorage.getItem("isApplicationReviewed");
  // useEffect(() => {
  //   if (open === "false") {
  //     Navigate("/investorDetails");
  //   }
  // });
  // const [filteredDeals, setFilteredDeals] = useState([]);
  function dealsLength(type) {
    try {
      const length = type.length ? type.length : 0;
      return length.toString().padStart(2, "0");
    } catch (error) {
      return "00";
    }
  }
  const fetchDetails = async () => {
    const token = localStorage.getItem("token");
    let response;
    if (token) {
      response = await fetchInvestorMemberDetails(token);
    }
    if (token) {
      if (response && response.data !== null) {
        setName(response.data.data.name);
        setInvestorId(response.data.data.investorID);
      } else {
      }
    }
  };
  useEffect(() => {
    loader.start("Fetching deals...");
    fetchDetails();
  }, [token]);

  useEffect(() => {
    const fetchDeals = async () => {
      if (investorId) {
        try {
          loader.start("Fetching deals...");
          const response = await getDealsByInvestorID(investorId, token);
          setDeals(response.data);
        } catch (error) {
          loader.stop();
        }
      }
    };

    fetchDeals();
  }, [investorId]);

  useEffect(() => {
    const fetchDealDetails = async () => {
      const details = {};
      if (token) {
        for (const deal of deals) {
          try {
            const response = await getDealByStage(deal.DealID, token);
            details[deal.DealID] = response.data;
          } catch (error) {}
        }
        setDealDetails(details);
        loader.stop();
      }
      setDealDetails(details);
    };
    if (deals) {
      fetchDealDetails();
    } else if (deals.length === 0 && investorId) {
      loader.stop();
    }
  }, [deals, token]);

  const stageRenameMap = {
    requestSent: "Screening",
    ioiSubmitted: "Indication of Interest",
    loiSubmitted: "Letter of Intent",
    dealClosed: "Deal Close",
  };
  const stageMap = {
    requestSent: "requestSent",
    requestAccepted: "requestSent",
    ioiSubmitted: "ioiSubmitted",
    ioiAccepted: "ioiSubmitted",
    loiSubmitted: "loiSubmitted",
    loiAccepted: "loiSubmitted",
    spaNegotiation: "dealClosed",
    dealClosed: "dealClosed",
  };
  const groupedDeals = reducedStages.reduce((acc, stage) => {
    acc[stage] = deals
      ? deals.filter(
          (deal) =>
            stageMap[deal.Stage] === stage &&
            deal.IsInterested !== false &&
            deal.isRejected !== false
        )
      : [];
    return acc;
  }, {});

  const getFormattedDate = (date) => {
    const createdAt = new Date(date);
    const tenDaysAgo = subDays(new Date(), 10);
    const isWithinLastTenDays = isWithinInterval(createdAt, {
      start: tenDaysAgo,
      end: new Date(),
    });

    if (isWithinLastTenDays) {
      return createdAt.toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "short",
        year: "numeric",
      });
    } else {
      return formatDistanceToNow(parseISO(date), { addSuffix: true });
    }
  };

  const renderDealCard = (deal, stage, index) => {
    const details = dealDetails[deal.DealID];
    if (!details) return null;
    // const latestActivity = getFormattedDate(deal.createdAt);
    const latestActivity = moment(deal.createdAt)
      .tz("Asia/Kolkata")
      .format("D MMM YYYY");
    let EndMarketFocus;

    try {
      EndMarketFocus = JSON.parse(details.EndMarketFocus);
    } catch (error) {
      EndMarketFocus = details.EndMarketFocus;
    }
    return (
      <OnGoingDealCard
        index={index}
        key={deal.DealID}
        requestedDate={stage === "requestSent" ? latestActivity : undefined}
        submittedDate={
          stage === "ioiSubmitted" || stage === "loiSubmitted"
            ? latestActivity
            : undefined
        }
        acceptedDate={
          stage === "requestAccepted" ||
          stage === "ioiAccepted" ||
          stage === "loiAccepted"
            ? latestActivity
            : undefined
        }
        IOIBidSubmittedAmount={
          stage === "ioiSubmitted"
            ? deal?.IOI_Bid_Details?.investmentAmount
            : undefined
        }
        IOIBidAcceptedAmount={
          stage === "ioiAccepted"
            ? deal?.IOI_Bid_Details?.investmentAmount
            : undefined
        }
        LOIBidSubmittedAmount={
          stage === "loiSubmitted"
            ? deal?.LOI_Agreement_Details?.investmentAmount
            : undefined
        }
        LOIBidAcceptedAmount={
          stage === "loiAccepted"
            ? deal.LOI_Agreement_Details.investmentAmount
            : undefined
        }
        closingDate={stage === "dealClosed" ? latestActivity : undefined}
        deal={details}
        stage={deal.Stage}
        isInterested={deal.IsInterested}
        isRejected={deal.IsRejected}
      />
    );
  };

  const handleTabClick = (tab) => {
    setSelectedTab(tab);
  };
  // const filteredDeals = () => {
  //   switch (selectedTab) {
  //     case "allDeals":
  //       return deals;
  //     case "currentDeals":
  //       return deals ? deals.filter((deal) => deal.IsInterested === true) : "";
  //     case "deadDeals":
  //       return deals
  //         ? deals.filter(
  //             (deal) => deal.IsInterested === false || deal.IsRejected === true
  //           )
  //         : "";
  //     default:
  //       return deals;
  //   }
  // };

  return (
    <div className="InvestorSide onGoingDeals-section newOngoingDeals">
      <Helmet>
        <title>Investor | Ongoing Deals</title>
      </Helmet>
      <Sidebar active="ongoingDeals" />
      <Topbar title="Ongoing Deals" />
      <div className="InvestorSide-box">
        <div className="InvestorSide-content">
          <div className="onGoingDeals-div">
            {reducedStages.map((stage, index) => (
              <div key={index} className="onGoingDeals-col" id={stage}>
                <div className="onGoingDeals-types-div">
                  <div className="onGoingDeals-types-div-2">
                    <div className="onGoingDeals-types-div-3">
                      <div className="onGoingDeals-types-div-4">
                        {stageRenameMap[stage]}
                      </div>
                      <div className="onGoingDeals-types-div-5">
                        {dealsLength(groupedDeals[stage])}
                      </div>
                    </div>
                  </div>
                  <div className="onGoingDeals-allCards">
                    {groupedDeals[stage].map((deal, index) =>
                      renderDealCard(deal, stage, index)
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
    // <div className="InvestorSide">
    //   <Helmet>
    //     <title>Investor | Ongoing Deals</title>
    //   </Helmet>
    //   <Sidebar active="ongoingDeals" />
    //   <Topbar title="Ongoing Deals" />
    //   <div className="InvestorSide-box">
    //     <div className="InvestorSide-content">
    //       <p className="stay-tuned-text">
    //         Stay Tuned <span className="blue-text">:)</span>
    //       </p>
    //       <p className="under-construnction-text">
    //         We are under construction...
    //       </p>
    //       <div className="img-box">
    //         <img src={staytuned}></img>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
};

export default OngoingDeals;
