import React, { useState, useEffect } from "react";
import Header from "../../Common/components/header";
import CommentBox from "./commentBox";
import DetailedCardHeader from "./detailedCardHeader";
import {
  getDealIdbyStartupId,
  getDealDetailsByDealId,
} from "../../../endpoints/deal";
import { ToastContainer } from "react-toastify";
import Overview from "../../Investor/Dashboard/companyDetails/overview";
import Dataroom from "../../Investor/Dashboard/companyDetails/dataroom";
import NoNamePreview from "../../Common/components/NoNamePreview";

import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilValue, useRecoilState } from "recoil";
import { companyOnBoardingState, saveState } from "./companyOnBoardingState";

import previous from "../../../Assets/Images/signup/previous.svg";
import Next from "../../../Assets/Images/signup/next.svg";
import reqEdit from "../../../Assets/Images/FounderPage/reqedit.svg";
import commentIcon from "../../../Assets/Images/FounderPage/commentIcon.svg";
import AdminAnalytics from "../../Admin/Company/AdminAnalytics";
import { useLoader } from "../../Common/LoaderProvider";
const ProfilePreview = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { startupID, DealID, token, analystID } = location.state || {};
  const { numMessages } = useRecoilValue(companyOnBoardingState);
  const [dealId, setDealId] = useState(DealID || null);
  const [deal, setDeal] = useState(null);
  const [cardType, setCardType] = useState("noNameCard");
  const { activeBtn } = useRecoilValue(companyOnBoardingState);
  const [state, setState] = useRecoilState(companyOnBoardingState);
  const loader = useLoader();

  const handleOpen = () => {
    const newState = { ...state, notificationBarOpen: true };
    setState(newState);
    saveState(newState); // Save to local storage
  };

  const handleCardTypeChnage = (event, newCardType) => {
    setCardType(newCardType);
  };

  const getDealId = async () => {
    try {
      if (startupID) {
        const response = await getDealIdbyStartupId(startupID, token);
        setDealId(response.data.dealId);
      }
    } catch (error) {}
  };
  const getDealDetails = async () => {
    try {
      const response = await getDealDetailsByDealId(dealId, token);
      setDeal(response.data);
      loader.stop();
    } catch (error) {
      loader.stop();
    }
  };
  useEffect(() => {
    loader.start();
    getDealId();
  }, [startupID]);
  useEffect(() => {
    if (dealId) {
      getDealDetails();
    } else {
      loader.stop();
    }
  }, [dealId]);
  const navigateToCompanyDetails = () => {
    navigate("/companyDetails", {
      state: {
        tkn: token,
      },
    });
  };
  useEffect(() => {
    if ((!startupID && !DealID) || !token) {
      navigate("/company/fundraising");
    }
  }, [startupID, token, analystID]);
  // useEffect(() => {
  //     if (windowWidth < 1300) {
  //       document.getElementsByClassName("InvestorSide")[0].style.zoom = "75%";
  //       document.getElementsByClassName("InvestorSide-box")[0].style.height = "calc(133.33vh - 100px)";
  //       document.getElementsByClassName("showInterestButtons-div")[0].style.marginLeft = "calc(133.33vw - 1610px)";
  //       document.getElementsByClassName("cm-header-wrap")[0].style.marginLeft = "calc(133.33vw - 1610px)";
  //       document.getElementsByClassName("company-logo")[0].style.left = "calc(10vw)";
  //     } else {
  //       document.getElementsByClassName("InvestorSide")[0].style.zoom = "100%";
  //       document.getElementsByClassName("InvestorSide-box")[0].style.height = "calc(100vh - 100px)";
  //       document.getElementsByClassName("showInterestButtons-div")[0].style.marginLeft = "calc(50% - 750px)";
  //       document.getElementsByClassName("cm-header-wrap")[0].style.marginLeft = "calc(50vw - 700px)";
  //       document.getElementsByClassName("company-logo")[0].style.left = "0px";
  //       document.getElementsByClassName("company-logo")[0].style.marginLeft = "calc(50% - 700px)";
  //     }
  //   }, [windowWidth]);

  // useEffect(() => {
  // const handleResize = () => {
  //     setWindowWidth(window.innerWidth);
  // };

  // window.addEventListener("resize", handleResize);

  // return () => {
  //     window.removeEventListener("resize", handleResize);
  // };
  // }, []);

  return (
    <div className="company-profile-preview investorDetailsPage">
      <div className="InvestorSide">
        <ToastContainer position="top-center" containerId="profilePreview" />
        <Header color="black" token="Startuptoken" type="Startup" />
        <CommentBox dealId={dealId} token={token} analystID={analystID} />
        <div
          className="InvestorSide-box"
          style={{ margin: "100px auto 0px auto" }}
        >
          <div className="InvestorSide-content">
            <div className="cdetails-div" style={{ marginBottom: "100px" }}>
              <div className="Preview-div">
                <p className="cdetails-div-2">Preview</p>
                <div className="cdetails-first-div">
                  <ToggleButtonGroup
                    color="primary"
                    value={cardType}
                    exclusive
                    onChange={handleCardTypeChnage}
                    aria-label="card-type"
                    className="toggle-card-type-btn"
                  >
                    <ToggleButton
                      value="noNameCard"
                      sx={{
                        textTransform: "capitalize",
                      }}
                    >
                      No Name Card
                    </ToggleButton>
                    <ToggleButton
                      value="detailedCard"
                      sx={{
                        textTransform: "capitalize",
                      }}
                    >
                      Detailed Card
                    </ToggleButton>
                  </ToggleButtonGroup>
                  {numMessages > 0 ? (
                    <div onClick={handleOpen} className="comments-div">
                      <p>
                        <img src={commentIcon} />
                        &nbsp; {numMessages} comments
                      </p>
                    </div>
                  ) : (
                    <div onClick={handleOpen} className="request-to-edit-btn">
                      <img
                        src={reqEdit}
                        className="reqEdit"
                        alt="request to edit"
                      />
                      &nbsp;&nbsp; No comments yet
                    </div>
                  )}
                </div>
              </div>
              {cardType === "noNameCard" ? (
                <NoNamePreview deal={deal} stage={"companyOnBoarding"} />
              ) : (
                <div className="detailed-card">
                  <DetailedCardHeader
                    activeBtn={1}
                    from={"companyOnBoarding"}
                    dealId={dealId}
                    token={token}
                    analystID={analystID}
                  />
                  {activeBtn === 1 && (
                    <Overview deal={deal} stage={"companyReview"} />
                  )}
                  {activeBtn === 2 && <AdminAnalytics deal={deal} />}
                  {activeBtn === 3 && (
                    <Dataroom
                      stage={"loiPending"}
                      deal={deal}
                      role={analystID ? "fundrevAnalyst" : "company"}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        {analystID ? (
          ""
        ) : (
          <div className="showInterestButtons-div">
            <div className="btns-insideContent">
              <div
                className="previousStep"
                style={{ visibility: "hidden" }}
                onClick={() => navigate(-1)}
              >
                <img src={previous} alt="back-arrow" />
                &nbsp;&nbsp; Previous Step
              </div>
              <div
                className="showInterestButtons-div-3"
                onClick={navigateToCompanyDetails}
                style={{ display: "flex", alignItems: "center" }}
              >
                Next Step&nbsp;&nbsp;{" "}
                <img src={Next} className="next" alt="Next" />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProfilePreview;
