import React, { useState, useEffect } from "react";
import Toast from "../Common/Toast";
import { fetchStartupData, checkDealExist } from "../../endpoints/admin";
import CreateOrAssignDealModal from "../Common/modals/createOrAssignDealModal";
import { getAllEditRequests, toggleOpenListing } from "../../endpoints/deal";
import { getAllQnAs } from "../../endpoints/qnA";

import { Box, Modal, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import Verify from "../../Assets/Images/signup/InvestorRegister/Verify.png";

const Companies = () => {
  const token = localStorage.getItem("token");
  const fundrevAnalystID = "FN123456";
  const navigate = useNavigate();
  const verificationToken = localStorage.getItem("token");
  const [startups, setStartups] = useState([]);
  const [dealId, setDealId] = useState({});
  const [, setPublishStatus] = useState({});
  const [openListingStatus, setOpenListingStatus] = useState({});
  const [open, setOpen] = useState({});
  const [qnAs, setqnAs] = useState([]);
  const [, setEditRequests] = useState({});
  const [selectedCompanyID, setSelectedCompanyID] = useState("");
  const [createOrAssignDealModalOpen, setCreateOrAssignDealModalOpen] =
    useState(false);
  useEffect(() => {
    fetchData();
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const fetchData = async () => {
    try {
      const response = await fetchStartupData(token);
      if (response && response.data) {
        setStartups(response.data);
        // Check if deals exist for each startup and update the publish status
        response.data.forEach(async (startup) => {
          const dealsExistResponse = await checkDealExist(
            startup.startupID,
            token
          );

          setPublishStatus((prevState) => ({
            ...prevState,
            [startup.startupID]: dealsExistResponse.data.dealsExist,
          }));
          setOpenListingStatus((prevState) => ({
            ...prevState,
            [startup.startupID]: dealsExistResponse.data.openListing,
          }));
          setDealId((prev) => ({
            ...prev,
            [startup.startupID]: dealsExistResponse.data.dealId,
          }));
        });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const AssignDeal = async (id, approval) => {
    if (approval) {
      Toast("Already approved the startup", "error", "adminPanel");
      return;
    } else {
      setSelectedCompanyID(id);
      setCreateOrAssignDealModalOpen(open);
    }
  };
  async function toggleStatus(DealID) {
    try {
      await toggleOpenListing(DealID, token);

      fetchData();
    } catch (error) {
      Toast("Error getting NoNameDeals", "error", "adminPanel");
    }
  }
  const fetchEditRequests = async () => {
    try {
      if (verificationToken) {
        const response = await getAllEditRequests(verificationToken);
        if (response && response.data) {
          setEditRequests(response.data);
        }
      }
    } catch (error) {
      console.error("Error fetching edit requests:", error);
    }
  };
  const QnACount = ({ startupID }) => {
    const [qnACount, setQnACount] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
      let count = 0;
      if (qnAs) {
        count = qnAs?.filter((doc) => doc.askedBy === startupID).length;
      }
      setQnACount(count);
    }, [startupID]);

    return qnACount !== null ? (
      qnACount > 0 ? (
        <div
          className="toggleStatusbtn Pending-btn"
          onClick={() =>
            navigate("/admin/answerQnAs", {
              state: {
                startupID: startupID,
                fundrevAnalystID: "FN123456",
              },
            })
          }
        >
          <p>&nbsp; {qnACount} questions</p>
        </div>
      ) : (
        <p>No comments</p>
      )
    ) : (
      <p>Loading...</p>
    );
  };

  const fetchAllQnAs = async (fundrevAnalystID) => {
    if (fundrevAnalystID && token) {
      const response = await getAllQnAs(fundrevAnalystID, verificationToken);
      setqnAs(response.data);
      try {
        if (response.response.data === "No qnA found") {
          return;
        }
      } catch (error) {}
    } else {
      return;
    }
  };
  useEffect(() => {
    fetchAllQnAs(fundrevAnalystID);
  }, [fundrevAnalystID]);
  useEffect(() => {
    fetchEditRequests();
  }, [verificationToken]);
  return (
    <div className="table-container">
      <CreateOrAssignDealModal
        open={createOrAssignDealModalOpen}
        setOpen={setCreateOrAssignDealModalOpen}
        id={selectedCompanyID}
        fetchData={fetchData}
      />
      <table>
        <thead>
          <tr>
            <th>Startup Name</th>
            <th>Company website</th>
            <th>Startup ID</th>
            <th>Deal ID</th>
            <th>Deal assign</th>
            <th>OnBoarding Completed</th>
            <th>Deal details</th>
            <th>Open Listing</th>
            <th>Answer QnAs</th>
            <th>Investors</th>
          </tr>
        </thead>
        <tbody>
          {startups.map((startup) => (
            <>
              <tr key={startup._id}>
                <td>{startup.companyName}</td>
                <td>{startup.website}</td>
                <td>{startup.startupID}</td>
                <td>
                  {dealId[startup.startupID] ? dealId[startup.startupID] : "-"}
                </td>
                <td>
                  <button
                    className={`toggleStatusbtn ${
                      startup.isApproved ? "Approved-btn" : "Pending-btn"
                    }`}
                    onClick={() => AssignDeal(startup._id, startup.isApproved)}
                  >
                    {startup.isApproved ? "Assigned" : "Not assigned"}
                  </button>
                </td>
                <td>
                  {startup.onBoardingComplete
                    ? moment(startup.updatedAt)
                        .tz("Asia/Kolkata")
                        .format("DD/MM/YYYY h:mm A")
                    : "-"}
                </td>
                <td>
                  {dealId[startup.startupID] ? (
                    <div
                      className="toggleStatusbtn"
                      style={{ width: "fit-content" }}
                      onClick={() => {
                        navigate("/admin/dealDetailsEdit", {
                          state: {
                            dealID: dealId[startup.startupID],
                            startupID: startup.startupID,
                          },
                        });
                      }}
                    >
                      Edit
                    </div>
                  ) : (
                    <div>Deal not created</div>
                  )}
                </td>
                <td>
                  {dealId[startup.startupID] ? (
                    <button
                      className={`toggleStatusbtn ${
                        openListingStatus[startup.startupID]
                          ? "Approved-btn"
                          : "Pending-btn"
                      }`}
                      onClick={() => toggleStatus(dealId[startup.startupID])}
                    >
                      {openListingStatus[startup.startupID]
                        ? "Visible"
                        : "Not Visible"}
                    </button>
                  ) : (
                    "Deal not created"
                  )}
                </td>
                <td>
                  {dealId ? <QnACount startupID={startup.startupID} /> : null}
                </td>
                <td>
                  {dealId[startup.startupID] ? (
                    <button
                      className="toggleStatusbtn"
                      onClick={() =>
                        navigate("/admin/investorStages", {
                          state: {
                            startupId: startup.startupID,
                            dealId: dealId[startup.startupID],
                            token: verificationToken,
                            fundrevAnalystID: "FN123456",
                            companyName: startup.companyName,
                          },
                        })
                      }
                    >
                      View
                    </button>
                  ) : (
                    "Deal not created yet"
                  )}
                </td>
                <Modal
                  open={open[startup.startupID]}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box className="modal-box">
                    <img src={Verify} alt="verify" className="verify-image" />
                    <Typography
                      id="modal-modal-title"
                      style={{ textAlign: "center" }}
                      variant="h5"
                    >
                      Do you really want to Unpublish this Deal?
                    </Typography>
                    <Typography id="modal-modal-description">
                      You might have interacted with investors.
                    </Typography>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                        gap: "50px",
                      }}
                    >
                      <Button className="" variant="outlined">
                        Yes
                      </Button>

                      <Button
                        className=""
                        variant="outlined"
                        color="error"
                        onClick={handleClose}
                      >
                        No
                      </Button>
                    </div>
                  </Box>
                </Modal>
              </tr>
            </>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Companies;
