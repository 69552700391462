import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { investorState, saveState } from "../investorState";

function CompanyDetailsSubheader(props) {
  const [activeBtn, setActiveBtn] = useState(props.activeBtn);
  const [state, setState] = useRecoilState(investorState);
  const handleActiveBtn = (activeBtn) => {
    const newState = { ...state, activeBtn: activeBtn };
    setState(newState);
    saveState(newState);
  };
  useEffect(() => {
    setActiveBtn(state.activeBtn);
  }, [state.activeBtn]);
  // const { type } = useParams();

  // const isActive = (section) => section === type;

  // const getCircleBgColor = (section) =>
  //   isActive(section) ? "bg-blue-700" : "bg-gray-500";

  return (
    <div className="flex flex-col pt-2 px-4 bg-slate-50 max-md:px-5 border-color-[#EBF0FA] border-b-2">
      <div className="flex flex-wrap gap-10 justify-between items-end w-full max-md:max-w-full">
        <div className="flex flex-col min-w-[240px] max-md:max-w-full">
          <div className="flex flex-wrap gap-6 items-center text-sm min-h-[32px] max-md:max-w-full">
            <div
              className="flex gap-1 items-center self-stretch my-auto whitespace-nowrap"
              onClick={() => {
                setActiveBtn(1);
                handleActiveBtn(1);
              }}
            >
              <div
                className={`self-stretch my-auto font-bold cursor-pointer ${
                  activeBtn === 1 ? "text-blue-700" : "text-gray-500"
                }`}
              >
                Overview
              </div>
            </div>

            <div
              className="flex gap-1 items-center self-stretch my-auto"
              onClick={() => {
                setActiveBtn(2);
                handleActiveBtn(2);
              }}
            >
              <div
                className={`self-stretch my-auto font-bold cursor-pointer ${
                  activeBtn === 2 ? "text-blue-700" : "text-gray-500"
                }`}
              >
                Analytics
              </div>
            </div>

            <div
              className="flex gap-1 items-center self-stretch my-auto font-semibold"
              onClick={() => {
                setActiveBtn(3);
                handleActiveBtn(3);
              }}
            >
              <div
                className={`self-stretch my-auto font-bold cursor-pointer ${
                  activeBtn === 3 ? "text-blue-700" : "text-gray-500"
                }`}
              >
                Dataroom
              </div>
            </div>

            <div
              className="flex gap-1 items-center self-stretch my-auto whitespace-nowrap"
              onClick={() => {
                setActiveBtn(4);
                handleActiveBtn(4);
              }}
            >
              <div
                className={`self-stretch my-auto font-bold cursor-pointer ${
                  activeBtn === 4 ? "text-blue-700" : "text-gray-500"
                }`}
              >
                Activity
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CompanyDetailsSubheader;
