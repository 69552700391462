import React, { useState, useEffect } from "react";
import "../../../../CSS/InvestorPage/Dashboard/profileBox.css";
import { fetchInvestorMemberDetails } from "../../../../endpoints/investor";
import { useRecoilState } from "recoil";
import { investorState, useInvestorLogout } from "../investorState";

import { Modal, Box } from "@mui/joy";

import { useLocation, useNavigate } from "react-router-dom";
import userProfile from "../../../../Assets/Images/InvestorPage/Dashboard/userProfile.jpg";
import CustomBlackBtn from "../../../Common/components/custom/customBlackBtn";
const ProfileBox = ({ modal }) => {
  const logout = useInvestorLogout();
  const [logoutModal, setLogoutModal] = useState(false);
  const location = useLocation();
  const Navigate = useNavigate();
  const [investorProfile, setInvestorProfile] = useState("");
  const [state, setState] = useRecoilState(investorState);
  const token = localStorage.getItem("token");

  useEffect(() => {
    const fetchDetails = async () => {
      let response;
      if (token) {
        response = await fetchInvestorMemberDetails(token);
      }
      if (token) {
        if (response && response.data !== null) {
          const newState = {
            ...state,
            name: response.data.data.name,
            investorId: response.data.data.investorID,
          };
          setInvestorProfile(response.data.data.memberImg);
          setState(newState);
        } else {
        }
      }
    };

    fetchDetails();
  }, [token, investorState, modal]);
  useEffect(() => {
    if (location.pathname === "/investor/account") {
      const ele = document.getElementsByClassName("profile-btn")[0];
      if (ele) {
        ele.style.backgroundColor = "#D2DCFF";
        ele.style.borderBottom = "none";
      }
    }
  }, [location.pathname]);
  const handleLogout = () => {
    logout();
    localStorage.removeItem("token");
    localStorage.removeItem("onBoardingComplete");
    localStorage.removeItem("role");
    Navigate("/signin", {
      state: {
        type: "Investor",
      },
    });
  };
  return (
    <div
      className="profile-box z-30"
      style={{ display: modal ? "block" : "none" }}
    >
      <Modal
        open={logoutModal}
        onClose={() => setLogoutModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal-box" style={{ width: "400px", height: "170px" }}>
          Are you sure you want to logout?
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              gap: "10px",
              marginTop: "20px",
              width: "100%",
              alignItems: "center",
            }}
          >
            <div onClick={() => setLogoutModal(false)}>
              <CustomBlackBtn text="No" type="button" color="#fff" />
            </div>
            <div onClick={handleLogout}>
              <CustomBlackBtn text="Yes" type="button" />
            </div>
          </Box>
        </Box>
      </Modal>
      <div className="flex flex-col max-w-[179px] shadow-[4px_4px_12px_rgba(161,161,161,0.16)] rounded-[8px]">
        <div className="flex overflow-hidden gap-2 items-center p-4 w-full bg-white rounded-[8px]">
          <img
            loading="lazy"
            srcSet={
              state.memberImg
                ? state.memberImg
                : investorProfile === undefined || investorProfile === ""
                ? userProfile
                : investorProfile
            }
            className="object-cover shrink-0 self-stretch my-auto w-10 h-10 rounded-[50%] border-[1px] border-[#8692A6];"
          />
          <div className="flex flex-col justify-center my-auto">
            <div className="text-base font-medium text-zinc-900 ">
              {state.name}
            </div>
            <div className="mt-1 text-xs text-gray-500">Investor</div>
          </div>
        </div>
        <div
          onClick={() => {
            Navigate("/investor/account", {
              state: {
                activeButton: 2,
              },
            });
          }}
          className="profile-btn flex overflow-hidden gap-10 items-center px-4 py-5 w-full text-base font-medium whitespace-nowrap bg-white border-y-[1px] border-[#000] min-h-[56px] text-zinc-900 cursor-pointer"
        >
          <div className="gap-4 self-stretch my-auto">Profile</div>
        </div>
        <div
          onClick={() => setLogoutModal(true)}
          className="flex overflow-hidden gap-10 items-center px-4 py-5 w-full text-base font-medium whitespace-nowrap bg-white min-h-[56px] text-zinc-900 rounded-[0px_0px_8px_8px] cursor-pointer"
        >
          <div className="gap-4 self-stretch my-auto">Logout</div>
        </div>
      </div>
    </div>
  );
};

export default ProfileBox;
