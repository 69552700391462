import React, { useState } from "react";

import { Box } from "@mui/material";

import Person from "../../../Assets/Images/signup/Person.svg";

const ProfileCard = ({ member, type }) => {
  const [showFullBio, setShowFullBio] = useState(false);

  const truncateText = (text, wordLimit) => {
    const words = text.split(" ");
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(" ") + "...";
    }
    return text;
  };

  const handleToggleBio = () => {
    setShowFullBio(!showFullBio);
  };

  const displayedBio = showFullBio
    ? member.biography
    : truncateText(member.biography, 18);
  return (
    <Box
      className="team-profile-div"
      sx={{ background: "#FAFAFA", border: "1px solid #CACACA" }}
    >
      <img
        loading="lazy"
        src={
          type === "investor"
            ? member.memberImg || Person
            : member.foundingTeamProfile || Person
        }
        className="team-profile-img"
        style={{
          width: "80px",
          height: "80px",
          objectFit: "cover",
        }}
      />

      <div className="team-profile-div-2">
        <div
          className="team-profile-div-3"
          style={{ textDecoration: "none", color: "#1A1A1A" }}
        >
          {member.name}
        </div>
        <div className="team-profile-div-4">{member.title}</div>
        <div className="team-profile-div-5">
          {displayedBio}
          {!showFullBio && member.biography.split(" ").length > 18 && (
            <span
              onClick={handleToggleBio}
              style={{ cursor: "pointer", color: "#00A6FB" }}
            >
              ... more
            </span>
          )}
          {showFullBio && (
            <span
              onClick={handleToggleBio}
              style={{ cursor: "pointer", color: "blue" }}
            >
              show less
            </span>
          )}
        </div>{" "}
      </div>
    </Box>
  );
};

export default ProfileCard;
