import React, { useState } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { Checkbox, FormControl, FormLabel, styled } from "@mui/material";
import CustomBlackBtnPhone from "../../Common/components/custom/customBlackBtnPhone";
import CustomBlackBtn from "../../Common/components/custom/customBlackBtn";
import CustomPasswordInput from "../../Common/components/custom/customPasswordInput";

const InvestorToolkitStep4 = ({ handleSubmit }) => {
  const initialValues = {
    password: "",
    confirmPassword: "",
    acceptTC: false,
  };
  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required("Password is required")
      .min(8, "Password must be at least 8 characters long")
      .matches(
        /(?=.*[a-z])/,
        "Password must contain at least one lowercase letter"
      )
      .matches(
        /(?=.*[A-Z])/,
        "Password must contain at least one uppercase letter"
      )
      .matches(/(?=.*[0-9])/, "Password must contain at least one number")
      .matches(
        /(?=.*[!@#$%^&*])/,
        "Password must contain at least one special character"
      ),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required"),
    acceptTC: Yup.boolean()
      .oneOf([true], "You must accept the terms and conditions")
      .required("You must accept the terms and conditions"),
  });
  const [allStates, setAllStates] = useState({
    lowerValidated: false,
    upperValidated: false,
    numberValidated: false,
    specialValidated: false,
    lengthValidated: false,
  });

  const receiveStatesFromChild = (states) => {
    setAllStates(states);
  };
  const StyledFormLabel = styled(FormLabel)`
    font-family: Karla;
    color: var(--Primary-Text-Color);
    font-size: 16px;
    font-weight: 600;
    ::after {
      content: " *";
      color: #d32f2f;
    }
  `;

  const StyledFormControl = styled(FormControl)`
    display: flex;
    gap: 8px;
  `;
  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          errors,
          touched,
          handleChange,
          handleBlur,
          values,
          setFieldValue,
        }) => (
          <Form
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "40px",
              width: "100%",
            }}
          >
            <div className="sign-up-input-fields">
              <StyledFormControl fullWidth>
                <StyledFormLabel>Create Password</StyledFormLabel>
                <CustomPasswordInput
                  placeholder="Enter password"
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                  error={Boolean(touched.password && errors.password)}
                  helperText={touched.password && errors.password}
                  sendStatesToParent={receiveStatesFromChild}
                />
              </StyledFormControl>

              <StyledFormControl fullWidth>
                <StyledFormLabel>Confirm Password</StyledFormLabel>
                <CustomPasswordInput
                  placeholder="Re-enter password"
                  name="confirmPassword"
                  value={values.confirmPassword}
                  onChange={handleChange}
                  error={Boolean(
                    touched.confirmPassword && errors.confirmPassword
                  )}
                  helperText={touched.confirmPassword && errors.confirmPassword}
                  sendStatesToParent={receiveStatesFromChild}
                />
              </StyledFormControl>
            </div>
            <div className="tnc-text-block laptop-design">
              <Checkbox
                className="checkbox"
                aria-label="Checkbox"
                sx={{
                  color: "#00a6fb",
                  "&.Mui-checked": {
                    color: "#00a6fb",
                  },
                }}
                name="acceptTC"
                checked={values.acceptTC}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(touched.acceptTC && errors.acceptTC)}
              />
              <p>
                By clicking this, I hereby acknowledge and confirm that I have
                read, understood, and fully accept the platform's
                <a
                  href="https://fundrev.ai/terms-of-use"
                  target="_blank"
                  style={{ color: "#00A6FB" }}
                >
                  &nbsp; terms of use
                </a>{" "}
                and
                <a
                  href="https://fundrev.ai/privacy-policy"
                  target="_blank"
                  style={{ color: "#00A6FB" }}
                >
                  &nbsp;privacy policy.
                </a>
              </p>
            </div>
            {touched.acceptTC && errors.acceptTC && (
              <div style={{ color: "red" }}>{errors.acceptTC}</div>
            )}
            <div className="sign-up-btn-section laptop-design">
              <CustomBlackBtn type="submit" text="Submit" />
            </div>

            <div className="sign-up-btn-section-phone phone-design">
              <div className="tnc-text-block">
                <Checkbox
                  className="checkbox"
                  aria-label="Checkbox"
                  sx={{
                    color: "#00a6fb",
                    "&.Mui-checked": {
                      color: "#00a6fb",
                    },
                  }}
                  name="acceptTC"
                  checked={values.acceptTC}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={Boolean(touched.acceptTC && errors.acceptTC)}
                />
                <p>
                  By clicking this, I hereby acknowledge and confirm that I have
                  read, understood, and fully accept the platform's
                  <a
                    href="https://fundrev.ai/terms-of-use"
                    target="_blank"
                    style={{ color: "#00A6FB" }}
                  >
                    &nbsp; terms of use
                  </a>{" "}
                  and
                  <a
                    href="https://fundrev.ai/privacy-policy"
                    target="_blank"
                    style={{ color: "#00A6FB" }}
                  >
                    &nbsp;privacy policy.
                  </a>
                </p>
              </div>
              <CustomBlackBtnPhone type="submit" text="Submit" />
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default InvestorToolkitStep4;
