import React, { useEffect, useState } from "react";
import { fetchInvestorMemberDetails } from "../../../../endpoints/investor";

import { useRecoilState } from "recoil";
import { investorState, saveState } from "../investorState";

import dropdownwhite from "../../../../Assets/Images/InvestorPage/dropdownwhite.svg";
import userProfile from "../../../../Assets/Images/InvestorPage/Dashboard/userProfile.jpg";
const CornerProfileBox = ({ setModal, modal }) => {
  const token = localStorage.getItem("token");
  const [investorProfile, setInvestorProfile] = useState("");
  const [state, setState] = useRecoilState(investorState);
  const handleProfileBoxOpen = () => {
    setModal(!modal);
  };
  useEffect(() => {
    const fetchDetails = async () => {
      if (token) {
        const response = await fetchInvestorMemberDetails(token);
        if (response && response.data !== null) {
          const { name, memberImg, investorId, investorMemberID } =
            response.data.data;
          const newState = {
            ...state,
            name,
            memberImg,
            investorId,
            investorMemberId: investorMemberID,
          };
          setInvestorProfile(memberImg);
          setState(newState);
          saveState(newState);
        }
      }
    };

    fetchDetails();
  }, [token]);
  return (
    <div className="investor-profile-div" onClick={handleProfileBoxOpen}>
      <img
        loading="lazy"
        srcSet={
          state.memberImg
            ? state.memberImg
            : investorProfile === undefined || investorProfile === ""
            ? userProfile
            : investorProfile
        }
        className="topbarimg-6"
      />
      <div className="topbardiv-9">
        <div className="topbardiv-10">
          {/* <div className="topbardiv-11">{state.name}</div> */}
          <img
            loading="lazy"
            src={dropdownwhite}
            className="topbarimg-7"
            style={{
              transform: modal ? "rotate(180deg)" : "rotate(0deg)",
            }}
          />
        </div>
        {/* <div className="topbardiv-12">Investor</div> */}
      </div>
    </div>
  );
};

export default React.memo(CornerProfileBox);
