import React from "react";
import {
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  Typography,
} from "@mui/material";

// Custom Select Component
const CustomSelect = ({
  label,
  name,
  value,
  onChange,
  error,
  options,
  placeholder,
  helperText,
  ...rest
}) => {
  return (
    <FormControl
      fullWidth
      error={error}
      {...rest}
      sx={{
        "& .MuiOutlinedInput-root": {
          borderRadius: "6px",
          borderWidth: "0.5px",
          "& fieldset": {
            borderColor: "#8692A6", // Custom border color
          },
          "&:hover fieldset": {
            borderColor: "#3C82F6", // Color on hover
          },
          "&.Mui-focused fieldset": {
            borderColor: "#3C82F6", // Color when focused
          },
        },
      }}
    >
      <Select
        name={name}
        value={value}
        onChange={onChange}
        displayEmpty
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: "300px",
            },
          },
        }}
        sx={{
          padding: "10px 16px",
          fontFamily: "'Karla', sans-serif",
          fontSize: "15px",
          color: value ? "#000" : "#A0A0A0", // Change color based on value
          "& .MuiSelect-select": {
            padding: "3px 4px", // Change this padding only
          },
        }}
      >
        <MenuItem value="" sx={{ color: "#A0A0A0" }}>
          <em>{placeholder}</em>
        </MenuItem>
        {options.map((option, index) => (
          <MenuItem
            key={index}
            value={option.value}
            style={{ fontFamily: "Karla" }}
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>
      <Typography
        sx={{
          color: "#d32f2f",
          fontFamily: "Karla, sans-serif",
          fontWeight: "400px",
          fontSize: "0.75rem",
          lineHeight: "1.66",
          letterSpacing: "0.033em",
          textAlign: "left",
          marginTop: "3px",
          marginX: "14px",
          marginBottom: 0,
        }}
      >
        {helperText}
      </Typography>
    </FormControl>
  );
};

export default CustomSelect;
