import axios from "axios";
const token = localStorage.getItem("token");

const backend_url = process.env.REACT_APP_BACKEND_URL;
export const getFundrevAnalystDetailsById = async (data) => {
  try {
    const response = await axios.post(
      `${backend_url}/fundrevAnalyst/getFundrevAnalystDetailsById`,
      data,
      {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    );
    return { data: response.data, error: null };
  } catch (error) {
    return {
      data: null,
      error: error.response ? error.response.data : error.message,
    };
  }
};

export const getCustomerTapeData = async (data, token) => {
  try {
    const response = await axios.post(
      `${backend_url}/fundrevAnalyst/getCustomerTape`,
      data,
      {
        headers: {
          Authorization: data.token,
          "Content-Type": "application/json",
        },
      }
    );
    return { data: response.data, error: null };
  } catch (error) {
    return {
      data: null,
      error: error.response ? error.response.data : error.message,
    };
  }
};

export const getFinancialStatementData = async (data, token) => {
  try {
    const response = await axios.post(
      `${backend_url}/fundrevAnalyst/getFinancialStatement`,
      data,
      {
        headers: {
          Authorization: data.token,
          "Content-Type": "application/json",
        },
      }
    );
    return { data: response.data, error: null };
  } catch (error) {
    return {
      data: null,
      error: error.response ? error.response.data : error.message,
    };
  }
};
