import React, { useEffect, useState } from "react";
import Logo from "../../../Assets/Images/LandingPage/FUNDREV_LOGO.svg";
import Back from "../../../Assets/Images/signup/back.svg";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";

import InvestorToolkitStep1 from "./investorToolkitStep1";
import InvestorToolkitStep2 from "./investorToolkitStep2";
import InvestorToolkitStep3 from "./investorToolkitStep3";
import {
  checkIfStartupAlreadyRegistered,
  saveInvestorToolkitLead,
  startupSignUp,
} from "../../../endpoints/startup";
import InvestorToolkitResult from "./investorToolkitResult";
import { Box, stepLabelClasses, styled, Typography } from "@mui/material";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import InvestorToolkitStep4 from "./investorToolkitStep4";
import { useLoader } from "../../Common/LoaderProvider";
import Toast from "../../Common/Toast";
import { ToastContainer } from "react-toastify";

const InvestorToolkit = () => {
  const [step, setStep] = useState(1);
  const [data, setData] = useState({});
  const loader = useLoader();
  const Navigate = useNavigate();
  const [alreadyExists, setAlreadyExists] = useState(false);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      // Standard message (some browsers may not display the custom message)
      const message =
        "Are you sure you want to leave? Your changes may not be saved.";
      event.returnValue = message; // Standard for most browsers
      return message; // Required for some older browsers
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: "9px",
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: "#D2DCFF",
    },
    [`& .${linearProgressClasses.bar}`]: {
      backgroundColor: "#00CD9E",
    },
  }));

  const saveData = async (data) => {
    const response = await saveInvestorToolkitLead(data);
  };

  const checkAlreadyRegistered = async (data) => {
    const response = await checkIfStartupAlreadyRegistered(data.email);
    if (response.status === 200) {
      const values = response.data.data;
      if (response.data.type === "existingStartup") {
        setAlreadyExists(true);
        setData({
          fullName: data.fullName,
          email: data.email,
          phoneNumber: data.phoneNumber,
          companyName: values.companyName,
          companyWebsite: values.website,
          businessDescription: values.description,
          industry: JSON?.parse(values.industryVerticals[0] || "[]")[0] || ``,
          endMarket: values.endMarket,
          revenue: values.revenue,
          fundingType: values.fundingType,
          timelineToRaise: values.timeline,
          roundType: JSON?.parse(values.roundType[0] || "[]")[0] || ``,
          fundingRequired: values.fundingRequired,
        });
      } else if (response.data.type === "existingLead") {
        setData(values);
      }
    } else {
      return;
    }
  };

  const saveCompanyRegisterDetails = async (data) => {
    const formData = {
      companyName: data.companyName,
      name: data.fullName,
      email: data.email,
      password: data.password,
      phoneNumber: data.phoneNumber,
      revenue: data.revenue,
      industryVerticals: [JSON.stringify([data.industry])],
      fundingType: data.fundingType,
      timeline: data.timelineToRaise,
      website: data.companyWebsite,
      description: data.businessDescription,
      roundType: [JSON.stringify([data.roundType])],
      endMarket: data.endMarket,
      fundingRequired: data.fundingRequired,
    };
    let responseData;
    try {
      loader.start("Signing up...");
      responseData = await startupSignUp(formData);
      loader.stop();
    } catch (error) {
      loader.stop();
    }
    const response = responseData?.data;
    if (
      response &&
      response?.data?.message === "Please verify your email with the code sent."
    ) {
      Navigate("/company/emailVerification", {
        state: {
          values: formData,
          startPoint: "investor-toolkit",
          startupToken: response.data.verificationToken,
          role: "startup",
        },
      });
    } else if (responseData.error.response.status === 409) {
      Toast(
        "Alerady Registered with this email. Please sign in.",
        "info",
        "investor-toolkit"
      );
      setTimeout(() => {
        Navigate("/signIn", {
          state: { sentemail: data.email, type: "Startup" },
        });
      }, 2000);
    } else {
      Toast(
        "Something went wrong. Please try again",
        "error",
        "investor-toolkit"
      );
    }
  };

  const getBottomText = () => {
    switch (step) {
      case 1:
        return "2 more pages left to complete";
      case 2:
        return "1 more page left to complete";
      case 3:
        return "Almost Done";
      default:
        return null;
    }
  };
  const getSubHeading = () => {
    switch (step) {
      case 1:
        return "Tell us about yourself";
      case 2:
        return "Tell us about what you are building";
      case 3:
        return "Tell us about your fundraising plans";
      default:
        return null;
    }
  };
  const getHeading = () => {
    switch (step) {
      case 1:
        return "Find Active Investors";
      case 2:
        return "Find Active Investors";
      case 3:
        return "Find Active Investors";
      case 5:
        return "Raise funds through Fundrev!";
      case 6:
        return "Raise funds through Fundrev!";
      default:
        return null;
    }
  };
  const getForm = () => {
    switch (step) {
      case 1:
        return <InvestorToolkitStep1 handleSubmit={handleSubmit} data={data} />;
      case 2:
        return <InvestorToolkitStep2 handleSubmit={handleSubmit} data={data} />;
      case 3:
        return <InvestorToolkitStep3 handleSubmit={handleSubmit} data={data} />;
      case 5:
        return <InvestorToolkitStep4 handleSubmit={handleSubmit} data={data} />;
      default:
        return null;
    }
  };
  const handleSubmit = async (values) => {
    switch (step) {
      case 1:
        setData((prev) => {
          return { ...prev, ...values };
        });
        await checkAlreadyRegistered(values);
        setStep(2);
        break;
      case 2:
        setData((prev) => {
          return { ...prev, ...values };
        });
        setStep(3);
        break;
      case 3:
        // Here you can handle the form submission
        setData((prev) => {
          return { ...prev, ...values };
        });
        saveData({ ...data, ...values });
        setStep(4);
        break;
      case 5:
        setData((prev) => {
          return { ...prev, ...values };
        });
        saveCompanyRegisterDetails({ ...data, ...values });
        break;
      default:
        break;
    }
  };
  return step === 4 ? (
    <InvestorToolkitResult
      industry={data.industry}
      setStep={setStep}
      alreadyExists={alreadyExists}
    />
  ) : (
    <>
      <div className="investor-sign-up-page">
        <Helmet>
          <title>Fundrev | Investor Toolkit</title>
        </Helmet>
        <ToastContainer
          position="top-center"
          containerId={"investor-toolkit"}
        />
        <div className="investor-sign-up-page-header">
          <Link to="/">
            <img src={Logo} alt="fundrev_logo" />
          </Link>
        </div>
        <div className="sign-up-block">
          <div className="heading-block">
            {step !== 1 && step !== 4 && (
              <img
                src={Back}
                alt="back"
                className="back-icon phone-design"
                onClick={() => setStep(step - 1)}
                style={{
                  position: "absolute",
                }}
              />
            )}
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: "10px",
              }}
            >
              <p className="fundrev-heading investor-register-heading">
                {getHeading()}
              </p>
              <Typography
                sx={{
                  fontFamily: "Roboto",
                  fontSize: "16px",
                  lineHeight: "35px",
                  color: "var(--Secondary-Text-Color)",
                }}
              >
                {getSubHeading()}
              </Typography>
            </Box>
          </div>
          <div className="middle-block">
            <div className="sign-up-left-section">
              {step !== 1 && step !== 4 && (
                <img
                  src={Back}
                  alt="back"
                  className="back-icon"
                  onClick={() => setStep(step - 1)}
                />
              )}
            </div>
            <div className={"sign-up-right-block"}>{getForm()}</div>
          </div>
        </div>
        {step <= 3 && (
          <Box
            sx={{
              position: "fixed",
              bottom: 0,
              width: "100%",
              "@media (max-width: 576px)": {
                display: "none",
              },
            }}
          >
            <Typography
              sx={{
                fontFamily: "Karla, sans-serif",
                fontSize: "14px",
                fontWeight: "100",
                color: "#696F79",
                marginLeft: "5px",
              }}
            >
              {getBottomText()}
            </Typography>
            <BorderLinearProgress
              variant="determinate"
              value={(step / 3) * 100}
            />
          </Box>
        )}
      </div>
    </>
  );
};

export default InvestorToolkit;
