import React from "react";
import { Link, useParams } from "react-router-dom";

import { FiFilter } from "react-icons/fi";

function SubHeader({
  recommendedDealsCount,
  wishListCount,
  noNameDealCount,
  otherDealsCount,
  filterBoxOpen,
  setFilterBoxOpen,
}) {
  const { type } = useParams();

  const isActive = (section) => section === type;

  const getCircleBgColor = (section) =>
    isActive(section) ? "bg-blue-700" : "bg-gray-500";

  return (
    <div className="flex flex-col pt-2 px-4 bg-slate-50 max-md:px-5 border-color-[#EBF0FA] border-b-2">
      <div className="flex flex-wrap gap-10 justify-between items-end w-full max-md:max-w-full">
        <div className="flex flex-col min-w-[240px] max-md:max-w-full">
          <div className="flex flex-wrap gap-6 items-center text-sm min-h-[32px] max-md:max-w-full">
            <Link to="/investor/newDeals/recommended">
              <div className="flex gap-1 items-center self-stretch my-auto whitespace-nowrap">
                <div
                  className={`self-stretch my-auto ${
                    isActive("recommended") ? "text-blue-700" : "text-gray-500"
                  }`}
                >
                  Recommended
                </div>
                <div
                  className={`gap-2 self-stretch my-auto px-1 py-0.5 tracking-wide text-white rounded-[50%] ${getCircleBgColor(
                    "recommended"
                  )}`}
                >
                  {recommendedDealsCount}
                </div>
              </div>
            </Link>
            <Link to="/investor/newDeals/otherDeals">
              <div className="flex gap-1 items-center self-stretch my-auto">
                <div
                  className={`self-stretch my-auto ${
                    isActive("otherDeals") ? "text-blue-700" : "text-gray-500"
                  }`}
                >
                  Other Deals
                </div>
                <div
                  className={`gap-2 self-stretch my-auto px-1 py-0.5 tracking-wide text-white rounded-[50%] ${getCircleBgColor(
                    "otherDeals"
                  )}`}
                >
                  {otherDealsCount}
                </div>
              </div>
            </Link>
            <Link to="/investor/newDeals/allListing">
              <div className="flex gap-1 items-center self-stretch my-auto font-semibold">
                <div
                  className={`self-stretch my-auto ${
                    isActive("allListing") ? "text-blue-700" : "text-gray-500"
                  }`}
                >
                  All Listing
                </div>
                <div
                  className={`gap-2 self-stretch px-1 py-0.5 my-auto tracking-wide text-white rounded-2xl border border-solid ${getCircleBgColor(
                    "allListing"
                  )}`}
                >
                  {noNameDealCount}
                </div>
              </div>
            </Link>
            <Link to="/investor/newDeals/wishlist">
              <div className="flex gap-1 items-center self-stretch my-auto whitespace-nowrap">
                <div
                  className={`self-stretch my-auto ${
                    isActive("wishlist") ? "text-blue-700" : "text-gray-500"
                  }`}
                >
                  Wishlist
                </div>
                <div
                  className={`gap-2 self-stretch my-auto px-1 py-0.5 tracking-wide text-white rounded-[50%] ${getCircleBgColor(
                    "wishlist"
                  )}`}
                >
                  {wishListCount}
                </div>
              </div>
            </Link>
            <Link to="/investor/newDeals/archive">
              <div className="flex gap-1 items-center self-stretch my-auto whitespace-nowrap">
                <div
                  className={`self-stretch my-auto ${
                    isActive("archive") ? "text-blue-700" : "text-gray-500"
                  }`}
                >
                  Archive
                </div>
                <div
                  className={`gap-2 self-stretch my-auto px-1 py-0.5 tracking-wide text-white rounded-[50%] ${getCircleBgColor(
                    "archive"
                  )}`}
                >
                  00
                </div>
              </div>
            </Link>
          </div>
          <div className="flex flex-col mt-1.5 w-full max-md:max-w-full">
            <div className="flex relative flex-col items-start px-14 min-h-[2px] max-md:px-5 max-md:max-w-full"></div>
          </div>
        </div>
        <div
          className="flex gap-2.5 justify-center items-center px-2 w-11 h-11 rounded-2xl border border-solid cursor-pointer"
          onClick={() => setFilterBoxOpen(!filterBoxOpen)}
          style={{
            backgroundColor: filterBoxOpen ? "#114fee" : "#fff",
            border: filterBoxOpen ? "none" : "1px solid #114fee",
            color: filterBoxOpen ? "#fff" : "#114fee",
            marginBottom: "5px",
          }}
        >
          <FiFilter />
        </div>
      </div>
    </div>
  );
}

export default SubHeader;
