import React, { useEffect, useState } from "react";
import Message from "./message";
import {
  addDealInteraction,
  getDealInteraction,
} from "../../../endpoints/deal";
import { fetchStartupMemberDetails } from "../../../endpoints/startup";
import { fetchStartupMemberDetailsbyId } from "../../../endpoints/startup";
import { getFundrevAnalystDetailsById } from "../../../endpoints/fundrevAnalyst";
import { companyOnBoardingState, saveState } from "./companyOnBoardingState";
import Toast from "../../Common/Toast";

import { Textarea } from "@mui/joy";
import moment from "moment-timezone";
import { useRecoilState } from "recoil";
import { Modal, Slide } from "@mui/material";
import { ErrorOutlined } from "@mui/icons-material";

import sendMessage from "../../../Assets/Images/InvestorPage/Dashboard/addMessage.svg";
import file from "../../../Assets/Images/InvestorPage/Dashboard/file.svg";
import FileImg from "../../../Assets/Images/signup/file.svg";
import cross from "../../../Assets/Images/signup/cross.svg";

const CommentBox = ({ dealId, token, analystID }) => {
  const [Messages, setMessages] = useState([]);
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [startupMemberID, setStartupMemberID] = useState("");
  const [fundrevAnalystID, setFundrevAnalystID] = useState(analystID);
  const [state, setState] = useRecoilState(companyOnBoardingState);
  const [fileUploads, setFileUploads] = useState([]);
  const [fileInfo, setFileInfo] = useState({});

  const handleClose = () => {
    const newState = { ...state, notificationBarOpen: false };
    setState(newState);
    saveState(newState);
  };

  const setNumMessages = (numMessages) => {
    const newState = { ...state, numMessages };
    setState(newState);
    saveState(newState);
  };
  const handleEnterClick = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      addMessage();
    }
  };
  const handleFileUpload = (uploadType, e) => {
    const file = e.target.files[0];
    if (!file) return;
    fileUploads[uploadType] = 1;
    var filename = file.name;
    const fileURL = URL.createObjectURL(file);
    setFileInfo((prevState) => ({
      ...prevState,
      [uploadType]: { file, filename, fileURL },
    }));
  };
  const handleDeleteFile = (e, uploadType) => {
    fileUploads[uploadType] = 0;
    setFileInfo((prevState) => {
      const newState = { ...prevState };
      delete newState[uploadType];
      return newState;
    });
  };
  const fetchMemberDetails = async () => {
    if (!token) return;
    try {
      // If the user is fundrev analyst then fetch the details of the fundrev analyst
      if (fundrevAnalystID) {
        const response = await getFundrevAnalystDetailsById({
          fundrevAnalystID: fundrevAnalystID,
        });
        if (response.data !== null) {
          const responseData = response.data;
          const { data } = responseData;
          setEmail(data.email);
          setName(data.name);
        }
        return;
      }
      const response = await fetchStartupMemberDetails(token);
      if (response.data !== null) {
        const responseData = response.data;
        const { data } = responseData;
        setEmail(data.email);
        setName(data.name);
        setStartupMemberID(data.startupMemberID);
      }
    } catch (error) {}
  };
  useEffect(() => {
    fetchMemberDetails();
  }, [fundrevAnalystID]);

  const fetchMemberDetailsById = async (ID, role) => {
    try {
      if (role === "Startup") {
        const data = await fetchStartupMemberDetailsbyId({
          startupMemberID: ID,
          token: token,
        });
        return data;
      } else {
        const data = await getFundrevAnalystDetailsById({
          fundrevAnalystID: ID,
          token: token,
        });
        return data;
      }
    } catch (error) {}
  };
  const fetchMessages = async () => {
    try {
      if (dealId) {
        const data = {
          DealID: dealId,
          RoundType: "companyOnBoarding",
        };
        const response = await getDealInteraction(data, token);
        const fetchedMessages = response.data.data;
        setNumMessages(fetchedMessages.length);
        let messagesData = []; // Object to store messages by threadID

        // messgaes can be written by both investor and startup

        for (const message of fetchedMessages) {
          let memberDetailsdata;
          let memberDetails;
          let isStartup;
          if (message.startupMemberID) {
            memberDetailsdata = await fetchMemberDetailsById(
              message.startupMemberID,
              "Startup"
            );
            isStartup = true;
            memberDetails = memberDetailsdata.data.data.data;
          } else {
            memberDetailsdata = await fetchMemberDetailsById(
              message.fundrevAnalystID,
              "FundrevAnalyst"
            );
            isStartup = false;
            memberDetails = memberDetailsdata.data.data;
          }
          // We are not saving whether the message is saved ny an investor or statrto hence can be identified by the investorMemberID or startupMemberID (message.resolvedBy)
          const newMessage = {
            name: memberDetails.name,
            profilePhoto: isStartup
              ? memberDetails.memberImg
              : memberDetails.profile,
            message: message.message,
            messageID: message.messageID,
            threadID: message.threadID,
            startupMemberID: message.startupMemberID
              ? message.startupMemberID
              : null,
            file: message.file ? message.file : null,
            time: moment(message.createdAt)
              .tz("Asia/Kolkata")
              .format("MM/DD/YYYY h:mm A"), // Convert to IST and format
            role: isStartup ? "" : "Fundrev Analyst",
          };

          messagesData.push(newMessage);
        }
        setMessages(messagesData);
      }
    } catch (error) {
      console.error("Error fetching messages:", error);
    }
  };
  const addMessage = async () => {
    if (message || fileInfo["attachFile"]) {
      const formData = new FormData();
      formData.append("name", name);
      formData.append("message", message);
      formData.append("DealID", dealId);
      if (fileUploads["attachFile"]) {
        formData.append("file", fileInfo["attachFile"].file);
      }
      if (fundrevAnalystID) {
        formData.append("fundrevAnalystID", fundrevAnalystID);
      } else {
        formData.append("startupMemberID", startupMemberID);
      }
      formData.append("RoundType", "companyOnBoarding");

      const result = await addDealInteraction(formData, token);
      if (result.data.status === 201) {
        fetchMessages();
        setMessage("");
        setFileInfo({});
        setFileUploads([]);
      } else {
        Toast("Failed to add the message", "error");
      }
    }
  };
  useEffect(() => {
    fetchMessages();
  }, [dealId]);
  return (
    <Modal
      open={state.notificationBarOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      closeAfterTransition
    >
      <Slide
        direction="left"
        in={state.notificationBarOpen}
        mountOnEnter
        unmountOnExit
      >
        <div className="Notify-div">
          <div className="Notify-div-2">
            <div className="Notify-div-3">
              <div className="Notify-div-4">Comments</div>
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/6b07b7cd00d36c34b4f217f00e32fcf00c888d8cb668be2d1842f641b5588a92?apiKey=32c42a50fb8f44509096999a9c1a8276&"
                className="Notify-img"
                style={{ cursor: "pointer" }}
                onClick={handleClose}
              />
            </div>
          </div>
          <div className="messages-box">
            {Object.values(Messages).map((message, index) => (
              <Message
                key={index}
                name={message.name}
                role={message.role}
                time={message.time}
                message={message.message}
                file={message.file ? message.file : null}
                updatedAt={message.updatedAt}
                profilePhoto={message.profilePhoto}
                fetchMessages={fetchMessages}
              />
            ))}
          </div>
          {fileUploads["attachFile"] ? (
            <div
              className="NDA-document editRequest-file-upload"
              style={{ marginTop: "0px", marginBottom: "20px" }}
            >
              <div className="NDA-document-left">
                <img src={FileImg} className="FileImg" />
                <div className="NDA-document-name">
                  <p>{fileInfo["attachFile"].filename}</p>
                </div>
              </div>
              <div className="NDA-document-right">
                <img
                  className="attch-file-delete"
                  src={cross}
                  style={{ cursor: "pointer" }}
                  onClick={(e) => handleDeleteFile(e, "attachFile")}
                />
              </div>
            </div>
          ) : (
            ""
          )}
          <Textarea
            className="companyOnBoarding-comment-box"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            onKeyDown={handleEnterClick}
            placeholder="Add any comment …"
          />

          <div className="companyOnBoarding-add-msg-btn">
            <div
              class="upload-btn-wrapper attach-file-wrapper"
              style={{ marginRight: "10px" }}
            >
              <button className="upload-img-btn" style={{ cursor: "pointer" }}>
                <img loading="lazy" src={file} alt="Attach File" />
              </button>
              <input
                id="attachFile"
                type="file"
                onChange={(e) => handleFileUpload("attachFile", e)}
              />
            </div>
            <img
              loading="lazy"
              src={sendMessage}
              onClick={addMessage}
              className="add-comment-img-2"
            />
          </div>
        </div>
      </Slide>
    </Modal>
  );
};

export default CommentBox;
