import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import TimePeriodButton from "./TimePeriodButton";
import { formatToLakhs } from "../../../../../../utilities/numberFormatter";

const CashFlowSmallTable = ({ data }) => {
  const [type, setType] = useState("m");
  const [option, setOption] = useState(`previous${type}`);
  const keys = Object.keys(
    data?.data[type][Object.keys(data?.data[type])[0]].values
  );

  const [key, setKey] = useState(keys.slice(-1)[0]);
  const [ckey, setCKey] = useState(null);
  useEffect(() => {
    switch (option) {
      case "previousm":
        setCKey(keys.slice(-2)[0]);
        break;
      case "lastm":
        setCKey(keys.slice(-13)[0]);
        break;
      case "previousq":
        setCKey(keys.slice(-2)[0]);
        break;
      case "lastq":
        setCKey(keys.slice(-5)[0]);
        break;
      case "previousltm":
        setCKey(keys.slice(-13)[0]);
        break;
      default:
        setCKey(keys.slice(-1)[0]);
    }
  }, [data, key, ckey, option, keys]);
  useEffect(() => {
    setKey(
      Object.keys(
        data?.data[type][Object.keys(data?.data[type])[0]].values
      ).slice(-1)[0]
    );
  }, [type, data]);
  const rows = [
    "Operating Activity",
    "Investing Activity",
    "Financing Activity",
    "Net Change in Cash",
    "Beginning Cash",
    "Ending Cash",
  ];
  const IMPRows = ["Net Change in Cash", "Ending Cash"];
  function getVariance(row, last = false) {
    let a, b;
    if (last) {
      a = row[Object.keys(row)[0]][Object.keys(row)[0]][key];
      b = row[Object.keys(row)[0]][Object.keys(row)[0]][ckey];
    } else {
      a = data?.data[type][row].values[key];
      b = data?.data[type][row].values[ckey];
    }

    // Check if either 'a' or 'b' is zero to prevent division by zero
    if (a === 0 || b === 0) {
      return 0;
    }

    return ((a / b - 1) * 100).toFixed(1);
  }

  return (
    <>
      <Box
        sx={{
          backgroundColor: "#FFFFFF",
          width: "569px",
          height: "453px",
          borderRadius: "8px",
          boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.05)",
          fontFamily: "Karla, sans-serif",
          padding: 2,
          paddingRight: "11px",
          paddingLeft: 2.5,
        }}
      >
        <TimePeriodButton
          title={data?.name}
          type={type}
          setType={setType}
          select={true}
          ltm={true}
          year={false}
          option={option}
          setOption={setOption}
        />
        <Paper
          sx={{
            width: "529px",
            overflow: "hidden",
            boxShadow: "0",
            marginTop: "10px",
          }}
        >
          <TableContainer>
            <Table aria-label="cash flow summary table">
              <TableHead>
                <TableRow>
                  {["Summary", ckey, key, "% Change"].map((column, index) => (
                    <TableCell
                      sx={{
                        width: index === 0 ? "169px" : "125px",
                        paddingY: "11px",
                        fontFamily: "Karla, sans-serif",
                        fontWeight: 700,
                        fontSize: "14px",
                        whiteSpace: "nowrap",
                        background: "white",
                        border: "1px solid #B8B8B8",
                      }}
                      key={index}
                    >
                      <Box
                        sx={{
                          display: `flex`,
                          gap: "2px",
                          alignItems: "baseline",
                          justifyContent: index === 3 && "end",
                        }}
                      >
                        {column}
                        {index === 0 && (
                          <Typography
                            sx={{
                              fontFamily: `Karla, sans-serif`,
                              fontSize: "10px",
                              fontWeight: "700px",
                              color: "#6B7494",
                            }}
                          >
                            (in lakhs)
                          </Typography>
                        )}
                      </Box>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.slice(0, 3).map((row, index) => (
                  <TableRow key={index}>
                    <TableCell
                      sx={{
                        width: "169px",
                        paddingY: "11px",
                        fontFamily: "Karla, sans-serif",
                        fontWeight: IMPRows.includes(row) ? 700 : 400,
                        fontSize: "14px",
                        whiteSpace: "nowrap",
                        background: "white",
                        color: IMPRows.includes(row) && "#00A6FB",
                        border: "1px solid #B8B8B8",
                        fontStyle: "normal",
                        borderTop: IMPRows.includes(row) && "2px solid #00A6FB",
                      }}
                    >
                      {row}
                    </TableCell>
                    <TableCell
                      sx={{
                        width: "169px",
                        paddingY: "11px",
                        fontFamily: "Karla, sans-serif",
                        fontWeight: IMPRows.includes(row) ? 700 : 400,
                        fontSize: "14px",
                        whiteSpace: "nowrap",
                        background: "white",
                        color: IMPRows.includes(row) && "#00A6FB",
                        border: "1px solid #B8B8B8",
                        fontStyle: "normal",
                        borderTop: IMPRows.includes(row) && "2px solid #00A6FB",
                      }}
                    >
                      {formatToLakhs(
                        data?.data[type][Object.keys(data?.data[type])[index]]
                          .values[ckey]
                      ) || `-`}
                    </TableCell>
                    <TableCell
                      sx={{
                        width: "169px",
                        paddingY: "11px",
                        fontFamily: "Karla, sans-serif",
                        fontWeight: IMPRows.includes(row) ? 700 : 400,
                        fontSize: "14px",
                        whiteSpace: "nowrap",
                        background: "white",
                        color: IMPRows.includes(row) && "#00A6FB",
                        border: "1px solid #B8B8B8",
                        fontStyle: "normal",
                        borderTop: IMPRows.includes(row) && "2px solid #00A6FB",
                      }}
                    >
                      {formatToLakhs(
                        data?.data[type][Object.keys(data?.data[type])[index]]
                          .values[key]
                      ) || `-`}
                    </TableCell>
                    <TableCell
                      sx={{
                        width: "169px",
                        paddingY: "11px",
                        fontFamily: "Karla, sans-serif",
                        fontWeight: IMPRows.includes(row) ? 700 : 400,
                        fontSize: "14px",
                        whiteSpace: "nowrap",
                        border: "1px solid #B8B8B8",
                        fontStyle: "italic",
                        borderTop: IMPRows.includes(row) && "2px solid #00A6FB",
                        background:
                          getVariance(Object.keys(data?.data[type])[index]) > 0
                            ? "#4EF79A1A"
                            : "#D32F2F1A",
                        color:
                          getVariance(Object.keys(data?.data[type])[index]) > 0
                            ? "#00CD9E"
                            : "#D32F2F",
                      }}
                      align="right"
                    >
                      {getVariance(Object.keys(data?.data[type])[index])
                        ? getVariance(Object.keys(data?.data[type])[index]) +
                          `%`
                        : `-`}
                    </TableCell>
                  </TableRow>
                ))}
                {data?.data[type][Object.keys(data?.data[type])[3]].mappers.map(
                  (row, index) => (
                    <TableRow key={index}>
                      <TableCell
                        sx={{
                          width: "169px",
                          paddingY: "11px",
                          fontFamily: "Karla, sans-serif",
                          fontWeight: IMPRows.includes(rows[index + 3])
                            ? 700
                            : 400,
                          fontSize: "14px",
                          whiteSpace: "nowrap",
                          background: "white",
                          color: IMPRows.includes(rows[index + 3]) && "#00A6FB",
                          border: "1px solid #B8B8B8",
                          fontStyle: "normal",
                          borderTop:
                            IMPRows.includes(rows[index + 3]) &&
                            "2px solid #00A6FB",
                        }}
                      >
                        {rows[index + 3]}
                      </TableCell>
                      <TableCell
                        sx={{
                          width: "169px",
                          paddingY: "11px",
                          fontFamily: "Karla, sans-serif",
                          fontWeight: IMPRows.includes(rows[index + 3])
                            ? 700
                            : 400,
                          fontSize: "14px",
                          whiteSpace: "nowrap",
                          background: "white",
                          color: IMPRows.includes(rows[index + 3]) && "#00A6FB",
                          border: "1px solid #B8B8B8",
                          fontStyle: "normal",
                          borderTop:
                            IMPRows.includes(rows[index + 3]) &&
                            "2px solid #00A6FB",
                        }}
                      >
                        {formatToLakhs(
                          row[Object.keys(row)[0]][Object.keys(row)[0]][ckey]
                        ) || `-`}
                      </TableCell>
                      <TableCell
                        sx={{
                          width: "169px",
                          paddingY: "11px",
                          fontFamily: "Karla, sans-serif",
                          fontWeight: IMPRows.includes(rows[index + 3])
                            ? 700
                            : 400,
                          fontSize: "14px",
                          whiteSpace: "nowrap",
                          background: "white",
                          color: IMPRows.includes(rows[index + 3]) && "#00A6FB",
                          border: "1px solid #B8B8B8",
                          fontStyle: "normal",
                          borderTop:
                            IMPRows.includes(rows[index + 3]) &&
                            "2px solid #00A6FB",
                        }}
                      >
                        {formatToLakhs(
                          row[Object.keys(row)[0]][Object.keys(row)[0]][key]
                        ) || `-`}
                      </TableCell>
                      <TableCell
                        sx={{
                          width: "169px",
                          paddingY: "11px",
                          fontFamily: "Karla, sans-serif",
                          fontWeight: IMPRows.includes(rows[index + 3])
                            ? 700
                            : 400,
                          fontSize: "14px",
                          whiteSpace: "nowrap",
                          border: "1px solid #B8B8B8",
                          fontStyle: "italic",
                          borderTop:
                            IMPRows.includes(rows[index + 3]) &&
                            "2px solid #00A6FB",
                          background:
                            getVariance(row, true) > 0
                              ? "#4EF79A1A"
                              : "#D32F2F1A",
                          color:
                            getVariance(row, true) > 0 ? "#00CD9E" : "#D32F2F",
                        }}
                        align="right"
                      >
                        {getVariance(row, true)
                          ? getVariance(row, true) + `%`
                          : `-`}
                      </TableCell>
                    </TableRow>
                  )
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
    </>
  );
};

export default CashFlowSmallTable;
