import { Typography } from "@mui/material";
import React from "react";

const AnalyticsPageTitle = ({ title }) => {
  return (
    <Typography
      variant="h4"
      sx={{
        font: "Roboto, sans-serif",
        fontWeight: 500,
        fontSize: "30px",
      }}
    >
      {title}
    </Typography>
  );
};

export default AnalyticsPageTitle;
