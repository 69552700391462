import React from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { FormControl, FormLabel, styled } from "@mui/material";
import CustomBlackBtn from "../../Common/components/custom/customBlackBtn";
import CustomBlackBtnPhone from "../../Common/components/custom/customBlackBtnPhone";
import CustomInput from "../../Common/components/custom/customInput";
import CustomSelect from "../../Common/components/custom/customSelect";
const InvestorToolkitStep2 = ({ handleSubmit, data }) => {
  const initialValues = {
    companyName: data.companyName || "",
    companyWebsite: data.companyWebsite || "",
    businessDescription: data.businessDescription || "",
    industry: data.industry || "",
    endMarket: data.endMarket || "",
  };
  const validationSchema = Yup.object({
    companyName: Yup.string().required("Company Name is required"),

    companyWebsite: Yup.string()
      .matches(
        /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/,
        "Please enter a valid URL"
      )
      .required("Company Website is required"),
    businessDescription: Yup.string().required("Business description required"),
    industry: Yup.string().required("Industry is required"),
    endMarket: Yup.string().required("End Market is required"),
  });
  const StyledFormLabel = styled(FormLabel)`
    font-family: Karla;
    color: var(--Primary-Text-Color);
    font-size: 16px;
    font-weight: 600;
    ::after {
      content: " *";
      color: #d32f2f;
    }
  `;
  const StyledFormControl = styled(FormControl)`
    display: flex;
    gap: 8px;
  `;
  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          errors,
          touched,
          handleChange,
          handleBlur,
          values,
          setFieldValue,
        }) => (
          <Form
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "40px",
              width: "100%",
            }}
          >
            <div className="sign-up-input-fields">
              <StyledFormControl fullWidth>
                <StyledFormLabel>Company Name</StyledFormLabel>
                <CustomInput
                  placeholder="ABC Corporation"
                  name="companyName"
                  value={values.companyName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={Boolean(touched.companyName && errors.companyName)}
                  helperText={touched.companyName && errors.companyName}
                />
              </StyledFormControl>
              <StyledFormControl fullWidth>
                <StyledFormLabel>Company Website</StyledFormLabel>
                <CustomInput
                  placeholder="Enter website"
                  name="companyWebsite"
                  value={values.companyWebsite}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={Boolean(
                    touched.companyWebsite && errors.companyWebsite
                  )}
                  helperText={touched.companyWebsite && errors.companyWebsite}
                />
              </StyledFormControl>
            </div>
            <StyledFormControl fullWidth>
              <StyledFormLabel>Business Description</StyledFormLabel>
              <CustomInput
                placeholder="Describe your business"
                name="businessDescription"
                value={values.businessDescription}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(
                  touched.businessDescription && errors.businessDescription
                )}
                helperText={
                  touched.businessDescription && errors.businessDescription
                }
                multiline={true} // Enable multiline
                minRows={3}
              />
            </StyledFormControl>
            <div className="sign-up-input-fields">
              <StyledFormControl fullWidth>
                <StyledFormLabel>Industry</StyledFormLabel>
                <CustomSelect
                  label="Industry"
                  name="industry"
                  options={[
                    { value: "FinTech", label: "FinTech" },
                    { value: "eCommerce", label: "eCommerce" },
                    { value: "CleanTech", label: "CleanTech" },
                    { value: "TravelTech", label: "TravelTech" },
                    { value: "DeepTech", label: "DeepTech" },
                    { value: "EdTech", label: "EdTech" },
                    { value: "AgriTech", label: "AgriTech" },
                    { value: "Consumer", label: "Consumer" },
                    { value: "RealTech", label: "RealTech" },
                    { value: "HealthTech", label: "HealthTech" },
                    { value: "Logistics", label: "Logistics" },
                    { value: "Media", label: "Media" },
                    { value: "EnterpriseTech", label: "EnterpriseTech" },
                  ]}
                  value={values.industry}
                  onChange={handleChange}
                  placeholder="Select Industry"
                  error={Boolean(touched.industry && errors.industry)}
                  helperText={touched.industry && errors.industry}
                />
              </StyledFormControl>
              <StyledFormControl fullWidth>
                <StyledFormLabel>End Market</StyledFormLabel>
                <CustomSelect
                  label="End Market"
                  name="endMarket"
                  options={[
                    { value: "B2B", label: "B2B" },
                    { value: "B2C", label: "B2C" },
                    { value: "B2B2C", label: "B2B2C" },
                    { value: "D2C", label: "D2C" },
                  ]}
                  value={values.endMarket}
                  onChange={handleChange}
                  placeholder="Select End Market"
                  error={Boolean(touched.endMarket && errors.endMarket)}
                  helperText={touched.endMarket && errors.endMarket}
                />
              </StyledFormControl>
            </div>
            <div className="sign-up-btn-section laptop-design">
              <CustomBlackBtn type="submit" text="Continue" />
            </div>

            <div className="sign-up-btn-section-phone phone-design">
              <CustomBlackBtnPhone type="submit" text="Continue" />
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default InvestorToolkitStep2;
