import React, { useEffect, useState } from "react";
import {
  inputCustomStyles,
  inputCustomStylesAssestClass,
} from "../../../Common/functions";
import {
  getProfileCompletionPercentage,
  getValues,
} from "../../../../endpoints/investor";
import Editable from "../../../Common/components/editable";
import InputCell from "../../../Common/components/inputCell";
import Toast from "../../../Common/Toast";
import { investorState } from "../investorState";
import {
  fetchInvestorDetails,
  PostDetails,
} from "../../../../endpoints/investor";
import { isValidUrl } from "../../../Common/functions";
import { useRecoilState } from "recoil";

import { Checkbox, FormControl, FormLabel, Input } from "@mui/joy";
import Select from "react-select";
import { Helmet } from "react-helmet";
import { useRecoilValue } from "recoil";

import { RiDeleteBin6Line } from "react-icons/ri";
import { AiOutlinePlus } from "react-icons/ai";
import uploadImg from "../../../../Assets/Images/signup/upload-img.svg";
import editImg from "../../../../Assets/Images/signup/edit-img.svg";
import { ToastContainer } from "react-toastify";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import { useLoader } from "../../../Common/LoaderProvider";
import { FaFilterCircleXmark } from "react-icons/fa6";
import CustomBlackBtn from "../../../Common/components/custom/customBlackBtn";

const Profile = () => {
  const token = localStorage.getItem("token");
  const loader = useLoader();
  const [state, setState] = useRecoilState(investorState);
  const [industryVerticalOptions, setIndustryVerticalOptions] = useState([]);
  const [filteredOptions1, setFilteredOptions1] = useState([]);
  const [filteredOptions2, setFilteredOptions2] = useState([]);
  const [userData, setUserData] = useState({});
  const [idealFocusOptions, setIdealFocusOptions] = useState([]);
  const [moderatelySuitableOptions, setModeratelySuitableOptions] = useState(
    []
  );
  const [investorWebsiteError, setInvestorWebsiteError] = useState("");
  const [negativeListOptions, setNegativeListOptions] = useState([]);
  const [investorType, setinvestorType] = useState("");
  const [ticketSizevalue, setTicketSizeValue] = useState([]);
  const [totalExposure, setTotalExposure] = useState([]);
  const [stakeAcquired, setStakeAcquired] = useState([0, 0]);
  const [investmentStage, setInvestmentStage] = useState([]);
  const [assetClass, setAssetClass] = useState([]);
  const [coInvestment, setCoInvestment] = useState(false);
  const [financialCriteria, setFinancialCriteria] = useState([]);
  const [endMarketFocus, setEndMarketFocus] = useState([]);
  const [investorLogoFile, setInvestorLogoFile] = useState("");
  const [fileUploads, setFileUploads] = useState({});
  const [rows, setRows] = useState(3);
  const [formData, setFormData] = useState({
    investorLogo: investorLogoFile,
    companyName: "",
    headquarters: "",
    foundedYear: "",
    numEmployees: "",
    investorType: "",
    investorWebsite: "",
    investmentThesis: "",
    ticketSizevalue: [],
    totalExposure: [],
    stakeAcquired: [],
    investmentStage: [],
    assetClass: [],
    coInvestment: "",
    otherCriteria: "",
    financialCriteria: [],
    industryVerticalOptions: [],
    idealFocusOptions: [],
    moderatelySuitableOptions: [],
    negativeListOptions: [],
    endMarketFocus: [],
    ndaKey: "",
    investmentDetails: [
      {
        fund: "",
        vintage: "",
        investments: "",
        aum: "",
        dryPowder: "",
      },
    ],
    onBoardingComplete: false,
    investorID: "",
    keyInvestments: "",
  });
  const [InvestorTypeOptions, setInvestorTypeOptions] = useState([
    {
      value: "",
      label: "",
    },
  ]);
  const [stageOptions, setStageOptions] = useState([
    {
      value: "",
      label: "",
    },
  ]);
  const [AssetOptions, setAssetOptions] = useState([
    {
      value: "",
      label: "",
    },
  ]);
  const [CriteriaOptions, setCriteriaOptions] = useState([
    {
      value: "",
      label: "",
    },
  ]);
  const [industryFocusOptions, setIndustryFocusOptions] = useState([
    {
      value: "",
      label: "",
    },
  ]);
  const [endMarketFocusOptions, setEndMarketFocusOptions] = useState([
    {
      value: "",
      label: "",
    },
  ]);
  const columns = React.useMemo(
    () => [
      {
        Header: "Fund",
        accessor: "fund",
        placeholder: "fund",
        Cell: InputCell,
        width: 200, // Customize the width for this column
      },
      {
        Header: "Vintage",
        accessor: "vintage",
        placeholder: "vintage",
        Cell: InputCell,
        width: 150, // Customize the width for this column
      },
      {
        Header: "Investments",
        accessor: "investments",
        placeholder: "investments",
        Cell: InputCell,
        width: 250, // Customize the width for this column
      },
      {
        Header: "AUM (₹Cr)",
        accessor: "aum",
        placeholder: "aum",
        Cell: InputCell,
        width: 180, // Customize the width for this column
      },
      {
        Header: "Dry Powder (₹Cr)",
        accessor: "dryPowder",
        placeholder: "dryPowder",
        Cell: InputCell,
        width: 180, // Customize the width for this column
      },
      {
        id: "delete",
        Cell: ({ row }) =>
          row.index > 0 && (
            <RiDeleteBin6Line
              onClick={() => deleteRow(row.index)}
              className="red-bin"
            />
          ),
        width: 50,
      },
    ],
    []
  );
  useEffect(() => {
    const loadData = async () => {
      try {
        const result = await getValues(); // Assuming this fetches all data
        if (result.error) {
          console.error("Error fetching data:", result.error);
        } else {
          // Process each options set
          if (result.data[0]) {
            if (result.data[0].InvestorTypeOptions) {
              const formattedInvestorTypeOptions =
                result.data[0].InvestorTypeOptions.map((item) => ({
                  value: item,
                  label: item,
                }));
              setInvestorTypeOptions(formattedInvestorTypeOptions);
            }

            if (result.data[0].StageOptions) {
              const formattedStageOptions = result.data[0].StageOptions.map(
                (item) => ({
                  value: item,
                  label: item,
                })
              );
              setStageOptions(formattedStageOptions);
            }

            if (result.data[0].AssetOptions) {
              const formattedAssetOptions = result.data[0].AssetOptions.map(
                (item) => ({
                  value: item,
                  label: item,
                })
              );
              setAssetOptions(formattedAssetOptions);
            }

            if (result.data[0].CriteriaOptions) {
              const formattedCriteriaOptions =
                result.data[0].CriteriaOptions.map((item) => ({
                  value: item,
                  label: item,
                }));
              setCriteriaOptions(formattedCriteriaOptions);
            }

            if (result.data[0].IndustryFocusOptions) {
              const formattedIndustryFocusOptions =
                result.data[0].IndustryFocusOptions.map((item) => ({
                  value: item,
                  label: item,
                }));
              setIndustryFocusOptions(formattedIndustryFocusOptions);
            }

            if (result.data[0].EndMarketFocusOptions) {
              const formattedEndMarketFocusOptions =
                result.data[0].EndMarketFocusOptions.map((item) => ({
                  value: item,
                  label: item,
                }));
              setEndMarketFocusOptions(formattedEndMarketFocusOptions);
            }
          }
        }
      } catch (error) {
        console.error("Unexpected error fetching data:", error);
      }
    };

    loadData();
  }, []);
  const fetchDetails = async () => {
    if (!token) return;
    let response;
    try {
      loader.start("Fetching investor details...");
      response = await fetchInvestorDetails(token);
      loader.stop();
    } catch (error) {
      loader.stop();
    }
    if (response?.data !== null) {
      const responseData = response.data;
      const { data } = responseData;
      const {
        investorLogo,
        companyName,
        headquarters,
        foundedYear,
        numEmployees,
        investorType,
        investorWebsite,
        investmentThesis,
        ticketSizevalue,
        totalExposure,
        stakeAcquired,
        investmentStage,
        assetClass,
        coInvestment,
        otherCriteria,
        financialCriteria,
        industryVerticalOptions,
        idealFocusOptions,
        moderatelySuitableOptions,
        negativeListOptions,
        endMarketFocus,
        ndaKey,
        investmentDetails,
        onBoardingComplete,
        investorID,
        keyInvestments,
        profileCompleted,
      } = data;
      const parsedDetails = investmentDetails.map((detail) =>
        JSON.parse(detail)
      );
      setFormData({
        investorLogo,
        companyName,
        headquarters,
        foundedYear,
        numEmployees,
        investorType,
        investorWebsite,
        investmentThesis,
        ticketSizevalue: ticketSizevalue.map((detail) => JSON.parse(detail))[0],
        totalExposure: totalExposure.map((detail) => JSON.parse(detail))[0],
        stakeAcquired: stakeAcquired.map((detail) => JSON.parse(detail))[0],
        investmentStage: investmentStage.map((detail) => JSON.parse(detail))[0],
        assetClass: assetClass.map((detail) => JSON.parse(detail))[0],
        coInvestment: coInvestment || false,
        otherCriteria,
        financialCriteria: financialCriteria.map((detail) =>
          JSON.parse(detail)
        )[0],
        industryVerticalOptions: industryVerticalOptions.map((detail) =>
          JSON.parse(detail)
        )[0],
        idealFocusOptions: idealFocusOptions.map((detail) =>
          JSON.parse(detail)
        )[0],
        moderatelySuitableOptions: moderatelySuitableOptions.map((detail) =>
          JSON.parse(detail)
        )[0],
        negativeListOptions: negativeListOptions.map((detail) =>
          JSON.parse(detail)
        )[0],
        endMarketFocus:
          endMarketFocus.length > 0
            ? endMarketFocus.map((detail) => JSON.parse(detail))[0]
            : [],
        ndaKey,
        investmentDetails: parsedDetails
          ? parsedDetails[0]
          : [
              {
                fund: "",
                vintage: "",
                investments: "",
                aum: "",
                dryPowder: "",
              },
            ],
        onBoardingComplete,
        investorID,
        keyInvestments,
        profileCompleted,
      });
      if (ticketSizevalue.length > 0)
        setTicketSizeValue(
          ticketSizevalue.map((detail) => JSON.parse(detail))[0]
        );
      if (totalExposure.length > 0)
        setTotalExposure(totalExposure.map((detail) => JSON.parse(detail))[0]);
      if (parsedDetails.length > 0) {
        setData(parsedDetails[0]);
      }
      if (industryVerticalOptions.length > 0) {
        setIndustryVerticalOptions(
          industryVerticalOptions?.map((detail) => JSON.parse(detail))[0]
        );
      }
      if (idealFocusOptions.length > 0)
        setIdealFocusOptions(
          JSON.parse(idealFocusOptions).map((option) => ({
            value: option,
            label: option,
          }))
        );
      if (moderatelySuitableOptions.length > 0)
        setModeratelySuitableOptions(
          JSON.parse(moderatelySuitableOptions).map((option) => ({
            value: option,
            label: option,
          }))
        );
    } else {
      loader.stop();
    }
  };
  const [data, setData] = useState(formData.investmentDetails);
  useEffect(() => {
    const filteredOptions1 = industryFocusOptions.filter(
      (option) =>
        !moderatelySuitableOptions.find(
          (selected) => selected.value === option.value
        )
    );
    const filteredOptions2 = industryFocusOptions.filter(
      (option) =>
        !idealFocusOptions.find((selected) => selected.value === option.value)
    );
    setFilteredOptions1(filteredOptions1);
    setFilteredOptions2(filteredOptions2);
  }, [idealFocusOptions, moderatelySuitableOptions, industryFocusOptions]);
  useEffect(() => {
    fetchDetails();
  }, []);
  const updateData = (rowIndex, columnId, value) => {
    setData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value,
          };
        }
        return row;
      })
    );
  };
  const addNew = () => {
    setData((oldData) => {
      const lastRow = oldData[oldData.length - 1];
      if (
        !lastRow.fund ||
        !lastRow.vintage ||
        !lastRow.investments ||
        !lastRow.aum ||
        !lastRow.dryPowder
      ) {
        Toast(
          "Please fill in all fields of the last row before adding a new one.",
          "info",
          "investorMembers"
        );
        return oldData;
      }

      return [
        ...oldData,
        { fund: "", vintage: "", investments: "", aum: "", dryPowder: "" },
      ];
    });
  };
  useEffect(() => {
    const updatedInvestmentDetails = data.map((row) => {
      return row;
    });

    setFormData((prev) => ({
      ...prev,
      investmentDetails: updatedInvestmentDetails,
    }));
  }, [data]);
  const deleteRow = (rowIndex) => {
    setData((currentData) => {
      if (currentData.length > 1) {
        setFormData((prevStepOneData) => {
          const updatedInvestmentDetails =
            prevStepOneData.investmentDetails.filter(
              (_, index) => index !== rowIndex
            );
          return {
            ...prevStepOneData,
            investmentDetails: updatedInvestmentDetails,
          };
        });

        return currentData.filter((_, index) => index !== rowIndex);
      }
      return currentData;
    });
  };
  function handleFileUpload(e) {
    fileUploads["investorLogo"] = 1;
    const file = e.target.files[0];
    if (file && (file.type === "image/png" || file.type === "image/jpeg")) {
      fileUploads["investorLogo"] = 1;
      const fileURL = URL.createObjectURL(file);
      setInvestorLogoFile(fileURL);
      setFormData((prevStepOneData) => ({
        ...prevStepOneData,
        investorLogo: file,
      }));
    } else {
      Toast(
        "Please select an image file (PNG or JPG format only.",
        "info",
        "investorMembers"
      );
    }
  }
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "investorWebsite") {
      if (!isValidUrl(value)) {
        setInvestorWebsiteError("Please enter a valid URL.");
      } else {
        setInvestorWebsiteError("");
      }
    }
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleInvestorTypeChange = (selected) => {
    setinvestorType(selected);
    setFormData({
      ...formData,
      investorType: selected.value,
    });
  };
  const handleTicketChange = (event, index) => {
    const newValue = [...ticketSizevalue];
    newValue[index] = parseFloat(event.target.value, 10);
    setTicketSizeValue(newValue);

    setFormData({
      ...formData,
      ticketSizevalue: newValue,
    });
  };
  const handleExposureChange = (event, index) => {
    const newValue = [...totalExposure];
    newValue[index] = parseFloat(event.target.value, 10);
    setTotalExposure(newValue);

    setFormData({
      ...formData,
      totalExposure: newValue,
    });
  };
  const handleStakeChangeInput = (event, index) => {
    const value = parseFloat(event.target.value);
    if (value < 0 || value > 100) {
      Toast(
        "Please enter a value between 0 and 100.",
        "info",
        "investorMembers"
      );
      return; // Optionally reset the value or handle as needed
    }
    const newValue = [...stakeAcquired];
    newValue[index] = parseFloat(event.target.value, 10);
    setStakeAcquired(newValue);
    setFormData({
      ...formData,
      stakeAcquired: newValue,
    });
  };
  const handleStageChange = (selectedOption) => {
    const selectedValues = selectedOption.map((option) => option.value);
    setInvestmentStage(selectedOption);
    setFormData({
      ...formData,
      investmentStage: selectedValues,
    });
  };
  const handleAssetClassChange = (selectedOption) => {
    const selectedValues = selectedOption.map((option) => option.value);
    setAssetClass(selectedOption);
    setFormData({
      ...formData,
      assetClass: selectedValues,
    });
  };
  const handleCoInvestment = (option) => {
    if (option === 1) {
      setCoInvestment(true);
      setFormData({
        ...formData,
        coInvestment: true,
      });
    } else {
      setCoInvestment(false);
      setFormData({
        ...formData,
        coInvestment: false,
      });
    }
  };
  const handleCriteriaChange = (selected) => {
    const selectedValues = selected.map((option) => option.value);
    setFinancialCriteria(selected);
    setFormData({
      ...formData,
      financialCriteria: selectedValues,
    });
  };
  const handleIdealFocusChange = (selected) => {
    setIdealFocusOptions(selected);
    const selectedValues = selected.map((option) => option.value);
    setFormData({
      ...formData,
      idealFocusOptions: selectedValues,
    });
  };
  const handleModeratelySuitableChange = (selected) => {
    setModeratelySuitableOptions(selected);
    const selectedValues = selected.map((option) => option.value);
    setFormData({
      ...formData,
      moderatelySuitableOptions: selectedValues,
    });
  };
  useEffect(() => {
    handleNegativeListChange();
  }, [idealFocusOptions, moderatelySuitableOptions, industryFocusOptions]);

  const handleCheckboxChangeEndMarket = (selected) => {
    if (formData.endMarketFocus.includes(selected)) {
      setFormData({
        ...formData,
        endMarketFocus: formData.endMarketFocus.filter(
          (item) => item !== selected
        ),
      });
    } else {
      setFormData({
        ...formData,
        endMarketFocus: [...formData?.endMarketFocus, selected],
      });
    }
  };

  const handleEndMarketChange = (selected) => {
    setEndMarketFocus(selected);
    const selectedValues = selected.map((option) => option.value);
    setFormData({
      ...formData,
      endMarketFocus: selectedValues,
    });
  };
  const handleNegativeListChange = () => {
    const selectedValuesSet = new Set([
      ...idealFocusOptions.map((opt) => opt.value),
      ...moderatelySuitableOptions.map((opt) => opt.value),
    ]);

    const negativeOptions = industryFocusOptions.filter(
      (opt) => !selectedValuesSet.has(opt.value)
    );
    setNegativeListOptions(negativeOptions);
    setFormData({
      ...formData,
      negativeListOptions: negativeOptions,
    });
  };
  const checkField = (data) => {
    let allFieldsFilled = true;
    let unfilledFields = [];

    const addUnfilledField = (fieldName) => {
      unfilledFields.push(fieldName);
      allFieldsFilled = false;
    };

    if (!data.companyName || data.companyName.trim() === "") {
      addUnfilledField("Company Name");
    }

    // if (!data.headquarters || data.headquarters.trim() === "") {
    //   addUnfilledField("Headquarters");
    // }

    // if (data.foundedYear === undefined || data.foundedYear === null) {
    //   addUnfilledField("Founded Year");
    // }

    // if (data.numEmployees === undefined || data.numEmployees <= 0) {
    //   addUnfilledField("Number of Employees");
    // }

    if (!data.investorType || data.investorType.trim() === "") {
      addUnfilledField("Investor Type");
    }

    if (!data.investorWebsite || data.investorWebsite.trim() === "") {
      addUnfilledField("Investor Website");
    }

    if (!data.investmentThesis || data.investmentThesis.trim() === "") {
      addUnfilledField("Investment Thesis");
    }

    // if (
    //   !Array.isArray(data.investmentDetails) ||
    //   data.investmentDetails.length === 0 ||
    //   !data.investmentDetails.every(
    //     (detail) =>
    //       detail.fund &&
    //       detail.fund.trim() !== "" &&
    //       detail.vintage &&
    //       detail.vintage.trim() !== "" &&
    //       detail.investments &&
    //       detail.investments.trim() !== "" &&
    //       detail.aum &&
    //       detail.aum.trim() !== ""
    //   )
    // ) {
    //   addUnfilledField("Investment Details");
    // }

    if (
      !Array.isArray(data.investmentStage) ||
      data.investmentStage.length === 0 ||
      !data.investmentStage.every((v) => v && v.trim() !== "")
    ) {
      addUnfilledField("Stage of Investment");
    }

    if (
      !Array.isArray(data.assetClass) ||
      data.assetClass.length === 0 ||
      !data.assetClass.every((v) => v && v.trim() !== "")
    ) {
      addUnfilledField("Asset Class");
    }

    // if (
    //   !Array.isArray(data.financialCriteria) ||
    //   data.financialCriteria.length === 0 ||
    //   !data.financialCriteria.every((v) => v && v.trim() !== "")
    // ) {
    //   addUnfilledField("Financial Criteria");
    // }

    if (
      !Array.isArray(data.ticketSizevalue) ||
      data.ticketSizevalue.length === 0 ||
      !data.ticketSizevalue.every((v) => v > 0)
    ) {
      addUnfilledField("Initial Check Size");
    }
    if (
      !Array.isArray(data.totalExposure) ||
      data.totalExposure.length === 0 ||
      !data.totalExposure.every((v) => v > 0)
    ) {
      addUnfilledField("Total Exposure");
    }

    if (
      !Array.isArray(data.stakeAcquired) ||
      data.stakeAcquired.length === 0 ||
      !data.stakeAcquired.every((v) => v >= 0 && v <= 100)
    ) {
      addUnfilledField("Stake Acquired");
    }

    if (
      !Array.isArray(data.industryVerticalOptions) ||
      data.industryVerticalOptions.length === 0 ||
      !data.industryVerticalOptions.every((v) => v && v.trim() !== "")
    ) {
      addUnfilledField("Industry Verticals");
    }

    // if (
    //   !Array.isArray(data.idealFocusOptions) ||
    //   data.idealFocusOptions.length === 0 ||
    //   !data.idealFocusOptions.every((v) => v && v.trim() !== "")
    // ) {
    //   addUnfilledField("Ideal Fit");
    // }

    // if (
    //   !Array.isArray(data.moderatelySuitableOptions) ||
    //   data.moderatelySuitableOptions.length === 0 ||
    //   !data.moderatelySuitableOptions.every((v) => v && v.trim() !== "")
    // ) {
    //   addUnfilledField("Moderate Fit");
    // }

    if (
      !Array.isArray(data.endMarketFocus) ||
      data.endMarketFocus.length === 0 ||
      !data.endMarketFocus.every((v) => v && v.trim() !== "")
    ) {
      addUnfilledField("End Market Focus");
    }
    return allFieldsFilled;
  };

  const checkMinMax = (data) => {
    let minMax = true;
    let minMax2 = true;
    if (
      !Array.isArray(data.ticketSizevalue) ||
      !data.ticketSizevalue.length ||
      !Array.isArray(data.totalExposure) ||
      !data.totalExposure.length
    ) {
      return true;
    }
    if (Number(data?.ticketSizevalue[0]) > Number(data?.ticketSizevalue[1])) {
      Toast(
        `Please check min and max values of Initial Check size field in Investment Focus.
        `,
        "info",
        "investorMembers"
      );
      minMax = false;
    }

    if (Number(data.totalExposure[0]) > Number(data.totalExposure[1])) {
      Toast(
        `Please check min and max values of Total Exposure field in Investment Focus.
        `,
        "info",
        "investorMembers"
      );
      minMax2 = false;
    }
    return minMax && minMax2;
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (checkField(formData)) {
      formData.profileCompleted = true;
      const newState = {
        ...state,
        profileCompleted: true,
      };
      setState(newState);
    } else {
      const newState = {
        ...state,
        profileCompleted: false,
      };
      setState(newState);
      formData.profileCompleted = false;
    }
    const formDataInput = new FormData();
    const data = formData;
    for (const key in data) {
      if (data[key] === true || data[key] === false) {
        formDataInput.append(key, data[key]);
      } else if (data.hasOwnProperty(key)) {
        if (data[key]) {
          if (typeof data[key] === "object" && !(data[key] instanceof File)) {
            formDataInput.append(key, JSON.stringify(data[key]));
          } else {
            formDataInput.append(key, data[key]);
          }
        } else if (data[key] === "") {
          formDataInput.append(key, data[key]);
        }
      }
    }
    if (!data.investorID) {
      formDataInput.append("investorID", formData.investorID);
    }
    if (checkMinMax(data)) {
      try {
        loader.start("Updating Investor Details...");
        const response = await PostDetails(formDataInput, token);
        loader.stop();
        if (response.data.data !== null) {
          Toast("Details saved successfully", "success", "investorMembers");
          if (!formData.profileCompleted) {
            Toast(
              "Your profile is incomplete. Please complete your profile for deal interactions",
              "info",
              "investorMembers"
            );
          }
        }
        profileCompletionPercentage();
      } catch (error) {
        loader.stop();
      }
    }
  };
  const profileCompletionPercentage = async () => {
    try {
      const response = await getProfileCompletionPercentage(token);
      const percent = response.data.percentage;

      const newState = {
        ...state,
        percentComplete: percent,
      };
      setState(newState);
    } catch (error) {}
  };
  const handleCheckboxChange = (option) => {
    if (industryVerticalOptions.includes(option)) {
      // Remove the option if it already exists in the array
      setIndustryVerticalOptions(
        industryVerticalOptions.filter((item) => item !== option)
      );
      setFormData({
        ...formData,
        industryVerticalOptions: industryVerticalOptions.filter(
          (item) => item !== option
        ),
      });
    } else {
      // Add the option if it doesn't exist
      industryVerticalOptions
        ? setIndustryVerticalOptions([...industryVerticalOptions, option])
        : setIndustryVerticalOptions([option]);
      formData.industryVerticalOptions
        ? setFormData({
            ...formData,
            industryVerticalOptions: [
              ...formData.industryVerticalOptions,
              option,
            ],
          })
        : setFormData({ ...formData, industryVerticalOptions: [option] });
    }
  };
  return (
    <div className="investorDetails investor-account-box">
      <Helmet>
        <title>Investor | Profile</title>
      </Helmet>
      <ToastContainer position="top-center" containerId="investorMembers" />
      <div className="investor-profile-investment-details">
        <div className="add-member-div" style={{ alignItems: "center" }}>
          <p
            className="investor-account-subheading"
            style={{ marginTop: "30px" }}
          >
            Investor Details
          </p>
          <button className="add-row OrgId">
            Investor. ID: {formData.investorID}
          </button>
        </div>
        <div className="upload-btn-wrapper" style={{ marginTop: "0px" }}>
          <button className="upload-img-btn">
            <img
              src={
                !fileUploads["investorLogo"]
                  ? formData.investorLogo
                    ? formData.investorLogo
                    : uploadImg
                  : investorLogoFile
              }
              className="InvestorLogo"
              alt="Upload Image"
              style={{ cursor: "pointer" }}
            />
          </button>
          {(fileUploads["investorLogo"] || formData.investorLogo) && (
            <label htmlFor="investor-logo" className="edit-img-btn">
              <img src={editImg} className="editImg" alt="Edit" />
            </label>
          )}
          <input
            id="investor-logo"
            type="file"
            name="image"
            accept="image/png, image/jpeg" // Accept only JPEG and PNG
            onChange={handleFileUpload}
            style={{ cursor: "pointer" }}
          />
        </div>
        <form className="input-section account-input-section">
          <div className="input-div">
            <FormControl
              className="form-control investorDetails-row-input"
              id="Id"
              required
              size="lg"
              color="primary"
            >
              <FormLabel className="label">Investment Firm Name</FormLabel>
              <Input
                className="input"
                placeholder="Investment Co."
                color="neutral"
                name="companyName"
                type="text"
                value={formData.companyName}
                onChange={handleInputChange}
                style={{
                  border: !formData.companyName ? "1px solid red" : "",
                }}
                required
              />
            </FormControl>
            <FormControl
              className="form-control investorDetails-row-input"
              id="Id"
              required
              size="lg"
              color="primary"
            >
              <FormLabel className="label">Headquarter</FormLabel>
              <Input
                className="input"
                placeholder="Bengaluru, India"
                color="neutral"
                name="headquarters"
                type="text"
                value={formData.headquarters}
                onChange={handleInputChange}
                style={{
                  border: !formData.headquarters ? "1px solid red" : "",
                }}
                required
              />
            </FormControl>
            <FormControl
              className="form-control investorDetails-row-input"
              id="Id"
              required
              size="lg"
              color="primary"
            >
              <FormLabel className="label">Founded Year</FormLabel>
              <Input
                className="input"
                placeholder="2018"
                color="neutral"
                name="foundedYear"
                type="number"
                value={formData.foundedYear}
                onChange={handleInputChange}
                required
                style={{
                  border: !formData.foundedYear ? "1px solid red" : "",
                }}
              />
            </FormControl>
          </div>
          <div className="input-div">
            <FormControl
              className="form-control investorDetails-row-input"
              id="Id"
              required
              size="lg"
              color="primary"
            >
              <FormLabel className="label">Number of Employees</FormLabel>
              <Input
                className="input"
                placeholder="10"
                color="neutral"
                name="numEmployees"
                type="number"
                value={formData.numEmployees}
                onChange={handleInputChange}
                style={{
                  border: !formData.numEmployees ? "1px solid red" : "",
                }}
                required
              />
            </FormControl>
            <FormControl
              className="form-control investorDetails-row-input"
              id="Id"
              required
              size="lg"
              color="primary"
            >
              <FormLabel className="label">Investor Type</FormLabel>

              <Select
                // className="input"
                className="react-select-input"
                options={InvestorTypeOptions}
                name="investorType"
                value={formData.investorType}
                style={{
                  border: !formData.investorType ? "1px solid red" : "",
                }}
                onChange={handleInvestorTypeChange}
                placeholder={formData.investorType}
                required
                styles={inputCustomStyles}
              />
            </FormControl>
            <FormControl
              className="form-control investorDetails-row-input"
              id="Id"
              required
              size="lg"
              color="primary"
            >
              <FormLabel className="label">Investor Website</FormLabel>
              <Input
                className="input"
                placeholder="www.investment.com"
                color="neutral"
                name="investorWebsite"
                type="url"
                value={formData.investorWebsite}
                onChange={handleInputChange}
                style={{
                  border: !formData.investorWebsite ? "1px solid red" : "",
                }}
                required
              />
              {investorWebsiteError && (
                <div style={{ color: "red" }}>{investorWebsiteError}</div>
              )}
            </FormControl>
          </div>
          <div
            className="input-div"
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 2fr",
              gap: "5px",
            }}
          >
            <FormControl
              className="key-investment-form-control"
              id="Id"
              required
              size="lg"
              style={{ width: "88%" }}
            >
              <FormLabel className="label">Key Investments</FormLabel>
              <TextField
                className="key-investment-input"
                placeholder="Flipkart, Rupee, TATA"
                name="keyInvestments"
                type="text"
                value={formData.keyInvestments}
                style={{
                  border: !formData.keyInvestments ? "1px solid red" : "",
                }}
                onChange={handleInputChange}
                required
                multiline
                minRows={rows}
                sx={{ border: "1px solid #8692a6" }}
              />
            </FormControl>

            <FormControl
              className="investment-thesis-form-control"
              id="Id"
              required
              size="lg"
              color="primary"
              style={{ width: "95%" }}
            >
              <FormLabel className="label">Investment Thesis</FormLabel>
              <TextField
                multiline
                minRows={rows}
                className="key-investment-input"
                placeholder="Describe your investment thesis"
                name="investmentThesis"
                type="text"
                value={formData.investmentThesis}
                onChange={handleInputChange}
                style={{
                  border: !formData.investmentThesis ? "1px solid red" : "",
                }}
                required
                sx={{ border: "1px solid #8692a6" }}
              />
            </FormControl>
          </div>
          <div className="investmentDetails-table">
            <FormLabel className="label">Investment Details</FormLabel>
            <div className="table-details">
              <Editable
                columns={columns}
                data={data}
                updateData={updateData}
                deleteRow={deleteRow}
                isCompany={false}
              />
              <div className="table-edit-buttons">
                <button
                  onClick={(event) => {
                    event.preventDefault(); // This line prevents the form from being submitted
                    addNew();
                  }}
                  className="add-row"
                >
                  <AiOutlinePlus /> Add row
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div className="investor-profile-investment-focus">
        <p className="investor-account-subheading">Investment Focus</p>
        <div className="third-step-first-row">
          <div className="ticket-size-text-div">
            <FormLabel className="label">Initial Check Size (Cr)</FormLabel>
            <div className="ticket-size-div">
              <FormControl
                className="form-control investorDetails-row-input"
                id="Id"
                required
                size="lg"
                color="primary"
              >
                <Input
                  startDecorator=" ₹"
                  endDecorator="min"
                  className="input ticket-size-min"
                  placeholder="in crores"
                  color="neutral"
                  name="ticketSizeLowerLimit"
                  type="number"
                  required
                  value={
                    Array.isArray(formData?.ticketSizevalue)
                      ? formData?.ticketSizevalue[0]
                      : ""
                  }
                  style={{
                    border: !Array.isArray(formData?.ticketSizevalue)
                      ? "1px solid red"
                      : "",
                  }}
                  onChange={(event) => handleTicketChange(event, 0)}
                />
              </FormControl>
              <FormControl
                className="form-control investorDetails-row-input"
                id="Id"
                required
                size="lg"
                color="primary"
              >
                <Input
                  startDecorator=" ₹"
                  endDecorator="max"
                  className="input ticket-size-max"
                  placeholder="in crores"
                  color="neutral"
                  name="ticketSizeUpperLimit"
                  type="number"
                  value={
                    Array.isArray(formData.ticketSizevalue)
                      ? formData.ticketSizevalue[1]
                      : ""
                  }
                  style={{
                    border: !Array.isArray(formData.ticketSizevalue)
                      ? "1px solid red"
                      : "",
                  }}
                  required
                  onChange={(event) => handleTicketChange(event, 1)}
                />
              </FormControl>
            </div>
          </div>
          <div className="ticket-size-text-div">
            <FormLabel className="label">
              Total Portfolio Exposure (Cr)
            </FormLabel>
            <div className="ticket-size-div">
              <FormControl
                className="form-control investorDetails-row-input"
                id="Id"
                required
                size="lg"
                color="primary"
              >
                <Input
                  startDecorator=" ₹"
                  endDecorator="min"
                  className="input ticket-size-min"
                  placeholder="in crores"
                  color="neutral"
                  name="totalExposureLowerLimit"
                  type="number"
                  required
                  value={
                    Array.isArray(formData.totalExposure)
                      ? formData.totalExposure[0]
                      : ""
                  }
                  onChange={(event) => handleExposureChange(event, 0)}
                  style={{
                    border: !Array.isArray(formData.totalExposure)
                      ? "1px solid red"
                      : "",
                  }}
                />
              </FormControl>
              <FormControl
                className="form-control investorDetails-row-input"
                id="Id"
                required
                size="lg"
                color="primary"
              >
                <Input
                  startDecorator=" ₹"
                  endDecorator="max"
                  className="input ticket-size-max"
                  placeholder="in crores"
                  color="neutral"
                  name="totalExposureUpperLimit"
                  type="number"
                  value={
                    Array.isArray(formData.totalExposure)
                      ? formData.totalExposure[1]
                      : ""
                  }
                  style={{
                    border: !Array.isArray(formData.totalExposure)
                      ? "1px solid red"
                      : "",
                  }}
                  required
                  onChange={(event) => handleExposureChange(event, 1)}
                />
              </FormControl>
            </div>
          </div>
        </div>
        <div className="third-step-second-row">
          <div className="ticket-size-text-div">
            <FormLabel className="label">Stake Acquired (%)</FormLabel>
            <div className="ticket-size-div">
              <FormControl
                className="form-control investorDetails-row-input"
                id="Id"
                required
                size="lg"
                color="primary"
              >
                <Input
                  endDecorator="min"
                  className="input stake-size-min"
                  placeholder="In percentage"
                  color="neutral"
                  name="stakeAcquiredLowerLimit"
                  type="number"
                  value={
                    Array.isArray(formData.stakeAcquired)
                      ? formData.stakeAcquired[0]
                      : ""
                  }
                  style={{
                    border: !Array.isArray(formData.stakeAcquired)
                      ? "1px solid red"
                      : "",
                  }}
                  required
                  maxLength="2"
                  onChange={(event) => handleStakeChangeInput(event, 0)}
                />
              </FormControl>
              <FormControl
                className="form-control investorDetails-row-input"
                id="Id"
                required
                size="lg"
                color="primary"
              >
                <Input
                  endDecorator="max"
                  className="input stake-size-max"
                  placeholder="In percentage"
                  color="neutral"
                  name="stakeAcquiredUpperLimit"
                  type="number"
                  value={
                    Array.isArray(formData.stakeAcquired)
                      ? formData.stakeAcquired[1]
                      : ""
                  }
                  style={{
                    border: !Array.isArray(formData.stakeAcquired)
                      ? "1px solid red"
                      : "",
                  }}
                  required
                  min="0"
                  max="100"
                  onChange={(event) => handleStakeChangeInput(event, 1)}
                />
              </FormControl>
            </div>
          </div>
          <FormControl
            className="form-control stage-of-investment-input"
            id="Id"
            required
            size="lg"
            color="primary"
          >
            <FormLabel className="label">Asset Class</FormLabel>
            <Select
              isMulti
              className="react-select-input"
              options={AssetOptions}
              placeholder="Please Select"
              required
              styles={inputCustomStylesAssestClass}
              value={
                formData.assetClass
                  ? AssetOptions.filter((option) =>
                      formData.assetClass.includes(option.value)
                    )
                  : []
              }
              onChange={handleAssetClassChange}
              isClearable={true}
              isSearchable={true}
            />
          </FormControl>
        </div>
        <div className="third-step-third-row flex">
          <FormControl
            className="form-control stage-of-investment-input"
            id="Id"
            required
            size="lg"
            color="primary"
          >
            <FormLabel className="label">Stage of Investment</FormLabel>
            <Select
              isMulti
              className="react-select-input"
              options={stageOptions}
              placeholder="Please Select"
              required
              styles={inputCustomStyles}
              value={
                formData.investmentStage
                  ? stageOptions.filter((option) =>
                      formData.investmentStage.includes(option.value)
                    )
                  : []
              }
              onChange={handleStageChange}
            />
          </FormControl>
          <FormControl
            className="form-control stage-of-investment-input"
            id="Id"
            size="lg"
            color="primary"
          >
            <FormLabel className="label">Financial Criteria</FormLabel>
            <Select
              isMulti
              className="react-select-input"
              options={CriteriaOptions}
              value={
                formData.financialCriteria
                  ? CriteriaOptions.filter((option) =>
                      formData.financialCriteria.includes(option.value)
                    )
                  : []
              }
              onChange={handleCriteriaChange}
              placeholder="Please Select"
              required
              styles={inputCustomStyles}
              isClearable={true}
              isSearchable={true}
            />
          </FormControl>
          <div className="coinvestment-div">
            <FormControl required color="primary">
              <FormLabel className="label" required>
                Co-Investment?
              </FormLabel>
              <div>
                <button
                  className={`yes-or-no-button yes-button ${
                    formData.coInvestment ? "selected-button" : ""
                  }`}
                  onClick={() => handleCoInvestment(1)}
                >
                  YES
                </button>
                <button
                  className={`yes-or-no-button ${
                    !formData.coInvestment ? "selected-button" : ""
                  }`}
                  onClick={() => handleCoInvestment(0)}
                >
                  NO
                </button>
              </div>
            </FormControl>
          </div>
        </div>
        <div className="third-step-third-row">
          <FormControl
            className="form-control investorDetails-row-input other-criteria"
            id="Id"
            size="lg"
            color="primary"
          >
            <FormLabel className="label">Other Criteria</FormLabel>
            <TextField
              className="key-investment-input2"
              placeholder="Describe any other criteria"
              name="otherCriteria"
              type="text"
              minRows={2}
              multiline
              value={formData.otherCriteria}
              onChange={handleInputChange}
            />
          </FormControl>
        </div>
      </div>
      <div
        className="investor-profile-industry-focus"
        style={{ marginBottom: "100px" }}
      >
        <p
          className="investor-account-subheading"
          style={{ marginTop: "10px" }}
        >
          Industry Focus
        </p>
        <div className="industry-focus-first-row investor-profile-row">
          <Box
            className="form-control stage-of-investment-input"
            id="Id"
            required
            size="lg"
            color="primary"
            sx={{
              width: "100% !important",
            }}
          >
            <Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "baseline",
                  justifyContent: "space-between",
                  marginBottom: "5px",
                }}
              >
                <Typography
                  className="label"
                  sx={{
                    display: "flex",
                  }}
                >
                  Industry Verticals{" "}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "right",
                  }}
                >
                  <Button
                    onClick={() => {
                      setIndustryVerticalOptions(
                        industryFocusOptions.map((option) => option.label)
                      );
                      setFormData({
                        ...formData,
                        industryVerticalOptions: industryFocusOptions.map(
                          (option) => option.label
                        ),
                      });
                    }}
                    sx={{
                      textTransform: "capitalize",
                      color: "#00a6fb",
                    }}
                  >
                    Select All
                  </Button>
                  <Button
                    onClick={() => {
                      setIndustryVerticalOptions([]);
                      setFormData({
                        ...formData,
                        industryVerticalOptions: [],
                      });
                    }}
                    sx={{
                      textTransform: "capitalize",
                      color: "#00a6fb",
                    }}
                  >
                    <FaFilterCircleXmark />
                    Clear All
                  </Button>
                </Box>
              </Box>
              <Grid container spacing={2} className={`deals-filter-option `}>
                {industryFocusOptions?.length > 0 &&
                  industryFocusOptions.map((option, index) => (
                    <Grid item xs="6" md="4" lg="2" key={index}>
                      <Checkbox
                        key={index}
                        label={option.label}
                        className="deal-checkbox"
                        checked={industryVerticalOptions.includes(option.label)}
                        onChange={(e) => handleCheckboxChange(option.label)}
                      />
                    </Grid>
                  ))}
              </Grid>
            </Box>
          </Box>
        </div>
        <div className="industry-focus-first-row">
          <Box
            className="form-control industry-focus-input investor-profile-fit-input"
            id="Id"
            required
            size="lg"
            color="primary"
          >
            <Typography
              className="label"
              sx={{
                marginBottom: "5px",
              }}
            >
              End Market Focus
            </Typography>
            <div className={`deals-filter-option `}>
              <div className={`deals-checkboxes`}>
                {endMarketFocusOptions?.length > 0 &&
                  endMarketFocusOptions.map((option, index) => (
                    <Checkbox
                      key={index}
                      label={option.label}
                      className="deal-checkbox"
                      checked={
                        formData.endMarketFocus
                          ? formData.endMarketFocus.includes(option.value)
                          : false
                      }
                      onChange={(e) =>
                        handleCheckboxChangeEndMarket(option.label)
                      }
                    />
                  ))}
              </div>
            </div>
            {/* <Select
              className="react-select-input"
              options={endMarketFocusOptions}
              placeholder="Please Select"
              required
              isMulti
              styles={inputCustomStyles}
              value={
                formData.endMarketFocus
                  ? endMarketFocusOptions.filter((option) =>
                      formData.endMarketFocus.includes(option.value)
                    )
                  : []
              }
              onChange={handleEndMarketChange}
              isClearable={true}
              isSearchable={true}
            /> */}
          </Box>
        </div>
      </div>
      {/* <div
        className="showInterestButtons-div"
        style={{ justifyContent: "flex-end" }}
      >
        <div className="showInterestButtons-right">
          <div
            className="stage-display-btn ask-question"
            onClick={handleFormSubmit}
            style={{ padding: "16px 50px" }}
          >
            Save
          </div>
        </div>
      </div> */}
      <div className="showInterestButtons-div">
        <div className="btns-insideContent">
          <div onClick={handleFormSubmit}>
            <CustomBlackBtn type="button" text="Save" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
