import React, { useState, useEffect } from "react";

import Sidebar from "../Components/sidebar";
import Topbar from "../Components/topbar";
import SubHeader from "../Components/subHeader";
import DealsFilterBox from "../../../Common/components/DealsFilterBox";
import { useLoader } from "../../../Common/LoaderProvider";
import DealsBox from "./dealsBox";
import NoRecommendedDeals from "../Components/noRecommendedDeals";

import { fetchInvestorDetails } from "../../../../endpoints/investor";
import {
  getAllNoNameDeals,
  getDealsByInvestorID,
  getNoNameDeals,
  getPassedDeals,
} from "../../../../endpoints/deal";

import { useNavigate, useParams } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { investorState } from "../investorState";
import "../../../../CSS/InvestorPage/Dashboard/style.css";

const NewDeals = () => {
  const Navigate = useNavigate();
  const loader = useLoader();
  const { type } = useParams();
  const [details, setDetails] = useState(null);
  const token = localStorage.getItem("token");
  const [idealFit, setIdealFit] = useState([]);
  const [moderateFit, setModerateFit] = useState([]);
  const [nonFit, setNonFit] = useState([]);
  const [otherDeals, setOtherDeals] = useState([]);
  const [allFocusOptions, setAllFocusOptions] = useState([]);
  const [state, setState] = useRecoilState(investorState);
  const [filterBoxOpen, setFilterBoxOpen] = useState(false);
  const [passedDeals, setPassedDeals] = useState([]);
  const [profileCompleted, setProfileCompleted] = useState(false);
  const [interactedDeals, setInteractedDeals] = useState([]);
  const { investorID } = useRecoilValue(investorState);
  const [filteredDeals, setFilteredDeals] = useState([]);
  const [wishlistDealIds, setWishlistDealIds] = useState([]);
  const [wishlistDeals, setWishlistDeals] = useState([]);
  const [noNameDeals, setNoNameDeals] = useState([]);

  const [startups, setStartups] = useState();
  const fetchDetails = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetchInvestorDetails(token);
      setDetails(response.data.data);
      setWishlistDealIds(response.data.data.wishlistStartup);
      setProfileCompleted(response.data.data.profileCompleted);
      const newState = {
        ...state,
        investorID: response.data.data.investorID,
      };
      setState(newState);
    } catch (error) {
      loader.stop();
    }
  };
  useEffect(() => {
    fetchDetails();
  }, [state.investorID]);
  useEffect(() => {
    const getInteractedDeals = async () => {
      try {
        const response = await getDealsByInvestorID(investorID, token);
        setInteractedDeals(response.data);
      } catch (error) {
        loader.stop();
      }
    };
    if (investorID) {
      getInteractedDeals();
    }
  }, [investorID, token]);
  const fetchAllStartupsAndCategorize = async () => {
    let filterDeals = [];
    try {
      const response = await getNoNameDeals(investorID, token);
      loader.stop();
      if (response.data !== null) {
        const allDeals = response.data;
        for (let index = 0; index < allDeals.length; index++) {
          const element = allDeals[index];
          if (interactedDeals.some((deal) => deal.DealID === element.DealID)) {
          } else {
            filterDeals.push(element);
          }
        }
        setStartups(filterDeals);

        const validResponses = filterDeals.filter((response) =>
          wishlistDealIds.includes(response.OrganizationID)
        );
        setWishlistDeals(validResponses);
      }
    } catch (error) {
      loader.stop();
    }
  };
  useEffect(() => {
    if (details && interactedDeals) {
      loader.start("fetching deals");
      fetchAllStartupsAndCategorize();
    }
  }, [details, interactedDeals]);

  useEffect(() => {}, [details]);
  const categorizeStartups = () => {
    const ideal = [];
    const moderate = [];
    const non = [];
    if (
      details &&
      details.idealDealIds &&
      details.moderateDealIds &&
      details.nonFitDealIds
    ) {
      // Extract deal IDs from details
      const idealDealIds = details.idealDealIds;
      const moderateDealIds = details.moderateDealIds;
      const nonFitDealIds = details.nonFitDealIds;

      // Categorize startups
      startups &&
        startups.length > 0 &&
        startups.forEach((startup) => {
          //
          if (idealDealIds.includes(startup.DealID)) {
            ideal.push(startup);
          } else if (moderateDealIds.includes(startup.DealID)) {
            moderate.push(startup);
          } else if (nonFitDealIds.includes(startup.DealID)) {
            non.push(startup);
          }
        });
    } else {
      console.error("Details object does not contain the required fields.");
    }

    // Set state variables
    setIdealFit(ideal);
    setModerateFit(moderate);
    setNonFit(non);
    setOtherDeals(moderate.concat(non));
    setAllFocusOptions([...ideal, ...moderate, ...non]);
  };
  useEffect(() => {
    if (details && startups) categorizeStartups();
  }, [startups, details]);
  useEffect(() => {}, [filteredDeals]);

  async function getOpenListingDeals() {
    try {
      //loader is not used in this page because there is one gif which will not be properly shown due to loader
      // loader.start();
      const data = await getAllNoNameDeals(token);
      const deals = data.data;
      const filterDeals = [];
      for (let index = 0; index < deals.length; index++) {
        const element = deals[index];
        if (interactedDeals.some((deal) => deal.DealID === element.DealID)) {
        } else {
          filterDeals.push(element);
        }
      }
      setNoNameDeals(filterDeals);
      loader.stop();
    } catch (error) {
      // Toast("Error getting NoNameDeals", "error", "noNameDeals");
      loader.stop();
    }
  }
  useEffect(() => {
    getOpenListingDeals();
  }, [token, interactedDeals]);
  useEffect(() => {
    const fetchPassedDeals = async () => {
      try {
        const response = await getPassedDeals(investorID, token);
        if (response.data !== null) {
          setPassedDeals(response.data);
        }
      } catch (error) {}
    };
    fetchPassedDeals();
  }, [investorID]);
  function dealsLength(fitType) {
    try {
      // Do not show even count of deals if his profile is not completed yet
      if (
        fitType !== noNameDeals &&
        fitType !== wishlistDeals &&
        !profileCompleted
      ) {
        return "00";
      }
      const length = fitType.length ? fitType.length : 0;
      return length.toString().padStart(2, "0");
    } catch (error) {
      return "00";
    }
  }
  const renderDeals = () => {
    switch (type) {
      case "recommended":
        return (
          <>
            {profileCompleted ? (
              <DealsBox
                idealFit={filteredDeals}
                details={details}
                title="Recommended deals"
                fetchDetails={fetchDetails}
                fetchAllStartupsAndCategorize={fetchAllStartupsAndCategorize}
                stage="initialStage"
              />
            ) : (
              <NoRecommendedDeals />
            )}
          </>
        );
      case "otherDeals":
        return (
          <>
            {profileCompleted ? (
              <DealsBox
                idealFit={filteredDeals}
                details={details}
                title="Other deals"
                fetchDetails={fetchDetails}
                fetchAllStartupsAndCategorize={fetchAllStartupsAndCategorize}
                stage="initialStage"
              />
            ) : (
              <NoRecommendedDeals />
            )}
          </>
        );
      case "allListing":
        return (
          <>
            {" "}
            <DealsBox
              idealFit={filteredDeals}
              details={details}
              title="Open Listing"
              fetchDetails={fetchDetails}
              fetchAllStartupsAndCategorize={fetchAllStartupsAndCategorize}
              stage="initialStage"
            />
          </>
        );
      case "wishlist":
        return (
          <>
            <DealsBox
              idealFit={filteredDeals}
              details={details}
              title="Wishlist"
              fetchDetails={fetchDetails}
              fetchAllStartupsAndCategorize={fetchAllStartupsAndCategorize}
              stage="initialStage"
            />
          </>
        );
      //Only deals will show here that are passed at initial stages
      case "archive":
        return (
          <>
            {profileCompleted ? (
              <DealsBox
                idealFit={filteredDeals}
                details={details}
                title="Archive"
                fetchAllStartupsAndCategorize={fetchAllStartupsAndCategorize}
                stage="initialStage"
              />
            ) : (
              <NoRecommendedDeals />
            )}
          </>
        );
      default:
        return (
          <>
            {profileCompleted ? (
              <DealsBox
                idealFit={filteredDeals}
                details={details}
                title="Recommended deals"
                fetchDetails={fetchDetails}
                fetchAllStartupsAndCategorize={fetchAllStartupsAndCategorize}
                stage="initialStage"
              />
            ) : (
              <NoRecommendedDeals />
            )}
          </>
        );
    }
  };
  useEffect(() => {
    if (!type) {
      Navigate("/investor/newDeals/recommended");
    }
  });
  return (
    <div className="InvestorSide newDeals-page">
      <Sidebar active="opportunities" />
      <Topbar title="Opportunities" />
      <DealsFilterBox
        deals={
          type === "recommended"
            ? idealFit
            : type === "otherDeals"
            ? otherDeals
            : type === "allListing"
            ? noNameDeals
            : type === "wishlist"
            ? wishlistDeals
            : type === "archive"
            ? passedDeals
            : idealFit
        }
        filterBoxOpen={filterBoxOpen}
        setFilteredDeals={setFilteredDeals}
        shouldUpdateUrl={false}
      />
      <div className="InvestorSide-box">
        <div className="InvestorSide-content">
          <SubHeader
            recommendedDealsCount={dealsLength(idealFit)}
            wishListCount={dealsLength(wishlistDeals)}
            noNameDealCount={dealsLength(noNameDeals)}
            otherDealsCount={dealsLength(otherDeals)}
            filterBoxOpen={filterBoxOpen}
            setFilterBoxOpen={setFilterBoxOpen}
          />
          {renderDeals()}
        </div>
      </div>
    </div>
  );
};

export default NewDeals;
