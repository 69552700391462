import React, { useEffect, useState, useMemo } from "react";
import Editable from "./editable";
import { cols } from "../functions";
import CardTags from "./molecules/cardTags";
import ProfileCard from "./profileCard";
import DetailedCardFinancials from "../../Investor/Dashboard/Components/detailedCardFinanicals";
import { companyState, saveState } from "../../Founders/Dashboard/companyState";
import { useRecoilState, useRecoilValue } from "recoil";
import CommentBox from "../../Founders/Dashboard/MyProfile/commentBox";
import { getDealTeamDetails } from "../../../endpoints/startup";
import { getFundrevAnalystDetailsById } from "../../../endpoints/fundrevAnalyst";
import { fetchMembers } from "../../../endpoints/startup";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

import commentIcon from "../../../Assets/Images/FounderPage/commentIcon.svg";
import reqEdit from "../../../Assets/Images/FounderPage/reqedit.svg";
import Person from "../../../Assets/Images/signup/Person.svg";
import { useLoader } from "../LoaderProvider";
import { Box, styled, Typography } from "@mui/material";

const CompanyOverview = ({ startup, deal, reqToEdit, stage }) => {
  const [tableData, setTableData] = useState([]);
  const columns = useMemo(() => cols, []);
  const token = localStorage.getItem("token");
  const [state, setState] = useRecoilState(companyState);
  const [analyst, setAnalyst] = useState({});
  const { numMessages, startupMemberId } = useRecoilValue(companyState);
  const [dealId, setDealId] = useState(null);
  const [invitedMembers, setInvitedMembers] = useState([]);
  const [teamDetails, setTeamDetails] = useState({});
  const analystID = "FN123456";
  const loader = useLoader();
  const handleOpen = () => {
    const newState = { ...state, notificationBarOpen: true };
    setState(newState);
    saveState(newState); // Save to local storage
  };

  const StyledHeading = styled(Typography)(({ theme }) => ({
    fontFamily: "Karla, sans-serif",
    fontSize: "14px",
    fontWeight: "300",
    lineHeight: "21px",
    textAlign: "justified",
  }));
  const StyledContent = styled(Typography)(({ theme }) => ({
    fontFamily: "Karla, sans-serif",
    fontSize: "16px",
    fontWeight: "600",
    lineHeight: "24px",
    textAlign: "justified",
    maxWidth: "259px",
  }));
  const StyledTableHeading = styled(Typography)(({ theme }) => ({
    color: "#696F79",
    fontSize: "14px",
  }));
  useEffect(() => {
    setTableData(JSON.parse(deal?.fundingRounds || "[]"));
  }, [deal]);
  const fetchAnalystDetails = async () => {
    try {
      const response = await getFundrevAnalystDetailsById({
        fundrevAnalystID: analystID,
      });
      setAnalyst(response.data.data);
    } catch (error) {
      loader.stop();
    }
  };
  useEffect(() => {
    fetchAnalystDetails();
  }, []);

  const getTeamDetails = async () => {
    try {
      if (!startup?.startupID) return;
      const response = await getDealTeamDetails(startup.startupID, token);
      if (response.data !== null) {
        setTeamDetails(response.data.data);
      }
    } catch (error) {
      loader.stop();
    }
  };
  const fetchMembersData = async () => {
    try {
      const response = await fetchMembers(deal.OrganizationID, token);
      loader.stop();
      if (response && response.data) {
        const membersData = response.data.data;
        setInvitedMembers(membersData);
      }
    } catch (error) {
      loader.stop();
      console.error("Failed to fetch members:", error);
    }
  };
  useEffect(() => {
    fetchMembersData();
  }, [deal]);
  useEffect(() => {
    getTeamDetails();
  }, [startup]);
  return (
    <>
      <CommentBox
        dealId={deal?.DealID}
        token={token}
        memberId={startupMemberId}
      />
      <Box
        className="cdetails-div"
        sx={{
          margin: stage === "companyReview" && "0px",
          paddingX: "10px",
          gap: "16px",
        }}
      >
        <Box
          className="companyReview"
          sx={{
            backgroundColor: "white",
            marginTop: "10px",
            padding: "16px 24px",
            borderRadius: "8px",
            boxShadow: "0px 2px 20px 0px rgba(0, 0, 0, 0.1)",
            border: "none !important",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <p
              className="investorDetails-subheading"
              style={{
                color: "#696F79",
                fontSize: "20px",
                fontWeight: "300",
                marginTop: "0px",
              }}
            >
              Company Overview
            </p>
            <div
              className="cdetails-first-div"
              style={{
                marginRight: "20px",
                display: reqToEdit ? "flex" : "none",
              }}
            >
              {numMessages > 0 ? (
                <Box onClick={handleOpen} className="comments-div">
                  <p>
                    <img src={commentIcon} />
                    &nbsp; {numMessages} comments
                  </p>
                </Box>
              ) : (
                <div onClick={handleOpen} className="request-to-edit-btn">
                  <img
                    src={reqEdit}
                    className="reqEdit"
                    alt="request to edit"
                  />
                  &nbsp;&nbsp; request to edit
                </div>
              )}
            </div>
          </Box>
          <Box>
            <Box
              sx={{
                display: "flex",
                gap: "20px",
                marginTop: "20px",
              }}
            >
              <Box className="companyReview-img">
                <img
                  src={deal ? deal.CompanyLogo : ""}
                  className="InvestorLogo"
                  alt="Upload Image"
                  style={{
                    cursor: "pointer",
                    objectFit: "fill",
                    maxWidth: "none",
                    width: "80px",
                    height: "80px",
                    borderRadius: "16px",
                  }}
                />
              </Box>
              <div>
                <p
                  className="companyReview-companyName"
                  style={{
                    fontWeight: "600",
                    fontSize: "20px",
                    lineHeight: "30px",
                  }}
                >
                  {deal ? deal.CompanyName : ""}
                </p>
                <p
                  className="companyReview-comapnyDesc"
                  style={{
                    color: "#696F79",
                    fontSize: "16px",
                    fontWeight: "400",
                    lineHeight: "24px",
                    textAlign: "left",
                  }}
                >
                  <div
                    className="setInnerHTML"
                    dangerouslySetInnerHTML={{
                      __html: deal ? deal.CompanyDescription : "",
                    }}
                  />
                </p>
              </div>
            </Box>
            <Box
              sx={{
                marginY: "20px",
              }}
            >
              <CardTags deal={deal} page={"detailsPage"} />
            </Box>
            <Box
              sx={{
                marginTop: "20px",
                padding: "12px 24px",
                backgroundColor: "#FAFAFA",
                borderRadius: "16px",
                minHeight: "93px",
                alignContent: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                }}
              >
                <Box>
                  <StyledHeading>Legal Entity Name</StyledHeading>
                  <StyledContent>
                    {deal ? deal.LegalEntityName : "-"}
                  </StyledContent>
                </Box>
                <Box>
                  <StyledHeading>Headquarters</StyledHeading>
                  <StyledContent>
                    {deal ? deal.Headquarters : "-"}
                  </StyledContent>
                </Box>
                <Box>
                  <StyledHeading>Founded Year</StyledHeading>
                  <StyledContent>{deal ? deal.FoundedYear : "-"}</StyledContent>
                </Box>
                <Box>
                  <StyledHeading>No. of Employees</StyledHeading>
                  <StyledContent>
                    {deal ? deal.NumberOfEmployees : "-"}
                  </StyledContent>
                </Box>
                <Box>
                  <StyledHeading>Website</StyledHeading>
                  <StyledContent>
                    {deal ? deal.CompanyWebsite : "-"}
                  </StyledContent>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          className="details-key-highlights"
          sx={{
            backgroundColor: "white",
            marginTop: "10px",
            padding: "16px 24px",
            borderRadius: "8px",
            boxShadow: "0px 2px 20px 0px rgba(0, 0, 0, 0.1)",
            border: "none !important",
          }}
        >
          <p
            style={{
              color: "#1A1A1A",
              fontSize: "16px",
              fontWeight: "600",
            }}
          >
            Key Highlights
          </p>
          {/* <ul style={{ listStyleType: "disc" }}>
            <Box sx={{ margin: "5px 0px 0px 15px" }}>
              {deal && deal.KeyHighlights
                ? (() => {
                    try {
                      const highlights = JSON.parse(deal.KeyHighlights);
                      return Array.isArray(highlights)
                        ? highlights.map((highlight, index) => (
                            <li key={index} className="details-li-tag">
                              {highlight}
                            </li>
                          ))
                        : "";
                    } catch (error) {
                      console.error("Error parsing KeyHighlights:", error);
                      return "";
                    }
                  })()
                : ""}
            </Box>
          </ul> */}
          <div
            className="setInnerHTML details-li-tag"
            dangerouslySetInnerHTML={{
              __html: deal ? deal.KeyHighlights : "",
            }}
          />
        </Box>
        <Box
          className="details-key-highlights"
          sx={{
            backgroundColor: "white",
            marginTop: "10px",
            padding: "16px 24px",
            borderRadius: "8px",
            boxShadow: "0px 2px 20px 0px rgba(0, 0, 0, 0.1)",
            border: "none !important",
          }}
        >
          <p
            style={{
              color: "#1A1A1A",
              fontSize: "16px",
              fontWeight: "600",
            }}
          >
            Purpose of Fundraising
          </p>
          {/* <ul style={{ listStyleType: "disc" }}>
            <Box sx={{ margin: "5px 0px 0px 15px" }}>
              {deal && deal.PurposeOfFundraising
                ? (() => {
                    try {
                      const purposes = JSON.parse(deal.PurposeOfFundraising);
                      return Array.isArray(purposes)
                        ? purposes.map((purpose, index) => (
                            <li key={index} className="details-li-tag">
                              {purpose}
                            </li>
                          ))
                        : "";
                    } catch (error) {
                      console.error("Error parsing KeyHighlights:", error);
                      return "";
                    }
                  })()
                : ""}
            </Box>
          </ul> */}
          <div
            className="setInnerHTML details-li-tag"
            dangerouslySetInnerHTML={{
              __html: deal ? deal.PurposeOfFundraising : "",
            }}
          />
        </Box>
        <Box
          className="FoundingTeam-box"
          sx={{
            backgroundColor: "white",
            marginTop: "10px",
            padding: "16px 24px",
            borderRadius: "8px",
            boxShadow: "0px 2px 20px 0px rgba(0, 0, 0, 0.1)",
          }}
        >
          <div
            className="FoundingTeam"
            style={{
              border: "none",
              display: "flex",
              flexDirection: "column",
              gap: "24px",
            }}
          >
            <p
              className="investorDetails-subheading"
              style={{
                color: "#696F79",
                fontSize: "20px",
                fontWeight: "300",
                marginTop: "0px",
              }}
            >
              Founding Team
            </p>
            <div className="profile-boxes">
              {deal && deal.FoundingTeamDetails
                ? (() => {
                    try {
                      const teamDetails = JSON.parse(deal.FoundingTeamDetails);
                      return Array.isArray(teamDetails)
                        ? teamDetails.map((member, index) => (
                            <ProfileCard
                              member={member}
                              type="company"
                              key={member?.name || index}
                            />
                          ))
                        : "";
                    } catch (error) {
                      console.error(
                        "Error parsing FoundingTeamDetails:",
                        error
                      );
                      return ""; // or some fallback UI
                    }
                  })()
                : ""}
            </div>
          </div>
        </Box>
        <Box
          className="Financials-box"
          sx={{
            backgroundColor: "white",
            marginTop: "10px",
            padding: "16px 24px",
            borderRadius: "8px",
            boxShadow: "0px 2px 20px 0px rgba(0, 0, 0, 0.1)",
            border: "none !important",
          }}
        >
          <DetailedCardFinancials deal={deal} />
        </Box>
        <Box
          className="CompanyReview-FundingDetails"
          sx={{
            backgroundColor: "white",
            marginTop: "10px",
            padding: "16px 24px",
            borderRadius: "8px",
            boxShadow: "0px 2px 20px 0px rgba(0, 0, 0, 0.1)",
            border: "none !important",
            display: "flex",
            flexDirection: "column",
            gap: "24px",
          }}
        >
          <p
            className="investorDetails-subheading"
            style={{
              color: "#696F79",
              fontSize: "20px",
              fontWeight: "300",
              marginTop: "0px",
            }}
          >
            Previous Rounds
          </p>
          <Box>
            <p
              style={{
                color: "#1A1A1A",
                fontWeight: "16px",
                fontWeight: "600",
              }}
            >
              Key Investors
            </p>
            <p
              style={{
                color: "#696F79",
                fontWeight: "16px",
                fontWeight: "400",
              }}
            >
              {deal ? deal?.ExistingKeyInvestors : ""}
            </p>
          </Box>
          <TableContainer
            component={Paper}
            sx={{ borderRadius: "0px", boxShadow: "none" }}
          >
            <Table>
              <TableHead>
                <TableRow sx={{ height: "48px" }}>
                  <TableCell
                    style={{ padding: "0px 15px", lineHeight: "48px" }}
                  >
                    <StyledTableHeading>Date</StyledTableHeading>
                  </TableCell>{" "}
                  {/* Adjust cell padding and line height */}
                  <TableCell
                    style={{ padding: "0px 15px", lineHeight: "48px" }}
                  >
                    <StyledTableHeading>Round</StyledTableHeading>
                  </TableCell>
                  <TableCell
                    style={{ padding: "0px 15px", lineHeight: "48px" }}
                  >
                    <StyledTableHeading>Raised</StyledTableHeading>
                  </TableCell>
                  <TableCell
                    style={{ padding: "0px 15px", lineHeight: "48px" }}
                  >
                    <StyledTableHeading>% Stake</StyledTableHeading>
                  </TableCell>
                  <TableCell
                    style={{ padding: "0px 15px", lineHeight: "48px" }}
                  >
                    <StyledTableHeading>Valuation</StyledTableHeading>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData.map((row, index) => (
                  <TableRow key={index} border>
                    <TableCell sx={{ fontWeight: 600, color: "#1A1A1A" }}>
                      {row.date}
                    </TableCell>
                    <TableCell sx={{ fontWeight: 600, color: "#1A1A1A" }}>
                      {row.round}
                    </TableCell>
                    <TableCell sx={{ fontWeight: 600, color: "#1A1A1A" }}>
                      {row.raised}
                    </TableCell>
                    <TableCell sx={{ fontWeight: 600, color: "#1A1A1A" }}>
                      {row.stake}
                    </TableCell>
                    <TableCell sx={{ fontWeight: 600, color: "#1A1A1A" }}>
                      {row.valuation}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Box
          sx={{
            backgroundColor: "white",
            marginTop: "10px",
            padding: "16px 24px",
            borderRadius: "8px",
            boxShadow: "0px 2px 20px 0px rgba(0, 0, 0, 0.1)",
            border: "none !important",
          }}
        >
          <p
            className="investorDetails-subheading"
            style={{
              color: "#696F79",
              fontSize: "20px",
              fontWeight: "300",
              marginTop: "0px",
              marginBottom: "16px",
            }}
          >
            Deal Team
          </p>
          <Box
            className="team-profile-div"
            sx={{ backgroundColor: "#FAFAFA", border: "1px solid #ACACACA" }}
          >
            <img
              loading="lazy"
              src={analyst.profile}
              className="team-profile-img"
              alt="Fundrev Analyst Profile"
            />
            <div className="team-deal-div-2">
              <div className="team-deal-div-3">{analyst.name}</div>
              <div className="team-deal-div-4 blue-text">Fundrev Analyst</div>
              <div className="team-deal-div-5">
                <span className="deal-team-span">Email Id: &nbsp;</span>
                <a
                  href={`mailto:${analyst.email}`}
                  style={{ color: "#1A1A1A", textDecoration: "none" }}
                >
                  {analyst.email}
                </a>
              </div>
              <div className="team-deal-div-5">
                <span className="deal-team-span">Contact Info: &nbsp;</span>
                <a
                  href={`tel:${analyst.phoneNumber}`}
                  style={{ color: "#1A1A1A", textDecoration: "none" }}
                >
                  {analyst.phoneNumber}
                </a>
              </div>
            </div>
          </Box>
        </Box>
        {/* <div
          className="dealTeam"
          style={{ marginBottom: stage === "companyReview" && "0px" }}
        >
          <p className="investorDetails-subheading">Deal Team</p>
          <div className="team-profiles-box">
            <div className="team-profile-div">
              <img
                loading="lazy"
                src={analyst.profile}
                className="team-profile-img"
                alt="Fundrev Analyst Profile"
              />
              <div className="team-deal-div-2">
                <div className="team-deal-div-3">{analyst.name}</div>
                <div className="team-deal-div-4 blue-text">Fundrev Analyst</div>
                <div className="team-deal-div-5">
                  <span className="deal-team-span">Email Id: &nbsp;</span>
                  <a
                    href={`mailto:${analyst.email}`}
                    style={{ color: "#696F79", textDecoration: "none" }}
                  >
                    {analyst.email}
                  </a>
                </div>
                <div className="team-deal-div-5">
                  <span className="deal-team-span">Contact Info: &nbsp;</span>
                  <a
                    href={`tel:${analyst.phoneNumber}`}
                    style={{ color: "#696F79", textDecoration: "none" }}
                  >
                    {analyst.phoneNumber}
                  </a>
                </div>
              </div>
            </div>
            {invitedMembers.map((member) => (
              <div className="team-profile-div">
                <img
                  loading="lazy"
                  src={member.memberImg ? member.memberImg : Person}
                  className="team-profile-img"
                  alt="team profile"
                />
                <div className="team-deal-div-2">
                  <div className="team-deal-div-3">
                    {member.name ? member.name : "-"}
                  </div>
                  <div className="team-deal-div-4">
                    {member.title ? member.title : "-"}
                  </div>
                  <div className="team-deal-div-5">
                    <span className="deal-team-span">Email Id: &nbsp;</span>
                    <a
                      href={`mailto:${member.email ? member.email : "-"}`}
                      style={{ color: "#696F79", textDecoration: "none" }}
                    >
                      {member.email ? member.email : "-"}
                    </a>
                  </div>

                  <div className="team-deal-div-5">
                    <span className="deal-team-span">Contact Info: &nbsp;</span>
                    <a
                      href={`tel:${
                        member.phoneNumber ? member.phoneNumber : "-"
                      }`}
                      style={{ color: "#696F79", textDecoration: "none" }}
                    >
                      {member.phoneNumber ? member.phoneNumber : "-"}
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div> */}
      </Box>
    </>
  );
};

export default CompanyOverview;
