import React, { useState, useEffect } from "react";
import Sidebar from "../Components/sidebar";
import Topbar from "../Components/topbar";
import { useZoomEffect, stages } from "../../../Common/functions";
import { fetchStartupMemberDetails } from "../../../../endpoints/startup";
import { getDealIdbyStartupId } from "../../../../endpoints/deal";

import { useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { ToastContainer } from "react-toastify";

import { useRecoilState } from "recoil";
import { companyState } from "../companyState";
import OngoingInvestors from "./ongoingInvestors";
import { useLoader } from "../../../Common/LoaderProvider";
// import OngoingInvestors from './ongoingInvestors'
const Fundraising = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const token = localStorage.getItem("token");

  const loader = useLoader();
  const [companyData, setCompanyData] = useRecoilState(companyState);
  const [name, setName] = useState("");
  const [startupId, setStartupId] = useState("");
  const [dealId, setDealId] = useState([]);
  const [dealDetails, setDealDetails] = useState({});
  const open = localStorage.getItem("isOpen");
  useEffect(() => {
    if (open === "false") {
      navigate("/companyDetails");
    }
  });
  useEffect(() => {
    if (token) {
      const newData = {
        ...companyData,
      };
      setCompanyData(newData);
      localStorage.setItem("companyState", JSON.stringify(newData));
    }
  }, [token]);

  //For startupId
  const fetchDetails = async () => {
    let response;
    if (token) {
      response = await fetchStartupMemberDetails(token);
    }
    if (token) {
      if (response && response.data !== null) {
        setName(response.data.data.name);
        setStartupId(response.data.data.startupId);
      } else {
        loader.stop();
      }
    }
  };
  const fetchDeals = async () => {
    try {
      const response = await getDealIdbyStartupId(startupId, token);
      setDealId(response.data.dealId);
      const newData = {
        ...companyData,
        dealId: response.data.dealId,
      };
      setCompanyData(newData);
    } catch (error) {
      loader.stop();
    }
  };
  useEffect(() => {
    if (startupId) {
      fetchDeals();
    }
  }, [startupId, token]);
  useEffect(() => {
    loader.start("Fetching Deals...");
    if (token) fetchDetails();
  }, [token]);
  useZoomEffect();
  return (
    <div className="InvestorSide companySide onGoingDeals-section">
      {/* <ToastContainer position="top-center" containerId="fundraising-id" /> */}
      <Sidebar active="fundraising" />
      <Topbar title="Fundraising" />
      <Helmet>
        <title>Company | Fundraising</title>
      </Helmet>
      <div className="InvestorSide-box">
        <div className="InvestorSide-content">
          <div className="InvestorSide-content-box">
            <div className="InvestorSide-content-text">
              <p className="InvestorSide-content-text-1">Hi {name}</p>
              <p className="InvestorSide-content-text-2">
                Here's an overview of your advancements.
              </p>
            </div>
          </div>
        </div>
        <OngoingInvestors dealId={dealId} />
      </div>
    </div>
  );
};

export default Fundraising;
