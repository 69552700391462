import React from "react";
import Card from "../../../Common/components/Card";
import NoDeals from "../Components/noDeals";

import { Helmet } from "react-helmet";

const DealsBox = ({
  idealFit,
  details,
  title,
  fetchDetails,
  fetchAllStartupsAndCategorize,
  stage,
}) => {
  const pageTitle = title || "deals";
  return (
    <>
      <Helmet>
        <title>Investor | {pageTitle}</title>
      </Helmet>
      {idealFit.length > 0 ? (
        <div className="deals-box">
          {idealFit.map((deal, index) => (
            <Card
              key={deal.OrganizationID}
              isNew={false}
              investorId={details?.investorID}
              isStar={
                details?.wishlistStartup?.includes(deal.OrganizationID)
                  ? true
                  : false
              }
              deal={deal}
              fetchDetails={fetchDetails}
              stage={stage}
              investorVerified={details?.isApplicationReviewed}
              profileCompleted={details?.profileCompleted}
              fetchAllStartupsAndCategorize={fetchAllStartupsAndCategorize}
              index={index}
            />
          ))}
        </div>
      ) : (
        <>
          <NoDeals text={title} />
        </>
      )}
    </>
  );
};

export default DealsBox;
