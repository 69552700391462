import React, { useState, useRef } from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import MSAAgreement from "./MSAAgreement";
import TermsOfUse from "./TermsOfUse";
const TermsAndConditions = () => {
  const [cardType, setCardType] = useState("MSA");
  const handleCardTypeChange = (event, newCardType) => {
    if (newCardType !== null) setCardType(newCardType);
  };
  const termsRefs = {
    agreementRef: useRef(null),
    definitionsRef: useRef(null),
    scopeOfServicesRef: useRef(null),
    transactionFeeRef: useRef(null),
    fundraiserResponsibilitiesRef: useRef(null),
    intellectualPropertyRef: useRef(null),
    confidentialityRef: useRef(null),
    nonSolicitationRef: useRef(null),
    warrantiesRef: useRef(null),
    limitationOfLiabilityRef: useRef(null),
    indemnificationRef: useRef(null),
    termAndTerminationRef: useRef(null),
    disputeResolutionRef: useRef(null),
    dataProtectionRef: useRef(null),
    miscellaneousRef: useRef(null),
    saleOfCompany: useRef(null),
    mandatoryDisclosure: useRef(null),
    bindingNature: useRef(null),
  };
  const toggleButtonStyles = {
    textTransform: "capitalize !important",
    color: "#000",
    height: "32px",
    border: "none",
    padding: "0px 8px",
    fontSize: "14px",
    "&.Mui-selected": {
      backgroundColor: "#fff !important",
      borderRadius: "8px !important",
      color: "#000 !important",
      border: "1px solid #AEB3C1",
    },
    "&.Mui-selected:hover": {
      backgroundColor: "#115293",
    },
  };
  return (
    <section
      className="companies-TermsAndUse"
      style={{ display: "flex", gap: "24px", flexDirection: "column" }}
    >
      <ToggleButtonGroup
        color="primary"
        value={cardType}
        exclusive
        onChange={handleCardTypeChange}
        aria-label="card-type"
        sx={{
          backgroundColor: "#D2DCFF",
          padding: "4px 8px",
          borderRadius: "8px",
          width: "fit-content",
        }}
      >
        <ToggleButton value="MSA" sx={toggleButtonStyles}>
          Master Service Agreement
        </ToggleButton>
        <ToggleButton value="TnC" sx={toggleButtonStyles}>
          Terms of Use
        </ToggleButton>
      </ToggleButtonGroup>
      {cardType === "MSA" ? (
        <MSAAgreement setCardType={setCardType} termsRefs={termsRefs} />
      ) : (
        <TermsOfUse termsRefs={termsRefs} />
      )}
    </section>
  );
};

export default TermsAndConditions;
