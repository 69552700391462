import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import expandIcon from "../../../../Assets/Images/InvestorPage/Dashboard/expandIcon.svg";
import contractIcon from "../../../../Assets/Images/InvestorPage/Dashboard/contractIcon.svg";

import Accepted from "../../../../Assets/Images/InvestorPage/Dashboard/accepted.svg";
import Pending from "../../../../Assets/Images/InvestorPage/Dashboard/pending.svg";
import ProgressBar from "../../../Common/components/molecules/progressBar";
const OnGoingDealCard = ({
  deal,
  requestedDate,
  submittedDate,
  acceptedDate,
  IOIBidSubmittedAmount,
  IOIBidAcceptedAmount,
  LOIBidSubmittedAmount,
  LOIBidAcceptedAmount,
  isInterested,
  isRejected,
  stage,
}) => {
  const {
    Title,
    RoundType,
    IndustryVerticals,
    FundingType,
    EndMarketFocus,
    FundingRequired,
    TotalFundingRaised,
    DealTags,
  } = deal;
  const [firstTag, setFirstTag] = useState();

  function processFirstTag() {
    try {
      setFirstTag(JSON.parse(DealTags)[0]);
    } catch (error) {}
  }
  useEffect(() => {
    processFirstTag();
  }, [DealTags]);
  const Navigate = useNavigate();
  const [expand, setExpand] = React.useState(false);
  const [cardTags, setCardTags] = useState([]);
  const containerRef = useRef(null);
  const [visibleTags, setVisibleTags] = useState([]);
  const [remainingTags, setRemainingTags] = useState(0);
  const toggleExpand = (stage, index) => {
    const col = document.getElementById(stage);
    const ele1 = col.getElementsByClassName("group-5")[index];
    const ele2 = col.getElementsByClassName("group-8")[index];
    if (ele1)
      if (ele1.style.display === "none") {
        setExpand(false);
        ele1.style.display = "grid";
      } else {
        setExpand(true);
        ele1.style.display = "none";
      }
    if (ele2) {
      if (ele2.style.display === "none") {
        ele2.style.display = "block";
      } else {
        ele2.style.display = "none";
      }
    }
  };
  // useEffect(() => {
  //   if (containerRef.current) {
  //     const containerWidth = containerRef.current.offsetWidth;
  //     let totalWidth = 0;
  //     let tagsToShow = [];
  //     let remainingCount = 0;

  //     props.badges.forEach((tag, index) => {
  //       const tagElement = document.createElement("div");
  //       tagElement.style.display = "inline-block";
  //       tagElement.style.visibility = "hidden";
  //       tagElement.style.position = "absolute";
  //       tagElement.className = "Card-div-6";
  //       tagElement.innerText = tag;
  //       document.body.appendChild(tagElement);

  //       const tagWidth = tagElement.offsetWidth;
  //       document.body.removeChild(tagElement);

  //       if (totalWidth + tagWidth <= containerWidth) {
  //         totalWidth += tagWidth;
  //         tagsToShow.push(tag);
  //       } else {
  //         remainingCount = props.badges.length - tagsToShow.length;
  //         return false; // Break out of the loop
  //       }
  //     });

  //     setVisibleTags(tagsToShow);
  //     setRemainingTags(remainingCount);
  //   }
  // }, [props.badges]);
  useEffect(() => {
    if (containerRef.current) {
      const containerWidth = containerRef.current.offsetWidth * 0.9;
      let totalWidth = 0;
      let tagsToShow = [];
      let remainingCount = 0;

      cardTags.forEach((tag, index) => {
        const tagElement = document.createElement("div");
        tagElement.style.display = "inline-block";
        tagElement.style.visibility = "hidden";
        tagElement.style.position = "absolute";
        tagElement.className = "Card-div-6";
        tagElement.innerText = tag;
        document.body.appendChild(tagElement);

        const tagWidth = tagElement.offsetWidth;
        document.body.removeChild(tagElement);

        if (totalWidth + tagWidth <= containerWidth) {
          totalWidth += tagWidth;
          tagsToShow.push(tag);
        } else {
          remainingCount = cardTags.length - tagsToShow.length;
          return false; // Break out of the loop
        }
      });

      setVisibleTags(tagsToShow);
      setRemainingTags(remainingCount);
    }
  }, [cardTags]);
  useEffect(() => {
    const computeCardTags = () => {
      const tags = [];

      // Handle IndustryVerticals parsing
      try {
        if (IndustryVerticals.length > 0) {
          const industryVerticals = JSON.parse(IndustryVerticals);
          if (Array.isArray(industryVerticals)) tags.push(...industryVerticals);
        }
      } catch (error) {
        console.error("Error parsing Industry Verticals:", error);
      }
      // Handle EndMarketFocus parsing
      try {
        if (EndMarketFocus.length > 0) {
          const endMarketFocus = JSON.parse(EndMarketFocus);
          if (Array.isArray(endMarketFocus)) tags.push(...endMarketFocus);
        }
      } catch (error) {
        console.error("Error parsing End Market Focus:", error);
        tags.push("_"); // Fallback value
      }
      try {
        if (FundingType.length > 0) {
          const fundingTypes = JSON.parse(FundingType);
          if (Array.isArray(fundingTypes)) tags.push(...fundingTypes);
        }
      } catch (error) {
        console.error("Error parsing End Market Focus:", error);
      }
      return tags;
    };

    // Set computed card tags
    setCardTags(computeCardTags());
  }, [RoundType, IndustryVerticals, EndMarketFocus]);
  const handleCardClick = () => {
    isInterested &&
      Navigate(
        stage === "requestSent"
          ? "/investor/companyDetails"
          : "/investor/companyDetails/requestAccepted",
        {
          state: {
            deal: deal,
            passedStage: stage,
          },
        }
      );
  };
  return (
    // <div className="onGoingDealCard">
    //   <div
    //     className="div"
    //     style={{
    //       cursor: props.isInterested ? "pointer" : "not-allowed",
    //       alignItems:
    //         props.stage === "requestSent" || props.stage === "requestAccepted"
    //           ? "self-start"
    //           : "center",
    //     }}
    //     onClick={() => {
    //       props.isInterested &&
    //         Navigate(
    //           props.stage === "requestSent"
    //             ? "/investor/companyDetails"
    //             : "/investor/companyDetails/requestAccepted",
    //           {
    //             state: {
    //               deal: props.deal,
    //               passedStage: props.stage,
    //             },
    //           }
    //         );
    //     }}
    //   >
    //     <div
    //       className={`dead_deal_tag ${
    //         props.isInterested === false && "passed"
    //       } ${props.isRejected === true && "rejected"}`}
    //       style={{ display: props.isInterested ? "none" : "flex" }}
    //     >
    //       {props.isInterested === false && "Passed Deal by Investor"}
    //       {props.isRejected === true && "Declined Offer by Company"}
    //     </div>
    //     {(props.stage === "ioiSubmitted" ||
    //       props.stage === "ioiAccepted" ||
    //       props.stage === "loiSubmitted" ||
    //       props.stage === "loiAccepted" ||
    //       props.stage === "spaNegotiation" ||
    //       props.stage === "dealClosed") && (
    //       <div className="group">
    //         <div className="make-money-online-th">{props.companyName}</div>
    //         <a
    //           className="text-wrapper"
    //           href={"https://" + props.companyWebsite}
    //           rel="noopener noreferrer"
    //           target="_blank"
    //         >
    //           {props.companyWebsite}
    //         </a>
    //         <img
    //           className="rectangle"
    //           alt="Rectangle"
    //           src={props.companyLogoLink}
    //         />
    //       </div>
    //     )}
    //     {(props.stage === "requestSent" ||
    //       props.stage === "requestAccepted") && (
    //       <>
    //         <p className="Deal-card-heading">{props.companyInfo}</p>
    //         <p className="Deal-card-subheading">{props.companySubHeading}</p>
    //       </>
    //     )}
    //     <div
    //       className="group-2"
    //       // style={{
    //       //   margin:
    //       //     props.stage === "requestSent" || props.stage === "requestAccepted"
    //       //       ? "auto"
    //       //       : "intial",
    //       // }}
    //     >
    //       <div className="group-3">
    //         <div className="make-money-online-th-2">Amount Being Raised</div>
    //         <div className="make-money-online-th-3">
    //           ₹{props.amountBeingRaised}Cr
    //         </div>
    //       </div>
    //       {props.stage === "requestSent" && (
    //         <div className="group-4">
    //           <div className="make-money-online-th-2">Requested Date</div>
    //           <div className="make-money-online-th-3">
    //             {props.requestedDate}
    //           </div>
    //         </div>
    //       )}
    //       {props.stage === "requestAccepted" && (
    //         <div className="group-4">
    //           <div className="make-money-online-th-2">Accepted Date</div>
    //           <div className="make-money-online-th-3">{props.acceptedDate}</div>
    //         </div>
    //       )}

    //       {props.stage === "ioiSubmitted" && (
    //         <div className="group-4">
    //           <div className="make-money-online-th-2">Submitted Date</div>
    //           <div className="make-money-online-th-3">
    //             {props.submittedDate}
    //           </div>
    //         </div>
    //       )}
    //       {props.stage === "ioiAccepted" && (
    //         <div className="group-4">
    //           <div className="make-money-online-th-2">Accepted Date</div>
    //           <div className="make-money-online-th-3">{props.acceptedDate}</div>
    //         </div>
    //       )}
    //       {props.stage === "loiSubmitted" && (
    //         <div className="group-4">
    //           <div className="make-money-online-th-2">Submitted Date</div>
    //           <div className="make-money-online-th-3">
    //             {props.submittedDate}
    //           </div>
    //         </div>
    //       )}
    //       {props.stage === "loiAccepted" && (
    //         <div className="group-4">
    //           <div className="make-money-online-th-2">Accepted Date</div>
    //           <div className="make-money-online-th-3">{props.acceptedDate}</div>
    //         </div>
    //       )}
    //       {props.stage === "dealClosed" && (
    //         <div className="group-4">
    //           <div className="make-money-online-th-2">Closing Date</div>
    //           <div className="make-money-online-th-3">{props.closingDate}</div>
    //         </div>
    //       )}
    //     </div>
    //     {(props.stage === "ioiSubmitted" ||
    //       props.stage === "ioiAccepted" ||
    //       props.stage === "loiSubmitted" ||
    //       props.stage === "loiAccepted" ||
    //       props.stage === "spaNegotiation" ||
    //       props.stage === "dealClosed") && (
    //       <div className="group-5 group-2">
    //         <div className="group-3">
    //           <div className="make-money-online-th-2">Owner</div>
    //           <div className="make-money-online-th-3">{props.owner}</div>
    //         </div>
    //         <div className="group-4">
    //           <div className="make-money-online-th-2">Location</div>
    //           <div className="make-money-online-th-3">{props.location}</div>
    //         </div>
    //       </div>
    //     )}
    //     {props.stage === "ioiSubmitted" && (
    //       <div className="group-8 group-2">
    //         <div className="group-3">
    //           <div className="make-money-online-th-2">
    //             IOI Bid Submitted Amount
    //           </div>
    //           <div className="make-money-online-th-3">
    //             ₹{props.IOIBidSubmittedAmount}Cr
    //           </div>
    //         </div>
    //       </div>
    //     )}
    //     {props.stage === "ioiAccepted" && (
    //       <div className="group-8 group-2">
    //         <div className="group-3">
    //           <div className="make-money-online-th-2">
    //             IOI Bid Accepted Amount
    //           </div>
    //           <div className="make-money-online-th-3">
    //             ₹{props.IOIBidAcceptedAmount}Cr
    //           </div>
    //         </div>
    //         {/* <div className="group-7">
    //           <div className="make-money-online-th-2">Latest Activity</div>
    //           <div className="make-money-online-th-3">
    //             {props.latestActivity}
    //           </div>
    //         </div> */}
    //       </div>
    //     )}
    //     {props.stage === "loiSubmitted" && (
    //       <div className="group-4">
    //         <div className="group-6">
    //           <div className="make-money-online-th-2">
    //             LOI Bid Submitted Amount
    //           </div>
    //           <div className="make-money-online-th-3">
    //             ₹{props.LOIBidSubmittedAmount}Cr
    //           </div>
    //         </div>
    //       </div>
    //     )}
    //     {props.stage === "loiAccepted" && (
    //       <div className="group-4">
    //         <div className="group-6">
    //           <div className="make-money-online-th-2">
    //             LOI Bid Accepted Amount
    //           </div>
    //           <div className="make-money-online-th-3">
    //             ₹{props.LOIBidAcceptedAmount}Cr
    //           </div>
    //         </div>
    //       </div>
    //     )}
    //     {props.stage === "dealClosed" && (
    //       <div className="group-4">
    //         <div className="group-6">
    //           <div className="make-money-online-th-2">
    //             IOI Bid Accepted Amount
    //           </div>
    //           <div className="make-money-online-th-3">
    //             ₹{props.IOIBidAcceptedAmount}Cr
    //           </div>
    //         </div>
    //       </div>
    //     )}
    //     <div
    //       className="badges"
    //       style={{
    //         margin:
    //           props.stage === "requestSent" || props.stage === "requestAccepted"
    //             ? "auto"
    //             : "intial",
    //       }}
    //     >
    //       <div className="badge-soft-secondary" ref={containerRef}>
    //         {visibleTags.map((tag, index) => (
    //           <div className="badge-base-wrapper" key={index}>
    //             <div className="badge-base">
    //               <div className="elements">
    //                 <div className="text-wrapper-2">{tag}</div>
    //               </div>
    //             </div>
    //           </div>
    //         ))}
    //         {remainingTags > 0 && (
    //           <div className="blue-text" style={{ fontSize: "12px" }}>
    //             +{remainingTags}
    //           </div>
    //         )}
    //       </div>
    //     </div>
    //     <div className="group-9">
    //       <ProgressBar
    //         totalFundingRaised={props.amountBeingRaised}
    //         fundingRequired={props.fundingRequired}
    //       />
    //     </div>
    //   </div>
    //   {(props.stage === "ioiSubmitted" ||
    //     props.stage === "ioiAccepted" ||
    //     props.stage === "loiSubmitted" ||
    //     props.stage === "loiAccepted" ||
    //     props.stage === "spaNegotiation" ||
    //     props.stage === "dealClosed") && (
    //     <img
    //       id={props.index}
    //       className="streamline-interface"
    //       alt="Streamline interface"
    //       src={expand ? contractIcon : expandIcon}
    //       onClick={() => toggleExpand(props.stage, props.index)}
    //     />
    //   )}
    // </div>
    <div className="Card-div">
      <div style={{ cursor: "pointer" }} onClick={handleCardClick}>
        <div className="card-section-1">
          <div className="Card-div-3">
            <>{Title}</>
            {stage === "requestAccepted" ||
            stage === "ioiAccepted" ||
            stage === "loiAccepted" ||
            stage === "dealClosed" ? (
              <img src={Accepted} alt="accepted" />
            ) : (
              <img src={Pending} alt="pending"></img>
            )}
          </div>

          <div className="Card-div-5" ref={containerRef}>
            {visibleTags.map((tag, index) => (
              <div key={index} className="Card-div-6 blue-text">
                {tag}
              </div>
            ))}
            {remainingTags > 0 && (
              <div className="Card-div-7 blue-text">+{remainingTags}</div>
            )}
          </div>
        </div>
        <div className="card-section-3">
          <ProgressBar
            totalFundingRaised={TotalFundingRaised}
            fundingRequired={FundingRequired}
            roundType={RoundType}
            DealTag={firstTag}
            DealID={deal.DealID}
            card={"ongoingDealCard"}
            requestedDate={requestedDate}
            submittedDate={submittedDate}
            acceptedDate={acceptedDate}
            IOIBidSubmittedAmount={IOIBidSubmittedAmount}
            IOIBidAcceptedAmount={IOIBidAcceptedAmount}
            LOIBidSubmittedAmount={LOIBidSubmittedAmount}
            LOIBidAcceptedAmount={LOIBidAcceptedAmount}
            stage={stage}
          />
        </div>
      </div>
    </div>
  );
};

export default OnGoingDealCard;
