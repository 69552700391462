import React, { useState } from "react";
import Analytics from "../../Investor/Dashboard/companyDetails/analytics/analytics";
import { Box, ToggleButton, ToggleButtonGroup } from "@mui/material";
import AnalyticsHomble from "../../Investor/Dashboard/companyDetails/analyticsHomble/AnalyticsHomble";

const AdminAnalytics = ({ deal }) => {
  const [analytics, setAnalytics] = useState("summary");
  const handleChange = (e, newAlignment) => {
    if (newAlignment !== null) {
      setAnalytics(newAlignment);
    }
  };
  return (
    <div
      style={{
        paddingLeft: "80px",
      }}
    >
      {deal.DealID !== "D787569" ? (
        <ToggleButtonGroup
          exclusive
          aria-label="Analytics"
          value={analytics}
          onChange={handleChange}
          sx={{
            marginY: 2,
          }}
        >
          <ToggleButton
            value={`summary`}
            sx={{
              fontSize: "14px",
              fontFamily: "Karla, sans-serif",
              textTransform: "capitalize",
            }}
            size="small"
          >
            Summary
          </ToggleButton>
          <ToggleButton
            value={`businessAnalytics`}
            sx={{
              fontSize: "14px",
              fontFamily: "Karla, sans-serif",
              textTransform: "capitalize",
            }}
            size="small"
          >
            Business Analytics
          </ToggleButton>
          <ToggleButton
            value={`salesAnalytics`}
            sx={{
              fontSize: "14px",
              fontFamily: "Karla, sans-serif",
              textTransform: "capitalize",
            }}
            size="small"
          >
            Sales Analytics
          </ToggleButton>
          <ToggleButton
            value={`historicalFinancials`}
            sx={{
              fontSize: "14px",
              fontFamily: "Karla, sans-serif",
              textTransform: "capitalize",
            }}
            size="small"
          >
            Historical Financials
          </ToggleButton>
          <ToggleButton
            value={`financialForecast`}
            sx={{
              fontSize: "14px",
              fontFamily: "Karla, sans-serif",
              textTransform: "capitalize",
            }}
            size="small"
          >
            Financial Forecast{" "}
          </ToggleButton>
          <ToggleButton
            value={`fundingDetails`}
            sx={{
              fontSize: "14px",
              fontFamily: "Karla, sans-serif",
              textTransform: "capitalize",
            }}
            size="small"
          >
            Funding Details
          </ToggleButton>
        </ToggleButtonGroup>
      ) : (
        <ToggleButtonGroup
          exclusive
          aria-label="Analytics"
          value={analytics}
          onChange={handleChange}
          sx={{
            marginY: 2,
          }}
        >
          <ToggleButton
            value={`summary`}
            sx={{
              fontSize: "14px",
              fontFamily: "Karla, sans-serif",
              textTransform: "capitalize",
            }}
            size="small"
          >
            Summary
          </ToggleButton>
          <ToggleButton
            value={`businessAnalytics`}
            sx={{
              fontSize: "14px",
              fontFamily: "Karla, sans-serif",
              textTransform: "capitalize",
            }}
            size="small"
          >
            Financial Performance
          </ToggleButton>
          <ToggleButton
            value={`salesAnalytics`}
            sx={{
              fontSize: "14px",
              fontFamily: "Karla, sans-serif",
              textTransform: "capitalize",
            }}
            size="small"
          >
            Customer Analytics
          </ToggleButton>
        </ToggleButtonGroup>
      )}
      {deal?.DealID === "D787569" ? (
        <AnalyticsHomble deal={deal} analytics={analytics} />
      ) : (
        <Analytics deal={deal} analytics={analytics} />
      )}
    </div>
  );
};

export default AdminAnalytics;
