import { useEffect, useState } from "react";
import {
  addStartuptoWishlist,
  removeStartupFromWishlist,
} from "../../endpoints/investor";
import InputCell from "./components/inputCell";
import folder from "../../Assets/Images/InvestorPage/Dashboard/folder.svg";
import pdfIcon from "../../Assets/Images/InvestorPage/Dashboard/FileIcons/pdfIcon.svg";
import docxIcon from "../../Assets/Images/InvestorPage/Dashboard/FileIcons/docxIcon.svg";
import pptIcon from "../../Assets/Images/InvestorPage/Dashboard/FileIcons/pptIcon.svg";
import jpgIcon from "../../Assets/Images/InvestorPage/Dashboard/FileIcons/jpgIcon.svg";
import mpIcon from "../../Assets/Images/InvestorPage/Dashboard/FileIcons/mpIcon.svg";
import FileImg from "../../Assets/Images/signup/file.svg";

const fetchBlobName = (url) => {
  try {
    const str = decodeURIComponent(url.split("/").pop());
    const lastIndex = str.lastIndexOf("_");
    if (lastIndex === -1 || str.indexOf("_") === lastIndex) {
      return str;
    }
    const result = str.replace(/_\d{8}_\d{6}/, "");
    return result;
  } catch (error) {
    console.error("Error extracting file name:", error);
    return "";
  }
};
const fetchFullFileName = (url) => {
  try {
    const str = decodeURIComponent(url.split("/").pop());
    return str;
  } catch (error) {
    console.error("Error extracting file name:", error);
    return "";
  }
};
function isValidEmail(val) {
  let regEmail =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!regEmail.test(val)) {
    return false;
  } else {
    return true;
  }
}

function isValidUrl(url) {
  const regex =
    /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/;
  return regex.test(url);
}

function isValidLinkedInUrl(url) {
  const regex = /^(https?:\/\/)?(www\.)?linkedin\.com\/in\/[a-zA-Z0-9-]+\/?$/;
  return regex.test(url);
}
const timeDiff = (givenTime, hours) => {
  const givenDate = new Date(givenTime);
  const currentDate = new Date();
  const diffInMilliseconds = Math.abs(currentDate - givenDate);
  const diffInHours = diffInMilliseconds / (1000 * 60 * 60);
  return diffInHours < hours;
};
const useZoomEffect = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    if (windowWidth < 1300) {
      document.getElementsByClassName("InvestorSide")[0].style.zoom = "75%";
      const plots = document.getElementsByClassName("plot");
      for (let i = 0; i < plots.length; i++) {
        plots[i].style.zoom = "133.33%";
      }
      document.getElementsByClassName("InvestorSide-box")[0].style.height =
        "calc(133.33vh - 100px)";
    } else {
      document.getElementsByClassName("InvestorSide")[0].style.zoom = "100%";
      document.getElementsByClassName("InvestorSide-box")[0].style.height =
        "calc(100vh - 100px)";
    }
  }, [windowWidth]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return windowWidth;
};
//Navigating user if he/she has token
export const handleNavigation = (navigate) => {
  const userRole = localStorage.getItem("role");
  const token = localStorage.getItem("token");

  if (userRole === "investor") {
    navigate("/investor/newDeals", {
      state: {
        token: token,
      },
    });
    // } else {
    //   navigate("/investorDetails", {
    //     state: {
    //       tkn: token,
    //     },
    //   });
  } else if (userRole === "company") {
    const isOpen = localStorage.getItem("isOpen");
    if (isOpen === "true") {
      navigate("/company/fundraising", {
        state: {
          token: token,
        },
      });
    } else {
      navigate("/companyDetails", {
        state: {
          tkn: token,
        },
      });
    }
  }
  window.scrollTo(0, 0);
};

const addDealToWishlist = async (startupId, investorId) => {
  const token = localStorage.getItem("token");
  try {
    const response = await addStartuptoWishlist(startupId, investorId, token);
    if (!response) {
    } else if (response.data !== null) {
      // Toast("Added to wishlist", "success");
    }
  } catch (error) {}
};

const removeDealFromWishlist = async (startupId, investorId) => {
  const token = localStorage.getItem("token");
  try {
    const response = await removeStartupFromWishlist(
      startupId,
      investorId,
      token
    );
    if (!response) {
    } else if (response.data !== null) {
      // Toast("Removed from wishlist", "success");
    }
  } catch (error) {}
};

function calculateTotalAum(investmentDetails) {
  try {
    const totalAum = JSON.parse(investmentDetails).reduce((sum, investment) => {
      const aumValue = Number(investment.aum) || 0;
      return sum + aumValue;
    }, 0);
    return totalAum;
  } catch (error) {
    console.error("Error calculating total AUM:", error);
    return 0;
  }
}

function calculateInvestments(investmentDetails) {
  const totalInvestments = investmentDetails.reduce((sum, investment) => {
    const InvestmentValue = Number(investment.investments) || 0;
    return sum + InvestmentValue;
  }, 0);
  return totalInvestments;
}

const truncateText = (text, maxLength) => {
  if (text.length > maxLength) {
    return text.slice(0, maxLength) + "...";
  }
  return text;
};
const industryVerticalsShort = {
  AT: "AdTech",
  AM: "Advanced Manufacturing",
  AG: "AgTech",
  AI: "AI/ML",
  AU: "Audiotech",
  AR: "AR/VR",
  AC: "Autonomous cars",
  BP: "B2B payments",
  BE: "Beauty",
  BD: "Big Data",
  CA: "Cannabis",
  CS: "Carsharing",
  CL: "Cleantech",
  CT: "Climate tech",
  CC: "Cloudtech",
  CO: "ConstructionTech",
  CN: "Consumer",
  BC: "Blockchain",
  CY: "Cybersecurity",
  DH: "Digital health",
  EC: "Ecommerce",
  ED: "Edtech",
  EP: "Ephemeral content",
  ES: "eSports",
  FT: "Femtech",
  FI: "Fintech",
  FD: "Foodtech",
  GA: "Gaming",
  HT: "Healthtech",
  HR: "HRtech",
  IM: "Impact investing",
  IN: "Industrials",
  IF: "Infrastructure",
  IT: "Insurtech",
  IO: "IOT",
  LT: "Legal tech",
  LS: "Life sciences",
  MF: "Manufacturing",
  MT: "Marketing tech",
  MM: "Micro-mobility",
  MB: "Mobile",
  MC: "Mobile commerce",
  MG: "Mortgage tech",
  NT: "Nanotechnology",
  OG: "Oil and gas",
  ON: "Oncology",
  PT: "Pet tech",
  PR: "PropTech",
  RT: "Restaurant tech",
  RS: "Ridesharing",
  RD: "Robotics and drones",
  SA: "SaaS",
  ST: "Space tech",
  SC: "Supply chain technology",
  TM: "TMT",
  WE: "Wearables",
  WT: "WorkTech",
  "3D": "3D printing",
};

const stages = [
  "requestSent",
  "requestAccepted",
  "ioiSubmitted",
  "ioiAccepted",
  "loiSubmitted",
  "loiAccepted",
  "spaNegotiation",
  "dealClosed",
];
const reducedStages = [
  "requestSent",
  "ioiSubmitted",
  "loiSubmitted",
  "dealClosed",
];
const customStyles = {
  control: (provided) => ({
    ...provided,
    minHeight: "64px",
    width: "100%",
    fontSize: "14px",
    background: "none",
    border: "1px solid #cdd7e1",
    borderRadius: "6px",
    padding: "15px",
    fontFamily: '"Karla", sans-serif',
    alignItems: "base-line",
  }),
};
const inputCustomStyles = {
  control: (provided) => ({
    ...provided,
    minHeight: "64px",
    width: "100%",
    fontSize: "14px",
    background: "none",
    border: "1px solid #8692a6",
    borderRadius: "6px",
    padding: "15px",
    fontFamily: '"Karla", sans-serif',
    alignItems: "base-line",
  }),
};
const inputCustomStylesAssestClass = {
  control: (provided) => ({
    ...provided,
    minHeight: "64px",
    width: "100%",
    fontSize: "14px",
    background: "white",
    border: "1px solid #8692a6",
    borderRadius: "6px",
    padding: "15px",
    fontFamily: '"Karla", sans-serif',
    alignItems: "base-line",
  }),
};
const cols = [
  {
    Header: "Date",
    accessor: "date",
    placeholder: "date",
    Cell: InputCell,
    width: 180,
  },
  {
    Header: "Round",
    accessor: "round",
    placeholder: "round",
    Cell: InputCell,
    width: 180,
  },
  {
    Header: "Raised (₹Cr)",
    accessor: "raised",
    placeholder: "raised",
    Cell: InputCell,
    width: 180,
  },
  {
    Header: "% Stake",
    accessor: "stake",
    placeholder: "stake",
    Cell: InputCell,
    width: 180,
  },
  {
    Header: "Valuation (₹Cr)",
    accessor: "valuation",
    placeholder: "valuation",
    Cell: InputCell,
    width: 180,
  },
];
const folderIcons = {
  folder: folder,
  pdf: pdfIcon,
  docx: docxIcon,
  ppt: pptIcon,
  jpg: jpgIcon,
  mp3: mpIcon,
};
const getIcon = (fileType) => {
  const defaultIcon = FileImg; // Replace with your generic icon
  return folderIcons[fileType] || defaultIcon;
};

const plotColorPallete = [
  "#00A6FB44",
  "#0075AF94",
  "#00A6FB94",
  "#9CC85A",
  "#C2F970",
  "#6B7494",
  "#98A6D4",
  "#114FEE",
  "#98DBFD",
  "#00CD9E",
  "#D3D9EE",
  "#9747FF",
  "#F4BD50",
  "#FF9933",
  "#FF855F",
  "#D32F2F",
  "#B22234",
  "#DD2590",
  "#99005C",
  "#161616",
];

function validateFolderName(folderName) {
  const prohibitedCharacters = /[<>:"\/\\|?*]/;
  const reservedNames = [
    "CON",
    "PRN",
    "AUX",
    "NUL",
    "COM1",
    "COM2",
    "COM3",
    "COM4",
    "COM5",
    "COM6",
    "COM7",
    "COM8",
    "COM9",
    "LPT1",
    "LPT2",
    "LPT3",
    "LPT4",
    "LPT5",
    "LPT6",
    "LPT7",
    "LPT8",
    "LPT9",
  ];
  const minLength = 3;
  const maxLength = 255;
  if (!folderName) {
    return "Folder name cannot be empty.";
  }
  if (prohibitedCharacters.test(folderName)) {
    return "Folder name contains prohibited characters.";
  }
  if (reservedNames.includes(folderName.toUpperCase())) {
    return "Folder name is reserved and cannot be used.";
  }
  if (folderName.length < minLength) {
    return `Folder name must be at least ${minLength} character long.`;
  }
  if (folderName.length > maxLength) {
    return `Folder name must not exceed ${maxLength} characters.`;
  }
  return null;
}
function formatSize(bytes) {
  if (bytes < 1024) {
    return `${bytes} bytes`;
  } else if (bytes < 1024 * 1024) {
    return `${(bytes / 1024).toFixed(2)} KB`;
  } else {
    return `${(bytes / (1024 * 1024)).toFixed(2)} MB`;
  }
}
function investorStepClick(stepNumber, isApplicationReviewed) {
  if (isApplicationReviewed) {
    return;
  }
  document.getElementsByClassName("first-step")[0].style.display = "none";
  document.getElementsByClassName("second-step")[0].style.display = "none";
  document.getElementsByClassName("third-step")[0].style.display = "none";
  document.getElementsByClassName("fourth-step")[0].style.display = "none";
  document.getElementsByClassName("fifth-step")[0].style.display = "none";
  document.getElementsByClassName("sixth-step")[0].style.display = "none";

  switch (stepNumber) {
    case 1:
      document.getElementsByClassName("first-step")[0].style.display = "flex";
      break;
    case 2:
      document.getElementsByClassName("second-step")[0].style.display = "flex";
      break;
    case 3:
      document.getElementsByClassName("third-step")[0].style.display = "flex";
      break;
    case 4:
      document.getElementsByClassName("fourth-step")[0].style.display = "flex";
      break;
    case 5:
      document.getElementsByClassName("fifth-step")[0].style.display = "flex";
      break;
    case 6:
      document.getElementsByClassName("sixth-step")[0].style.display = "flex";
      break;
    default:
      break;
  }
}
export {
  fetchBlobName,
  fetchFullFileName,
  isValidEmail,
  isValidUrl,
  isValidLinkedInUrl,
  useZoomEffect,
  timeDiff,
  addDealToWishlist,
  removeDealFromWishlist,
  calculateTotalAum,
  truncateText,
  calculateInvestments,
  investorStepClick,
  industryVerticalsShort,
  stages,
  reducedStages,
  customStyles,
  inputCustomStyles,
  cols,
  getIcon,
  validateFolderName,
  formatSize,
  plotColorPallete,
  inputCustomStylesAssestClass,
};
