import React, { useState, useEffect } from "react";

import Toast from "../Toast";
import { customStyles } from "../functions";
import { Modal } from "@mui/material";
import { Input, Box, FormLabel, FormControl } from "@mui/joy";
import Select from "react-select";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { ToastContainer } from "react-toastify";

import closeIcon from "../../../Assets/Images/InvestorPage/Dashboard/closeIcon.svg";
import uploadFile from "../../../Assets/Images/InvestorPage/Dashboard/uploadFileBgWhite.svg";
import Bin from "../../../Assets/Images/signup/bin.svg";
import infoIcon from "../../../Assets/Images/InvestorPage/Dashboard/infoIcon.svg";
import investmentTypeInfo from "../../../Assets/Images/InvestorPage/Dashboard/investmentTypeInfo.svg";

const InvestmentTypeOptions = [
  { value: "Co-Invest", label: "Co-Invest" },
  { value: "Lead Round", label: "Lead Round" },
  { value: "Sole Investor", label: "Sole Investor" },
];
const calculateStakeAcquired = (investmentAmount, postMoneyValuation) => {
  if (!investmentAmount || !postMoneyValuation) return 0;
  return ((investmentAmount / postMoneyValuation) * 100).toFixed(2);
};
const LOIBidModal = ({
  LOIAgreementModalOpen,
  setLOIAgreementModalOpen,
  handleLOIInputChange,
  handleDateChange,
  selectedDate,
  handleLOISubmit,
  handleFileUpload,
  handleDeleteFile,
  fileUploads,
  fileInfo,
  setLOIAgreement,
  investmentType,
  loiAgreement,
  fundingRequired,
  calculatePercentage,
  percentage,
  minPostMoneyValuation,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [stakeAcquired, setStakeAcquired] = useState(0);
  const DEBOUNCE_DELAY = 500;
  const [debouncedStakeAcquired, setDebouncedStakeAcquired] =
    useState(stakeAcquired);
  const [debouncedPercentage, setDebouncedPercentage] = useState(percentage);
  const [debouncedPostMoneyValuation, setDebouncedPostMoneyValuation] =
    useState(loiAgreement.valuationAmount);
  useEffect(() => {
    setStakeAcquired(
      calculateStakeAcquired(
        loiAgreement.investmentAmount,
        loiAgreement.valuationAmount
      )
    );
  }, [loiAgreement.investmentAmount, loiAgreement.valuationAmount]);
  useEffect(() => {
    if (debouncedStakeAcquired > 100) {
      Toast(
        "Stake acquired cannot be more than 100%",
        "error",
        "LOIBidSubmission"
      );
    }
  }, [debouncedStakeAcquired]);
  useEffect(() => {
    if (parseFloat(debouncedPercentage) > 100) {
      Toast(
        "Investment Amount cannot be greater than current round",
        "error",
        "LOIBidSubmission"
      );
    }
  }, [debouncedPercentage]);
  useEffect(() => {
    if (parseFloat(debouncedPostMoneyValuation) < minPostMoneyValuation) {
      Toast(
        `Post money valuation should be at least ${minPostMoneyValuation}`,
        "error",
        "LOIBidSubmission"
      );
    }
  }, [debouncedPostMoneyValuation]);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedStakeAcquired(stakeAcquired);
    }, DEBOUNCE_DELAY);
    const percentageHandler = setTimeout(() => {
      setDebouncedPercentage(percentage);
    }, DEBOUNCE_DELAY);
    const postMoneyValuationHandler = setTimeout(() => {
      setDebouncedPostMoneyValuation(loiAgreement.valuationAmount);
    }, DEBOUNCE_DELAY);
    return () => {
      clearTimeout(handler);
      clearTimeout(percentageHandler);
      clearTimeout(postMoneyValuationHandler);
    };
  }, [stakeAcquired, percentage, loiAgreement.valuationAmount]);
  return (
    <>
      <ToastContainer position="top-center" containerId="LOIBidSubmission" />
      <Modal
        open={LOIAgreementModalOpen}
        onClose={() => setLOIAgreementModalOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal-box reason-box" style={{ width: "750px" }}>
          <div className="reason-box-first-div">
            <p className="IOI-bid-title">LOI Agreement</p>
            <img
              className="reason-box-closeicon"
              src={closeIcon}
              style={{ cursor: "pointer" }}
              onClick={() => setLOIAgreementModalOpen(false)}
            ></img>
          </div>
          <div className="IOI-bid-form">
            <div className="IOI-bid-form-left">
              <div className="IOI-bid-form-input">
                <FormControl
                  className="form-control investorDetails-row-input"
                  id="Id"
                  required
                  size="lg"
                  color="primary"
                >
                  <FormLabel className="IOI-bid-form-label">
                    Amount of Investment (₹Cr)
                  </FormLabel>
                </FormControl>
                <Input
                  endDecorator={
                    <p className="form-green-text">{`${percentage} % of current round`}</p>
                  }
                  className="IOI-bid-input"
                  size="md"
                  type="number"
                  placeholder="1.25"
                  onChange={(e) => {
                    calculatePercentage(e.target.value, fundingRequired);
                    setLOIAgreement((prevState) => ({
                      ...prevState,
                      investmentAmount: e.target.value,
                    }));
                  }}
                />
                <p className="form-green-text input-below-text">
                  Current Round: ₹ {fundingRequired}Cr
                </p>
              </div>
              <div className="IOI-bid-form-input">
                <FormControl
                  className="form-control investorDetails-row-input"
                  id="Id"
                  required
                  size="lg"
                  color="primary"
                >
                  <div className="investment-type-text-div">
                    <FormLabel className="IOI-bid-form-label">
                      Investment Type
                    </FormLabel>
                    <img
                      src={infoIcon}
                      alt="info-icon"
                      className="info-icon"
                      onMouseEnter={() => setIsHovered(true)}
                      onMouseLeave={() => setIsHovered(false)}
                    />
                    <img
                      src={investmentTypeInfo}
                      alt="info"
                      className="investment-type-info"
                      style={{ opacity: isHovered ? 1 : 0 }}
                    />
                  </div>
                </FormControl>
                <div className="filter-box-inputs">
                  <Select
                    options={InvestmentTypeOptions}
                    name="investmentType"
                    value={InvestmentTypeOptions.find(
                      (option) => option.value === investmentType
                    )}
                    onChange={(selectedOption) => {
                      setLOIAgreement((prevState) => ({
                        ...prevState,
                        investmentType: selectedOption
                          ? selectedOption.value
                          : "",
                      }));
                    }}
                    placeholder="Please Select"
                    required
                    styles={customStyles}
                  />
                </div>
              </div>
              <div className="IOI-bid-form-input">
                <FormControl
                  className="form-control investorDetails-row-input"
                  id="Id"
                  required
                  size="lg"
                  color="primary"
                >
                  <FormLabel className="IOI-bid-form-label">
                    Expected Transaction Completion Date
                  </FormLabel>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["DatePicker", "DatePicker"]}>
                      <DatePicker
                        className="IOI-bid-datepicker"
                        value={selectedDate}
                        onChange={(newValue) => {
                          setLOIAgreement((prevState) => ({
                            ...prevState,
                            selectedDate: newValue,
                          }));
                          handleDateChange(newValue);
                        }}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </FormControl>
              </div>
              <div className="IOI-bid-form-input">
                <FormControl
                  className="form-control investorDetails-row-input"
                  id="Id"
                  required
                  size="lg"
                  color="primary"
                >
                  <FormLabel className="IOI-bid-form-label">
                    Closing Conditions
                  </FormLabel>
                </FormControl>
                <Input
                  sx={{ "font-family": "Karla,sans-serif" }}
                  className="IOI-bid-input"
                  size="md"
                  type="text"
                  placeholder="Management Meetings"
                  onChange={(e) =>
                    handleLOIInputChange("closingConditions", e.target.value)
                  }
                />
              </div>
            </div>
            <div className="IOI-bid-form-right">
              <div className="IOI-bid-form-input">
                <FormControl
                  className="form-control investorDetails-row-input"
                  id="Id"
                  required
                  size="lg"
                  color="primary"
                >
                  <FormLabel className="IOI-bid-form-label">
                    Valuation Amount (₹Cr)
                  </FormLabel>
                </FormControl>
                <Input
                  endDecorator={
                    <p className="form-green-text input-below-text">
                      Min. Post Money Valuation: {minPostMoneyValuation}
                    </p>
                  }
                  className="IOI-bid-input"
                  size="md"
                  type="number"
                  placeholder="250"
                  onChange={(e) =>
                    handleLOIInputChange("valuationAmount", e.target.value)
                  }
                />
                <p className="form-green-text input-below-text">
                  Stake Acquired:{" "}
                  <span style={{ fontWeight: 600 }}>{stakeAcquired} %</span>
                </p>
              </div>
              <div className="IOI-bid-form-input">
                <FormControl
                  className="form-control investorDetails-row-input"
                  id="Id"
                  required
                  size="lg"
                  color="primary"
                >
                  <FormLabel className="IOI-bid-form-label">
                    Valuation Considerations
                  </FormLabel>
                </FormControl>
                <Input
                  className="IOI-bid-input"
                  size="md"
                  type="text"
                  placeholder="Multiple Revenue"
                  onChange={(e) =>
                    handleLOIInputChange(
                      "valuationConsiderations",
                      e.target.value
                    )
                  }
                />
              </div>
              <div className="IOI-bid-form-input">
                <FormControl
                  className="form-control investorDetails-row-input"
                  id="Id"
                  required
                  size="lg"
                  color="primary"
                >
                  <FormLabel className="IOI-bid-form-label">
                    Payment Options
                  </FormLabel>
                </FormControl>
                <Input
                  sx={{ "font-family": "Karla,sans-serif" }}
                  className="IOI-bid-input"
                  size="md"
                  type="text"
                  placeholder="Milestones"
                  onChange={(e) =>
                    handleLOIInputChange("paymentOptions", e.target.value)
                  }
                />
              </div>
              <div className="IOI-bid-form-input">
                <FormControl
                  className="form-control investorDetails-row-input"
                  id="Id"
                  required
                  size="lg"
                  color="primary"
                >
                  <FormLabel className="IOI-bid-form-label">
                    Attach LOI Letter
                  </FormLabel>

                  <div class="upload-file-wrapper">
                    <button class="upload-img-btn">
                      {!fileUploads["LOILetter"] && (
                        <img
                          style={{ width: "100%", cursor: "pointer" }}
                          src={uploadFile}
                          className=""
                          alt="Upload Image"
                        />
                      )}
                    </button>
                    <input
                      id="LOILetter"
                      type="file"
                      onChange={(e) => handleFileUpload("LOILetter", e)}
                      style={{ cursor: "pointer" }}
                    />
                    {fileUploads["LOILetter"] ? (
                      <div className="fileUploads">
                        <div className="fileUploads-div-2">
                          <div
                            className="fileUploads-div-3"
                            style={{
                              textDecoration: "none",
                              width: "fit-content",
                              maxWidth: "200px",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {fileInfo.LOILetter.filename}
                          </div>
                        </div>
                        <img
                          cursor="pointer"
                          loading="lazy"
                          src={Bin}
                          className="fileUploads-img"
                          onClick={(e) => handleDeleteFile(e, "LOILetter")}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </FormControl>
              </div>
            </div>
          </div>
          <button
            className="reason-submit IOI-bid-submit"
            onClick={handleLOISubmit}
          >
            Submit
          </button>
        </Box>
      </Modal>
    </>
  );
};

export default LOIBidModal;
