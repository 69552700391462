import React, { useState, useEffect } from "react";
import Toast from "../../../Common/Toast";
import { isValidLinkedInUrl } from "../../../Common/functions";

import ChangePasswordModal from "../../../Common/modals/changePasswordModal";

import { useNavigate } from "react-router-dom";
import { FormControl, FormLabel, Input } from "@mui/joy";
import FormControlLabel from "@mui/material/FormControlLabel";
import { MuiTelInput } from "mui-tel-input";
import Switch from "@mui/material/Switch";
import { Helmet } from "react-helmet";
import TextField from "@mui/material/TextField";

import editImg from "../../../../Assets/Images/signup/edit-img.svg";
import uploadProfile from "../../../../Assets/Images/signup/upload-profile.svg";
import {
  fetchStartupMemberDetails,
  memberUpdate,
} from "../../../../endpoints/startup";
import { getFundrevAnalystDetailsById } from "../../../../endpoints/fundrevAnalyst";
import { useLoader } from "../../../Common/LoaderProvider";
import { ToastContainer } from "react-toastify";

const Account = () => {
  const Navigate = useNavigate();
  const token = localStorage.getItem("token");
  const loader = useLoader();
  const [userDetails, setUserDetails] = useState({});
  const [email, setEmail] = useState("");
  const [startupID, setStartupID] = useState("");
  const [linkedinUrlError, setLinkedinUrlError] = useState("");
  const [memberImgFile, setMemberImgFile] = useState("");
  const [isPushSwitchOn, setPushSwitchOn] = useState(true);
  const [isEmailSwitchOn, setEmailSwitchOn] = useState(true);
  const [passwordModalOpen, setPasswordModalOpen] = useState(false);
  const [analyst, setAnalyst] = useState({});
  const [formData, setFormData] = useState({
    name: "",
    title: "",
    linkedIn: "",
    phoneNumber: "",
    pushNotifications: isPushSwitchOn,
    emailNotifications: isEmailSwitchOn,
    memberImg: "",
    biography: "",
  });
  const handleSwitchChange = async (type) => {
    const formdata = new FormData();
    const updatedData =
      type === 1
        ? { pushNotifications: !isPushSwitchOn }
        : { emailNotifications: !isEmailSwitchOn };

    setFormData({ ...formData, ...updatedData });
    type === 1
      ? setPushSwitchOn(!isPushSwitchOn)
      : setEmailSwitchOn(!isEmailSwitchOn);

    const data = { ...updatedData, email };

    // Append each key-value pair to the FormData
    for (const key in data) {
      formdata.append(key, data[key]);
    }
    try {
      loader.start("Updating account settings...");
      console.log(token);
      const response = await memberUpdate(data, token);
      loader.stop();
    } catch (error) {
      loader.stop();
    }
  };
  useEffect(() => {
    const fetchAnalystDetails = async () => {
      try {
        const response = await getFundrevAnalystDetailsById({
          fundrevAnalystID: "FN123456",
        });
        //
        setAnalyst(response.data.data);
      } catch (error) {
        loader.stop();
      }
    };

    const fetchDetails = async () => {
      let response;
      if (token) {
        try {
          loader.start("Fetching  details...");
          response = await fetchStartupMemberDetails(token);
          loader.stop();
        } catch (error) {
          loader.stop();
        }
      }
      if (token) {
        if (response && response.data !== null) {
          setUserDetails(response.data.data);
          setEmail(response.data.data.email);
          setStartupID(response.data.data.startupID);
          setPushSwitchOn(response.data.data.pushNotifications);
          setEmailSwitchOn(response.data.data.emailNotifications);
        } else {
        }
      }
    };
    fetchDetails();
    fetchAnalystDetails();
  }, [token]);
  useEffect(() => {
    setFormData({
      name: userDetails.name,
      title: userDetails.title,
      email: userDetails.email,
      linkedIn: userDetails.linkedIn,
      phoneNumber: userDetails.phoneNumber,
      biography: userDetails.biography,
    });
  }, [userDetails]);
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "linkedIn") {
      const errors = [...linkedinUrlError];
      if (!isValidLinkedInUrl(value)) {
        errors[0] = "Please enter a valid LinkedIn URL.";
      } else {
        errors[0] = "";
      }
      setLinkedinUrlError(errors);
    }
    setFormData({ ...formData, [name]: value });
  };
  const handleBlur = (index) => {
    const errors = [...linkedinUrlError];
    if (!isValidLinkedInUrl(formData.linkedIn)) {
      errors[0] = "Please enter a valid LinkedIn URL.";
    } else {
      errors[0] = "";
    }
    setLinkedinUrlError(errors);
  };
  const handlePhoneChange = (phoneNumber) => {
    setFormData({ ...formData, phoneNumber: phoneNumber });
  };
  function handleImageUpload(e) {
    const file = e.target.files[0];

    setMemberImgFile(file);
    setFormData({ ...formData, memberImg: file });
  }
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    for (const key in formData) {
      if (
        formData.hasOwnProperty(key) &&
        key !== "memberImg" &&
        key !== "pushNotifications" &&
        key !== "emailNotifications" &&
        key !== "linkedIn"
      ) {
        if (!formData[key]) {
          Toast("Please fill all the fields", "warning", "companyAccount");
          return;
        }
      }
    }
    const data = {
      ...formData,
      memberImg: memberImgFile,
    };
    const formDataInput = new FormData();
    for (const key in data) {
      if (data[key] === true || data[key] === false) {
        formDataInput.append(key, data[key]);
      } else if (data.hasOwnProperty(key)) {
        if (data[key]) {
          if (typeof data[key] === "object" && !(data[key] instanceof File)) {
            formDataInput.append(key, JSON.stringify(data[key]));
          } else {
            formDataInput.append(key, data[key]);
          }
        }
      }
    }
    if (!data.startupID) {
      formDataInput.append("startupID", startupID);
    }
    let response = null;
    try {
      loader.start("Updating member details...");
      response = await memberUpdate(formDataInput, token);
      loader.stop();
    } catch (error) {
      loader.stop();
    }
    if (response?.data !== null) {
      if (response.data.status === 200) {
        Toast(
          "Member details updated successfully",
          "success",
          "companyAccount"
        );
      } else {
        Toast("Failed to update member details", "error", "companyAccount");
      }
    } else {
      loader.stop();
      console.error("Error updating member details:", response.error);
    }
  };

  return (
    <div className="investorDetails investor-account-box">
      <Helmet>
        <title>Company | Account</title>
      </Helmet>
      <ToastContainer position="top-center" containerId="companyAccount" />
      <ChangePasswordModal
        passwordModalOpen={passwordModalOpen}
        setPasswordModalOpen={setPasswordModalOpen}
        token={token}
        containerId="companyAccount"
      />
      {/* <ToastContainer position="top-center" /> */}
      <div className="investor-personal-details-section">
        <div className="upload-btn-wrapper">
          <p className="investor-account-subheading">Account Details</p>
          <button className="upload-img-btn">
            <img
              src={
                memberImgFile
                  ? URL.createObjectURL(memberImgFile)
                  : userDetails.memberImg
                  ? userDetails.memberImg
                  : uploadProfile
              }
              className="InvestorLogo"
              alt="Upload Image"
              style={{ cursor: "pointer" }}
            />
          </button>
          {/* Check if a userDetails.memberImg is a file if not then check wheter it is empty or not */}
          {(memberImgFile instanceof File || userDetails.memberImg !== "") && (
            <label htmlFor={`founding-profile`} className="edit-img-btn">
              <img src={editImg} className="editImg" alt="Edit" />
            </label>
          )}
          <input
            id={`founding-profile`}
            type="file"
            name="image"
            accept="image/png, image/jpeg" // Accept only JPEG and PNG
            onChange={(e) => handleImageUpload(e)}
            style={{ cursor: "pointer" }}
          />
        </div>
        <form className="input-section">
          <div className="input-div">
            <FormControl
              className="form-control investorDetails-row-input"
              required
              size="lg"
              color="primary"
            >
              <FormLabel className="label">Full Name</FormLabel>
              <Input
                className="input"
                placeholder="Full Name"
                color="neutral"
                name="name"
                type="text"
                value={formData.name}
                onChange={(e) => {
                  handleInputChange(e);
                }}
                required
              />
            </FormControl>
            <FormControl
              className="form-control investorDetails-row-input"
              required
              size="lg"
              color="primary"
            >
              <FormLabel className="label">Title</FormLabel>
              <Input
                className="input"
                placeholder="Title"
                color="neutral"
                name="title"
                type="text"
                value={formData.title}
                onChange={(e) => {
                  handleInputChange(e);
                }}
                required
              />
            </FormControl>
            <FormControl
              className="form-control investorDetails-row-input"
              required
              size="lg"
              color="primary"
            >
              <FormLabel className="label">Official Email Address</FormLabel>
              <Input
                className="input"
                placeholder="Email"
                color="neutral"
                name="email"
                type="email"
                value={formData.email}
                disabled
                required
              />
            </FormControl>
          </div>
          <div className="input-div">
            <FormControl
              className="form-control investorDetails-row-input"
              size="lg"
              color="primary"
            >
              <FormLabel className="label">LinkedIn Profile</FormLabel>
              <Input
                className="input"
                placeholder="LinkedIn Profile"
                color="neutral"
                name="linkedIn"
                type="url"
                value={formData.linkedIn}
                onChange={(e) => {
                  handleInputChange(e);
                }}
                onBlur={() => handleBlur(0)}
              />
              {linkedinUrlError[0] && (
                <div style={{ color: "red" }}>{linkedinUrlError[0]}</div>
              )}
            </FormControl>
            <FormControl
              className="form-control investorDetails-row-input"
              required
              size="lg"
              color="primary"
            >
              <FormLabel className="label">Phone Number</FormLabel>
              <MuiTelInput
                defaultCountry="IN"
                className="input"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={(phoneNumber) => handlePhoneChange(phoneNumber)}
                required
                sx={{
                  "& .MuiOutlinedInput-root": {
                    height: "25px",
                  },
                }}
              />
            </FormControl>
            <FormControl
              className="form-control investorDetails-row-input"
              style={{ visibility: "hidden" }}
            >
              <FormLabel className="label" style={{ visibility: "hidden" }}>
                Biography
              </FormLabel>
              <Input style={{ visibility: "hidden" }} className="input" />
            </FormControl>
          </div>
          <div className="input-div account-biography">
            <FormControl
              className="business-desc-form-control"
              size="lg"
              color="primary"
              required
            >
              <FormLabel className="label">Biography</FormLabel>
              <TextField
                multiline
                minRows={3}
                className="large-input"
                name="biography"
                value={formData.biography}
                onChange={(e) => {
                  handleInputChange(e);
                }}
              />
            </FormControl>
          </div>
          <button
            className="stage-display-btn ask-question"
            style={{ marginTop: "30px", padding: "15px 40px" }}
            onClick={handleFormSubmit}
          >
            Save
          </button>
        </form>
      </div>

      <div className="investor-account-change-password">
        <p className="investor-account-subheading">
          Password and Authentication
        </p>
        <button
          className="showInterestButtons-div-3"
          style={{ border: "0px", fontSize: "16px" }}
          onClick={() => setPasswordModalOpen(true)}
        >
          Change Password
        </button>
      </div>
      {/* <div className="investor-account-notifications">
        <p className="investor-account-subheading">Notifications</p>
        <div className="notifications-switches">
          <FormControlLabel
            control={
              <Switch
                color="primary"
                checked={isPushSwitchOn}
                onChange={() => handleSwitchChange(1)}
              />
            }
            label={
              <span
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                  width: "150px",
                  display: "block",
                }}
              >
                Push Notifications
              </span>
            }
            labelPlacement="start"
            style={{ gap: "6px", marginLeft: "0px" }}
          />
          <FormControlLabel
            control={
              <Switch
                color="primary"
                checked={isEmailSwitchOn}
                onChange={() => handleSwitchChange(2)}
              />
            }
            label={
              <span
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                  width: "150px",
                  display: "block",
                }}
              >
                Email Notifications
              </span>
            }
            labelPlacement="start"
            style={{ gap: "6px", marginLeft: "0px" }}
          />
        </div>
      </div> */}
      <div className="investor-account-delete">
        <p className="investor-account-subheading">Delete Account</p>
        <p className="investor-account-delete-text">
          If you like to delete your Fundrev account linked with:{" "}
          <span className="blue-text">{formData.email},</span> <br />
          <span>
            Please write us on{" "}
            <a
              href="mailto:admin@fundrev.ai"
              className="blue-text"
              style={{
                textDecoration: "None",
              }}
            >
              admin@fundrev.ai
            </a>
            .
          </span>
        </p>
        {/* <p className="investor-account-delete-subtext">
          Deleting your account will remove all of your content and data
          associated with it.
        </p>
        <p
          className="investor-account-delete-button-text"
          onClick={() => setPasswordModalOpen(true)}
          style={{ cursor: "pointer" }}
        >
          I want to delete my account
        </p> */}
      </div>
    </div>
  );
};

export default Account;
