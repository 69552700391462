import React from "react";
import { TextField, InputAdornment } from "@mui/material";

// Custom Input Component
const CustomInput = ({
  placeholder,
  name,
  value,
  onChange,
  error,
  helperText,
  type = "text", // Default input type
  multiline = false, // Add this prop to control multiline
  minRows = 1, // Number of rows for multiline text
  startDecorator, // New prop to pass start adornment like rupee symbol
  handleKeyDown,
  ...rest
}) => {
  return (
    <TextField
      fullWidth
      type={type}
      variant="outlined"
      placeholder={placeholder}
      name={name}
      value={value}
      onChange={onChange}
      error={error}
      helperText={helperText}
      multiline={multiline} // Apply multiline prop
      minRows={minRows} // Apply rows prop for multiline
      onKeyDown={handleKeyDown ? handleKeyDown : undefined}
      InputProps={{
        startAdornment: startDecorator ? (
          <InputAdornment position="start">{startDecorator}</InputAdornment>
        ) : null,
      }} // Apply startAdornment for fields like revenue
      {...rest}
      sx={{
        "& .MuiFormHelperText-root": {
          fontFamily: "Karla, sans-serif",
        },
        "& .MuiOutlinedInput-root": {
          borderRadius: "6px",
          borderWidth: "0.5px",
          "& fieldset": {
            borderColor: "#8692A6", // Custom border color
          },
          "&:hover fieldset": {
            borderColor: "#3C82F6", // Color on hover
          },
          "&.Mui-focused fieldset": {
            borderColor: "#3C82F6", // Color when focused
          },
          "& input": {
            padding: "12px 16px",
            fontFamily: "'Karla', sans-serif",
          },
          "& textarea": {
            fontFamily: "'Karla', sans-serif",
          },
          "& input::placeholder, & textarea::placeholder": {
            fontFamily: "'Karla', sans-serif",
            fontSize: "14px",
            color: "#696F79",
            opacity: 1,
            fontWeight: 300,
          },
        },
      }}
    />
  );
};

export default CustomInput;
