import React, { useState } from "react";
import { adminLogin } from "../../endpoints/admin";
import Toast from "../Common/Toast";

import CustomInput from "../Common/components/custom/customInput";
import CustomPasswordInput from "../Common/components/custom/customPasswordInput";
import CustomBlackBtn from "../Common/components/custom/customBlackBtn";

import { Helmet } from "react-helmet";
import { useNavigate, Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import { Formik, Form } from "formik";
import { FormControl, FormLabel } from "@mui/material";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import * as Yup from "yup";
import { styled } from "@mui/material/styles";

import Logo from "../../Assets/Images/LandingPage/FUNDREV_LOGO.svg";

const AdminSignIn = () => {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Email is required"),
    userPassword: Yup.string().required("Password is required"),
  });

  const initialValues = {
    email: "",
    userPassword: "",
  };
  const StyledFormLabel = styled(FormLabel)`
    font-family: Karla;
    color: var(--Primary-Text-Color);
    font-size: 16px;
    font-weight: 600;
    ::after {
      content: " *";
      color: #d32f2f;
    }
  `;

  const StyledFormControl = styled(FormControl)`
    display: flex;
    gap: 8px;
  `;
  const handleSignInSubmit = async (values) => {
    try {
      const signInData = {
        email: values.email,
        password: values.userPassword,
      };
      const response = await adminLogin(signInData, token);
      if (response.data !== null) {
        localStorage.setItem("token", response.data.data.verificationToken);
        localStorage.setItem("role", "admin");
        if (response.data.message === "Admin signed in successfully") {
          navigate("/adminPanel", {
            state: {
              verificationToken: response.data.data.verificationToken,
            },
          });
        } else if (response.status === 401) {
          alert("Invalid credentials");
        }
      } else {
        Toast("Internal server error", "error", "adminSignin");
      }
    } catch (error) {
      Toast("Internal server error", "error", "adminSignin");
      console.error("Error signing in:", error);
    }
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSignInSubmit();
    }
  };
  return (
    <>
      <Helmet>
        <title>Fundrev | Sign In</title>
      </Helmet>
      <ToastContainer position="top-center" containerId="adminSignin" />
      {/* <div className="signUp-div signIn-div">
        <LeftSection
          heading="Accelerated Deal Closures"
          content="Fundrev closes deals 75% faster, saving time and resources."
        />
        <div className="investorRegisterWithHeader">
          <Header color="black" type="investor" />
          <div className="investorRegister">
            <div className="section">
              <div className="goBack" onClick={() => navigate(-1)}>
                <ArrowBackIosIcon />
                <Typography className="back-text">Back</Typography>
              </div>
              <div className="title">
                <Typography
                  variant="subtitle1"
                  color="grey"
                  className="heading"
                >
                  Enter Admin Credentials
                </Typography>
              </div>
              <div className="form">
                <FormControl
                  className="form-control full-width-input"
                  id="Id"
                  required
                  size="lg"
                  color="primary"
                >
                  <FormLabel className="label">Email Address</FormLabel>
                  <Input
                    className="input"
                    placeholder="Enter email address"
                    color="neutral"
                    type="email"
                    name="email"
                    value={signInData.email}
                    onChange={handleChange}
                  />
                </FormControl>
                <FormControl
                  className="form-control full-width-input"
                  id="Id"
                  required
                  size="lg"
                  color="primary"
                >
                  <FormLabel className="label">Password</FormLabel>
                  <Input
                    endDecorator={
                      !showPassword.value1 ? (
                        <VisibilityOffIcon
                          onClick={() =>
                            setShowPassword((prevState) => ({
                              ...prevState,
                              value1: true,
                            }))
                          }
                        />
                      ) : (
                        <Visibility
                          onClick={() =>
                            setShowPassword((prevState) => ({
                              ...prevState,
                              value1: false,
                            }))
                          }
                        />
                      )
                    }
                    className="input"
                    placeholder="Enter password"
                    color="neutral"
                    type={showPassword.value1 ? "text" : "password"}
                    value={signInData.password}
                    name="password"
                    onChange={handleChange}
                  />
                </FormControl>
                <Button
                  className="button proceed-btn"
                  fullWidth
                  onClick={handleSignInSubmit}
                  style={{ textAlign: "center" }}
                >
                  Sign In
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div className="sign-in-page">
        <ToastContainer position="top-center" containerId="signIn" />
        <div className="sign-in-page-header">
          <Link to="/">
            <img src={Logo} alt="fundrev_logo" />
          </Link>
        </div>

        <div className="sign-in-block">
          <div className="heading-block">
            <p className="fundrev-heading">Welcome Admin!</p>
            <p className="fundrev-subheading">
              Please enter your admin credentials to sign in to adminPanel.
            </p>
          </div>
          <div className="middle-block">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSignInSubmit}
            >
              {({ errors, touched, handleChange, handleBlur, values }) => (
                <Form
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "40px",
                    width: "100%",
                  }}
                >
                  <div className="sign-in-input-fields">
                    <div className="first-section">
                      <StyledFormControl fullWidth>
                        <StyledFormLabel>Email Address</StyledFormLabel>
                        <CustomInput
                          placeholder="Enter email address"
                          name="email"
                          type="email"
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={Boolean(touched.email && errors.email)}
                          helperText={touched.email && errors.email}
                          handleKeyDown={handleKeyDown}
                        />
                      </StyledFormControl>

                      <StyledFormControl fullWidth>
                        <StyledFormLabel>Password</StyledFormLabel>
                        <CustomPasswordInput
                          placeholder="Enter password"
                          name="userPassword"
                          value={values.userPassword}
                          onChange={handleChange}
                          error={Boolean(
                            touched.userPassword && errors.userPassword
                          )}
                          helperText={
                            touched.userPassword && errors.userPassword
                          }
                          handleKeyDown={handleKeyDown}
                        />
                      </StyledFormControl>
                    </div>
                  </div>
                  <div className="sign-in-btn-section laptop-design">
                    <CustomBlackBtn type="submit" text="Sign In" />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminSignIn;
