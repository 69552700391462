import React, { useState, useEffect } from "react";
import Header from "../../Common/components/header";
import Step from "./steps";
import Toast from "../../Common/Toast";
import { useLocation, useNavigate } from "react-router-dom";

import { Helmet } from "react-helmet";
import { ToastContainer } from "react-toastify";
import { Checkbox, FormControl, FormLabel, Input } from "@mui/joy";
import { MuiTelInput } from "mui-tel-input";

import editImg from "../../../Assets/Images/signup/edit-img.svg";
import uploadProfile from "../../../Assets/Images/signup/upload-profile.svg";
import Next from "../../../Assets/Images/signup/next.svg";
import previous from "../../../Assets/Images/signup/previous.svg";

import { fetchMembers, memberUpdate } from "../../../endpoints/startup";
import { TextField } from "@mui/material";
import TnC from "../../Founders/RegisterPage/TnC";
const StartupMembersOnBoarding = () => {
  const Navigate = useNavigate();
  const location = useLocation();
  const { name, title, startupID, phoneNumber, email } = location.state || {};
  const [fileUploads, setFileUploads] = useState({});
  const [memberImgFile, setMemberImgFile] = useState({});
  const [memberImg, setMemberImg] = useState("");
  const token = localStorage.getItem("token");
  const [formData, setFormData] = useState({
    memberImg: memberImgFile,
    name: "",
    title: "",
    email: "",
    linkedIn: "",
    phoneNumber: "",
    biography: "",
    ndaSigned: false,
    organizationId: startupID,
  });
  const [stepOneData, setStepOneData] = useState({
    memberImg: memberImgFile,
    name: "",
    title: "",
    email: "",
    linkedIn: "",
    phoneNumber: "",
    biography: "",
  });
  const [stepTwoData, setStepTwoData] = useState({
    ndaSigned: false,
  });
  const [stepsCompleted, setStepsCompleted] = useState({
    step1: false,
    step2: false,
  });
  useEffect(() => {
    setFormData({
      ...stepOneData,
      ...stepTwoData,
    });
  }, [stepOneData, stepTwoData]);
  useEffect(() => {
    const fetchAllMembers = async () => {
      try {
        const response = await fetchMembers(startupID, token);
        if (response) {
          const members = response.data.data;

          if (members) {
            const member = members.find((member) => member.email === email);
            if (member) {
              setStepOneData((prev) => ({
                ...prev,
                name: member.name,
                title: member.title,
                email: member.email,
                phoneNumber: member.phoneNumber,
              }));
            }
          }
        }
      } catch (error) {}
    };

    fetchAllMembers();
  }, [startupID]);

  const prevSection = (step1, step2, currentStepData) => {
    document.getElementsByClassName(step1)[0].style.display = "none";
    document.getElementsByClassName(step2)[0].style.display = "flex";
    window.scrollTo(0, 0);
  };

  function nextSection(step1, step2, currentStepData) {
    document.getElementsByClassName(step1)[0].style.display = "none";
    document.getElementsByClassName(step2)[0].style.display = "flex";
    window.scrollTo(0, 0);
  }
  function handleImageUpload(uploadType, e) {
    const file = e.target.files[0];

    if (file && (file.type === "image/png" || file.type === "image/jpeg")) {
      let fileUploadsKey;
      let setFileFunction;
      let setStepDataFunction;

      if (uploadType === "memberImg") {
        fileUploadsKey = "memberImg";
        setFileFunction = setMemberImg;
        setStepDataFunction = setStepOneData;
      }

      fileUploads[fileUploadsKey] = 1;
      const fileURL = URL.createObjectURL(file);
      setFileFunction(fileURL);
      setStepDataFunction((prevStepData) => ({
        ...prevStepData,
        [uploadType]: file,
      }));
    } else {
      Toast(
        "Please select an image file (PNG or JPG format only).",
        "info",
        "companyMemeberSignUp"
      );
    }
  }
  function handleFileUpload(e) {
    fileUploads["memberImg"] = 1;
    const file = e.target.files[0];
    if (file && (file.type === "image/png" || file.type === "image/jpeg")) {
      fileUploads["memberImg"] = 1;
      const fileURL = URL.createObjectURL(file);
      setMemberImg(fileURL);
      setStepOneData((prevStepOneData) => ({
        ...prevStepOneData,
        memberImg: file,
      }));
    } else {
      Toast(
        "Please select an image file (PNG or JPG format only.",
        "info",
        "companyMemeberSignUp"
      );
    }
  }
  const handleMemberDetails = (event) => {
    const { name, value } = event.target;

    setStepOneData({
      ...stepOneData,
      [name]: value,
    });
  };
  const handlePhoneChange = (phoneNumber) => {
    setStepOneData({
      ...stepOneData,
      phoneNumber: phoneNumber,
    });
  };
  const validateStep = (currentStepData, step) => {
    let isValid = true;
    switch (step) {
      case "first-step":
        isValid =
          currentStepData.memberImg &&
          currentStepData.name.trim() !== "" &&
          currentStepData.title.trim() !== "" &&
          currentStepData.email.trim() !== "" &&
          currentStepData.phoneNumber.trim() !== "" &&
          currentStepData.biography.trim() !== "";
        break;

      case "second-step":
        isValid = currentStepData.ndaSigned;
        break;

      default:
        break;
    }

    return isValid;
  };
  useEffect(() => {
    const validateAllSteps = () => {
      const steps = [
        { data: stepOneData, step: "first-step", key: "step1" },
        { data: stepTwoData, step: "second-step", key: "step2" },
      ];

      const updatedCompletionStatus = {};

      steps.forEach(({ data, step, key }) => {
        if (validateStep(data, step)) {
          updatedCompletionStatus[key] = true;
        } else {
          updatedCompletionStatus[key] = false;
        }
      });
      const areAllFirstFiveCompleted = ["step1", "step2"].every(
        (key) => updatedCompletionStatus[key]
      );

      setStepsCompleted(updatedCompletionStatus);
    };

    validateAllSteps();
  }, [stepOneData, stepTwoData]);
  const checkField = (currentStepData, step) => {
    let allFieldsFilled = true;
    let unfilledFields = [];

    const addUnfilledField = (fieldName) => {
      const formattedFieldName = fieldName
        .replace(/([A-Z])/g, " $1") // Add space before each uppercase letter
        .split(" ") // Split the string into an array of words
        .map(
          (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        ) // Capitalize the first letter of each word
        .join(" "); // Join the words back into a single string

      unfilledFields.push(formattedFieldName);
      allFieldsFilled = false;
    };

    switch (step) {
      case "first-step":
        [
          "name",
          "title",
          "email",
          // "linkedIn",
          "phoneNumber",
          "biography",
        ].forEach((field) => {
          if (!currentStepData[field] || currentStepData[field].trim() === "") {
            addUnfilledField(field.replace(/([A-Z])/g, " $1"));
          }
        });
        if (!currentStepData.memberImg) {
          addUnfilledField("Founding Team Profile");
        }
        break;

      case "second-step":
        if (!currentStepData.ndaSigned) {
          addUnfilledField("Acceptance of Terms & Conditions");
        }
        break;

      default:
        break;
    }

    if (!allFieldsFilled) {
      Toast(
        `Please fill in all fields in '${step}': ${unfilledFields.join(", ")}`,
        "info",
        "companyMemeberSignUp"
      );
    }

    return allFieldsFilled;
  };
  const postFormData = async (data) => {
    const formDataInput = new FormData();

    // Loop through each key in the data object and append it to FormData
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        if (data[key]) {
          // Check if the property is an array or an object that needs to be stringified
          if (typeof data[key] === "object" && !(data[key] instanceof File)) {
            formDataInput.append(key, JSON.stringify(data[key]));
          } else {
            formDataInput.append(key, data[key]);
          }
        }
      }
    }
    const response = await memberUpdate(formDataInput, token);
    return response;
  };
  const saveAndFinish = async (step1, step2, currentStepData) => {
    if (
      !checkField(stepOneData, "first-step") ||
      !checkField(stepTwoData, "second-step")
    ) {
      return;
    }
    try {
      const response = await postFormData(formData);
      if (response.data.status === 200) {
        Toast(
          "Member details saved successfully",
          "success",
          "companyMemeberSignUp"
        );
        setTimeout(() => {
          Navigate("/signin");
        }, 2000); // Move the delay outside the function body
      }
    } catch (error) {}
  };
  return (
    <section className="investorDetailsPage">
      <Helmet>
        <title>Fundrev | Members Onboarding</title>
      </Helmet>
      <ToastContainer
        position="top-center"
        containerId="companyMemeberSignUp"
      />
      <Header color="black" token="Startuptoken" type="Startup" />
      <div className="investorDetails first-step">
        <Step currentStep={1} stepsCompleted={stepsCompleted}></Step>
        <div className="mobile-div">
          <div className="mobile-div-2">
            <div className="mobile-div-3">
              <div className="mobile-div-4">50%</div>
              <div className="mobile-div-5">Your Details</div>
              <div className="mobile-div-6">In progress</div>
            </div>
            <div className="mobile-div-7">
              <div className="mobile-div-8" />
            </div>
          </div>
        </div>
        <div className="details-section">
          <div className="investorDetails-first-section">
            <p className="investorDetails-heading">Your Details</p>
            <div className="upload-btn-wrapper">
              <button className="upload-img-btn">
                <img
                  src={fileUploads["memberImg"] ? memberImg : uploadProfile}
                  className="InvestorLogo"
                  alt="Upload Image"
                  style={{ cursor: "pointer" }}
                />
              </button>

              {stepOneData.memberImg && (
                <label htmlFor={`founding-profile`} className="edit-img-btn">
                  <img src={editImg} className="editImg" alt="Edit" />
                </label>
              )}
              <input
                id={`founding-profile`}
                type="file"
                name="image"
                accept="image/png, image/jpeg"
                onChange={handleFileUpload}
                style={{ cursor: "pointer" }}
              />
            </div>
            <form className="input-section">
              <div className="input-div">
                <FormControl
                  className="form-control investorDetails-row-input"
                  required
                  size="lg"
                  color="primary"
                >
                  <FormLabel className="label">Full Name</FormLabel>
                  <Input
                    className="input"
                    placeholder="Full Name"
                    color="neutral"
                    name="name"
                    type="text"
                    value={stepOneData.name}
                    onChange={handleMemberDetails}
                    required
                  />
                </FormControl>
                <FormControl
                  className="form-control investorDetails-row-input"
                  required
                  size="lg"
                  color="primary"
                >
                  <FormLabel className="label">Title</FormLabel>
                  <Input
                    className="input"
                    placeholder="Title"
                    color="neutral"
                    name="title"
                    type="text"
                    value={stepOneData.title}
                    onChange={handleMemberDetails}
                    required
                  />
                </FormControl>
                <FormControl
                  className="form-control investorDetails-row-input"
                  required
                  size="lg"
                  color="primary"
                >
                  <FormLabel className="label">
                    Official Email Address
                  </FormLabel>
                  <Input
                    className="input"
                    placeholder="Email"
                    color="neutral"
                    name="email"
                    type="email"
                    value={stepOneData.email}
                    onChange={handleMemberDetails}
                    required
                    disabled
                  />
                </FormControl>
              </div>
              <div className="input-div">
                <FormControl
                  className="form-control investorDetails-row-input"
                  s
                  size="lg"
                  color="primary"
                >
                  <FormLabel className="label">linkedIn Profile</FormLabel>
                  <Input
                    className="input"
                    placeholder="linkedIn Profile"
                    color="neutral"
                    name="linkedIn"
                    type="url"
                    value={stepOneData.linkedIn}
                    onChange={handleMemberDetails}
                    required
                  />
                </FormControl>
                <FormControl
                  className="form-control investorDetails-row-input"
                  required
                  size="lg"
                  color="primary"
                >
                  <FormLabel className="label">Phone Number</FormLabel>
                  <MuiTelInput
                    defaultCountry="IN"
                    className="input"
                    name="phoneNumber"
                    value={stepOneData.phoneNumber}
                    onChange={(phoneNumber) => handlePhoneChange(phoneNumber)}
                    required
                  />
                </FormControl>
                <FormControl
                  className="form-control investorDetails-row-input"
                  style={{
                    visibility: "hidden",
                  }}
                >
                  <FormLabel className="label"></FormLabel>
                  <Input className="input" />
                </FormControl>
              </div>
              <div className="input-div companyDetails-business-desc-div">
                <FormControl
                  className="form-control investorDetails-row-input"
                  required
                  size="lg"
                >
                  <FormLabel className="label">Biography</FormLabel>
                  <TextField
                    className="large-input"
                    placeholder="Your Biography"
                    name="biography"
                    type="text"
                    value={stepOneData.biography}
                    onChange={handleMemberDetails}
                    multiline
                    minRows={3}
                    required
                  />
                </FormControl>
              </div>
            </form>
          </div>
          <div className="navigation-buttons">
            <button
              className="previousStep"
              style={{ visibility: "hidden" }}
              onClick={prevSection}
            >
              <img src={previous} alt="back-arrow" />
              &nbsp;&nbsp; Previous Step
            </button>
            <div className="next-buttons">
              <button
                className="nextStep"
                onClick={() => {
                  nextSection("first-step", "second-step", stepOneData);
                  // handleSave(stepOneData, "first-step");
                }}
              >
                Next Step&nbsp;&nbsp; <img src={Next} />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="investorDetails second-step">
        <Step currentStep={2} stepsCompleted={stepsCompleted}></Step>
        <div className="mobile-div">
          <div className="mobile-div-2">
            <div className="mobile-div-3">
              <div className="mobile-div-4">100%</div>
              <div className="mobile-div-5">Accept T&C</div>
              <div className="mobile-div-6">In progress</div>
            </div>
            <div className="mobile-div-7">
              <div className="mobile-div-8" />
            </div>
          </div>
        </div>
        <div className="details-section">
          <div className="investorDetails-first-section">
            <p className="investorDetails-heading">Accept T&C</p>
            <TnC />

            <p className="terms-condition-text">
              <Checkbox
                className="checkbox"
                sx={{
                  color: "#00a6fb",
                  "&.Mui-checked": {
                    color: "#00a6fb",
                  },
                }}
                checked={stepTwoData.ndaSigned}
                onChange={(event) =>
                  setStepTwoData({
                    ndaSigned: event.target.checked,
                  })
                }
              />
              <p className="AcceptTC-checkbox-text">
                By clicking this, I hereby acknowledge and confirm that I have
                read, understood, and fully accept the terms and conditions set
                forth in the Master Service Agreement and the Terms of Use
              </p>
            </p>
          </div>
          <div className="navigation-buttons">
            <button
              className="previousStep"
              onClick={() => {
                prevSection("second-step", "first-step", stepTwoData);
              }}
            >
              <img src={previous} alt="back-arrow" />
              &nbsp;&nbsp; Previous Step
            </button>
            <div className="next-buttons">
              <button
                className="nextStep"
                onClick={() => {
                  saveAndFinish("second-step", "third-step", stepTwoData);
                }}
              >
                Save & Finish&nbsp;&nbsp; <img src={Next} />
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default StartupMembersOnBoarding;
