import React from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { Checkbox, FormControl, FormLabel, styled } from "@mui/material";
import { Link } from "react-router-dom";
import CustomSelect from "../../../Common/components/custom/customSelect";
import CustomInput from "../../../Common/components/custom/customInput";
import CustomBlackBtn from "../../../Common/components/custom/customBlackBtn";
import CustomBlackBtnPhone from "../../../Common/components/custom/customBlackBtnPhone";

const CompanyRegisterStep3 = ({ handleSubmit, data }) => {
  const initialValuesStep3 = {
    revenue: data.revenue || "",
    fundingRequired: data.fundingRequired || "",
    fundingType: data.fundingType || "",
    timelineToRaise: data.timelineToRaise || "",
    roundType: data.roundType || "",
    acceptTC: false,
  };
  const StyledFormLabel = styled(FormLabel)`
    font-family: Karla;
    color: var(--Primary-Text-Color);
    font-size: 16px;
    font-weight: 600;
    ::after {
      content: " *";
      color: #d32f2f;
    }
  `;
  const StyledFormControl = styled(FormControl)`
    display: flex;
    gap: 8px;
  `;
  const validationSchemaStep3 = Yup.object().shape({
    revenue: Yup.string().required("Revenue is required"),
    fundingRequired: Yup.string().required("Funding required is required"),
    fundingType: Yup.string().required("Funding type is required"),
    timelineToRaise: Yup.string().required("Timeline to raise is required"),
    roundType: Yup.string().required("Round type is required"),
    acceptTC: Yup.boolean()
      .oneOf([true], "You must accept the terms and conditions")
      .required("You must accept the terms and conditions"),
  });
  return (
    <>
      <Formik
        initialValues={initialValuesStep3}
        validationSchema={validationSchemaStep3}
        onSubmit={handleSubmit}
      >
        {({
          errors,
          touched,
          handleChange,
          handleBlur,
          values,
          setFieldValue,
        }) => (
          <Form
            className="company-sign-up-page-2nd-page"
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "40px",
              width: "100%",
            }}
          >
            <div className="sign-up-input-fields">
              <StyledFormControl fullWidth>
                <StyledFormLabel>Revenue (Last 12 months)</StyledFormLabel>
                <CustomInput
                  placeholder="Enter revenue in crores"
                  name="revenue"
                  type="number"
                  value={values.revenue}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={Boolean(touched.revenue && errors.revenue)}
                  helperText={touched.revenue && errors.revenue}
                  startDecorator="₹"
                />
              </StyledFormControl>
              <StyledFormControl fullWidth>
                <StyledFormLabel>Backing</StyledFormLabel>
                <CustomSelect
                  label="Backing"
                  name="fundingType"
                  options={[
                    { value: "VC Backed", label: "VC Backed" },
                    { value: "Angel Backed", label: "Angel Backed" },
                    { value: "Bootstrapped", label: "Bootstrapped" },
                  ]}
                  value={values.fundingType}
                  onChange={handleChange}
                  placeholder="Select Backing"
                  error={Boolean(touched.fundingType && errors.fundingType)}
                  helperText={touched.fundingType && errors.fundingType}
                />
              </StyledFormControl>

              <StyledFormControl fullWidth>
                <StyledFormLabel>Funding Required</StyledFormLabel>
                <CustomInput
                  placeholder="Enter funding required in crores"
                  name="fundingRequired"
                  type="number"
                  value={values.fundingRequired}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={Boolean(
                    touched.fundingRequired && errors.fundingRequired
                  )}
                  helperText={touched.fundingRequired && errors.fundingRequired}
                  startDecorator="₹"
                />
              </StyledFormControl>

              <StyledFormControl fullWidth>
                <StyledFormLabel>Current Round Type</StyledFormLabel>
                <CustomSelect
                  label="Round Type"
                  name="roundType"
                  options={[
                    { value: "Pre Seed", label: "Pre Seed" },
                    { value: "Seed", label: "Seed" },
                    { value: "Pre-Series A", label: "Pre-Series A" },
                    { value: "Series A", label: "Series A" },
                    { value: "Series B", label: "Series B" },
                    { value: "Series C", label: "Series C" },
                    { value: "Series D", label: "Series D" },
                    { value: "Series D+", label: "Series D+" },
                    { value: "Debt", label: "Debt" },
                  ]}
                  value={values.roundType}
                  onChange={handleChange}
                  placeholder="Select Round Type"
                  error={Boolean(touched.roundType && errors.roundType)}
                  helperText={touched.roundType && errors.roundType}
                />
              </StyledFormControl>

              <StyledFormControl fullWidth>
                <StyledFormLabel>Timeline to Raise</StyledFormLabel>
                <CustomSelect
                  placeholder="Timeline to raise"
                  name="timelineToRaise"
                  options={[
                    {
                      value: "in less than 6 months",
                      label: "in less than 6 months",
                    },
                    { value: "6-12 months", label: "6-12 months" },
                    {
                      value: "more than 12 months",
                      label: "more than 12 months",
                    },
                  ]}
                  value={values.timelineToRaise}
                  onChange={handleChange}
                  error={Boolean(
                    touched.timelineToRaise && errors.timelineToRaise
                  )}
                  helperText={touched.timelineToRaise && errors.timelineToRaise}
                />
              </StyledFormControl>
            </div>
            <div className="tnc-text-block laptop-design">
              <Checkbox
                className="checkbox"
                aria-label="Checkbox"
                sx={{
                  color: "#00a6fb",
                  "&.Mui-checked": {
                    color: "#00a6fb",
                  },
                }}
                name="acceptTC"
                checked={values.acceptTC}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(touched.acceptTC && errors.acceptTC)}
              />
              <p>
                By clicking this, I hereby acknowledge and confirm that I have
                read, understood, and fully accept the platform's
                <a
                  href="https://fundrev.ai/terms-of-use"
                  target="_blank"
                  style={{ color: "#00A6FB" }}
                >
                  &nbsp; terms of use
                </a>{" "}
                and
                <a
                  href="https://fundrev.ai/privacy-policy"
                  target="_blank"
                  style={{ color: "#00A6FB" }}
                >
                  &nbsp;privacy policy.
                </a>
                <br />
                {touched.acceptTC && errors.acceptTC && (
                  <span style={{ color: "red" }}>{errors.acceptTC}</span>
                )}
              </p>
            </div>

            <div className="sign-up-btn-section laptop-design">
              <CustomBlackBtn type="submit" text="Continue" />
              <p>
                Already have an account?{" "}
                <Link to="/signin">
                  <span className="link-blue-text">Sign In</span>
                </Link>
              </p>
            </div>

            <div className="sign-up-btn-section-phone phone-design">
              <div className="tnc-text-block">
                <Checkbox
                  className="checkbox"
                  aria-label="Checkbox"
                  sx={{
                    color: "#00a6fb",
                    "&.Mui-checked": {
                      color: "#00a6fb",
                    },
                  }}
                  name="acceptTC"
                  checked={values.acceptTC}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={Boolean(touched.acceptTC && errors.acceptTC)}
                />
                <p>
                  By clicking this, I hereby acknowledge and confirm that I have
                  read, understood, and fully accept the platform's
                  <a
                    href="https://fundrev.ai/terms-of-use"
                    target="_blank"
                    style={{ color: "#00A6FB" }}
                  >
                    &nbsp; terms of use
                  </a>{" "}
                  and
                  <a
                    href="https://fundrev.ai/privacy-policy"
                    target="_blank"
                    style={{ color: "#00A6FB" }}
                  >
                    &nbsp;privacy policy.
                  </a>
                </p>
              </div>
              <CustomBlackBtnPhone type="submit" text="Continue" />
              <p>
                Already have an account?{" "}
                <Link to="/signin">
                  <span className="link-blue-text">Sign In</span>
                </Link>
              </p>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default CompanyRegisterStep3;
