import React from "react";
import AnalyticsPageTitle from "../analytics/AnalyticsPlots/AnalyticsPageTitle";
import { Box, Grid, Typography } from "@mui/material";
import { Link2Icon } from "@radix-ui/react-icons";
import FinancialPlot from "./FinancialPlot";
import FinanceSummaryTable from "./FinanceSummaryTable";
import PlotTemplate from "../analytics/AnalyticsPlots/PlotTemplate";
import SmallCardGrid from "./SmallCardGrid";

const AnalyticsSummary = ({ data, setAnalytics }) => {
  return (
    <Box
      sx={{
        marginBottom: "100px",
      }}
    >
      <AnalyticsPageTitle title={`Analytics Summary`} />
      <SmallCardGrid data={data?.fiveCards} />
      {data.plots.financialPlot.isVisible && (
        <Box>
          <Typography
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 0.5,
              cursor: "pointer",
              width: "30%",
              marginBottom: 1,
            }}
            onClick={() => setAnalytics(`businessAnalytics`)}
          >
            Financial Performance <Link2Icon />
          </Typography>
          <Box
            sx={{
              marginBottom: 3,
              background: "white",
              width: "1151px",
              height: "450px",
              borderRadius: "8px",
              paddingX: "10px",
              paddingTop: "10px",
            }}
          >
            <FinancialPlot data={data.plots.financialPlot} ltm={false} />
          </Box>
          <Grid container spacing={1.5} marginBottom={3}>
            <Grid item xs="auto" md="auto" lg="auto">
              <Box>
                <FinanceSummaryTable
                  data={data.tables.historicalIncomeStatement}
                  ltm={false}
                />
              </Box>
            </Grid>
            <Grid item xs="auto" md="auto" lg="auto">
              <Box>
                <FinanceSummaryTable
                  data={data.tables.forecastedIncomeStatement}
                  m={false}
                  q={false}
                  ltm={false}
                  t="ltm"
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}
      <Box>
        <Typography
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 0.5,
            cursor: "pointer",
            width: "30%",
            marginBottom: 1,
          }}
          onClick={() => setAnalytics(`salesAnalytics`)}
        >
          Customer Analytics
          <Link2Icon />
        </Typography>
        <Grid container spacing={1.5}>
          <Grid item xs="auto" md="auto" lg="auto">
            <Box
              sx={{
                backgroundColor: "#FFFFFF",
                width: "569px",
                borderRadius: "8px",
                boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.05)",
                fontFamily: "Karla, sans-serif",
                padding: 2,
                paddingRight: 0,
                paddingLeft: 2.5,
              }}
            >
              <PlotTemplate data={data.plots.numOrders} />
            </Box>
          </Grid>
          <Grid item xs="auto" md="auto" lg="auto">
            <Box
              sx={{
                backgroundColor: "#FFFFFF",
                width: "569px",
                borderRadius: "8px",
                boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.05)",
                fontFamily: "Karla, sans-serif",
                padding: 2,
                paddingRight: 0,
                paddingLeft: 2.5,
              }}
            >
              <PlotTemplate data={data.plots.aov} />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default AnalyticsSummary;
