import React, { useState, useEffect } from "react";
import "../../../CSS/Signup/investorDetails.css";
import "react-tagsinput/react-tagsinput.css";
import Header from "../../Common/components/header";
import Editable from "../../Common/components/editable";
import InputCell from "../../Common/components/inputCell";
import Step from "./steps";
import FileUpload from "./fileUpload";
import TnC from "../RegisterPage/TnC";
import { getFundrevAnalystDetailsById } from "../../../endpoints/fundrevAnalyst";
import {
  PostDetails,
  deleteMember,
  fetchMembers,
  fetchStartupDetails,
  fetchStartupMemberDetails,
  getValues,
  memberUpdate,
  startupAddMember,
} from "../../../endpoints/startup";
import { getDealIdbyStartupId, publishStartup } from "../../../endpoints/deal";
import {
  fetchBlobName,
  fetchFullFileName,
  isValidEmail,
  isValidUrl,
  isValidLinkedInUrl,
} from "../../Common/functions";
import { Helmet } from "react-helmet";
import { useLoader } from "../../Common/LoaderProvider";

import { Link } from "react-router-dom";
import { useLocation, useNavigate } from "react-router";
import { Box, Checkbox, FormControl, FormLabel, Input } from "@mui/joy";
import { MuiTelInput } from "mui-tel-input";
import Select from "react-select";
import DialogContentText from "@mui/material/DialogContentText";
import TagsInput from "react-tagsinput";
import { Modal, TextField, Typography } from "@mui/material";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import Toast from "../../Common/Toast";
import { ToastContainer } from "react-toastify";

import uploadImg from "../../../Assets/Images/signup/upload-img.svg";
import editImg from "../../../Assets/Images/signup/edit-img.svg";
import uploadProfile from "../../../Assets/Images/signup/upload-profile.svg";
import Next from "../../../Assets/Images/signup/next.svg";
import previous from "../../../Assets/Images/signup/previous.svg";
import members from "../../../Assets/Images/signup/members.svg";
import invite from "../../../Assets/Images/signup/invite.svg";
import { AiOutlinePlus } from "react-icons/ai";
import uploadFile from "../../../Assets/Images/signup/uploadFile.svg";
import uploadFileSmall from "../../../Assets/Images/signup/uploadFileSmall.svg";
import { RiDeleteBin6Line } from "react-icons/ri";
import Verify from "../../../Assets/Images/signup/InvestorRegister/Verify.png";
import Bin from "../../../Assets/Images/signup/bin.svg";
import FileImg from "../../../Assets/Images/signup/file.svg";
import companyOnboardingProcess from "../../../Assets/Images/signup/companyOnboardingProcess.svg";
import companyOnboardingProcessSmall from "../../../Assets/Images/signup/companyOnboardingProcessSmall.svg";
import Person from "../../../Assets/Images/signup/Person.svg";
import Illustration from "../../../Assets/Images/signup/illustration.svg";
import download from "../../../Assets/Images/signup/download.svg";

const CompanyDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const loader = useLoader();
  const [analyst, setAnalyst] = useState({});
  const [uploading, setUploading] = useState(false);
  const [startupID, setStartupID] = useState("");
  const [fileUploads, setFileUploads] = useState({});
  const [fileInfo, setFileInfo] = useState({});
  const [uploadedDocuments, setUploadedDocuments] = useState({});
  const [companyLogoFile, setCompanyLogoFile] = useState("");
  const [foundingTeamProfileFile, setFoundingTeamProfileFile] = useState([]);
  const [websiteError, setWebsiteError] = useState("");
  const [linkedinUrlError, setLinkedinUrlError] = useState([]);
  const [openSaveModal, setOpenSaveModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [newEmailAdded, setNewEmailAdded] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [name, setName] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [dealId, setDealId] = useState("");
  const [deletedFileNames, setDeletedFileNames] = useState({});
  const uploadImgUrl = windowWidth > 991 ? uploadFile : uploadFileSmall;
  const companyOnboardingProcessImg =
    windowWidth > 991
      ? companyOnboardingProcess
      : companyOnboardingProcessSmall;
  const [formData, setFormData] = useState({
    //screen1
    companyLogo: "",
    companyName: "",
    name: "",
    legalEntityName: "",
    headquarters: "",
    foundedYear: "",
    numEmployees: "",
    website: "",
    industryVerticals: [], // Assuming multi-select dropdown
    cin: "",
    taxNumber: "",
    endMarket: "",
    erpPlatform: "",
    description: "",
    //screen2
    foundingMember: [
      {
        foundingTeamProfile: "",
        name: "",
        title: "",
        email: "",
        linkedin: "",
        phoneNumber: "",
        biography: "",
      },
    ],
    //screen3
    fundingRequired: "",
    stakeOffered: "",
    minPostMoneyValuation: "",
    amountRaised: "",
    purpose: "",
    roundType: [],
    lastRoundType: "",
    keyInvestors: "",
    fundingRounds: [
      {
        date: "",
        round: "",
        raised: "",
        stake: "",
        valuation: "",
      },
    ],
    //screen4
    summary: "",
    pitch: "",
    businessPlan: "",
    competitorAnalysis: "",
    incomeStatement: "",
    balanceSheet: "",
    cashFlowStatement: "",
    auditReport: "",
    mis: "",
    financialModel: "",
    invoiceDetail: "",
    salesPipelineMetrics: "",
    marketingExpenses: "",
    profitabilityMetrics: "",
    otherMetrics: "",
    directorsProfiles: "",
    managementTeamProfile: "",
    organizationChart: "",
    memorandum: "",
    certificateIncorporation: "",
    taxIdentificationDocuments: "",
    sharePurchaseAgreements: "",
    debtAgreements: "",
    capitalizationTable: "",
    taxReturns: "",
    employeeCensus: "",
    employeeStockOptionPlan: "",
    employmentAgreements: "",
    productOverview: "",
    productRoadmap: "",
    recordedProductDemo: "",
    trademarks: "",
    patents: "",
    keyCustomerContracts: "",
    keyVendorContracts: "",
    securityContracts: "",
    otherMaterialAgreement: "",
    //screen5
    acceptTC: false, // For checkbox fields
    onBoardingComplete: false,
    isApplicationReviewed: false,
    //screen9
    inviteSentMembers: [],
    isActive: false,
    isAdmin: false,
    startupID: startupID,
    // Additional steps or specific fields based on your form requirements
  });

  // State for Screen 1
  const [stepOneData, setStepOneData] = useState({
    companyLogo: "",
    companyName: "",
    legalEntityName: "",
    headquarters: "",
    foundedYear: "",
    numEmployees: "",
    website: "",
    industryVerticals: [],
    cin: "",
    taxNumber: "",
    endMarket: "",
    erpPlatform: "",
    description: "",
  });

  // State for Screen 2
  const [stepTwoData, setStepTwoData] = useState({
    foundingMember: [
      {
        foundingTeamProfile: "",
        name: "",
        title: "",
        email: "",
        linkedin: "",
        phoneNumber: "",
        biography: "",
      },
    ],
  });

  useEffect(() => {}, [uploading]);

  // State for Screen 3
  const [stepThreeData, setStepThreeData] = useState({
    fundingRequired: "",
    stakeOffered: "",
    minPostMoneyValuation: "",
    amountRaised: "",
    purpose: "",
    roundType: [],
    lastRoundType: "",
    keyInvestors: "",
    fundingRounds: [
      {
        date: "",
        round: "",
        raised: "",
        stake: "",
        valuation: "",
      },
    ],
  });

  // State for Screen 4
  const [stepFourData, setStepFourData] = useState({
    summary: [],
    pitch: [],
    businessPlan: [],
    competitorAnalysis: [],
    incomeStatement: [],
    balanceSheet: [],
    cashFlowStatement: [],
    auditReport: [],
    mis: [],
    financialModel: [],
    invoiceDetail: [],
    salesPipelineMetrics: [],
    marketingExpenses: [],
    profitabilityMetrics: [],
    otherMetrics: [],
    directorsProfiles: [],
    managementTeamProfile: [],
    organizationChart: [],
    memorandum: [],
    certificateIncorporation: [],
    taxIdentificationDocuments: [],
    sharePurchaseAgreements: [],
    debtAgreements: [],
    capitalizationTable: [],
    taxReturns: [],
    employeeCensus: [],
    employeeStockOptionPlan: [],
    employmentAgreements: [],
    productOverview: [],
    productRoadmap: [],
    recordedProductDemo: [],
    trademarks: [],
    patents: [],
    keyCustomerContracts: [],
    keyVendorContracts: [],
    securityContracts: [],
    otherMaterialAgreement: [],
  });

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      // Standard message (some browsers may not display the custom message)
      const message =
        "Are you sure you want to leave? Your changes may not be saved.";
      event.returnValue = message; // Standard for most browsers
      return message; // Required for some older browsers
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  // State for Screen 5
  const [stepFiveData, setStepFiveData] = useState({
    acceptTC: false,
    onBoardingComplete: false,
    isApplicationReviewed: false,
  });
  const [stepNineData, setStepNineData] = useState({
    inviteMembers: [],
    inviteSentMembers: [],
  });
  const [industryVerticalOptions, setIndustryVerticalOptions] = useState([
    {
      value: "",
      label: "",
    },
  ]);

  const [roundTypeOptions, setRoundTypeOptions] = useState([
    {
      value: "",
      label: "",
    },
  ]);

  const [lastRoundTypeOptions, setLastRoundTypeOptions] = useState([
    {
      value: "",
      label: "",
    },
  ]);

  const endMarketOptions = [
    { value: "B2B", label: "B2B" },
    { value: "B2C", label: "B2C" },
    { value: "B2B2C", label: "B2B2C" },
    { value: "D2C", label: "D2C" },
  ];

  const [stepsCompleted, setStepsCompleted] = useState({
    step1: false,
    step2: false,
    step3: false,
    step4: false,
    step5: false,
    step6: false,
    step7: false,
    step9: true,
  });
  let stringFields = [
    "summary",
    "pitch",
    "businessPlan",
    "competitorAnalysis",
    "incomeStatement",
    "balanceSheet",
    "cashFlowStatement",
    "auditReport",
    "mis",
    "financialModel",
    "invoiceDetail",
    "salesPipelineMetrics",
    "marketingExpenses",
    "profitabilityMetrics",
    "otherMetrics",
    "directorsProfiles",
    "managementTeamProfile",
    "organizationChart",
    "memorandum",
    "certificateIncorporation",
    "taxIdentificationDocuments",
    "sharePurchaseAgreements",
    "debtAgreements",
    "capitalizationTable",
    "taxReturns",
    "employeeCensus",
    "employeeStockOptionPlan",
    "employmentAgreements",
    "productOverview",
    "productRoadmap",
    "recordedProductDemo",
    "trademarks",
    "patents",
    "keyCustomerContracts",
    "keyVendorContracts",
    "securityContracts",
    "otherMaterialAgreement",
  ];
  const documentsDictionary = {
    summary: "One Pager Summary",
    pitch: "Pitch deck",
    businessPlan: "Business Plan",
    competitorAnalysis: "Competitor Analysis",
    incomeStatement: "Monthly Income Statement (Last 3 years)",
    balanceSheet: "Monthly Balance Sheet (Last 3 years)",
    cashFlowStatement: "Monthly Cash Flow Statement (Last 3 Years)",
    auditReport: "Audit Report (Last 3 years)",
    mis: "Company MIS",
    financialModel: "Financial Model",
    invoiceDetail: "Invoice Detail",
    salesPipelineMetrics: "Historical Sales Pipeline Metrics",
    marketingExpenses: "Marketing Expenses / Performance",
    profitabilityMetrics: "Profitability Metrics",
    otherMetrics: "Any other Metrics",
    directorsProfiles: "Directors Profile",
    managementTeamProfile: "Management Team Profile",
    organizationChart: "Organization Chart",
    memorandum: "Memorandum & Articles of Association",
    certificateIncorporation: "Certificate of Incorporation",
    taxIdentificationDocuments: "Company ID - PAN/TAN",
    sharePurchaseAgreements: "Share Purchase Agreements",
    debtAgreements: "Debt Agreements (if any)",
    capitalizationTable: "Capitalization Table",
    taxReturns: "Tax Returns (Last 3 Years)",
    employeeCensus: "Employee Census",
    employeeStockOptionPlan: "Employee Stock Option Plan (if any)",
    employmentAgreements: "Employment Agreements",
    productOverview: "Product Overview",
    productRoadmap: "Product Roadmap",
    recordedProductDemo: "Recorded Product Demo",
    trademarks: "Trademarks",
    patents: "Granted & Filed Patents",
    keyCustomerContracts: "Key Customer Contracts",
    keyVendorContracts: "Key Vendor Contracts",
    securityContracts: "securityContracts",
    otherMaterialAgreement: "Any other material agreement",
  };

  const [data, setData] = useState(stepThreeData.fundingRounds);
  const getToken = localStorage.getItem("token");
  const [token, setToken] = useState(getToken !== null && getToken);

  const [memberData, setMemberData] = useState({
    name: "",
    title: "",
    companyName: "",
    organizationId: "",
    phoneNumber: "",
    email: "",
    password: "",
    confirmPassword: "",
  });
  useEffect(() => {
    if (stepFiveData.isApplicationReviewed) {
      document.getElementsByClassName("first-step")[0].style.display = "none";
      document.getElementsByClassName("seventh-step")[0].style.display = "flex";
    } else if (stepFiveData.onBoardingComplete) {
      document.getElementsByClassName("first-step")[0].style.display = "none";
      document.getElementsByClassName("eighth-step")[0].style.display = "flex";
    }
  }, [stepFiveData]);

  useEffect(() => {
    const fetchMemberDetails = async () => {
      if (!token) return;
      try {
        const response = await fetchStartupMemberDetails(token);
        if (response.data !== null) {
          const responseData = response.data;
          const { data } = responseData;
          setMemberData(data);
        }
      } catch (error) {}
    };
    fetchMemberDetails();
  }, []);
  const fetchAnalystDetails = async () => {
    try {
      const response = await getFundrevAnalystDetailsById({
        fundrevAnalystID: "FN123456",
      });
      setAnalyst(response.data.data);
    } catch (error) {}
  };
  useEffect(() => {
    fetchAnalystDetails();
  }, []);
  const fetchDetails = async () => {
    if (!token) return;
    let response = null;
    try {
      loader.start();
      response = await fetchStartupDetails(token);
      loader.stop();
    } catch (error) {
      loader.stop();
    }
    if (response?.data !== null) {
      const responseData = response.data;

      const { data } = responseData;
      const {
        startupID,
        companyName,
        name,
        cin,
        revenue,
        industry,
        fundingType,
        timeline,
        website,
        techstack,
        description,
        companyLogo,
        legalEntityName,
        headquarters,
        foundedYear,
        numEmployees,
        industryVerticals,
        finanicialStatus,
        taxNumber,
        endMarket,
        erpPlatform,
        // foundingTeamProfile,
        // name,
        // phoneNumber,
        // email,
        // title,
        // linkedin,
        // biography,
        foundingMember,
        fundingRequired,
        stakeOffered,
        minPostMoneyValuation,
        amountRaised,
        purpose,
        roundType,
        lastRoundType,
        keyInvestors,
        fundingRounds,
        summary,
        pitch,
        businessPlan,
        competitorAnalysis,
        incomeStatement,
        balanceSheet,
        cashFlowStatement,
        auditReport,
        mis,
        financialModel,
        invoiceDetail,
        salesPipelineMetrics,
        marketingExpenses,
        profitabilityMetrics,
        otherMetrics,
        directorsProfiles,
        managementTeamProfile,
        organizationChart,
        memorandum,
        certificateIncorporation,
        taxIdentificationDocuments,
        sharePurchaseAgreements,
        debtAgreements,
        capitalizationTable,
        taxReturns,
        employeeCensus,
        employeeStockOptionPlan,
        employmentAgreements,
        productOverview,
        productRoadmap,
        recordedProductDemo,
        trademarks,
        patents,
        keyCustomerContracts,
        keyVendorContracts,
        securityContracts,
        otherMaterialAgreement,
        acceptTC,
        inviteSentMembers,
        onBoardingComplete,
        isApplicationReviewed,
        isActive,
        isAdmin,
      } = data;
      if (data) {
        const parsedDetails = fundingRounds.map((detail) => JSON.parse(detail));
        if (data) {
          const parsedDetails = fundingRounds.map((detail) =>
            JSON.parse(detail)
          );
          if (data) {
            setName(name);
            setIsActive(isActive);
            setIsAdmin(isAdmin);

            setStepOneData({
              companyLogo,
              companyName,
              legalEntityName,
              headquarters,
              foundedYear,
              numEmployees,
              website,
              industryVerticals: industryVerticals.map((detail) =>
                JSON.parse(detail)
              )[0],
              cin,
              taxNumber,
              endMarket,
              erpPlatform,
              description,
            });
            if (parsedDetails.length > 0) {
              setData(parsedDetails[0]);
            }
            const parsedMemberDetails =
              foundingMember.length > 0 &&
              foundingMember.map((detail) => JSON.parse(detail));
            setStepTwoData({
              foundingMember: parsedMemberDetails
                ? parsedMemberDetails[0]
                : [
                    {
                      foundingTeamProfile: "",
                      name: "",
                      title: "",
                      email: "",
                      linkedin: "",
                      phoneNumber: "",
                      biography: "",
                    },
                  ],
            });
            setStepThreeData({
              fundingRequired,
              stakeOffered,
              minPostMoneyValuation,
              amountRaised,
              purpose,
              roundType: roundType.map((detail) => JSON.parse(detail))[0],
              lastRoundType,
              keyInvestors,
              fundingRounds: parsedDetails
                ? parsedDetails[0]
                : [
                    {
                      date: "",
                      round: "",
                      raised: "",
                      stake: "",
                      valuation: "",
                    },
                  ],
            });
            setStepFiveData({
              acceptTC,
              onBoardingComplete,
              isApplicationReviewed,
            });
            setStepNineData({
              inviteMembers: [],
              inviteSentMembers:
                inviteSentMembers.length > 0
                  ? inviteSentMembers.map((detail) => JSON.parse(detail))[0]
                  : [],
            });
            if (parsedDetails.length > 0) {
              setData(parsedDetails[0]);
            }
            setStartupID(startupID);
            const fields = [
              summary,
              pitch,
              businessPlan,
              competitorAnalysis,
              incomeStatement,
              balanceSheet,
              cashFlowStatement,
              auditReport,
              mis,
              financialModel,
              invoiceDetail,
              salesPipelineMetrics,
              marketingExpenses,
              profitabilityMetrics,
              otherMetrics,
              directorsProfiles,
              managementTeamProfile,
              organizationChart,
              memorandum,
              certificateIncorporation,
              taxIdentificationDocuments,
              sharePurchaseAgreements,
              debtAgreements,
              capitalizationTable,
              taxReturns,
              employeeCensus,
              employeeStockOptionPlan,
              employmentAgreements,
              productOverview,
              productRoadmap,
              recordedProductDemo,
              trademarks,
              patents,
              keyCustomerContracts,
              keyVendorContracts,
              securityContracts,
              otherMaterialAgreement,
            ];

            const newFileInfo = {};

            stringFields.forEach((field, index) => {
              if (fields[index]) {
                const urls = [];
                const fileUrls = [];

                fields[index].length > 0 &&
                  fields[index].map((file, index) => {
                    const newFileInfoEntry = {
                      filename: fetchBlobName(file),
                      fullName: fetchFullFileName(file),
                      size: "NA",
                      fileURL: file,
                    };
                    urls.push(newFileInfoEntry);
                    fileUrls.push(file);

                    setUploadedDocuments((prev) => ({
                      ...prev,
                      [field]: urls,
                    }));
                  });
                newFileInfo[field] = urls; // Store array of file info objects for each field
              }
            });
            setFileInfo((prevState) => ({
              ...prevState,
              ...newFileInfo,
            }));
            //
          }
        }
      }
    }
  };
  useEffect(() => {
    fetchDetails();
  }, []);

  const [invitedMembers, setInvitedMembers] = useState([]);
  const fetchMembersData = async () => {
    try {
      const response = await fetchMembers(startupID, token);

      if (response && response.data) {
        const membersData = response.data.data; // Adjust according to the actual structure of the response
        setInvitedMembers(membersData);
      }
    } catch (error) {
      console.error("Failed to fetch members:", error);
      // Optionally, handle errors by setting some state or showing a message
    }
  };

  useEffect(() => {
    fetchMembersData();
  }, [startupID]);

  useEffect(() => {
    setFormData({
      ...stepOneData,
      ...stepTwoData,
      ...stepThreeData,
      ...stepFourData,
      ...stepFiveData,
      ...stepNineData,
    });
  }, [
    stepOneData,
    stepTwoData,
    stepThreeData,
    stepFourData,
    stepFiveData,
    stepNineData,
  ]);
  useEffect(() => {
    const updatedFundingRounds = data.map((row) => {
      return row;
    });

    setStepThreeData((prev) => ({
      ...prev,
      fundingRounds: updatedFundingRounds,
    }));
  }, [data]);
  const customStyles = {
    control: (provided) => ({
      ...provided,
      height: "64px",
      width: "100%",
      fontSize: "14px",
      background: "none",
      border: "1px solid rgba(134, 146, 166, 1)",
      borderRadius: "6px",
      padding: "15px",
      fontFamily: '"Karla", sans-serif',
      minHeight: "64px",
      alignItems: "base-line",
    }),
  };

  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const columns = React.useMemo(
    () => [
      {
        Header: "Date",
        accessor: "date",
        placeholder: "date",
        Cell: InputCell,
      },
      {
        Header: "Round",
        accessor: "round",
        placeholder: "round",
        Cell: InputCell,
      },
      {
        Header: "Raised (₹Cr)",
        accessor: "raised",
        placeholder: "raised",
        Cell: InputCell,
      },
      {
        Header: "% Stake",
        accessor: "stake",
        placeholder: "stake",
        Cell: InputCell,
      },
      {
        Header: "Valuation (₹Cr)",
        accessor: "valuation",
        placeholder: "valuation",
        Cell: InputCell,
      },
      {
        id: "delete",
        Cell: ({ row }) =>
          row.index > 0 && (
            <RiDeleteBin6Line
              onClick={() => deleteRow(row.index)}
              className="red-bin"
            />
          ),
        width: 50,
      },
    ],
    []
  );

  const updateData = (rowIndex, columnId, value) => {
    setData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value,
          };
        }
        return row;
      })
    );
  };
  const addNew = () => {
    setData((oldData) => {
      // Validate last row data before adding a new row
      const lastRow = oldData[oldData.length - 1];
      if (
        !lastRow.date ||
        !lastRow.round ||
        !lastRow.raised ||
        !lastRow.stake ||
        !lastRow.valuation
      ) {
        Toast(
          "Please fill in all fields of the last row before adding a new one.",
          "info",
          "companyOnboarding"
        );
        return oldData; // Return the existing data without adding a new row if validation fails
      }

      // If validation is successful, add a new row
      return [
        ...oldData,
        { date: "", round: "", raised: "", stake: "", valuation: "" },
      ];
    });
  };

  const deleteRow = (rowIndex) => {
    setData((currentData) => {
      if (currentData.length > 1) {
        setStepThreeData((prevStepThreeData) => {
          const updatedFundingRounds = prevStepThreeData.fundingRounds.filter(
            (_, index) => index !== rowIndex
          );
          return {
            ...prevStepThreeData,
            fundingRounds: updatedFundingRounds,
          };
        });

        return currentData.filter((_, index) => index !== rowIndex);
      }
      return currentData;
    });
  };

  const handleDeleteMember = async (id, memberEmail) => {
    setStepNineData((prevState) => ({
      ...prevState,
      inviteSentMembers: prevState.inviteSentMembers.filter(
        (email) => email !== memberEmail
      ),
    }));

    try {
      const response = await deleteMember(id, startupID, token);
      if (response) {
        Toast("Member deleted successfully", "info", "companyOnboarding");
        fetchMembersData();
      } else {
        Toast("Error in deleting the member", "success", "companyOnboarding");
      }
    } catch (error) {
      Toast("Internal server error", "success", "companyOnboarding");
    }
  };

  useEffect(() => {
    const loadData = async () => {
      try {
        loader.start();
        const result = await getValues(); // Assuming this fetches all data
        loader.stop();
        if (result.error) {
          console.error("Error fetching data:", result.error);
        } else {
          // Process each options set
          if (result.data[0]) {
            if (result.data[0].IndustryVerticalOptions) {
              const formattedIndustryVerticalOptions =
                result.data[0].IndustryVerticalOptions.map((item) => ({
                  value: item,
                  label: item,
                }));
              setIndustryVerticalOptions(formattedIndustryVerticalOptions);
            }

            if (result.data[0].RoundTypeOptions) {
              const formattedRoundTypeOptions =
                result.data[0].RoundTypeOptions.map((item) => ({
                  value: item,
                  label: item,
                }));
              setRoundTypeOptions(formattedRoundTypeOptions);
            }

            if (result.data[0].LastRoundTypeOptions) {
              const formattedLastRoundTypeOptions =
                result.data[0].LastRoundTypeOptions.map((item) => ({
                  value: item,
                  label: item,
                }));
              setLastRoundTypeOptions(formattedLastRoundTypeOptions);
            }
          }
        }
      } catch (error) {
        loader.stop();
        console.error("Unexpected error fetching data:", error);
      }
    };

    loadData();
  }, []);

  const handlePhoneChange = (index, phoneNumber) => {
    const newFoundingMembers = [...stepTwoData.foundingMember];
    newFoundingMembers[index].phoneNumber = phoneNumber;
    // setFoundingMembers(newFoundingMembers);
    setStepTwoData({
      ...stepTwoData,
      foundingMember: newFoundingMembers,
    });
  };

  function handleFounderImageUpload(index, e) {
    const file = e.target.files[0];
    const newFoundingMembers = [...stepTwoData.foundingMember];
    const fileURL = URL.createObjectURL(file);
    newFoundingMembers[index].foundingTeamProfile = file;

    const newFoundingTeamProfileFile = [...foundingTeamProfileFile];
    newFoundingTeamProfileFile[index] = fileURL;
    setFoundingTeamProfileFile(newFoundingTeamProfileFile);
    setStepTwoData((prevState) => ({
      ...prevState,
      foundingMember: newFoundingMembers,
    }));
  }

  function handleImageUpload(uploadType, e) {
    const file = e.target.files[0];

    if (file && (file.type === "image/png" || file.type === "image/jpeg")) {
      let fileUploadsKey;
      let setFileFunction;
      let setStepDataFunction;

      if (uploadType === "companyLogo") {
        fileUploadsKey = "companyLogo";
        setFileFunction = setCompanyLogoFile;
        setStepDataFunction = setStepOneData;
      } else if (uploadType === "foundingTeamProfile") {
        fileUploadsKey = "foundingTeamProfile";
        setFileFunction = setFoundingTeamProfileFile;
        setStepDataFunction = setStepTwoData;
      }

      fileUploads[fileUploadsKey] = 1;
      const fileURL = URL.createObjectURL(file);
      setFileFunction(fileURL);
      setStepDataFunction((prevStepData) => ({
        ...prevStepData,
        [uploadType]: file,
      }));
    } else {
      Toast(
        "Please select an image file (PNG or JPG format only).",
        "info",
        "companyOnboarding"
      );
    }
  }

  const handleFileUpload = (uploadType, step, e) => {
    const files = Array.from(e.target.files);
    if (files.length === 0) return;
    const updatedFileUploads = { ...fileUploads };
    updatedFileUploads[uploadType] =
      (updatedFileUploads[uploadType] || 0) + files.length;
    const updatedFileInfo = { ...fileInfo };

    files.forEach((file) => {
      const size = file.size;
      const sizeInKB = parseInt(size / 1024);
      const filename = file.name;
      const fileURL = URL.createObjectURL(file);

      if (!updatedFileInfo[uploadType]) {
        updatedFileInfo[uploadType] = [];
      }
      updatedFileInfo[uploadType].push({ filename, size: sizeInKB, fileURL });
    });
    setFileUploads(updatedFileUploads);
    setFileInfo(updatedFileInfo);
    if (step === "third-step") {
      setStepThreeData((prev) => ({
        ...prev,
        [uploadType]: files,
      }));
    }
    if (step === "fourth-step") {
      const existingFiles = stepFourData[uploadType];
      let allFiles;
      if (existingFiles) {
        allFiles = [...existingFiles, ...files];
      } else {
        allFiles = files;
      }
      setStepFourData((prev) => ({
        ...prev,
        [uploadType]: allFiles,
      }));
    }
  };
  const handleDeleteFile = (uploadType, fileIndex, step, name) => {
    setDeletedFileNames((prevState) => {
      const newState = { ...prevState };
      if (!newState[uploadType]) {
        newState[uploadType] = [];
      }
      newState[uploadType] = newState[uploadType].concat(name);
      return newState;
    });
    setFileInfo((prevState) => {
      const newState = { ...prevState };
      const filesArray = newState[uploadType];

      if (filesArray && filesArray.length > 0) {
        filesArray.splice(fileIndex, 1); // Remove the file at the specified index

        if (filesArray.length === 0) {
          delete newState[uploadType]; // Remove the key if no files are left
        } else {
          newState[uploadType] = filesArray;
        }
      }

      return newState;
    });

    setFileUploads((prevState) => {
      const newUploadsState = { ...prevState };
      newUploadsState[uploadType] = newUploadsState[uploadType] - 1; // Decrease the upload count

      if (newUploadsState[uploadType] === 0) {
        delete newUploadsState[uploadType]; // Remove the key if no files are left
      }

      return newUploadsState;
    });

    if (step === "third-step") {
      setStepThreeData((prev) => {
        const newState = { ...prev };
        if (newState[uploadType]) {
          newState[uploadType] = newState[uploadType].filter(
            (_, index) => index !== fileIndex
          );
        }
        return newState;
      });
    }
    if (step === "fourth-step") {
      setStepFourData((prev) => {
        const newState = { ...prev };
        if (newState[uploadType]) {
          newState[uploadType] = newState[uploadType].filter(
            (_, index) => index !== fileIndex
          );
        }
        return newState;
      });
    }
  };

  const handleInputChangeStep1 = (event) => {
    const { name, value } = event.target;
    if (name === "numEmployees" && value < 0) {
      return;
    }
    if (
      name === "foundedYear" &&
      (value < 0 || value > new Date().getFullYear())
    ) {
      return;
    }
    if (name === "website") {
      if (!isValidUrl(value)) {
        setWebsiteError("Please enter a valid URL.");
      } else {
        setWebsiteError(""); // Clear the error message if the URL is valid
      }
    }
    setStepOneData({
      ...stepOneData,
      [name]: value,
    });
  };

  const handleInputChangeStep2 = (index, event) => {
    const { name, value } = event.target;
    if (name === "linkedin") {
      const errors = [...linkedinUrlError];
      if (!isValidLinkedInUrl(value)) {
        errors[index] = "Please enter a valid LinkedIn URL.";
      } else {
        errors[index] = "";
      }
      setLinkedinUrlError(errors);
    }
    const newFoundingMembers = [...stepTwoData.foundingMember];
    newFoundingMembers[index][name] = value;

    setStepTwoData({ ...stepTwoData, foundingMember: newFoundingMembers });
  };

  const handleBlur = (index) => {
    const errors = [...linkedinUrlError];
    if (!isValidLinkedInUrl(stepTwoData.foundingMember[index].linkedin)) {
      errors[index] = "Please enter a valid LinkedIn URL.";
    } else {
      errors[index] = "";
    }
    setLinkedinUrlError(errors);
  };

  const handleInputChangeStep3 = (event) => {
    const { name, value } = event.target;
    if (
      (name === "stakeOffered" ||
        name === "fundingRequired" ||
        name === "minPostMoneyValuation" ||
        name === "amountRaised") &&
      value < 0
    ) {
      return;
    }
    setStepThreeData({
      ...stepThreeData,
      [name]: value,
    });
  };

  const addFoundingMember = () => {
    const requiredFields = [
      "name",
      "title",
      "email",
      // "linkedin",
      "phoneNumber",
      "biography",
    ];
    const isAllFieldsFilled = (member) => {
      return requiredFields.every(
        (field) => member[field] && member[field].trim() !== ""
      );
    };

    setStepTwoData((prevState) => {
      const unfilledMembers = prevState.foundingMember.filter(
        (member) => !isAllFieldsFilled(member)
      );
      if (unfilledMembers.length > 0) {
        // You can display an error message here if needed
        console.error(
          "Please fill out all required fields for existing members before adding a new one."
        );
        Toast(
          "Please fill out all required fields for existing members before adding a new one.",
          "error",
          "companyOnboarding"
        );
        return prevState; // Do not add a new member
      }

      // All previous members have their details filled, add a new member
      return {
        ...prevState,
        foundingMember: [
          ...prevState.foundingMember,
          {
            name: "",
            title: "",
            email: "",
            linkedin: "",
            phoneNumber: "",
            biography: "",
            foundingTeamProfile: "",
          },
        ],
      };
    });
  };

  const handleSendInvite = async () => {
    const response = await startupAddMember(stepNineData.inviteMembers, token);
    if (response.data !== null) {
      setStepNineData((prevState) => ({
        ...prevState,
        inviteSentMembers: [
          ...prevState.inviteSentMembers,
          ...prevState.inviteMembers,
        ],
        inviteMembers: [],
      }));
      Toast("Member invited", "success", "companyOnboarding");
    } else {
      setStepNineData((prevState) => ({
        ...prevState,
        inviteMembers: [],
      }));
      Toast("Member already exist", "error", "companyOnboarding");
    }
    fetchMembersData();
  };

  const inviteFounderMember = async (index, email) => {
    const member = stepTwoData.foundingMember[index];
    const requiredFields = [
      "name",
      "title",
      "email",
      // "linkedin",
      "phoneNumber",
      "biography",
    ];

    const allFieldsFilled = requiredFields.every(
      (field) => member[field] && member[field].trim() !== ""
    );

    if (!allFieldsFilled) {
      Toast(
        "Please fill all the required fields before inviting the member.",
        "error",
        "companyOnboarding"
      );
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      Toast(
        "Please enter a valid email address.",
        "error",
        "companyOnboarding"
      );
      return;
    }

    const mem = invitedMembers.find((member) => member.email === email);
    if (mem) {
      Toast("Member already invited", "info", "companyOnboarding");
      const newFoundingMembers = [...stepTwoData.foundingMember];
      newFoundingMembers[index] = {
        name: "",
        title: "",
        email: "",
        linkedin: "",
        phoneNumber: "",
        biography: "",
        foundingTeamProfile: "",
      };
      const newProfileImage = [...foundingTeamProfileFile];
      newProfileImage[index] = "";
      setFoundingTeamProfileFile(newProfileImage);

      setStepTwoData((prevState) => ({
        ...prevState,
        foundingMember: newFoundingMembers,
      }));
      return;
    }

    try {
      const response = await startupAddMember([email], token);
      if (response.data !== null) {
        await postFormData(stepTwoData);
        fetchMembersData();
        setOpenSaveModal(true);
      } else {
        Toast("Error in inviting member", "error", "companyOnboarding");
      }
    } catch (error) {
      Toast("Error in inviting member", "error", "companyOnboarding");
    }
  };

  const deleteFounderMember = async (index, email) => {
    const newFoundingMembers = [...stepTwoData.foundingMember];
    newFoundingMembers.splice(index, 1);

    setStepTwoData({
      ...stepTwoData,
      foundingMember: newFoundingMembers,
    });

    // const newProfileImage = [...foundingTeamProfileFile];
    // newProfileImage[index] = "";
    //   setFoundingTeamProfileFile(newProfileImage);

    const member = invitedMembers.find((member) => member.email === email);

    try {
      const response = await deleteMember(member._id, member.startupId, token);
      if (response) {
        Toast("Member deleted successfully", "info", "companyOnboarding");
      } else {
      }
    } catch (error) {
      console.error("Error deleting member:", error);
    }
  };

  const handleIndustryVerticalChange = (selectedOption) => {
    const selectedValues = selectedOption.map((option) => option.value);
    setStepOneData((prev) => ({
      ...prev,
      industryVerticals: selectedValues,
    }));
  };

  const handleRoundTypeChange = (selectedOption) => {
    const selectedValues = selectedOption.map((option) => option.value);
    setStepThreeData((prev) => ({
      ...prev,
      roundType: selectedValues,
    }));
  };

  const handleEndMarketChange = (selected) => {
    setStepOneData((prev) => ({
      ...prev,
      endMarket: selected.value,
    }));
  };
  const handleLastRoundTypeChange = (selected) => {
    setStepThreeData((prev) => ({
      ...prev,
      lastRoundType: selected.value,
    }));
  };

  const validateStep = (currentStepData, step) => {
    let isValid = true;

    switch (step) {
      case "first-step":
        isValid =
          currentStepData.companyLogo &&
          currentStepData.companyName &&
          currentStepData.companyName.trim() !== "" &&
          currentStepData.legalEntityName &&
          currentStepData.legalEntityName.trim() !== "" &&
          currentStepData.headquarters &&
          currentStepData.headquarters.trim() !== "" &&
          currentStepData.foundedYear &&
          !isNaN(currentStepData.foundedYear) &&
          currentStepData.numEmployees &&
          currentStepData.numEmployees > 0 &&
          currentStepData.website &&
          currentStepData.website.trim() !== "" &&
          Array.isArray(currentStepData.industryVerticals) &&
          currentStepData.industryVerticals.length > 0 &&
          currentStepData.industryVerticals.every(
            (v) => v && v.trim() !== ""
          ) &&
          currentStepData.cin &&
          currentStepData.cin.trim() !== "" &&
          currentStepData.taxNumber &&
          currentStepData.taxNumber.trim() !== "" &&
          currentStepData.endMarket &&
          currentStepData.endMarket.trim() !== "" &&
          currentStepData.erpPlatform &&
          currentStepData.erpPlatform.trim() !== "" &&
          currentStepData.description &&
          currentStepData.description.trim() !== "";
        break;

      case "second-step":
        isValid =
          Array.isArray(currentStepData.foundingMember) &&
          currentStepData.foundingMember.length > 0 &&
          currentStepData.foundingMember[0].name.trim() !== "" &&
          currentStepData.foundingMember[0].title.trim() !== "" &&
          currentStepData.foundingMember[0].email.trim() !== "" &&
          // currentStepData.foundingMember[0].linkedin.trim() !== "" &&
          currentStepData.foundingMember[0].phoneNumber.trim() !== "" &&
          currentStepData.foundingMember[0].biography.trim() !== "";
        break;

      case "third-step":
        isValid =
          currentStepData.fundingRequired &&
          currentStepData.stakeOffered &&
          currentStepData.minPostMoneyValuation &&
          // currentStepData.amountRaised &&
          currentStepData.purpose &&
          currentStepData.purpose.trim() !== "" &&
          Array.isArray(currentStepData.roundType) &&
          currentStepData.roundType.length > 0 &&
          currentStepData.roundType.every((v) => v && v.trim() !== "");
        // currentStepData.lastRoundType &&
        // currentStepData.lastRoundType.trim() !== "";
        // Array.isArray(currentStepData.keyInvestors) &&
        // currentStepData.keyInvestors.length > 0 &&
        // currentStepData.keyInvestors &&
        // currentStepData.keyInvestors.trim() !== "" &&
        // ((currentStepData.capitalizationTable &&
        //   currentStepData.capitalizationTable.length > 0) ||
        //   (fileInfo.capitalizationTable &&
        //     fileInfo.capitalizationTable.length > 0)) &&
        // Array.isArray(currentStepData.fundingRounds) &&
        // currentStepData.fundingRounds.length > 0 &&
        // currentStepData.fundingRounds.every(
        //   (round) =>
        //     round.date.trim() !== "" &&
        //     round.round.trim() !== "" &&
        //     round.raised.trim() !== "" &&
        //     round.stake.trim() !== "" &&
        //     round.valuation.trim() !== ""
        // );
        break;

      case "fourth-step":
        isValid = stringFields.every(
          (field) =>
            (currentStepData[field] && currentStepData[field].length > 0) ||
            (fileInfo[field] && fileInfo[field].length > 0)
        );
        break;

      case "fifth-step":
        isValid = currentStepData.acceptTC === true;
        break;

      default:
        break;
    }

    return isValid;
  };

  useEffect(() => {
    const validateAllSteps = () => {
      const steps = [
        { data: stepOneData, step: "first-step", key: "step1" },
        { data: stepTwoData, step: "second-step", key: "step2" },
        { data: stepThreeData, step: "third-step", key: "step3" },
        { data: stepFourData, step: "fourth-step", key: "step4" },
        { data: stepFiveData, step: "fifth-step", key: "step5" },
        // { data: stepNineData, step: "ninth-step", key: "step9" },
      ];

      const updatedCompletionStatus = {};

      steps.forEach(({ data, step, key }) => {
        if (validateStep(data, step)) {
          updatedCompletionStatus[key] = true;
        } else {
          updatedCompletionStatus[key] = false;
        }
      });
      const areAllFirstFiveCompleted = [
        "step1",
        "step2",
        "step3",
        "step4",
        "step5",
        // "step9",
      ].every((key) => updatedCompletionStatus[key]);

      // Set steps 6 and 7 as completed only if steps 1 to 5 are completed
      updatedCompletionStatus.step6 = stepFiveData.onBoardingComplete;
      updatedCompletionStatus.step7 = false;
      updatedCompletionStatus.step9 = true;

      setStepsCompleted(updatedCompletionStatus);
    };

    validateAllSteps();
  }, [
    stepOneData,
    stepTwoData,
    stepThreeData,
    stepFourData,
    stepFiveData,
    stepNineData,
  ]);

  const prevSection = (step1, step2) => {
    document.getElementsByClassName(step1)[0].style.display = "none";
    document.getElementsByClassName(step2)[0].style.display = "flex";
    window.scrollTo(0, 0);
  };

  function nextSection(step1, step2, currentStepData) {
    if (step2 === "sixth-step") {
      fetchMembersData();
    }
    setFormData((prevFormData) => ({
      ...prevFormData,
      ...currentStepData, // Properly spread currentStepData's properties
    }));
    document.getElementsByClassName(step1)[0].style.display = "none";
    document.getElementsByClassName(step2)[0].style.display = "flex";
    window.scrollTo(0, 0);
  }

  const handleSave = async (currentStepData, currentStep, where) => {
    if (currentStep === "fourth-step") {
      setUploading(true);
    }
    try {
      loader.start();
      await postFormData(currentStepData); // Assuming postFormData is properly handling API requests
      loader.stop();
      if (currentStep === "fourth-step" && where === "prev") {
        prevSection("fourth-step", "third-step", stepThreeData);
        setStepFourData({});
        fetchDetails();
      } else if (currentStep === "fourth-step" && where === "next") {
        nextSection("fourth-step", "fifth-step", stepFourData);
        setStepFourData({});
        fetchDetails();
      }
    } catch (error) {
      loader.stop();
      console.error("Failed to save data:", error);
      Toast(
        "Failed to save data, please try again.",
        "error",
        "companyOnboarding"
      ); // User-friendly error message
    }
  };

  const checkField = (currentStepData, step, stepName) => {
    let allFieldsFilled = true;
    let unfilledFields = [];

    const addUnfilledField = (fieldName) => {
      const formattedFieldName = fieldName
        .replace(/([A-Z])/g, " $1") // Add space before each uppercase letter
        .split(" ") // Split the string into an array of words
        .map(
          (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        ) // Capitalize the first letter of each word
        .join(" "); // Join the words back into a single string

      unfilledFields.push(formattedFieldName);
      allFieldsFilled = false;
    };

    switch (step) {
      case "first-step":
        [
          "companyName",
          "legalEntityName",
          "headquarters",
          "foundedYear",
          "numEmployees",
          "website",
          // "industryVerticals",
          "cin",
          "taxNumber",
          "endMarket",
          "erpPlatform",
          "description",
        ].forEach((field) => {
          if (
            !currentStepData[field] ||
            (typeof currentStepData[field] === "string" &&
              currentStepData[field].trim() === "")
          ) {
            addUnfilledField(field.replace(/([A-Z])/g, " $1"));
          }
        });
        if (!currentStepData.companyLogo) {
          addUnfilledField("Company Logo");
        }
        if (
          !Array.isArray(currentStepData.industryVerticals) ||
          currentStepData.industryVerticals.length === 0 ||
          !currentStepData.industryVerticals.every((v) => v && v.trim() !== "")
        ) {
          addUnfilledField("Industry Verticals");
        }
        break;

      case "second-step":
        if (
          currentStepData.foundingMember &&
          currentStepData.foundingMember.length > 0
        ) {
          const firstMember = currentStepData.foundingMember[0];
          const requiredFields = [
            "name",
            "title",
            "email",
            // "linkedin",
            "phoneNumber",
            "biography",
          ];

          requiredFields.forEach((field) => {
            if (!firstMember[field] || firstMember[field].trim() === "") {
              addUnfilledField(field.replace(/([A-Z])/g, " $1"));
              // isValid = false;
            }
          });

          // if (!firstMember.foundingTeamProfile) {
          //   addUnfilledField("Founding Team Profile");
          //   isValid = false;
          // }
        } else {
          // isValid = false;
          addUnfilledField("Founding Member Details");
        }
        break;

      case "third-step":
        if (!currentStepData.fundingRequired) {
          addUnfilledField("Funding Required");
        }
        if (!currentStepData.stakeOffered) {
          addUnfilledField("Stake Offered");
        }
        if (!currentStepData.minPostMoneyValuation) {
          addUnfilledField("Minimum Post Money Valuation");
        }
        // if (
        //   !currentStepData.amountRaised
        // ) {
        //   addUnfilledField("Amount Raised");
        // }
        if (!currentStepData.purpose || currentStepData.purpose.trim() === "") {
          addUnfilledField("Purpose");
        }
        if (
          !Array.isArray(currentStepData.roundType) ||
          currentStepData.roundType.length === 0 ||
          !currentStepData.roundType.every((v) => v && v.trim() !== "")
        ) {
          addUnfilledField("Round Type");
        }
        // if (
        //   !currentStepData.lastRoundType ||
        //   currentStepData.lastRoundType.trim() === ""
        // ) {
        //   addUnfilledField("Last Round Type");
        // }
        // if (
        //   // !Array.isArray(currentStepData.keyInvestors) ||
        //   // currentStepData.keyInvestors.length === 0
        //   !currentStepData.keyInvestors ||
        //   currentStepData.keyInvestors.trim() === ""
        // ) {
        //   addUnfilledField("Key Investors");
        // }
        // if (
        //   !Array.isArray(currentStepData.fundingRounds) ||
        //   currentStepData.fundingRounds.length === 0 ||
        //   !currentStepData.fundingRounds.every(
        //     (detail) =>
        //       detail.date.trim() !== "" &&
        //       detail.round.trim() !== "" &&
        //       detail.raised.trim() !== "" &&
        //       detail.stake.trim() !== "" &&
        //       detail.valuation.trim() !== ""
        //   )
        // ) {
        //   addUnfilledField("Funding Rounds");
        // }
        break;
      // Currently not checking
      case "fourth-step":
        stringFields.forEach((field) => {
          if (currentStepData[field].length === 0) {
            addUnfilledField(field.replace(/([A-Z])/g, " $1"));
          }
        });
        break;

      case "fifth-step":
        if (
          currentStepData.acceptTC === false ||
          currentStepData.acceptTC === undefined
        ) {
          addUnfilledField("Acceptance of Terms & Conditions");
        }
        break;

      default:
        break;
    }

    if (!allFieldsFilled) {
      Toast(
        `Please fill in all fields in ${stepName}: ${unfilledFields.join(
          ", "
        )}`,
        "info",
        "companyOnboarding"
      );
    }

    return allFieldsFilled;
  };

  const saveAndFinish = async (step1, step2, currentStepData) => {
    if (
      !checkField(stepOneData, "first-step", "Step 1") ||
      !checkField(stepTwoData, "second-step", "Step 3") ||
      !checkField(stepThreeData, "third-step", "Step 4") ||
      !checkField(stepFiveData, "fifth-step", "Step 6")
    ) {
      return; // Stop the function if any check fails
    }
    if (formData.acceptTC === false) {
      Toast(
        "Please accept the terms and conditions",
        "info",
        "companyOnboarding"
      );
      return;
    }
    // }
    const updatedFormData = {
      ...formData,
      ...currentStepData,
      onBoardingComplete: true,
    };

    try {
      loader.start();
      await postFormData(updatedFormData);
      loader.stop();
      // Update formData state with updated data
      setFormData(updatedFormData);

      // UI updates should only be done if data posting is successful
      document.getElementsByClassName(step1)[0].style.display = "none";
      document.getElementsByClassName(step2)[0].style.display = "flex";
      window.scrollTo(0, 0);
      setStepFiveData((prev) => ({
        ...prev,
        onBoardingComplete: true,
      }));
      //   await postFormData({
      //     ...formData,
      //     onBoardingComplete: true,
      //  });
    } catch (error) {
      loader.stop();

      Toast(
        "Failed to save data, please try again.",
        "error",
        "companyOnboarding"
      );
    }
  };

  const postFormData = async (data) => {
    const formDataInput = new FormData();
    const changedIndices = [];
    // Loop through each key in the data object and append it to FormData
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        if (data[key]) {
          // Check if the property is an array or an object that needs to be stringified
          if (typeof data[key] === "object" && !(data[key] instanceof File)) {
            if (key === "foundingMember") {
              await updateMembers(data);
              data[key].map((item, index) => {
                if (item.foundingTeamProfile instanceof File) {
                  formDataInput.append(
                    "foundingTeamProfile",
                    item.foundingTeamProfile
                  );
                  changedIndices.push(index);
                }
              });
              formDataInput.append(key, JSON.stringify(data[key]));
            } else if (stringFields.includes(key)) {
              data[key].map((item, index) => {
                if (item instanceof File) {
                  formDataInput.append(key, item);
                }
              });
            } else {
              formDataInput.append(key, JSON.stringify(data[key]));
            }
          } else {
            formDataInput.append(key, data[key]);
          }
        }
      }
    }

    if (changedIndices.length > 0) {
      formDataInput.append("changedIndices", JSON.stringify(changedIndices));
    }
    if (deletedFileNames) {
      formDataInput.append(
        "deletedFileNames",
        JSON.stringify(deletedFileNames)
      );
    }
    if (!data.startupID) {
      formDataInput.append("startupID", startupID);
    }
    try {
      loader.start();
      const response = await PostDetails(formDataInput, token, setUploading);
      loader.stop();
      if (response) {
      }
    } catch (error) {
      loader.stop();
      setUploading(false);
    }
  };

  const updateMembers = async (stepTwoData) => {
    try {
      await Promise.all(
        stepTwoData.foundingMember.map(async (member, index) => {
          const memberData = new FormData();

          // Append each member property except for 'foundingTeamProfile'
          for (const key in member) {
            if (member.hasOwnProperty(key) && key !== "foundingTeamProfile") {
              memberData.append(key, member[key]);
            }
          }

          // Append 'memberImg' if it is provided as a file
          if (
            member.foundingTeamProfile &&
            member.foundingTeamProfile instanceof File
          ) {
            memberData.append("memberImg", member.foundingTeamProfile);
          }

          // Log the FormData contents for debugging (can be removed in production)
          // for (const [key, value] of memberData.entries()) {
          //
          // }

          // Attempt to update each member and wait for the response
          const response = await memberUpdate(memberData, token);

          if (response && response.data !== null) {
          }
        })
      );
    } catch (error) {
      console.error("An error occurred during processing:", error);
    }
  };

  const navigateToCompanyDashBoard = () => {
    localStorage.setItem("token", token);
    localStorage.setItem("role", "company");
    localStorage.setItem("onBoardingComplete", true);
    localStorage.setItem("isOpen", true);
    navigate("/company/fundraising", {
      state: {
        token: token,
      },
    });
  };
  useEffect(() => {
    let timer;
    if (openSaveModal) {
      timer = setTimeout(() => {
        setOpenSaveModal(false);
      }, 3000); // Close after 3 seconds
    }
    return () => clearTimeout(timer); // Clean up the timer
  }, [openSaveModal]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const handleTagsChange = (value) => {
    setStepNineData({
      ...stepNineData,
      inviteMembers: value,
    });
  };

  const handleInputChange = (value) => {
    setInputValue(value);
  };

  const handleKeyDown = (event) => {
    if (event.key === " " || event.key === "Enter") {
      event.preventDefault(); // Prevent the default action of the space bar
      const trimmedValue = inputValue.trim(); // Trim the current input value
      if (trimmedValue) {
        if (isValidEmail(trimmedValue) === false) {
          Toast(
            "Please enter a valid email address.",
            "error",
            "companyOnboarding"
          );
          return;
        }
        handleTagsChange([...stepNineData.inviteMembers, trimmedValue]);
        setInputValue(""); // Clear the input field after adding the tag
      }
    }
  };
  const getDealId = async () => {
    if (stepFiveData.isApplicationReviewed) {
      const response = await getDealIdbyStartupId(startupID, token);
      setDealId(response.data.dealId);
    }
  };
  useEffect(() => {
    getDealId();
  }, [startupID]);
  const PublishTheStartup = async () => {
    const data = {
      startupId: startupID,
      dealId: dealId,
    };
    const response = await publishStartup(data, token);
    if (response.data.message === "Startup published successfully") {
      document.getElementsByClassName("seventh-step")[0].style.display = "none";
      document.getElementsByClassName("tenth-step")[0].style.display = "flex";
      setStepsCompleted((prev) => ({
        ...prev,
        step7: true,
      }));
    } else {
      Toast("Failed to publish the startup", "error", "companyOnboarding");
    }
  };
  return (
    <section className="investorDetailsPage companyDetailsPage">
      <Helmet>
        <title>Fundrev | Company Onboarding</title>
      </Helmet>
      <ToastContainer position="top-center" containerId="companyOnboarding" />
      <Header
        color="black"
        token="Startuptoken"
        type="Startup"
        data={formData}
        startupID={startupID}
        postData={postFormData}
      />
      <div className="investorDetails first-step">
        <Step
          currentStep={1}
          stepsCompleted={stepsCompleted}
          fundrevAnalystID="FN123456"
          startupID={startupID}
          onBoardingComplete={stepFiveData.onBoardingComplete}
          fetchMembersData={fetchMembersData}
        />

        <div className="details-section">
          <div className="investorDetails-first-section">
            <div className="add-member-div">
              <p className="investorDetails-heading">Company Details</p>
              <button className="add-row OrgId">Org. ID: {startupID}</button>
            </div>
            {/* <p className="investorDetails-heading">Company Details</p> */}
            <div class="upload-btn-wrapper">
              <button class="upload-img-btn">
                <img
                  src={
                    !fileUploads["companyLogo"]
                      ? stepOneData.companyLogo
                        ? stepOneData.companyLogo
                        : uploadImg
                      : companyLogoFile
                  }
                  className="InvestorLogo"
                  alt="Upload"
                  style={{ cursor: "pointer" }}
                />
              </button>
              {(fileUploads["companyLogo"] || stepOneData.companyLogo) && (
                <label htmlFor="company-logo" className="edit-img-btn">
                  <img src={editImg} className="editImg" alt="Edit" />
                </label>
              )}

              <input
                id="company-logo"
                type="file"
                name="image"
                accept="image/png, image/jpeg"
                onChange={(e) => handleImageUpload("companyLogo", e)}
                style={{ cursor: "pointer" }}
              />
            </div>
            <form className="input-section">
              <div className="input-div">
                <FormControl
                  className="form-control investorDetails-row-input"
                  id="Id"
                  required
                  size="lg"
                  color="primary"
                >
                  <FormLabel className="label">Company Name</FormLabel>
                  <Input
                    className="input"
                    placeholder="Amazon"
                    color="neutral"
                    name="companyName"
                    type="text"
                    value={stepOneData.companyName}
                    onChange={handleInputChangeStep1}
                    required
                  />
                </FormControl>
                <FormControl
                  className="form-control investorDetails-row-input"
                  id="Id"
                  required
                  size="lg"
                  color="primary"
                >
                  <FormLabel className="label">Legal Entity Name</FormLabel>
                  <Input
                    className="input"
                    placeholder="Amazon Inc."
                    color="neutral"
                    name="legalEntityName"
                    type="text"
                    value={stepOneData.legalEntityName}
                    onChange={handleInputChangeStep1}
                    required
                  />
                </FormControl>
                <FormControl
                  className="form-control investorDetails-row-input"
                  id="Id"
                  required
                  size="lg"
                  color="primary"
                >
                  <FormLabel className="label">Headquarters</FormLabel>
                  <Input
                    className="input"
                    placeholder="Bengaluru, India"
                    color="neutral"
                    name="headquarters"
                    type="text"
                    value={stepOneData.headquarters}
                    onChange={handleInputChangeStep1}
                    required
                  />
                </FormControl>
              </div>
              <div className="input-div">
                <FormControl
                  className="form-control investorDetails-row-input"
                  id="Id"
                  required
                  size="lg"
                  color="primary"
                >
                  <FormLabel className="label">Founded Year</FormLabel>
                  <Input
                    className="input"
                    placeholder="2018"
                    color="neutral"
                    name="foundedYear"
                    type="number"
                    value={stepOneData.foundedYear}
                    onChange={handleInputChangeStep1}
                    required
                  />
                </FormControl>
                <FormControl
                  className="form-control investorDetails-row-input"
                  id="Id"
                  required
                  size="lg"
                  color="primary"
                >
                  <FormLabel className="label">Number of Employees</FormLabel>
                  <Input
                    className="input"
                    placeholder="1000"
                    color="neutral"
                    name="numEmployees"
                    type="number"
                    value={stepOneData.numEmployees}
                    onChange={handleInputChangeStep1}
                    required
                  />
                </FormControl>
                <FormControl
                  className="form-control investorDetails-row-input"
                  id="Id"
                  required
                  size="lg"
                  color="primary"
                >
                  <FormLabel className="label">Company Website</FormLabel>
                  <Input
                    className="input"
                    placeholder="www.amazon.com"
                    color="neutral"
                    name="website"
                    type="url"
                    value={stepOneData.website}
                    required
                    onChange={handleInputChangeStep1}
                  />
                  {websiteError && (
                    <div style={{ color: "red" }}>{websiteError}</div>
                  )}
                </FormControl>
              </div>
              <div className="input-div">
                <FormControl
                  className="form-control investorDetails-row-input"
                  id="Id"
                  required
                  size="lg"
                  color="primary"
                >
                  <FormLabel className="label">Industry Verticals</FormLabel>

                  <Select
                    // className="input"
                    isMulti
                    className="react-select-input"
                    options={industryVerticalOptions}
                    name="industryVerticals"
                    onChange={handleIndustryVerticalChange}
                    value={
                      stepOneData.industryVerticals
                        ? industryVerticalOptions.filter((option) =>
                            stepOneData.industryVerticals.includes(option.value)
                          )
                        : []
                    }
                    placeholder="Please Select"
                    required
                    styles={customStyles}
                    isClearable={true}
                    isSearchable={true}
                  />
                </FormControl>
                <FormControl
                  className="form-control investorDetails-row-input"
                  id="Id"
                  required
                  size="lg"
                  color="primary"
                >
                  <FormLabel className="label">CIN/ LLPIN</FormLabel>
                  <Input
                    className="input"
                    placeholder="U45690TN0983PTC567904"
                    color="neutral"
                    name="cin"
                    type="text"
                    value={stepOneData.cin}
                    onChange={handleInputChangeStep1}
                    required
                  />
                </FormControl>
                <FormControl
                  className="form-control investorDetails-row-input"
                  id="Id"
                  required
                  size="lg"
                  color="primary"
                >
                  <FormLabel className="label">
                    Tax Identification Number
                  </FormLabel>
                  <Input
                    className="input"
                    placeholder="ABCTY1234D"
                    color="neutral"
                    name="taxNumber"
                    type="text"
                    value={stepOneData.taxNumber}
                    onChange={handleInputChangeStep1}
                    required
                  />
                </FormControl>
              </div>

              <div className="input-div one-input-div">
                <FormControl
                  className="form-control investorDetails-row-input"
                  id="Id"
                  required
                  size="lg"
                  color="primary"
                >
                  <FormLabel className="label">End Market</FormLabel>
                  <Select
                    className="react-select-input"
                    options={endMarketOptions}
                    name="endMarket"
                    value={stepOneData.endMarket}
                    onChange={handleEndMarketChange}
                    placeholder={
                      stepOneData.endMarket
                        ? stepOneData.endMarket
                        : "Please Select"
                    }
                    required
                    styles={customStyles}
                  />
                </FormControl>
                <FormControl
                  className="form-control investorDetails-row-input"
                  id="Id"
                  required
                  size="lg"
                  color="primary"
                >
                  <FormLabel className="label">ERP Platform Used</FormLabel>

                  <Input
                    className="input"
                    placeholder="Epicore"
                    color="neutral"
                    name="erpPlatform"
                    type="text"
                    value={stepOneData.erpPlatform}
                    onChange={handleInputChangeStep1}
                    required
                  />
                </FormControl>
              </div>
              <div className="input-div companyDetails-business-desc-div pof-div">
                <FormControl
                  className="business-desc-form-control"
                  id="Id"
                  required
                  size="lg"
                  color="primary"
                >
                  <FormLabel className="label">Business Description</FormLabel>
                  <TextField
                    className="large-input"
                    placeholder="Describe your business"
                    name="description"
                    type="text"
                    value={stepOneData.description}
                    onChange={handleInputChangeStep1}
                    required
                    multiline
                    minRows={3}
                  />
                </FormControl>
              </div>
            </form>
          </div>
          <div className="navigation-buttons">
            <button
              className="previousStep"
              style={{ visibility: "hidden" }}
              onClick={prevSection}
            >
              <img src={previous} className="previous" alt="back-arrow" />
              &nbsp;&nbsp; Previous Step
            </button>
            <div className="next-buttons">
              <button
                className="nextStep"
                onClick={() => {
                  nextSection("first-step", "ninth-step", stepOneData);
                  handleSave(stepOneData, "first-step");
                }}
              >
                Next Step&nbsp;&nbsp;{" "}
                <img src={Next} className="next" alt="Next" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={openSaveModal}
        onClose={() => setOpenSaveModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal-box" style={{ width: "600px", height: "398px" }}>
          <img src={Verify} alt="verify" className="verify-image" />
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Hang on tight, your team members will join soon!
          </Typography>
          <Typography
            id="modal-modal-description"
            variant="body"
            component="h2"
          >
            We have shared an invitation email to your team member.
          </Typography>
        </Box>
      </Modal>
      <div className="investorDetails second-step">
        <Step
          currentStep={2}
          stepsCompleted={stepsCompleted}
          fundrevAnalystID="FN123456"
          startupID={startupID}
          onBoardingComplete={stepFiveData.onBoardingComplete}
          fetchMembersData={fetchMembersData}
        />
        <div className="details-section second-details-section">
          <div className="details-section">
            <div className="details-section-div">
              <div className="investorDetails-first-section">
                <div className="add-member-div">
                  <p className="investorDetails-heading">Founding Team</p>
                  <button onClick={addFoundingMember} className="add-row">
                    <AiOutlinePlus />
                    &nbsp;&nbsp; Add Member
                  </button>
                </div>
                {stepTwoData.foundingMember?.map((member, index) => (
                  <div
                    key={index}
                    className="member-form"
                    style={{
                      borderBottom:
                        index === stepTwoData.foundingMember.length - 1
                          ? "0px"
                          : "1px dashed #8692a6",
                    }}
                  >
                    <div className="founding-member">
                      <div className="upload-btn-wrapper">
                        <button className="upload-img-btn">
                          <img
                            src={
                              !foundingTeamProfileFile[index]
                                ? member.foundingTeamProfile
                                  ? member.foundingTeamProfile
                                  : uploadProfile
                                : foundingTeamProfileFile[index]
                            }
                            className="InvestorLogo"
                            alt="Upload"
                            style={{ cursor: "pointer" }}
                          />
                        </button>
                        {/* </div> */}

                        {member.foundingTeamProfile && (
                          <label
                            htmlFor={`founding-profile-${index}`}
                            className="edit-img-btn"
                          >
                            <img src={editImg} className="editImg" alt="Edit" />
                          </label>
                        )}
                        <input
                          id={`founding-profile-${index}`}
                          type="file"
                          name="image"
                          accept="image/png, image/jpeg" // Accept only JPEG and PNG
                          onChange={(e) => handleFounderImageUpload(index, e)}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                      {index > 0 && (
                        <div className="member-actions">
                          <div
                            className={(() => {
                              const foundMember = invitedMembers.find(
                                (item) => item.email === member.email
                              );
                              if (foundMember) {
                                return foundMember.isActive
                                  ? "active-email-div"
                                  : "invited-email-div-6";
                              } else {
                                return "add-row";
                              }
                            })()}
                            onClick={() => {
                              const foundMember = invitedMembers.find(
                                (item) => item.email === member.email
                              );
                              if (!foundMember) {
                                inviteFounderMember(index, member.email);
                              }
                            }}
                          >
                            {(() => {
                              const foundMember = invitedMembers.find(
                                (item) => item.email === member.email
                              );
                              if (foundMember) {
                                return foundMember.isActive
                                  ? "Active"
                                  : "Pending";
                              } else {
                                return (
                                  <>
                                    <img src={members} alt="Invite Members" />
                                    &nbsp;&nbsp;Invite Member
                                  </>
                                );
                              }
                            })()}
                          </div>
                          <button
                            className="delete-row"
                            onClick={() =>
                              deleteFounderMember(index, member.email)
                            }
                          >
                            <img src={Bin} />
                            &nbsp;&nbsp; Delete
                          </button>
                        </div>
                      )}
                    </div>
                    <form className="input-section">
                      <div className="input-div">
                        <FormControl
                          className="form-control investorDetails-row-input"
                          required
                          size="lg"
                          color="primary"
                        >
                          <FormLabel className="label">Full Name</FormLabel>
                          <Input
                            className="input"
                            placeholder="Full Name"
                            color="neutral"
                            name="name"
                            type="text"
                            value={member.name}
                            onChange={(e) => handleInputChangeStep2(index, e)}
                            required
                          />
                        </FormControl>
                        <FormControl
                          className="form-control investorDetails-row-input"
                          required
                          size="lg"
                          color="primary"
                        >
                          <FormLabel className="label">Title</FormLabel>
                          <Input
                            className="input"
                            placeholder="Title"
                            color="neutral"
                            name="title"
                            type="text"
                            value={member.title}
                            onChange={(e) => handleInputChangeStep2(index, e)}
                            required
                          />
                        </FormControl>
                        <FormControl
                          className="form-control investorDetails-row-input"
                          required
                          size="lg"
                          color="primary"
                        >
                          <FormLabel className="label">
                            Official Email Address
                          </FormLabel>
                          <Input
                            className="input"
                            placeholder="Email"
                            color="neutral"
                            name="email"
                            type="email"
                            value={member.email}
                            onChange={(e) => handleInputChangeStep2(index, e)}
                            required
                          />
                        </FormControl>
                      </div>
                      <div className="input-div">
                        <FormControl
                          className="form-control investorDetails-row-input"
                          size="lg"
                          color="primary"
                        >
                          <FormLabel className="label">
                            LinkedIn Profile
                          </FormLabel>
                          <Input
                            className="input"
                            placeholder="LinkedIn Profile"
                            color="neutral"
                            name="linkedin"
                            type="url"
                            value={member.linkedin}
                            onChange={(e) => {
                              handleInputChangeStep2(index, e);
                            }}
                            onBlur={() => handleBlur(index)}
                          />
                          {linkedinUrlError[index] && (
                            <div style={{ color: "red" }}>
                              {linkedinUrlError[index]}
                            </div>
                          )}
                        </FormControl>
                        <FormControl
                          className="form-control investorDetails-row-input"
                          required
                          size="lg"
                          color="primary"
                        >
                          <FormLabel className="label">Phone Number</FormLabel>
                          <MuiTelInput
                            defaultCountry="IN"
                            className="input"
                            name="phoneNumber"
                            value={member.phoneNumber}
                            onChange={(phoneNumber) =>
                              handlePhoneChange(index, phoneNumber)
                            }
                            required
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                height: "25px",
                              },
                            }}
                          />
                        </FormControl>
                        <FormControl
                          className="form-control investorDetails-row-input random-input"
                          style={{ visibility: "hidden" }}
                        >
                          <FormLabel className="label">Biography</FormLabel>
                          <Input className="input" />
                        </FormControl>
                      </div>
                      <div className="input-div companyDetails-business-desc-div pof-div">
                        <FormControl
                          className="business-desc-form-control"
                          id="Id"
                          size="lg"
                          color="primary"
                          required
                        >
                          <FormLabel className="label">Biography</FormLabel>
                          <TextField
                            className="large-input"
                            placeholder="Your biography"
                            name="biography"
                            type="text"
                            value={member.biography}
                            onChange={(e) => handleInputChangeStep2(index, e)}
                            multiline
                            minRows={3}
                          />
                        </FormControl>
                      </div>
                    </form>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="navigation-buttons">
            <button
              className="previousStep"
              onClick={() => {
                prevSection("second-step", "ninth-step", stepOneData);
                handleSave(stepTwoData, "second-step");
              }}
            >
              <img src={previous} className="previous" alt="back-arrow" />
              &nbsp;&nbsp; Previous Step
            </button>
            <div className="next-buttons">
              <button
                className="nextStep"
                onClick={() => {
                  nextSection("second-step", "third-step", stepTwoData);
                  handleSave(stepTwoData, "second-step");
                }}
              >
                Next Step&nbsp;&nbsp;{" "}
                <img src={Next} className="next" alt="next" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="investorDetails third-step">
        <Step
          currentStep={3}
          stepsCompleted={stepsCompleted}
          fundrevAnalystID="FN123456"
          startupID={startupID}
          onBoardingComplete={stepFiveData.onBoardingComplete}
          fetchMembersData={fetchMembersData}
        />
        <div className="details-section">
          <div className="investorDetails-first-section">
            <p className="investorDetails-heading">Funding Details</p>
            <p className="investorDetails-subheading">Current round</p>
            <div className="third-step-first-row">
              <FormControl
                className="form-control investorDetails-row-input"
                id="Id"
                required
                size="lg"
                color="primary"
              >
                <FormLabel className="label">Funding Required (₹Cr)</FormLabel>
                <Input
                  className="input"
                  placeholder="11.1"
                  color="neutral"
                  name="fundingRequired"
                  type="number"
                  value={stepThreeData.fundingRequired}
                  onChange={handleInputChangeStep3}
                  required
                />
              </FormControl>
              <FormControl
                className="form-control investorDetails-row-input"
                id="Id"
                required
                size="lg"
                color="primary"
              >
                <FormLabel className="label">Stake Offered %</FormLabel>
                <Input
                  className="input"
                  placeholder="10"
                  color="neutral"
                  name="stakeOffered"
                  type="number"
                  value={stepThreeData.stakeOffered}
                  onChange={handleInputChangeStep3}
                  required
                />
                <div>
                  {stepThreeData.fundingRequired &&
                    stepThreeData.stakeOffered && (
                      <p style={{ marginTop: "10px" }}>
                        Post money valuation (₹Cr) :
                        {(
                          (stepThreeData.fundingRequired /
                            stepThreeData.stakeOffered) *
                          100
                        ).toFixed(2)}
                      </p>
                    )}
                </div>
              </FormControl>
              <FormControl
                className="form-control investorDetails-row-input"
                id="Id"
                // required
                size="lg"
                color="primary"
              >
                <FormLabel className="label">
                  Min. Post Money Valuation (₹Cr)
                </FormLabel>
                <Input
                  className="input"
                  placeholder="10"
                  color="neutral"
                  name="minPostMoneyValuation"
                  type="number"
                  value={stepThreeData.minPostMoneyValuation}
                  onChange={handleInputChangeStep3}
                />
              </FormControl>
            </div>
            <div className="third-step-first-row">
              <FormControl
                className="form-control investorDetails-row-input"
                id="Id"
                // required
                size="lg"
                color="primary"
              >
                <FormLabel className="label">
                  Amount Already Raised (₹Cr)
                </FormLabel>
                <Input
                  className="input"
                  placeholder="0.12"
                  color="neutral"
                  name="amountRaised"
                  type="number"
                  value={stepThreeData.amountRaised}
                  onChange={handleInputChangeStep3}
                />
              </FormControl>
              <FormControl
                className="form-control investorDetails-row-input"
                id="Id"
                required
                size="lg"
                color="primary"
              >
                <FormLabel className="label">Round Type</FormLabel>

                <Select
                  // className="input"
                  className="react-select-input"
                  isMulti
                  options={roundTypeOptions}
                  name="roundType"
                  value={
                    stepThreeData.roundType
                      ? roundTypeOptions.filter((option) =>
                          stepThreeData.roundType.includes(option.value)
                        )
                      : []
                  }
                  onChange={handleRoundTypeChange}
                  placeholder={"Please select"}
                  required
                  styles={customStyles}
                />
              </FormControl>
              {/* Random input for proper spacing */}
              <FormControl className="form-control investorDetails-row-input random-input">
                <Input style={{ visibility: "hidden" }} className="input" />
              </FormControl>
            </div>
            <div className="third-step-second-row input-div companyDetails-business-desc-div pof-div">
              <FormControl
                className="business-desc-form-control"
                id="Id"
                size="lg"
                color="primary"
                required
              >
                <FormLabel className="label">Purpose of Fundraising</FormLabel>
                <TextField
                  className="large-input"
                  placeholder="Expansion of company"
                  name="purpose"
                  type="text"
                  value={stepThreeData.purpose}
                  onChange={handleInputChangeStep3}
                  multiline
                  minRows={3}
                />
              </FormControl>
            </div>
            <p className="investorDetails-subheading">Historical rounds</p>
            <div className="third-step-third-row">
              <FormControl
                className="form-control investorDetails-row-input"
                id="Id"
                // required
                size="lg"
                color="primary"
              >
                <FormLabel className="label">Last Round Type</FormLabel>

                <Select
                  // className="input"
                  className="react-select-input"
                  options={lastRoundTypeOptions}
                  name="lastRoundType"
                  value={stepThreeData.lastRoundType}
                  onChange={handleLastRoundTypeChange}
                  placeholder={
                    stepThreeData.lastRoundType
                      ? stepThreeData.lastRoundType
                      : "Please Select"
                  }
                  required
                  styles={customStyles}
                />
              </FormControl>
              <FormControl
                className="form-control investorDetails-row-input"
                id="Id"
                // required
                size="lg"
                color="primary"
              >
                <FormLabel className="label">Key Investors</FormLabel>
                <Input
                  className="input"
                  placeholder="Aman Gputa, Peeyush Bansal"
                  color="neutral"
                  name="keyInvestors"
                  type="text"
                  value={stepThreeData.keyInvestors}
                  onChange={handleInputChangeStep3}
                  required
                />
              </FormControl>
              <FormControl
                className="form-control investorDetails-row-input random-input"
                size="lg"
                color="primary"
              ></FormControl>
            </div>
            <div className="third-step-fourth-row">
              <div className="investmentDetails-table">
                <FormControl>
                  <FormLabel className="label">Funding Rounds</FormLabel>
                </FormControl>
                <div className="table-details">
                  <Editable
                    columns={columns}
                    // data={stepThreeData.fundingRounds}
                    data={data}
                    updateData={updateData}
                    deleteRow={deleteRow}
                  />
                  <div className="table-edit-buttons">
                    <button
                      onClick={(event) => {
                        event.preventDefault(); // This line prevents the form from being submitted
                        addNew();
                      }}
                      className="add-row"
                    >
                      <AiOutlinePlus />
                      &nbsp;&nbsp; Add Row
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="navigation-buttons">
            <button
              className="previousStep"
              onClick={() => {
                prevSection("third-step", "second-step", stepTwoData);
                handleSave(stepThreeData, "third-step");
              }}
            >
              <img src={previous} className="previous" alt="back-arrow" />
              &nbsp;&nbsp; Previous Step
            </button>
            <div className="next-buttons">
              <button
                className="nextStep"
                onClick={() => {
                  nextSection("third-step", "fourth-step", stepThreeData);
                  handleSave(stepThreeData, "third-step");
                }}
              >
                Next Step&nbsp;&nbsp;{" "}
                <img src={Next} className="next" alt="Next" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="investorDetails fourth-step">
        <Step
          currentStep={4}
          stepsCompleted={stepsCompleted}
          fundrevAnalystID="FN123456"
          startupID={startupID}
          onBoardingComplete={stepFiveData.onBoardingComplete}
          fetchMembersData={fetchMembersData}
        />
        <div className="details-section">
          <div className="investorDetails-first-section">
            <p className="investorDetails-heading">Dataroom preparation</p>
            <p className="investorDetails-heading-stage">
              1. Profile Creation and Analytics
            </p>
            <p className="investorDetails-subheading">Main Documents</p>
            <div className="third-step-first-row">
              <FileUpload
                label="One Pager Summary"
                id="summary"
                fileInfo={fileInfo}
                handleFileUpload={handleFileUpload}
                handleDeleteFile={handleDeleteFile}
                toolTipInfo="Executive overview of your company's value proposition and business model"
              />
              <FileUpload
                label="Pitch deck"
                id="pitch"
                fileInfo={fileInfo}
                handleFileUpload={handleFileUpload}
                handleDeleteFile={handleDeleteFile}
                toolTipInfo="12-15 slide presentation covering key aspects like the problem, solution, business model, growth strategy, and competitive advantage"
              />
              <FileUpload
                label="Business Plan"
                id="businessPlan"
                fileInfo={fileInfo}
                handleFileUpload={handleFileUpload}
                handleDeleteFile={handleDeleteFile}
                toolTipInfo="Detailed business plan, including market analysis, organizational structure, and financial projections"
              />
            </div>
            <div className="third-step-second-row">
              <FileUpload
                label="Competitor Analysis"
                id="competitorAnalysis"
                fileInfo={fileInfo}
                handleFileUpload={handleFileUpload}
                handleDeleteFile={handleDeleteFile}
                toolTipInfo="Analysis outlining how your company differentiates from the competition"
              />
              <FileUpload id="randomInput" />
              <FileUpload id="randomInput" />
            </div>
            <p className="investorDetails-subheading">Financials</p>
            <div className="third-step-first-row">
              <FileUpload
                label="Monthly Income Statement (Last 3 years)"
                id="incomeStatement"
                fileInfo={fileInfo}
                handleFileUpload={handleFileUpload}
                handleDeleteFile={handleDeleteFile}
                toolTipInfo="Raw data of income statement from the accounting system"
              />
              <FileUpload
                label="Monthly Balance Sheet (Last 3 years)"
                id="balanceSheet"
                fileInfo={fileInfo}
                handleFileUpload={handleFileUpload}
                handleDeleteFile={handleDeleteFile}
                toolTipInfo="Monthly balance of assets and liabilities"
              />
              <FileUpload
                label="Monthly Cash Flow Statement (Last 3 Years)"
                id="cashFlowStatement"
                fileInfo={fileInfo}
                handleFileUpload={handleFileUpload}
                handleDeleteFile={handleDeleteFile}
                toolTipInfo="Monthly Cash Inflow/Outflow"
              />
            </div>
            <div className="third-step-second-row">
              <FileUpload
                label="Audit Report (Last 3 years)"
                id="auditReport"
                fileInfo={fileInfo}
                handleFileUpload={handleFileUpload}
                handleDeleteFile={handleDeleteFile}
                toolTipInfo="Audit Report issued by the Auditor on the Financial Statements"
              />
              <FileUpload
                label="Company MIS"
                id="mis"
                fileInfo={fileInfo}
                handleFileUpload={handleFileUpload}
                handleDeleteFile={handleDeleteFile}
                toolTipInfo="Any internal MIS maintained by the management to track historical business performance"
              />
              <FileUpload
                label="Financial Model"
                id="financialModel"
                fileInfo={fileInfo}
                handleFileUpload={handleFileUpload}
                handleDeleteFile={handleDeleteFile}
                toolTipInfo="A projection of a company's future financial performance, based on its past performance and assumptions about future conditions"
              />
            </div>
            <p className="investorDetails-subheading">Analytics Dashboard</p>
            <div className="third-step-first-row">
              <FileUpload
                label="Invoice Detail"
                id="invoiceDetail"
                fileInfo={fileInfo}
                handleFileUpload={handleFileUpload}
                handleDeleteFile={handleDeleteFile}
                toolTipInfo="Transaction level data for Revenue, Cohort, Customer Churn Analysis"
              />
              <FileUpload
                label="Historical Sales Pipeline Metrics"
                id="salesPipelineMetrics"
                fileInfo={fileInfo}
                handleFileUpload={handleFileUpload}
                handleDeleteFile={handleDeleteFile}
                toolTipInfo="Metrics tracking Sales pipeline and conversion"
              />
              <FileUpload
                label="Marketing Expenses / Performance"
                id="marketingExpenses"
                fileInfo={fileInfo}
                handleFileUpload={handleFileUpload}
                handleDeleteFile={handleDeleteFile}
                toolTipInfo="Marketing Performance Metrics"
              />
            </div>
            <div className="third-step-second-row">
              <FileUpload
                label="Profitability Metrics"
                id="profitabilityMetrics"
                fileInfo={fileInfo}
                handleFileUpload={handleFileUpload}
                handleDeleteFile={handleDeleteFile}
                toolTipInfo="Customer Profitability Metrics by month"
              />
              <FileUpload
                label="Any other Metrics"
                id="otherMetrics"
                fileInfo={fileInfo}
                handleFileUpload={handleFileUpload}
                handleDeleteFile={handleDeleteFile}
                toolTipInfo="Any other important metrics to show to the investor"
              />
              <FileUpload id="randomInput" />
            </div>
            <p className="investorDetails-subheading">Team</p>
            <div className="third-step-second-row">
              <FileUpload
                label="Directors Profile"
                id="directorsProfiles"
                fileInfo={fileInfo}
                handleFileUpload={handleFileUpload}
                handleDeleteFile={handleDeleteFile}
                toolTipInfo="Profiles of your company directors"
              />
              <FileUpload
                label="Management Team Profile"
                id="managementTeamProfile"
                fileInfo={fileInfo}
                handleFileUpload={handleFileUpload}
                handleDeleteFile={handleDeleteFile}
                toolTipInfo="Profiles of the management team"
              />
              <FileUpload
                label="Organization Chart"
                id="organizationChart"
                fileInfo={fileInfo}
                handleFileUpload={handleFileUpload}
                handleDeleteFile={handleDeleteFile}
                toolTipInfo="Current Organization Chart"
              />
            </div>
            <p className="investorDetails-subheading">Legal</p>
            <div className="third-step-first-row">
              <FileUpload
                label="Memorandum & Articles of Association"
                id="memorandum"
                fileInfo={fileInfo}
                handleFileUpload={handleFileUpload}
                handleDeleteFile={handleDeleteFile}
                toolTipInfo="Documents filed for company registration, defining objectives, structure, and internal management"
              />
              <FileUpload
                label="Certificate of Incorporation"
                id="certificateIncorporation"
                fileInfo={fileInfo}
                handleFileUpload={handleFileUpload}
                handleDeleteFile={handleDeleteFile}
                toolTipInfo="Incorporation Document of the company"
              />
              <FileUpload
                label="Company ID - PAN/TAN"
                id="taxIdentificationDocuments"
                fileInfo={fileInfo}
                handleFileUpload={handleFileUpload}
                handleDeleteFile={handleDeleteFile}
                toolTipInfo="Tax identification Documents"
              />
            </div>
            <div className="third-step-second-row">
              <FileUpload
                label="Share Purchase Agreements"
                id="sharePurchaseAgreements"
                fileInfo={fileInfo}
                handleFileUpload={handleFileUpload}
                handleDeleteFile={handleDeleteFile}
                toolTipInfo=""
              />
              <FileUpload
                label="Debt Agreements (if any)"
                id="debtAgreements"
                fileInfo={fileInfo}
                handleFileUpload={handleFileUpload}
                handleDeleteFile={handleDeleteFile}
                toolTipInfo="Agreements for amount raised through Debt"
              />
              <FileUpload
                label="Capitalization Table"
                id="capitalizationTable"
                fileInfo={fileInfo}
                handleFileUpload={handleFileUpload}
                handleDeleteFile={handleDeleteFile}
                toolTipInfo="Fully Dilutive Capitalization Table"
              />
            </div>
            <p className="investorDetails-heading-stage">
              2. Investor Due Diligence Requirements
            </p>
            <p className="investorDetails-subheading">
              Financial and team data
            </p>
            <div className="third-step-first-row">
              <FileUpload
                label="Tax Returns (Last 3 Years)"
                id="taxReturns"
                fileInfo={fileInfo}
                handleFileUpload={handleFileUpload}
                handleDeleteFile={handleDeleteFile}
                toolTipInfo="Tax returns filed with the government of India"
              />
              <FileUpload
                label="Employee Census"
                id="employeeCensus"
                fileInfo={fileInfo}
                handleFileUpload={handleFileUpload}
                handleDeleteFile={handleDeleteFile}
                toolTipInfo="List of all employees, titles, and salary"
              />
              <FileUpload
                label="Employee Stock Option Plan (if any)"
                id="employeeStockOptionPlan"
                fileInfo={fileInfo}
                handleFileUpload={handleFileUpload}
                handleDeleteFile={handleDeleteFile}
                toolTipInfo=""
              />
            </div>
            <div className="third-step-second-row">
              <FileUpload
                label="Employment Agreements"
                id="employmentAgreements"
                fileInfo={fileInfo}
                handleFileUpload={handleFileUpload}
                handleDeleteFile={handleDeleteFile}
                toolTipInfo=""
              />
              <FileUpload id="randomInput" />
              <FileUpload id="randomInput" />
            </div>
            <p className="investorDetails-subheading">Product/Technology</p>
            <div className="third-step-second-row">
              <FileUpload
                label="Product Overview"
                id="productOverview"
                fileInfo={fileInfo}
                handleFileUpload={handleFileUpload}
                handleDeleteFile={handleDeleteFile}
                toolTipInfo=""
              />
              <FileUpload
                label="Product Roadmap"
                id="productRoadmap"
                fileInfo={fileInfo}
                handleFileUpload={handleFileUpload}
                handleDeleteFile={handleDeleteFile}
                toolTipInfo=""
              />
              <FileUpload
                label="Recorded Product Demo"
                id="recordedProductDemo"
                fileInfo={fileInfo}
                handleFileUpload={handleFileUpload}
                handleDeleteFile={handleDeleteFile}
                toolTipInfo=""
              />
            </div>
            <p className="investorDetails-subheading">Legal</p>
            <div className="third-step-first-row">
              <FileUpload
                label="Trademarks"
                id="trademarks"
                fileInfo={fileInfo}
                handleFileUpload={handleFileUpload}
                handleDeleteFile={handleDeleteFile}
                toolTipInfo=""
              />
              <FileUpload
                label="Granted & Filed Patents"
                id="patents"
                fileInfo={fileInfo}
                handleFileUpload={handleFileUpload}
                handleDeleteFile={handleDeleteFile}
                toolTipInfo=""
              />
              <FileUpload
                label="Key Customer Contracts"
                id="keyCustomerContracts"
                fileInfo={fileInfo}
                handleFileUpload={handleFileUpload}
                handleDeleteFile={handleDeleteFile}
                toolTipInfo=""
              />
            </div>
            <div className="third-step-first-row">
              <FileUpload
                label="Key Vendor Contracts"
                id="keyVendorContracts"
                fileInfo={fileInfo}
                handleFileUpload={handleFileUpload}
                handleDeleteFile={handleDeleteFile}
                toolTipInfo=""
              />
              <FileUpload
                label="Any other material agreement"
                id="otherMaterialAgreement"
                fileInfo={fileInfo}
                handleFileUpload={handleFileUpload}
                handleDeleteFile={handleDeleteFile}
                toolTipInfo=""
              />
              <FileUpload
                label="Security Contracts"
                id="securityContracts"
                fileInfo={fileInfo}
                handleFileUpload={handleFileUpload}
                handleDeleteFile={handleDeleteFile}
                toolTipInfo="SOC II, HIPA, GDPR, ISO, Service Level Agreement, Acceptable Use Policies, Ethics and Supplier COC, Data Protection Agreement"
              />
            </div>
          </div>
          <div className="navigation-buttons">
            <button
              className="previousStep"
              onClick={() => {
                handleSave(stepFourData, "fourth-step", "prev");
              }}
              disabled={uploading}
            >
              <img src={previous} className="previous" alt="back-arrow" />
              &nbsp;&nbsp; Previous Step
            </button>
            <div className="next-buttons">
              {/* {uploading && <CircularProgress />} */}
              <button
                className="nextStep"
                onClick={() => {
                  handleSave(stepFourData, "fourth-step", "next");
                }}
              >
                Next Step&nbsp;&nbsp;{" "}
                <img src={Next} className="next" alt="Next" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="investorDetails fifth-step">
        <Step
          currentStep={5}
          stepsCompleted={stepsCompleted}
          fundrevAnalystID="FN123456"
          startupID={startupID}
          onBoardingComplete={stepFiveData.onBoardingComplete}
          fetchMembersData={fetchMembersData}
        />
        <div className="details-section">
          <div className="investorDetails-first-section">
            <TnC />

            <div className="terms-condition-text">
              <Checkbox
                className="checkbox"
                {...label}
                sx={{
                  color: "#00a6fb",
                  "&.Mui-checked": {
                    color: "#00a6fb",
                  },
                }}
                checked={stepFiveData.acceptTC}
                onChange={(event) =>
                  setStepFiveData({
                    acceptTC: event.target.checked,
                  })
                }
              />
              <p className="AcceptTC-checkbox-text">
                By clicking this, I hereby acknowledge and confirm that I have
                read, understood, and fully accept the terms and conditions set
                forth in the Master Service Agreement and the Terms of Use
              </p>
            </div>
          </div>
          <div className="navigation-buttons">
            <button
              className="previousStep"
              onClick={() => {
                prevSection("fifth-step", "fourth-step", stepFiveData);
              }}
            >
              <img src={previous} className="previous" alt="back-arrow" />
              &nbsp;&nbsp; Previous Step
            </button>
            <div className="next-buttons">
              <button
                className="nextStep"
                onClick={() => {
                  nextSection("fifth-step", "sixth-step", stepFiveData);
                }}
              >
                Next Step&nbsp;&nbsp;{" "}
                <img src={Next} className="next" alt="Next" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="investorDetails sixth-step">
        <Step
          currentStep={6}
          stepsCompleted={stepsCompleted}
          fundrevAnalystID="FN123456"
          startupID={startupID}
          onBoardingComplete={stepFiveData.onBoardingComplete}
          fetchMembersData={fetchMembersData}
        />
        <div className="details-section">
          <div className="investorDetails-first-section sixth">
            <p className="investorDetails-heading-visible">
              {formData.companyName} - Pitch Review
            </p>
            <div className="Pitch-Overview">
              <div className="companyReview">
                <p className="investorDetails-subheading">Company Overview</p>
                <div className="companyReview-box">
                  <div className="companyReview-img ">
                    <img
                      src={
                        !fileUploads["companyLogo"]
                          ? stepOneData.companyLogo
                            ? stepOneData.companyLogo
                            : uploadImg
                          : companyLogoFile
                      }
                      className="InvestorLogo"
                      alt="Upload"
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                  <div>
                    <div className="companyReview-info">
                      <div>
                        <p className="companyReview-companyName">
                          {stepOneData.companyName
                            ? stepOneData.companyName
                            : "-"}
                        </p>
                        <p className="companyReview-comapnyDesc">
                          {stepOneData.description
                            ? stepOneData.description
                            : "-"}
                        </p>
                      </div>
                    </div>
                    <div className="companyReview-stats laptop-stats">
                      <div className="companyReview-group">
                        <div className="group-heading">
                          {stepOneData.legalEntityName
                            ? stepOneData.legalEntityName
                            : "-"}
                        </div>
                        <div className="group-subheading">
                          Legal Entity Name
                        </div>
                      </div>
                      <div className="companyReview-group">
                        <div className="group-heading">
                          {stepOneData.headquarters
                            ? stepOneData.headquarters
                            : "-"}
                        </div>
                        <div className="group-subheading">Headquarters</div>
                      </div>
                      <div className="companyReview-group">
                        <div className="group-heading">
                          {stepOneData.foundedYear
                            ? stepOneData.foundedYear
                            : "-"}
                        </div>
                        <div className="group-subheading">Founded Year</div>
                      </div>
                      <div className="companyReview-group">
                        <div className="group-heading">
                          {stepOneData.numEmployees
                            ? stepOneData.numEmployees
                            : "-"}
                        </div>
                        <div className="group-subheading">No. of Employees</div>
                      </div>
                      <div className="companyReview-group">
                        <div className="group-heading">
                          {stepOneData.website ? stepOneData.website : "-"}
                        </div>
                        <div className="group-subheading">Website</div>
                      </div>
                      <div className="companyReview-group">
                        <div className="group-heading">
                          {stepOneData.industryVerticals &&
                          stepOneData.industryVerticals.length > 0
                            ? stepOneData.industryVerticals.join(", ")
                            : "-"}
                        </div>
                        <div className="group-subheading">
                          Industry Verticals
                        </div>
                      </div>
                      <div className="companyReview-group">
                        <div className="group-heading">
                          {stepOneData.endMarket ? stepOneData.endMarket : "-"}
                        </div>
                        <div className="group-subheading">End Market</div>
                      </div>
                      <div className="companyReview-group">
                        <div className="group-heading">
                          {stepOneData.cin ? stepOneData.cin : "-"}
                        </div>
                        <div className="group-subheading">CIN/ LLPIN</div>
                      </div>
                      <div className="companyReview-group">
                        <div className="group-heading">
                          {stepOneData.taxNumber ? stepOneData.taxNumber : "-"}
                        </div>
                        <div className="group-subheading">
                          Tax Identification No.
                        </div>
                      </div>
                      <div className="companyReview-group">
                        <div className="group-heading">
                          {stepOneData.erpPlatform
                            ? stepOneData.erpPlatform
                            : "-"}
                        </div>
                        <div className="group-subheading">
                          ERP Platform Used
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="companyReview-stats mobile-stats">
                  <div className="companyReview-group">
                    <div className="group-heading">
                      {stepOneData.legalEntityName
                        ? stepOneData.legalEntityName
                        : "-"}
                    </div>
                    <div className="group-subheading">Legal Entity Name</div>
                  </div>
                  <div className="companyReview-group">
                    <div className="group-heading">
                      {stepOneData.headquarters
                        ? stepOneData.headquarters
                        : "-"}
                    </div>
                    <div className="group-subheading">Headquarters</div>
                  </div>
                  <div className="companyReview-group">
                    <div className="group-heading">
                      {stepOneData.foundedYear ? stepOneData.foundedYear : "-"}
                    </div>
                    <div className="group-subheading">Founded Year</div>
                  </div>
                  <div className="companyReview-group">
                    <div className="group-heading">
                      {stepOneData.numEmployees
                        ? stepOneData.numEmployees
                        : "-"}
                    </div>
                    <div className="group-subheading">No. of Employees</div>
                  </div>
                  <div className="companyReview-group">
                    <div className="group-heading">
                      {stepOneData.website ? stepOneData.website : "-"}
                    </div>
                    <div className="group-subheading">Website</div>
                  </div>
                  <div className="companyReview-group">
                    <div className="group-heading">
                      {stepOneData.industryVerticals &&
                      stepOneData.industryVerticals.length > 0
                        ? stepOneData.industryVerticals.join(", ")
                        : "-"}
                    </div>
                    <div className="group-subheading">Industry Verticals</div>
                  </div>
                  <div className="companyReview-group">
                    <div className="group-heading">
                      {stepOneData.cin ? stepOneData.cin : "-"}
                    </div>
                    <div className="group-subheading">CIN/ LLPIN</div>
                  </div>
                  <div className="companyReview-group">
                    <div className="group-heading">
                      {stepOneData.taxNumber ? stepOneData.taxNumber : "-"}
                    </div>
                    <div className="group-subheading">
                      Tax Identification No.
                    </div>
                  </div>
                  <div className="companyReview-group">
                    <div className="group-heading">
                      {stepOneData.erpPlatform ? stepOneData.erpPlatform : "-"}
                    </div>
                    <div className="group-subheading">ERP Platform Used</div>
                  </div>
                </div>
              </div>
              <div className="FoundingTeam" style={{ marginBottom: "0px" }}>
                <p className="investorDetails-subheading">Founding Team</p>

                <div className="team-profiles-box">
                  {stepTwoData.foundingMember?.map((member, index) => (
                    <div className="team-profile-div">
                      <img
                        loading="lazy"
                        src={
                          !foundingTeamProfileFile[index]
                            ? member.foundingTeamProfile
                              ? member.foundingTeamProfile
                              : Person
                            : foundingTeamProfileFile[index]
                        }
                        className="team-profile-img"
                        alt="team profile"
                      />
                      <div className="team-profile-div-2">
                        <div
                          className="team-profile-div-3"
                          onClick={() => {
                            if (member.linkedin) {
                              const url = member.linkedin.startsWith("http")
                                ? member.linkedin
                                : `https://${member.linkedin}`;
                              window.open(url, "_blank");
                            }
                          }}
                        >
                          {member.name}
                        </div>
                        <div className="team-profile-div-4">{member.title}</div>
                        <div className="team-profile-div-5">
                          {member.biography}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="fundingDetails">
                <p className="investorDetails-subheading">Funding Details</p>
                <div
                  className="companyReview-stats"
                  style={{ marginBottom: "20px" }}
                >
                  <div className="companyReview-group">
                    <div className="group-heading">
                      {stepThreeData.fundingRequired
                        ? stepThreeData.fundingRequired
                        : "-"}
                    </div>
                    <div className="group-subheading">
                      Funding Required (₹Cr)
                    </div>
                  </div>
                  <div className="companyReview-group">
                    <div className="group-heading">
                      {stepThreeData.stakeOffered
                        ? stepThreeData.stakeOffered
                        : "-"}
                    </div>
                    <div className="group-subheading">Stake Offered %</div>
                  </div>
                  <div className="companyReview-group">
                    <div className="group-heading">
                      {stepThreeData.minPostMoneyValuation
                        ? stepThreeData.minPostMoneyValuation
                        : "-"}
                    </div>
                    <div className="group-subheading">
                      Min. Post Money Valuation
                    </div>
                  </div>
                  <div className="companyReview-group">
                    <div className="group-heading">
                      {stepThreeData.amountRaised
                        ? stepThreeData.amountRaised
                        : "-"}
                    </div>
                    <div className="group-subheading">
                      Amount Already Raised (₹Cr)
                    </div>
                  </div>
                  <div className="companyReview-group">
                    <div className="group-heading">
                      {stepOneData.roundType
                        ? roundTypeOptions.filter((option) =>
                            stepOneData.roundType.includes(option.value)
                          )
                        : `-`}
                    </div>
                    <div className="group-subheading">Round Type</div>
                  </div>
                  <div className="companyReview-group">
                    <div className="group-heading">
                      {stepThreeData.lastRoundType
                        ? stepThreeData.lastRoundType
                        : "-"}
                    </div>
                    <div className="group-subheading">Last Round Type</div>
                  </div>
                  <div className="companyReview-group">
                    <div className="group-heading">
                      {stepThreeData.keyInvestors
                        ? stepThreeData.keyInvestors
                        : "-"}
                    </div>
                    <div className="group-subheading">
                      Existing Key Investors
                    </div>
                  </div>
                </div>
                <div
                  className="companyReview-group"
                  style={{ marginTop: "30px" }}
                >
                  <div className="group-heading">
                    {stepThreeData.purpose ? stepThreeData.purpose : "-"}
                  </div>
                  <div className="group-subheading">Purpose of Fundraising</div>
                </div>
                <div className="table-details">
                  <Editable
                    columns={columns}
                    data={
                      stepThreeData.fundingRounds
                        ? stepThreeData.fundingRounds
                        : [
                            {
                              date: "-",
                              round: "-",
                              raised: "-",
                              stake: "-",
                              valuation: "-",
                            },
                          ]
                    }
                    updateData={updateData}
                    deleteRow={deleteRow}
                  />
                </div>
              </div>
              <div className="documents">
                <p className="investorDetails-subheading">Documents</p>
                <div className="Documents-box">
                  {stringFields.some(
                    (field) => (fileInfo[field] && fileInfo[field].length) > 0
                  ) ? (
                    <TableContainer component={Paper}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell
                              sx={{
                                width: "40%",
                                fontFamily: "karla,sans-serif",
                                fontWeight: 600,
                              }}
                            >
                              Document Type
                            </TableCell>
                            <TableCell
                              sx={{
                                fontFamily: "karla,sans-serif",
                                fontWeight: 600,
                              }}
                            >
                              Files
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {stringFields.map((field) => {
                            if (fileInfo[field] && fileInfo[field].length > 0) {
                              return (
                                <TableRow key={field}>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    sx={{ fontFamily: "Karla, sans-serif" }}
                                  >
                                    {documentsDictionary[field]}
                                  </TableCell>
                                  <TableCell>
                                    <div className="pitch-review-files">
                                      {fileInfo[field].map(
                                        (file, fileIndex) => (
                                          <div
                                            key={fileIndex}
                                            className="fileUploads"
                                            style={{ width: "fit-content" }}
                                          >
                                            <img
                                              src={FileImg}
                                              className="FileImg"
                                              alt="File Icon"
                                            />
                                            <div className="fileUploads-div-2">
                                              <Link
                                                className="fileLink"
                                                to={file.fileURL}
                                              >
                                                <div
                                                  className="fileUploads-div-3"
                                                  style={{
                                                    width: "fit-content",
                                                  }}
                                                >
                                                  {file.filename}
                                                </div>
                                              </Link>
                                            </div>
                                          </div>
                                        )
                                      )}
                                    </div>
                                  </TableCell>
                                </TableRow>
                              );
                            }
                            return null;
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  ) : (
                    <div>No Documents Attached</div>
                  )}
                </div>
              </div>

              <div className="dealTeam">
                <p className="investorDetails-subheading">Deal Team</p>
                <div className="team-profiles-box">
                  <div className="team-profile-div">
                    <img
                      loading="lazy"
                      src={analyst.profile}
                      className="team-profile-img"
                      alt="Fundrev Analyst Profile"
                    />
                    <div className="team-deal-div-2">
                      <div className="team-deal-div-3">{analyst.name}</div>
                      <div className="team-deal-div-4 blue-text">
                        Fundrev Analyst
                      </div>
                      <div className="team-deal-div-5">
                        <span className="deal-team-span">Email Id: &nbsp;</span>
                        {analyst.email}
                      </div>
                      <div className="team-deal-div-5">
                        <span className="deal-team-span">
                          Contact Info: &nbsp;
                        </span>
                        {analyst.phoneNumber}
                      </div>
                    </div>
                  </div>
                  {invitedMembers.map((member) => (
                    <div className="team-profile-div">
                      <img
                        loading="lazy"
                        src={member.memberImg ? member.memberImg : Person}
                        className="team-profile-img"
                        alt="team profile"
                      />
                      <div className="team-deal-div-2">
                        <div className="team-deal-div-3">
                          {member.name ? member.name : "-"}
                        </div>
                        <div className="team-deal-div-4">
                          {member.title ? member.title : "-"}
                        </div>
                        <div className="team-deal-div-5">
                          <span className="deal-team-span">
                            Email Id: &nbsp;
                          </span>
                          {member.email ? member.email : "-"}
                        </div>
                        <div className="team-deal-div-5">
                          <span className="deal-team-span">
                            Contact Info: &nbsp;
                          </span>
                          {member.phoneNumber ? member.phoneNumber : "-"}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="navigation-buttons">
            <button
              className="previousStep"
              onClick={() => {
                prevSection("sixth-step", "fifth-step", stepFiveData);
              }}
            >
              <img src={previous} className="previous" alt="back-arrow" />
              &nbsp;&nbsp; Previous Step
            </button>
            <div className="next-buttons">
              <button
                className="nextStep"
                onClick={() => {
                  saveAndFinish("sixth-step", "eighth-step", stepFiveData);
                }}
              >
                Send for Fundrev Review
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="investorDetails seventh-step">
        <Step
          currentStep={7}
          stepsCompleted={stepsCompleted}
          fundrevAnalystID="FN123456"
          startupID={startupID}
          onBoardingComplete={stepFiveData.onBoardingComplete}
          fetchMembersData={fetchMembersData}
        />
        <div className="details-section">
          <div className="investorDetails-first-section">
            <p className="investorDetails-heading">Go Live</p>
            <div className="goLive-section">
              <p className="goLive-heading">
                Are you sure you want to publish your profile?
              </p>
              <p className="goLive-text">
                Once confirmed, this profile will be shared with the
                administrator before going live for investors to review. Please
                carefully review your profile as this step is crucial and
                irreversible!
              </p>
            </div>
          </div>
          <div className="navigation-buttons">
            <button style={{ visibility: "hidden" }} className="previousStep">
              <img src={previous} className="previous" alt="back-arrow" />
              &nbsp;&nbsp; Previous Step
            </button>
            <div className="next-buttons">
              <button
                className="Preview-button"
                onClick={() => {
                  navigate("/company/profilePreview", {
                    state: {
                      startupID: startupID,
                      token: token,
                    },
                  });
                }}
              >
                Preview
              </button>
              <button
                className="nextStep"
                onClick={PublishTheStartup}
                style={{ cursor: "pointer" }}
              >
                Confirm & Publish&nbsp;&nbsp;{" "}
                <img src={Next} className="next" alt="Next" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="investorDetails eighth-step">
        <Step
          currentStep={8}
          stepsCompleted={stepsCompleted}
          fundrevAnalystID="FN123456"
          startupID={startupID}
          onBoardingComplete={stepFiveData.onBoardingComplete}
          fetchMembersData={fetchMembersData}
        />
        <div className="details-section">
          <div className="investorDetails-first-section">
            <div className="eighth-step-div">
              <p className="eighth-step-heading">
                Your application is under fundrev review
              </p>
              <p className="eighth-step-subheading">
                Buckle up your excitement seat-belt! Your fundraising
                application has taken center stage under the scrutinizing gaze
                of our review wizards. Now, all that's left is to sit back,
                relax & twiddle your thumbs for{" "}
                <p className="blue-text bold">48-72 hours!</p>{" "}
              </p>
              <img
                src={companyOnboardingProcessImg}
                alt="Application review stage"
              ></img>
            </div>
          </div>
        </div>
      </div>
      <div className="investorDetails ninth-step">
        <Step
          currentStep={9}
          stepsCompleted={stepsCompleted}
          fundrevAnalystID="FN123456"
          startupID={startupID}
          onBoardingComplete={stepFiveData.onBoardingComplete}
          fetchMembersData={fetchMembersData}
        />
        <div className="details-section">
          <div className="investorDetails-first-section">
            <p className="investorDetails-heading">Deal Team</p>
            <div className="invite-members-full-div">
              <div className="dialogcontent">
                <DialogContentText className="dialogcontenttext">
                  Invite members by email
                </DialogContentText>
                <div className="select-peers-input">
                  <TagsInput
                    value={stepNineData.inviteMembers}
                    onChange={handleTagsChange}
                    inputValue={inputValue}
                    onChangeInput={handleInputChange}
                    inputProps={{
                      placeholder: "Select peers",
                      onKeyDown: handleKeyDown,
                    }}
                    onlyUnique
                  />
                  <button
                    className="send-invite"
                    disabled={
                      stepNineData.inviteMembers.length > 0 ? false : true
                    }
                    onlyUnique
                    style={{ cursor: "pointer" }}
                    onClick={handleSendInvite}
                  >
                    <p>Send Invite</p>
                    <img src={invite} alt="invite" />
                  </button>
                </div>
              </div>
              <hr
                style={{ borderTop: "1px dashed #8c97aa", marginTop: "20px" }}
              ></hr>
              <div className="invited-members-div">
                <p className="normal-small-text" style={{ fontSize: "16px" }}>
                  Members{" "}
                </p>
                <div className="all-invited-email-div">
                  {invitedMembers &&
                    invitedMembers.map((member, index) => (
                      <div className="invited-email-div" key={index}>
                        <div className="invited-email-div-2">
                          {/* You can replace these with actual member data */}
                          {member.name && (
                            <div className="invited-email-div-3">
                              {member.name}
                            </div>
                          )}
                          <div className="invited-email-div-4">
                            {member.email}
                          </div>
                        </div>
                        <div className="invited-email-div-5">
                          <div
                            className={`invited-email-div-6 ${
                              member.isActive && "active-email-div"
                            }`}
                          >
                            {member.isAdmin
                              ? "Admin"
                              : member.isActive
                              ? "Active"
                              : "Pending"}
                          </div>
                          {memberData.isAdmin &&
                            member.email !== memberData.email && (
                              <img
                                loading="lazy"
                                src={Bin}
                                className="invited-email-img"
                                alt="Delete"
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  handleDeleteMember(member._id, member.email)
                                }
                              />
                            )}
                          {/* <img
                                loading="lazy"
                                src={Bin}
                                className="invited-email-img"
                                alt="Delete"
                                cursor="pointer"
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  handleDeleteMember(member._id, member.email)
                                }
                              />
                            )} */}
                        </div>
                      </div>
                    ))}
                </div>
                {/* {stepNineData.inviteSentMembers &&
                    stepNineData.inviteSentMembers.map((member, index) => ( */}
              </div>
            </div>
          </div>
          <div className="navigation-buttons">
            <button
              className="previousStep"
              onClick={() => {
                prevSection("ninth-step", "first-step", stepOneData);
                handleSave(stepNineData, "ninth-step");
              }}
            >
              <img src={previous} className="previous" alt="back-arrow" />
              &nbsp;&nbsp; Previous Step
            </button>
            <div className="next-buttons">
              <button
                className="nextStep"
                onClick={() => {
                  nextSection("ninth-step", "second-step", stepOneData);
                  handleSave(stepNineData, "ninth-step");
                }}
              >
                Next Step&nbsp;&nbsp;{" "}
                <img src={Next} className="next" alt="Next" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="investorDetails tenth-step">
        <Step
          currentStep={7}
          stepsCompleted={stepsCompleted}
          fundrevAnalystID="FN123456"
          startupID={startupID}
          onBoardingComplete={stepFiveData.onBoardingComplete}
          fetchMembersData={fetchMembersData}
        />
        <div className="details-section">
          <div className="investorDetails-first-section sixth">
            <div className="div-sixth">
              <img
                loading="lazy"
                src={Illustration}
                className="sixth-img"
                alt="all steps completed"
              />
              <div className="text-3">Completed successfully</div>
              <div className="text-4">
                Profile completed! Ready to shine and make meaningful
                investments. Bring on the investment!
              </div>
              <button
                className="exploreDeals"
                onClick={navigateToCompanyDashBoard}
              >
                {/* <img src={exploreDeals}/> */}
                &nbsp;&nbsp;Start Raising Funds
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CompanyDetails;
