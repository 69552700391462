import React, { useState, useEffect } from "react";
import Arrow from "../../../Assets/Images/LandingPage/arrow-small-right.svg";
import dashboard from "../../../Assets/Images/InvestorPage/dashboard.svg";
import dashboardlarge from "../../../Assets/Images/InvestorPage/dashboard-large-1.svg";
import dashboardsmall from "../../../Assets/Images/InvestorPage/dashboard-small.svg";
import { Link } from "react-router-dom";
import { ShinyButton } from "../../Common/animations/shinyButton";
import { GoArrowRight } from "react-icons/go";
const InvestorPageFront = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const imageUrl =
    windowWidth > 1400
      ? dashboardlarge
      : windowWidth < 991
      ? dashboardsmall
      : dashboard;
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div className="investor-div">
      <section className="investor-section">
        <header className="investor-header">
          <button className="investor-header-btn">FOR INVESTORS</button>
        </header>
        <div className="investor-content">
          <div className="content-main">
            <div className="content-column">
              <h1 className="main-heading">
                Pre-screen promising investment opportunities
              </h1>
            </div>
            <div className="content-column action-column">
              <Link
                to="/investor/deals"
                state={{ active: "investor" }}
                className="get-started-link"
              >
                <ShinyButton
                  className="rounded-full h-12 px-6 py-3 max-[576px]:py-2.5 max-[576px]:h-10"
                  front={true}
                >
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    Check Listing
                    <GoArrowRight
                      style={{
                        color: "black",
                        height: "20px",
                        marginLeft: "5px",
                      }}
                      size={`15px`}
                    />
                  </span>
                </ShinyButton>
              </Link>
            </div>
          </div>
        </div>
        <img src={imageUrl} alt="" className="banner-image" />
      </section>
    </div>
  );
};

export default InvestorPageFront;
