import React from "react";
import AnalyticsPageTitle from "./AnalyticsPlots/AnalyticsPageTitle";
import CashFlowStatementTable from "./AnalyticsPlots/CashFlowStatementTable";
import CashFlowSmallTable from "./AnalyticsPlots/CashFlowSmallTable";
import BalanceSheetTable from "./AnalyticsPlots/BalanceSheetTable";
import BalanceSheetSummaryTable from "./AnalyticsPlots/BalanceSheetSummaryTable";
import { Box } from "@mui/material";

const HistoricalFinancials = ({ data }) => {
  return (
    <>
      <AnalyticsPageTitle title={`Historical Financials`} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <BalanceSheetTable data={data.tables.balanceSheet} />
        <CashFlowStatementTable data={data.tables.cashFlowStatement} />
        <Box
          sx={{
            display: "flex",
            gap: 2,
          }}
        >
          <CashFlowSmallTable data={data.tables.cashFlowStatement} />
          <BalanceSheetSummaryTable data={data.tables.balanceSheet} />
        </Box>
      </Box>
    </>
  );
};

export default HistoricalFinancials;
