import React from "react";
import { ToastContainer } from "react-toastify";
import Sidebar from "../../Company/Deal/Sidebar";
import InvestorSidebar from "./investorSidebar";
import InvestorOverview from "../../../Common/components/investorOverview";

import { useLocation } from "react-router-dom";
import InputCell from "../../../Common/components/inputCell";
import Topbar from "../../Company/Deal/topbar";
const InvestorPreview = () => {
  const location = useLocation();
  const { dealID, investor, startupID, state } = location.state;
  const columns = React.useMemo(
    () => [
      {
        Header: "Fund",
        accessor: "fund",
        placeholder: "fund",
        Cell: InputCell,
        width: 180,
      },
      {
        Header: "Vintage",
        accessor: "vintage",
        placeholder: "vintage",
        Cell: InputCell,
        width: 180,
      },
      {
        Header: "Investments",
        accessor: "investments",
        placeholder: "investments",
        Cell: InputCell,
        width: 180,
      },
      {
        Header: "Aum",
        accessor: "aum",
        placeholder: "aum",
        Cell: InputCell,
        width: 180,
      },
      {
        Header: "Dry Powder",
        accessor: "dryPowder",
        placeholder: "dryPowder",
        Cell: InputCell,
        width: 180,
      },
    ],
    []
  );
  return (
    <>
      <div className="InvestorSide" style={{ overflowY: "hidden" }}>
        <ToastContainer position="top-center" />
        {state === "companies" ? (
          <Sidebar
            dealID={dealID}
            active={"investorPanel"}
            startupID={startupID}
          />
        ) : (
          <InvestorSidebar active="investorPreview" />
        )}

        <Topbar title="Investor Preview" />
        <div className="InvestorSide-box">
          <div className="InvestorSide-content">
            <div className="cdetails-div">
              <InvestorOverview investor={investor} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InvestorPreview;
