import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Box, Typography } from "@mui/material";
import UploadCustomerTape from "./uploadCustomerTape";
import UploadFinancialStatement from "./uploadFinancialStatement";
import { ToastContainer } from "react-toastify";
import AnalyticsCompanyMapper from "./AnalyticsCompanyMapper";
import { getCompanyMapper } from "../../../../endpoints/admin";
import UploadCashFlow from "./uploadCashFlow";
import UploadBalanceSheet from "./uploadBalanceSheet";

const AnalyticsMapper = () => {
  const location = useLocation();
  const { dealID, startupID } = location.state || {};
  console.log(dealID, startupID);
  const [data, setData] = useState(null);
  const getMapper = async () => {
    const response = await getCompanyMapper(dealID);
    if (response) {
      setData(response.data);
    }
  };
  useEffect(() => {
    getMapper();
  }, [dealID]);
  return (
    <>
      <ToastContainer position="top-center" containerId={`analyticsMapper`} />
      <Helmet>
        <title>Admin Panel - analytics mapper</title>
      </Helmet>
      <div className="InvestorSide-box">
        <Typography variant="h4">Upload Data</Typography>
        <Box
          sx={{
            display: "flex",
            gap: "20px",
          }}
        >
          <div>
            Customer Tape Upload <br />
            <UploadCustomerTape dealId={dealID} />
          </div>
          <div>
            Financial Statement Upload <br />
            <UploadFinancialStatement dealId={dealID} />
          </div>
          <div>
            CashFlow Statement Upload <br />
            <UploadCashFlow dealId={dealID} />
          </div>
          <div>
            Balance Sheet Upload <br />
            <UploadBalanceSheet dealId={dealID} />
          </div>
        </Box>
        {data ? (
          <AnalyticsCompanyMapper data={data?.companyMapper} dealId={dealID} />
        ) : (
          <p
            style={{
              marginTop: "5px",
              color: "red",
            }}
          >
            No Mapper Data Found
          </p>
        )}
      </div>
    </>
  );
};

export default AnalyticsMapper;
