import React, { useEffect, useState } from "react";
import Sidebar2 from "../../Company/Deal/sidebar2";
import Topbar from "../../Company/Deal/topbar";
import { Helmet } from "react-helmet";
import { getDealDetailsByDealId } from "../../../../endpoints/deal";
import Analytics from "../../../Investor/Dashboard/companyDetails/analytics/analytics";
import { useLocation } from "react-router-dom";
import AnalyticsHomble from "../../../Investor/Dashboard/companyDetails/analyticsHomble/AnalyticsHomble";

const AdminAnalytics = () => {
  const location = useLocation();
  const { dealID, startupID } = location.state;
  const [deal, setDeal] = useState(null);
  const token = localStorage.getItem("token");
  const [analytics, setAnalytics] = useState("summary");

  useEffect(() => {
    const fetchDeal = async () => {
      const response = await getDealDetailsByDealId(dealID, token);
      setDeal(response.data);
    };
    fetchDeal();
  }, [dealID, token]);
  useEffect(() => {}, [deal]);
  return (
    <>
      <div className="InvestorSide">
        <Sidebar2
          active="myProfile"
          analytics={analytics}
          setAnalytics={setAnalytics}
          dealID={dealID}
        />
        <Topbar title="Analytics" />
        <Helmet>
          <title>{`${deal?.CompanyName} - Analytics`}</title>
        </Helmet>
        <div className="InvestorSide-box">
          {deal &&
            (dealID === "D787569" ? (
              <AnalyticsHomble
                deal={deal}
                analytics={analytics}
                setAnalytics={setAnalytics}
              />
            ) : (
              <Analytics
                deal={deal}
                analytics={analytics}
                setAnalytics={setAnalytics}
              />
            ))}
        </div>
      </div>
    </>
  );
};

export default AdminAnalytics;
