import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import TimePeriodButton from "../analytics/AnalyticsPlots/TimePeriodButton";

const FinanceSummaryTable = ({
  data,
  ltm = true,
  m = true,
  q = true,
  year = true,
  t = "m",
}) => {
  const [type, setType] = useState(t);
  const [option, setOption] = useState(`previous${type}`);
  const keys = Object.keys(data?.data[type]["Revenue"]);
  const [key, setKey] = useState(keys.slice(-1)[0]);
  const [ckey, setCKey] = useState(null);
  const getComparisonKey = () => {
    const currentMonthYear = key.split(" "); // Assuming key format is "MMM YY"
    let month = currentMonthYear[0]; // e.g., "Feb"
    let year = parseInt(currentMonthYear[1], 10); // e.g., 23

    // Create a date object using current month and year
    let date = new Date(`1 ${month} 20${year}`); // '1 Feb 2023' format

    if (option.includes("previous")) {
      if (option.includes("y") || option.includes("ltm")) {
        date.setFullYear(date.getFullYear() - 1);
      } else {
        // Decrement the month by 1 for "previous" option
        date.setMonth(date.getMonth() - 1);
      }
    } else if (option.includes("last")) {
      // Decrement the year by 1 for "last" option
      date.setFullYear(date.getFullYear() - 1);
    } else {
      return `-`;
    }

    // Format the new date back to "MMM YY"
    const newMonth = date.toLocaleString("en-IN", { month: "short" });
    const newYear = date.getFullYear().toString().slice(-2); // Take last 2 digits of the year

    return `${newMonth} ${newYear}`;
  };

  const rows = [
    "Revenue",
    "Cost of Revenue",
    "Gross Profit",
    "Gross Profit %",
    "SG&A",
    "EBITDA",
    "EBITDA %",
  ];
  const percentageRows = [
    "Gross Profit %",
    "EBITDA %",
    "Net Profit Margin",
    "EBIT %",
  ];
  const IMPRows = [
    "Gross Profit",
    "EBITDA",
    "Net Profit",
    "EBIT",
    "Profit (Before Tax)",
  ];
  useEffect(() => {
    switch (option) {
      case "previousm":
        setCKey(keys.length >= 2 ? keys.slice(-2)[0] : getComparisonKey());
        break;
      case "lastm":
        setCKey(keys.length >= 13 ? keys.slice(-13)[0] : getComparisonKey());
        break;
      case "previousq":
        setCKey(keys.length >= 2 ? keys.slice(-2)[0] : getComparisonKey());
        break;
      case "lastq":
        setCKey(keys.length >= 5 ? keys.slice(-5)[0] : getComparisonKey());
        break;
      case "previousltm":
        setCKey(keys.length >= 13 ? keys.slice(-13)[0] : getComparisonKey());
        break;
      case "previousy":
        setCKey(keys.length >= 13 ? keys.slice(-13)[0] : getComparisonKey());
        break;
      default:
        setCKey(keys.length >= 2 ? keys.slice(-2)[0] : getComparisonKey());
    }
  }, [data, key, ckey, option, keys]);
  useEffect(() => {
    setKey(Object.keys(data?.data[type]["Revenue"]).slice(-1)[0]);
  }, [type, data]);

  const formatter = (x) => {
    if (x === undefined) return null;
    if (typeof x === "string") {
      if (x.includes(`%`)) return x;
      const numx = Number(x);
      if ([NaN, Infinity].includes(numx)) {
        return 0;
      }
      return new Intl.NumberFormat("en-IN", {
        maximumFractionDigits: 1, // Customize decimal places if needed
        minimumFractionDigits: 1,
      }).format(numx);
    } else {
      return new Intl.NumberFormat("en-IN", {
        maximumFractionDigits: 1, // Customize decimal places if needed
        minimumFractionDigits: 1,
      }).format(x);
    }
  };

  function getVariance(row) {
    let a, b;
    if (row !== "Gross Profit %" && row !== "EBITDA %" && row !== "SG&A") {
      a = data?.data[type][row][key];
      b = data?.data[type][row][ckey];
    } else if (row === "SG&A") {
      a = data?.data[type][row]["Total SG&A Expenses"][key];
      b = data?.data[type][row]["Total SG&A Expenses"][ckey];
    }
    const variance = (((a - b) / Math.abs(b)) * 100).toFixed(1);
    // Check if the result is NaN
    return isNaN(variance) ? "-" : variance;
  }

  return (
    <Box
      sx={{
        backgroundColor: "#FFFFFF",
        width: "569px",
        height: "453px",
        borderRadius: "8px",
        boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.05)",
        fontFamily: "Karla, sans-serif",
        padding: 2,
        paddingRight: "11px",
        paddingLeft: 2.5,
      }}
    >
      <TimePeriodButton
        title={data?.name}
        type={type}
        setType={setType}
        select={true}
        ltm={ltm}
        year={false}
        option={option}
        setOption={setOption}
        q={q}
        m={m}
      />
      <Paper
        sx={{
          width: "529px",
          overflow: "hidden",
          boxShadow: "0",
          marginTop: "10px",
          border: "1px solid #B8B8B875",
          borderRadius: "10px",
        }}
      >
        <TableContainer>
          <Table aria-label="Financial statement comparison table" size="small">
            <TableHead>
              <TableRow>
                {["Summary", ckey, key, "% Change"].map((column, index) => (
                  <TableCell
                    sx={{
                      width: index === 0 ? "169px" : "125px",
                      paddingY: "11px",
                      fontFamily: "Karla, sans-serif",
                      fontWeight: 700,
                      fontSize: "14px",
                      whiteSpace: "nowrap",
                      background: "white",
                      border: 0,
                      borderRight: "0.25px solid #D3D9EE50",
                      backgroundColor: "#eaeaea",
                    }}
                    key={index}
                    align={index === 3 ? "right" : "left"}
                  >
                    <Box
                      sx={{
                        display: `flex`,
                        gap: "2px",
                        alignItems: "baseline",
                        justifyContent: index === 3 && "end",
                        color: "#1a1a1a",
                      }}
                    >
                      {column}
                      {index === 0 && (
                        <Typography
                          sx={{
                            fontFamily: `Karla, sans-serif`,
                            fontSize: "10px",
                            fontWeight: "700px",
                            color: "#1a1a1a",
                          }}
                        >
                          (in lakhs)
                        </Typography>
                      )}
                    </Box>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => (
                <TableRow key={index}>
                  <TableCell
                    sx={{
                      width: "169px",
                      paddingY: "11px",
                      fontFamily: "Karla, sans-serif",
                      fontWeight:
                        row === "Gross Profit" || row === "EBITDA" ? 700 : 400,
                      fontSize: "14px",
                      whiteSpace: "nowrap",
                      background: "white",
                      color:
                        (row === "Gross Profit" || row === "EBITDA") &&
                        "#00A6FB",
                      border: "0.25px solid #D3D9EE50",
                      fontStyle: percentageRows.includes(row)
                        ? "italic"
                        : "normal",
                      borderTop: IMPRows.includes(row) && "2px solid #00A6FB",
                    }}
                  >
                    {row}
                  </TableCell>
                  <TableCell
                    sx={{
                      width: "125px",
                      paddingY: "11px",
                      fontFamily: "Karla, sans-serif",
                      fontWeight:
                        row === "Gross Profit" || row === "EBITDA" ? 700 : 400,
                      fontSize: "14px",
                      whiteSpace: "nowrap",
                      background: "white",
                      color:
                        (row === "Gross Profit" || row === "EBITDA") &&
                        "#00A6FB",
                      border: "0.25px solid #D3D9EE50",
                      fontStyle: percentageRows.includes(row)
                        ? "italic"
                        : "normal",
                      borderTop: IMPRows.includes(row) && "2px solid #00A6FB",
                    }}
                  >
                    {row === "SG&A"
                      ? formatter(
                          data?.data[type][row]["Total SG&A Expenses"][ckey]
                        ) || `-`
                      : formatter(data?.data[type][row][ckey]) || `-`}
                  </TableCell>
                  <TableCell
                    sx={{
                      width: "125px",
                      paddingY: "11px",
                      fontFamily: "Karla, sans-serif",
                      fontWeight:
                        row === "Gross Profit" || row === "EBITDA" ? 700 : 400,
                      fontSize: "14px",
                      whiteSpace: "nowrap",
                      background: "white",
                      color:
                        (row === "Gross Profit" || row === "EBITDA") &&
                        "#00A6FB",
                      border: "0.25px solid #D3D9EE50",
                      fontStyle: percentageRows.includes(row)
                        ? "italic"
                        : "normal",
                      borderTop: IMPRows.includes(row) && "2px solid #00A6FB",
                    }}
                  >
                    {row === "SG&A"
                      ? formatter(
                          data?.data[type][row]["Total SG&A Expenses"][key]
                        ) || `-`
                      : formatter(data?.data[type][row][key]) || `-`}
                  </TableCell>
                  <TableCell
                    sx={{
                      width: "129px",
                      paddingY: "11px",
                      fontFamily: "Karla, sans-serif",
                      fontSize: "14px",
                      whiteSpace: "nowrap",
                      fontWeight:
                        row === "Gross Profit" || row === "EBITDA" ? 700 : 400,
                      background:
                        getVariance(row) && getVariance(row) !== `-`
                          ? getVariance(row) > 0
                            ? "#4EF79A1A"
                            : "#D32F2F1A"
                          : `white`,
                      color:
                        getVariance(row) && getVariance(row) !== `-`
                          ? getVariance(row) > 0
                            ? "#00CD9E"
                            : "#D32F2F"
                          : "black",
                      border: "0.25px solid #D3D9EE50",
                      fontStyle: percentageRows.includes(row)
                        ? "italic"
                        : "normal",
                      borderTop: IMPRows.includes(row) && "2px solid #00A6FB",
                    }}
                    align="right"
                  >
                    {getVariance(row) && getVariance(row) !== `-`
                      ? getVariance(row) + `%`
                      : `-`}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
};

export default FinanceSummaryTable;
