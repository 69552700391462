import React, { useState, useEffect } from "react";
import { Modal } from "@mui/material";
import { Input, Box } from "@mui/joy";
import { fetchInvestorMemberDetails } from "../../../endpoints/investor";
import { postqnAs } from "../../../endpoints/qnA";
import invite from "../../../Assets/Images/signup/invite.svg";
import closeIcon from "../../../Assets/Images/InvestorPage/Dashboard/closeIcon.svg";
const AskQuestionModal = ({
  askQuestionModal,
  setAskQuestionModal,
  token,
  dealID,
  investorID,
  roundType,
  setNewQuestionAsked,
  type,
  fundrevAnalystID,
  startupID,
}) => {
  const [inputValue, setInputValue] = useState("");
  const [questions, setQuestions] = useState([]);
  const [investorMemberID, setInvestorMemberID] = useState();
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent form submission
      addItem();
    }
  };
  const addItem = () => {
    if (inputValue.trim()) {
      setQuestions([...questions, inputValue]);
      setInputValue("");
    }
  };
  useEffect(() => {
    const fetchDetails = async () => {
      let response;
      if (token) {
        response = await fetchInvestorMemberDetails(token);
      }
      if (token) {
        if (response && response.data !== null) {
          setInvestorMemberID(response.data.data.investorMemberID);
        } else {
        }
      }
    };
    fetchDetails();
  }, [token]);
  const postquestions = async () => {
    let questionsArr = questions;
    if (inputValue === "" && questions.length === 0) {
      return;
    }
    if (questions.length === 0) {
      questionsArr = [inputValue];
    }
    questionsArr.map(async (question) => {
      var formData;
      if (type === "analystQuestion") {
        formData = {
          question: question,
          askedBy: startupID,
          fundrevAnalyst: fundrevAnalystID,
        };
      } else {
        formData = {
          question: question,
          askedBy: investorMemberID,
          dealID: dealID,
          investorID: investorID,
          roundType: roundType,
        };
      }
      const response = await postqnAs(formData);
      if (response) {
        setAskQuestionModal(false);
        setNewQuestionAsked(true);
        setInputValue("");
        setQuestions([]);
      }
    });
  };
  return (
    <Modal
      className="ask-question-modal"
      style={{ padding: "20px 30px" }}
      open={askQuestionModal}
      onClose={() => setAskQuestionModal(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="modal-box question-box" style={{ width: "700px" }}>
        <div className="reason-box-first-div" style={{ alignItems: "center" }}>
          <p className="qna-section-text">Ask A Question</p>
          <img
            className="reason-box-closeicon"
            src={closeIcon}
            style={{ cursor: "pointer" }}
            onClick={() => setAskQuestionModal(false)}
            alt="close"
          />
        </div>
        <div className="qna-section">
          <Input
            className="question-input"
            placeholder="Enter your question here"
            onChange={(e) => setInputValue(e.target.value)}
            value={inputValue}
            onKeyDown={handleKeyPress}
          />
          <button
            className="post-question-btn"
            onClick={postquestions}
            style={{
              backgroundColor:
                inputValue === "" && questions.length === 0
                  ? "#b9bbbd"
                  : "#00a6fb",
              cursor:
                inputValue === "" && questions.length === 0
                  ? "not-allowed"
                  : "pointer",
            }}
          >
            <p>Post</p>
            <img src={invite} alt="invite" />
          </button>
        </div>
        {/* <button
          className="stage-display-btn qna-question"
          type="submit"
          onClick={handleFormSubmit}
        >
          Add question
        </button> */}
        <div className="asked-questions-display">
          <p className="qna-section-text">Questions</p>
          <ol className="questions-div" style={{ listStyleType: "decimal" }}>
            {questions.map((question, index) => (
              <div className="question-div" key={index}>
                <li>{question}</li>
              </div>
            ))}
          </ol>
        </div>
      </Box>
    </Modal>
  );
};

export default AskQuestionModal;
