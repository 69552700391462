import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import TimePeriodButton from "./TimePeriodButton";
import { formatToLakhs } from "../../../../../../utilities/numberFormatter";

const BalanceSheetSummaryTable = ({ data }) => {
  const [type, setType] = useState("m");
  const [option, setOption] = useState(`previous${type}`);
  const keys = Object.keys(
    data?.data[type][Object.keys(data?.data[type])[0]].values
  );

  const [key, setKey] = useState(keys.slice(-1)[0]);
  const [ckey, setCKey] = useState(null);
  const getComparisonKey = () => {
    const currentMonthYear = key.split(" "); // Assuming key format is "MMM YY"
    let month = currentMonthYear[0]; // e.g., "Feb"
    let year = parseInt(currentMonthYear[1], 10); // e.g., 23

    // Create a date object using current month and year
    let date = new Date(`1 ${month} 20${year}`); // '1 Feb 2023' format

    if (option.includes("previous")) {
      if (option.includes("y") || option.includes("ltm")) {
        date.setFullYear(date.getFullYear() - 1);
      } else {
        // Decrement the month by 1 for "previous" option
        date.setMonth(date.getMonth() - 1);
      }
    } else if (option.includes("last")) {
      // Decrement the year by 1 for "last" option
      date.setFullYear(date.getFullYear() - 1);
    } else {
      return `-`;
    }

    // Format the new date back to "MMM YY"
    const newMonth = date.toLocaleString("en-IN", { month: "short" });
    const newYear = date.getFullYear().toString().slice(-2); // Take last 2 digits of the year

    return `${newMonth} ${newYear}`;
  };

  useEffect(() => {
    switch (option) {
      case "previousm":
        setCKey(keys.length >= 2 ? keys.slice(-2)[0] : getComparisonKey());
        break;
      case "lastm":
        setCKey(keys.length >= 13 ? keys.slice(-13)[0] : getComparisonKey());
        break;
      case "previousq":
        setCKey(keys.length >= 2 ? keys.slice(-2)[0] : getComparisonKey());
        break;
      case "lastq":
        setCKey(keys.length >= 5 ? keys.slice(-5)[0] : getComparisonKey());
        break;
      case "previousltm":
        setCKey(keys.length >= 13 ? keys.slice(-13)[0] : getComparisonKey());
        break;
      case "previousy":
        setCKey(keys.length >= 13 ? keys.slice(-13)[0] : getComparisonKey());
        break;
      default:
        setCKey(keys.length >= 2 ? keys.slice(-2)[0] : getComparisonKey());
    }
  }, [data, key, ckey, option, keys]);
  useEffect(() => {
    setKey(
      Object.keys(
        data?.data[type][Object.keys(data?.data[type])[0]].values
      ).slice(-1)[0]
    );
  }, [type, data]);
  const rows = [
    "Equity",
    "Non-current liability",
    "Current Liability",
    "Total Equity and Liability",
    "Fixed Assets",
    "Current Asset",
    "Cash",
    "Total Assets",
  ];
  const IMPRows = ["Total Equity and Liability", "Total Assets"];
  function getVariance(row) {
    const a = getValue(row)[key];
    const b = getValue(row)[ckey];

    // Check if either 'a' or 'b' is zero to prevent division by zero
    if (a === 0 || b === 0 || !a || !b) {
      return 0;
    }

    return ((a / b - 1) * 100).toFixed(1);
  }
  function mergeValues(target, source) {
    for (const key in source) {
      if (typeof source[key] === "number") {
        if (!target[key]) {
          target[key] = 0;
        }
        target[key] += source[key];
      }
    }
    return target;
  }
  function sumValues(mapper) {
    const keys = Object.keys(mapper[Object.keys(mapper)[0]]);
    const result = {};
    keys.forEach(
      (key) =>
        key !== "Cash" &&
        mergeValues(result, mapper[Object.keys(mapper)[0]][key])
    );
    return result;
  }
  function getValue(row) {
    if (row === "Equity") {
      return data?.data[type]["Equity and Liability"].mappers[0][
        "Shareholder's Equity"
      ]["Shareholder's Equity"];
    } else if (row === "Non-current liability") {
      return sumValues(data?.data[type]["Equity and Liability"].mappers[1]);
    } else if (row === "Current liability") {
      return sumValues(data?.data[type]["Equity and Liability"].mappers[2]);
    } else if (row === "Total Equity and Liability") {
      return data?.data[type]["Equity and Liability"].values;
    } else if (row === "Fixed Assets") {
      return sumValues(data?.data[type]["Assets"].mappers[0]);
    } else if (row === "Current Asset") {
      return sumValues(data?.data[type]["Assets"].mappers[1]);
    } else if (row === "Cash") {
      return data?.data[type]["Assets"].mappers[1]["Current Assets"]["Cash"];
    } else if (row === "Total Assets") {
      return data?.data[type]["Assets"].values;
    } else {
      return data?.data[type]["Equity and Liability"].mappers[0][
        "Shareholder's Equity"
      ]["Shareholder's Equity"];
    }
  }
  return (
    <>
      <Box
        sx={{
          backgroundColor: "#FFFFFF",
          width: "569px",
          height: "453px",
          borderRadius: "8px",
          boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.05)",
          fontFamily: "Karla, sans-serif",
          padding: 2,
          paddingRight: "11px",
          paddingLeft: 2.5,
        }}
      >
        <TimePeriodButton
          title={data?.name}
          type={type}
          setType={setType}
          select={true}
          year={true}
          option={option}
          setOption={setOption}
        />
        <Paper
          sx={{
            width: "529px",
            overflow: "hidden",
            boxShadow: "0",
            marginTop: "10px",
          }}
        >
          <TableContainer>
            <Table aria-label="balance sheet summary table">
              <TableHead>
                <TableRow>
                  {["Summary", ckey, key, "% Change"].map((column, index) => (
                    <TableCell
                      sx={{
                        width: index === 0 ? "169px" : "125px",
                        paddingY: "10px",
                        fontFamily: "Karla, sans-serif",
                        fontWeight: 700,
                        fontSize: "14px",
                        whiteSpace: "nowrap",
                        background: "white",
                        border: "1px solid #B8B8B8",
                      }}
                      key={index}
                    >
                      <Box
                        sx={{
                          display: `flex`,
                          gap: "2px",
                          alignItems: "baseline",
                          justifyContent: index === 3 && "end",
                        }}
                      >
                        {column}
                        {index === 0 && (
                          <Typography
                            sx={{
                              fontFamily: `Karla, sans-serif`,
                              fontSize: "10px",
                              fontWeight: "700px",
                              color: "#6B7494",
                            }}
                          >
                            (in lakhs)
                          </Typography>
                        )}
                      </Box>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell
                      sx={{
                        width: "169px",
                        paddingY: "8px",
                        fontFamily: "Karla, sans-serif",
                        fontWeight: IMPRows.includes(row) ? 700 : 400,
                        fontSize: "14px",
                        whiteSpace: "nowrap",
                        background: "white",
                        color: IMPRows.includes(row) && "#00A6FB",
                        border: "1px solid #B8B8B8",
                        fontStyle: "normal",
                        borderTop: IMPRows.includes(row) && "2px solid #00A6FB",
                      }}
                    >
                      {row}
                    </TableCell>
                    <TableCell
                      sx={{
                        width: "169px",
                        paddingY: "8px",
                        fontFamily: "Karla, sans-serif",
                        fontWeight: IMPRows.includes(row) ? 700 : 400,
                        fontSize: "14px",
                        whiteSpace: "nowrap",
                        background: "white",
                        color: IMPRows.includes(row) && "#00A6FB",
                        border: "1px solid #B8B8B8",
                        fontStyle: "normal",
                        borderTop: IMPRows.includes(row) && "2px solid #00A6FB",
                      }}
                    >
                      {formatToLakhs(getValue(row)[ckey]) || `-`}
                    </TableCell>
                    <TableCell
                      sx={{
                        width: "169px",
                        paddingY: "8px",
                        fontFamily: "Karla, sans-serif",
                        fontWeight: IMPRows.includes(row) ? 700 : 400,
                        fontSize: "14px",
                        whiteSpace: "nowrap",
                        background: "white",
                        color: IMPRows.includes(row) && "#00A6FB",
                        border: "1px solid #B8B8B8",
                        fontStyle: "normal",
                        borderTop: IMPRows.includes(row) && "2px solid #00A6FB",
                      }}
                    >
                      {formatToLakhs(getValue(row)[key]) || `-`}
                    </TableCell>
                    <TableCell
                      sx={{
                        width: "169px",
                        paddingY: "8px",
                        fontFamily: "Karla, sans-serif",
                        fontWeight: IMPRows.includes(row) ? 700 : 400,
                        fontSize: "14px",
                        whiteSpace: "nowrap",
                        border: "1px solid #B8B8B8",
                        fontStyle: "italic",
                        borderTop: IMPRows.includes(row) && "2px solid #00A6FB",
                        background:
                          getVariance(Object.keys(data?.data[type])[index]) > 0
                            ? "#4EF79A1A"
                            : "#D32F2F1A",
                        color:
                          getVariance(Object.keys(data?.data[type])[index]) > 0
                            ? "#00CD9E"
                            : "#D32F2F",
                      }}
                      align="right"
                    >
                      {getVariance(row) ? getVariance(row) + `%` : `-`}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
    </>
  );
};

export default BalanceSheetSummaryTable;
