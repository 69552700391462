import React, { useState, useEffect } from "react";

import CustomInput from "../../Common/components/custom/customInput";
import CustomPhoneInput from "../../Common/components/custom/customPhoneInput";
import CustomPasswordInput from "../../Common/components/custom/customPasswordInput";
import CustomBlackBtn from "../../Common/components/custom/customBlackBtn";
import CustomBlackBtnPhone from "../../Common/components/custom/customBlackBtnPhone";
import { useLoader } from "../../Common/LoaderProvider";
import Toast from "../../Common/Toast";

import { Helmet } from "react-helmet";
import { Formik, Form } from "formik";
import {
  Box,
  FormControl,
  FormLabel,
  LinearProgress,
  linearProgressClasses,
  Typography,
} from "@mui/material";
import * as Yup from "yup";
import { styled } from "@mui/material/styles";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { matchIsValidTel } from "mui-tel-input";

import {
  fetchMembers,
  fetchStartupDetails,
  memberSignUp,
  startupSignUp,
} from "../../../endpoints/startup";

import Logo from "../../../Assets/Images/LandingPage/FUNDREV_LOGO.svg";
import Back from "../../../Assets/Images/signup/back.svg";

import "../../../CSS/Signup/signup.css";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import CompanyRegisterStep2 from "./companyRegister/companyRegisterStep2";
import CompanyRegisterStep3 from "./companyRegister/companyRegisterStep3";

const CompanyRegister = ({ startPoint }) => {
  const location = useLocation();
  const { id } = useParams();
  const [name, setName] = useState();
  const Navigate = useNavigate();
  const [step, setStep] = useState(1);
  const loader = useLoader();
  const [token, setToken] = useState();
  const [currentPage, setCurrentPage] = useState();
  const [data, setData] = useState({});
  const [memberData, setMemberData] = useState({
    name: "",
    organizationId: "",
    phoneNumber: "",
    email: "",
    password: "",
  });
  const getSubHeading = () => {
    switch (step) {
      case 1:
        return "Tell us about yourself";
      case 2:
        return "Tell us about what you are building";
      case 3:
        return "Tell us about your fundraising plans";
      default:
        return null;
    }
  };
  const getBottomText = () => {
    switch (step) {
      case 1:
        return "2 more pages left to complete";
      case 2:
        return "1 more page left to complete";
      case 3:
        return "Almost Done";
      default:
        return null;
    }
  };

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      // Standard message (some browsers may not display the custom message)
      const message =
        "Are you sure you want to leave? Your changes may not be saved.";
      event.returnValue = message; // Standard for most browsers
      return message; // Required for some older browsers
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: "9px",
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: "#D2DCFF",
    },
    [`& .${linearProgressClasses.bar}`]: {
      backgroundColor: "#00CD9E",
    },
  }));

  const validationSchemaStep1 = Yup.object().shape({
    fullName: Yup.string().required("Full Name is required"),
    phoneNumber: Yup.string()
      .required("Phone Number is required")
      .test("invalid", "Please enter a valid phone number", (value) =>
        matchIsValidTel(value)
      ),
    email: Yup.string().email("Invalid email").required("Email is required"),
    password: Yup.string()
      .required("Password is required")
      .min(8, "Password must be at least 8 characters long")
      .matches(
        /(?=.*[a-z])/,
        "Password must contain at least one lowercase letter"
      )
      .matches(
        /(?=.*[A-Z])/,
        "Password must contain at least one uppercase letter"
      )
      .matches(/(?=.*[0-9])/, "Password must contain at least one number")
      .matches(
        /(?=.*[!@#$%^&*])/,
        "Password must contain at least one special character"
      ),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required"),
  });

  const validationSchema3 = Yup.object({
    fullName: Yup.string().required("Full Name is required"),
    phoneNumber: Yup.string()
      .required("Phone Number is required")
      .test("invalid", "Please enter a valid phone number", (value) =>
        matchIsValidTel(value)
      ),
    password: Yup.string()
      .required("Password is required")
      .min(8, "Password must be at least 8 characters long")
      .matches(
        /(?=.*[a-z])/,
        "Password must contain at least one lowercase letter"
      )
      .matches(
        /(?=.*[A-Z])/,
        "Password must contain at least one uppercase letter"
      )
      .matches(/(?=.*[0-9])/, "Password must contain at least one number")
      .matches(
        /(?=.*[!@#$%^&*])/,
        "Password must contain at least one special character"
      ),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required"),
  });
  const StyledFormLabel = styled(FormLabel)`
    font-family: Karla;
    color: var(--Primary-Text-Color);
    font-size: 16px;
    font-weight: 600;
    ::after {
      content: " *";
      color: #d32f2f;
    }
  `;

  const StyledFormControl = styled(FormControl)`
    display: flex;
    gap: 8px;
  `;

  const initialValuesStep1 = {
    fullName: data.fullName || "",
    phoneNumber: data.phoneNumber || "",
    email: data.email || "",
    password: data.password || "",
    confirmPassword: data.confirmPassword || "",
  };

  const [allStates, setAllStates] = useState({
    lowerValidated: false,
    upperValidated: false,
    numberValidated: false,
    specialValidated: false,
    lengthValidated: false,
  });

  const receiveStatesFromChild = (states) => {
    setAllStates(states);
  };

  async function handleSubmit(values) {
    if (values.acceptTC) {
      const finalFormData = {
        companyName: data.companyName,
        name: data.fullName,
        email: data.email,
        password: data.password,
        phoneNumber: data.phoneNumber,
        revenue: values.revenue,
        fundingType: values.fundingType,
        timeline: values.timelineToRaise,
        website: data.companyWebsite,
        description: data.businessDescription,
        industryVerticals: [JSON.stringify([data.industry])],
        roundType: [JSON.stringify([values.roundType])],
        endMarket: data.endMarket,
        fundingRequired: values.fundingRequired,
      };
      let responseData;
      try {
        loader.start("Signing up...");
        responseData = await startupSignUp(finalFormData);
        loader.stop();
      } catch (error) {
        loader.stop();
      }
      const response = responseData?.data;
      if (
        response &&
        response?.data?.message ===
          "Please verify your email with the code sent."
      ) {
        Navigate("/company/emailVerification", {
          state: {
            values: finalFormData,
            startPoint: startPoint,
            startupToken: response.data.verificationToken,
            role: "startup",
          },
        });
      } else if (responseData.error.response.status === 409) {
        Toast(
          "Alerady Registered with this email. Please sign in.",
          "info",
          "companyRegister"
        );
        setTimeout(() => {
          Navigate("/signIn", {
            state: { sentemail: values.email, type: "Startup" },
          });
        }, 2000);
      } else {
        Toast(
          "Something went wrong. Please try again",
          "error",
          "companyRegister"
        );
      }
    } else {
      Toast(
        "Please accept the terms and conditions",
        "error",
        "companyRegister"
      );
    }
  }
  async function handleStepChange(values) {
    if (currentPage === "companyMemberSignup") {
      const newMemberData = {
        email: memberData.email,
        name: values.fullName,
        phoneNumber: values.phoneNumber,
        password: values.password,
      };
      setMemberData((prevState) => ({
        ...prevState,
        ...newMemberData,
      }));
      try {
        loader.start("Signing up...");
        const response = await memberSignUp(newMemberData);
        if (response.error) {
          if (response.error.status === 401) {
            Toast(
              "Toy have already accepted the Terms and Conditions. Redirecting to respective page",
              "info",
              "companyRegister"
            );
          }
          setTimeout(() => {
            Navigate("/signIn", {
              state: { sentemail: memberData.email, type: "Startup" },
            });
          }, 2000);
        } else {
          localStorage.setItem("token", response.data.data.data.token);
          localStorage.setItem("role", response.data.data.data.isOpen);
          localStorage.setItem("role", "company");
          loader.stop();
          if (response.data !== null) {
            Toast(
              "Congratulations you signed up successfully",
              "success",
              "companyRegister"
            );
            setTimeout(() => {
              Navigate("/signIn");
            }, 2000);
            loader.stop();
          } else {
            loader.stop();
            Toast("Error in signUp", "error", "companyRegister");
          }
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setStep(step + 1);
      setData((prev) => {
        return { ...prev, ...values };
      });
    }
  }
  useEffect(() => {
    const fetchDetails = async () => {
      if (location.pathname.startsWith("/companyMemberSignup")) {
        setCurrentPage("companyMemberSignup");
      } else if (location.pathname.startsWith("/company/Register")) {
        setCurrentPage("companyRegister");
      }
      if (currentPage === "companyMemberSignup") {
        const queryParams = new URLSearchParams(location.search);
        const token = queryParams.get("token");

        const senderName = queryParams.get("name");
        console.log(senderName);
        setName(senderName);
        setToken(token);
        setMemberData((prev) => ({
          ...prev,
        }));

        try {
          loader.start("Loading...");
          const response = await fetchStartupDetails(token);
          loader.stop();
          if (response) {
            const startupDetails = response.data.data;

            if (startupDetails) {
              setMemberData((prev) => ({
                ...prev,
                companyName: startupDetails.companyName,
                organizationId: startupDetails.startupID,
              }));
            }
          }
        } catch (error) {
          loader.stop();
        }
      }
    };

    fetchDetails();
  }, [location, currentPage]);

  useEffect(() => {
    const fetchAllMembers = async () => {
      try {
        if (location.pathname.startsWith("/companyMemberSignup")) {
          loader.start("Loading Members...");
          const response = await fetchMembers(memberData.organizationId, token);
          loader.stop();
          if (response) {
            const members = response.data.data;

            if (members) {
              const member = members.find((member) => member._id === id);
              if (member) {
                setMemberData((prev) => ({
                  ...prev,
                  email: member.email,
                }));
              }
            }
          }
        }
      } catch (error) {
        loader.stop();
      }
    };

    fetchAllMembers();
  }, [memberData.organizationId]);
  const getForm = () => {
    switch (step) {
      case 1:
        return (
          <Formik
            initialValues={initialValuesStep1}
            validationSchema={
              currentPage === "companyRegister"
                ? validationSchemaStep1
                : validationSchema3
            }
            onSubmit={handleStepChange}
          >
            {({
              errors,
              touched,
              handleChange,
              handleBlur,
              values,
              setFieldValue,
            }) => (
              <Form
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "40px",
                  width: "100%",
                }}
              >
                <div className="sign-up-input-fields">
                  <StyledFormControl fullWidth>
                    <StyledFormLabel>Full Name</StyledFormLabel>
                    <CustomInput
                      placeholder="Richa Shah"
                      name="fullName"
                      value={values.fullName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(touched.fullName && errors.fullName)}
                      helperText={touched.fullName && errors.fullName}
                    />
                  </StyledFormControl>

                  <StyledFormControl fullWidth>
                    <StyledFormLabel>Phone Number</StyledFormLabel>
                    <CustomPhoneInput
                      value={values.phoneNumber}
                      onChange={(value) => setFieldValue("phoneNumber", value)}
                      error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                      helperText={touched.phoneNumber && errors.phoneNumber}
                    />
                  </StyledFormControl>
                  {currentPage === "companyRegister" ? (
                    <StyledFormControl fullWidth>
                      <StyledFormLabel>Official Email Address</StyledFormLabel>
                      <CustomInput
                        placeholder="Enter work email address"
                        name="email"
                        type="email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(touched.email && errors.email)}
                        helperText={touched.email && errors.email}
                      />
                    </StyledFormControl>
                  ) : (
                    <StyledFormControl fullWidth>
                      <StyledFormLabel>Official Email Address</StyledFormLabel>
                      <CustomInput
                        placeholder="Enter work email address"
                        name="email"
                        type="email"
                        value={memberData.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(touched.email && errors.email)}
                        helperText={touched.email && errors.email}
                        disabled
                      />
                    </StyledFormControl>
                  )}

                  <StyledFormControl fullWidth>
                    <StyledFormLabel>Create Password</StyledFormLabel>
                    <CustomPasswordInput
                      placeholder="Enter password"
                      name="password"
                      value={values.password}
                      onChange={handleChange}
                      error={Boolean(touched.password && errors.password)}
                      helperText={touched.password && errors.password}
                      sendStatesToParent={receiveStatesFromChild}
                    />
                  </StyledFormControl>

                  <StyledFormControl fullWidth>
                    <StyledFormLabel>Confirm Password</StyledFormLabel>
                    <CustomPasswordInput
                      placeholder="Re-enter password"
                      name="confirmPassword"
                      value={values.confirmPassword}
                      onChange={handleChange}
                      error={Boolean(
                        touched.confirmPassword && errors.confirmPassword
                      )}
                      helperText={
                        touched.confirmPassword && errors.confirmPassword
                      }
                      sendStatesToParent={receiveStatesFromChild}
                    />
                  </StyledFormControl>
                </div>
                <div className="sign-up-btn-section laptop-design">
                  <CustomBlackBtn type="submit" text="Continue" />
                  <p>
                    Already have an account?{" "}
                    <Link to="/signin">
                      <span className="link-blue-text">Sign In</span>
                    </Link>
                  </p>
                </div>

                <div className="sign-up-btn-section-phone phone-design">
                  <CustomBlackBtnPhone type="submit" text="Continue" />
                  <p>
                    Already have an account?{" "}
                    <Link to="/signin">
                      <span className="link-blue-text">Sign In</span>
                    </Link>
                  </p>
                </div>
              </Form>
            )}
          </Formik>
        );
      case 2:
        return (
          <CompanyRegisterStep2
            handleStepChange={handleStepChange}
            data={data}
          />
        );
      case 3:
        return <CompanyRegisterStep3 handleSubmit={handleSubmit} data={data} />;
      default:
        return null;
    }
  };
  return (
    <div className="company-sign-up-page">
      <Helmet>
        <title>Fundrev | Company Register</title>
      </Helmet>
      <ToastContainer position="top-center" containerId={"companyRegister"} />

      <div className="company-sign-up-page-header">
        <Link to="/">
          <img src={Logo} alt="fundrev_logo" />
        </Link>
      </div>

      <div className="sign-up-block">
        <div className="heading-block">
          <img
            src={Back}
            alt="back"
            className="back-icon phone-design"
            onClick={() => setStep(step === 1 ? Navigate(-1) : step - 1)}
          />
          <div>
            <p className="fundrev-heading investor-register-heading">
              {currentPage === "companyMemberSignup" ? (
                <>
                  {name} from {memberData.companyName}
                  <br /> has invited you to join Fundrev!
                </>
              ) : (
                <>Create your company account</>
              )}
            </p>
            <Typography
              // className="laptop-design"
              sx={{
                fontFamily: "Roboto",
                fontSize: "16px",
                lineHeight: "35px",
                color: "var(--Secondary-Text-Color)",
              }}
            >
              {getSubHeading()}
            </Typography>
          </div>
        </div>

        <div className="middle-block">
          <div className="sign-up-left-section">
            <img
              src={Back}
              alt="back"
              className="back-icon"
              onClick={() => setStep(step === 1 ? Navigate(-1) : step - 1)}
            />
          </div>

          <div className="sign-up-right-block">{getForm()}</div>
        </div>
      </div>
      {currentPage === "companyRegister" && (
        <Box
          sx={{
            position: "fixed",
            bottom: 0,
            width: "100%",
            "@media (max-width: 576px)": {
              display: "none",
            },
          }}
        >
          <Typography
            sx={{
              fontFamily: "Karla, sans-serif",
              fontSize: "14px",
              fontWeight: "100",
              color: "#696F79",
              marginLeft: "5px",
            }}
          >
            {getBottomText()}
          </Typography>
          <BorderLinearProgress
            variant="determinate"
            value={(step / 3) * 100}
          />
        </Box>
      )}
    </div>
  );
};

export default CompanyRegister;
