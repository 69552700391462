import React, { useEffect, useState } from "react";
import { useLoader } from "../Common/LoaderProvider";
import { getAllNoNameDeals } from "../../endpoints/deal";
import CheckboxGroup from "../Common/components/molecules/checkBoxGroup";
import {
  Box,
  Button,
  Checkbox,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { industryVerticalsShort } from "../Common/functions";

import { FaFilterCircleXmark } from "react-icons/fa6";
import { Input } from "@mui/joy";
import { Link2Icon } from "@radix-ui/react-icons";
import { generateShortURL } from "../../endpoints/common";
import { ToastContainer } from "react-toastify";
import Toast from "../Common/Toast";

const GenerateURL = () => {
  const [cardType, setCardType] = useState("Investors");
  const [deals, setDeals] = useState([]);
  const token = localStorage.getItem("token");
  const loader = useLoader();
  const [endMarketFocus, setEndMarketFocus] = useState([]);
  const [industryVerticals, setIndustryVerticals] = useState([]);
  const [roundTypes, setRoundTypes] = useState([]);
  const [selectedEndMarketFocus, setSelectedEndMarketFocus] = useState([]);
  const [selectedIndustryVerticals, setSelectedIndustryVerticals] = useState(
    []
  );
  const [selectedRoundTypes, setSelectedRoundTypes] = useState([]);
  const [investorName, setInvestorName] = useState("");
  const [companyMemberName, setCompanyMemberName] = useState("");
  const [firmName, setFirmName] = useState("");
  const [investorType, setInvestorType] = useState([]);
  const [seniority, setSeniority] = useState(``);
  const [campaign, setCampaign] = useState(``);
  const [version, setVersion] = useState(``);
  const fullToShortIndustryMapping = Object.fromEntries(
    Object.entries(industryVerticalsShort).map(([short, full]) => [full, short])
  );
  async function getNoNameDeals() {
    try {
      loader.start();
      const data = await getAllNoNameDeals(token);
      setDeals(data.data);
      loader.stop();
    } catch (error) {
      loader.stop();
    }
  }
  const handleCardTypeChange = (event, newCardType) => {
    if (newCardType !== null) setCardType(newCardType);
  };
  useEffect(() => {
    getNoNameDeals();
  }, [token]);

  useEffect(() => {
    getAllOptions(deals);
  }, [deals]);

  function updateOptions(deals, fieldName, setState, setSelectedState) {
    deals &&
      deals.length > 0 &&
      deals.forEach((deal) => {
        try {
          const parsedField = JSON.parse(deal[fieldName]);
          const fieldArray = Array.isArray(parsedField) ? parsedField : [];

          setState((prev) => {
            const updatedArray = [...prev, ...fieldArray];
            return [...new Set(updatedArray)];
          });
          setSelectedState((prev) => {
            const updatedArray = [...prev, ...fieldArray];
            return [...new Set(updatedArray)];
          });
        } catch (error) {
          setState((prev) => {
            const updatedArray = [...prev];
            return [...new Set(updatedArray)];
          });
          setSelectedState((prev) => {
            const updatedArray = [...prev];
            return [...new Set(updatedArray)];
          });
        }
      });
  }
  const investorTypes = [
    "Family Office",
    "Private Equity",
    "Venture Capital",
    "Corporate",
    "HNI/Angel Investor",
  ];
  const titles = ["1", "2", "3"];
  const campaigns = ["LinkedIn", "Email", "Advertisement"];
  function clearAllFilters() {
    setSelectedEndMarketFocus([]);
    setSelectedIndustryVerticals([]);
    setSelectedRoundTypes([]);
  }
  function getAllOptions(deals) {
    updateOptions(
      deals,
      "EndMarketFocus",
      setEndMarketFocus,
      setSelectedEndMarketFocus
    );
    updateOptions(
      deals,
      "IndustryVerticals",
      setIndustryVerticals,
      setSelectedIndustryVerticals
    );
    updateOptions(deals, "RoundType", setRoundTypes, setSelectedRoundTypes);
  }
  const handleInvestorTypeChange = (event) => {
    const {
      target: { value },
    } = event;

    // Since MUI Select with multiple returns an array, we can set it directly
    setInvestorType(value);
  };
  const handleCampaignTypeChange = (event) => {
    const {
      target: { value },
    } = event;

    setCampaign(value);
  };
  const handleVersionChange = (event) => {
    setVersion(event.target.value);
  };
  const handleNameChange = (event, type) => {
    if (type === "investor") {
      setInvestorName(event.target.value);
    } else {
      setCompanyMemberName(event.target.value);
    }
  };
  const handleFirmNameChange = (event) => {
    setFirmName(event.target.value);
  };
  const handleSeniorityTypeChange = (event) => {
    const {
      target: { value },
    } = event;

    setSeniority(value);
  };

  const generateLink = async () => {
    // Construct the query string
    let url;
    if (cardType === "Investors") {
      const queryParams = new URLSearchParams({
        emf: selectedEndMarketFocus.join(","),
        inv: selectedIndustryVerticals
          .map((industry) => fullToShortIndustryMapping[industry] || industry)
          .join(","),
        rt: selectedRoundTypes.join(","),
        invN: investorName,
        fN: firmName,
        invType: investorType,
        level: seniority,
        camp: campaign,
        v: version,
      });

      // Generate the URL
      url = `investor/deals?${queryParams.toString()}`;
    } else {
      const queryParams = new URLSearchParams({
        companyMemberName: companyMemberName,
        firmName: firmName,
        campaign: campaign,
        version: version,
      });

      // Generate the URL
      url = `?${queryParams.toString()}`;
    }
    const response = await generateShortURL(url);
    if (response) {
      await navigator.clipboard.writeText(
        process.env.REACT_APP_FRONTEND_URL + response
      );
      Toast("Successfully copied to clipboard", "success", `shortURL`);
    }
  };

  return (
    <>
      <ToastContainer containerId={`shortURL`} position="top-center" />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div>
          <ToggleButtonGroup
            color="primary"
            value={cardType}
            exclusive
            onChange={handleCardTypeChange}
            aria-label="card-type"
            className="toggle-card-type-btn"
          >
            <ToggleButton
              value="Investors"
              sx={{
                textTransform: "capitalize",
              }}
            >
              Investors
            </ToggleButton>
            <ToggleButton
              value="Companies"
              sx={{
                textTransform: "capitalize",
              }}
            >
              Companies
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
        <Button onClick={generateLink}>
          Generate Link <Link2Icon />
        </Button>
      </Box>
      {cardType === "Investors" ? (
        <>
          {" "}
          <div className="deals-filter-box">
            <Button className="clear-filters" onClick={clearAllFilters}>
              <FaFilterCircleXmark />
              &nbsp; Clear filters
            </Button>
            <CheckboxGroup
              title="Industry Vertical"
              options={industryVerticals}
              selectedOptions={selectedIndustryVerticals}
              setSelectedOptions={setSelectedIndustryVerticals}
            />
            <div className="deals-filter-box-first-row">
              <CheckboxGroup
                title="End Market Focus"
                options={endMarketFocus}
                selectedOptions={selectedEndMarketFocus}
                setSelectedOptions={setSelectedEndMarketFocus}
                className="noNameDeal-EndMarketFocus"
              />
              <CheckboxGroup
                title="Round Type"
                options={roundTypes}
                selectedOptions={selectedRoundTypes}
                setSelectedOptions={setSelectedRoundTypes}
              />
            </div>
          </div>
          <Box
            display={`flex`}
            sx={{
              justifyContent: "stretch",
              gap: "10px",
            }}
          >
            <Box>
              <Box
                sx={{
                  width: "300px",
                }}
              >
                Investor Name
                <Input
                  type="text"
                  onChange={(event) => handleNameChange(event, "investor")}
                  size="lg"
                />
              </Box>

              <Box
                sx={{
                  width: "300px",
                }}
              >
                Firm Name
                <Input type="text" onChange={handleFirmNameChange} size="lg" />
              </Box>
              <Box
                sx={{
                  width: "300px",
                }}
              >
                Version
                <Input type="text" onChange={handleVersionChange} size="lg" />
              </Box>
            </Box>
            <Box>
              <Box
                sx={{
                  width: "300px",
                  flexDirection: "column",
                  display: "flex",
                }}
              >
                Investor Type
                <Select
                  multiple
                  value={investorType}
                  onChange={handleInvestorTypeChange}
                  input={<OutlinedInput label="Investor Type" />}
                  renderValue={(selected) => selected.join(", ")}
                >
                  {investorTypes.map((name) => (
                    <MenuItem key={name} value={name}>
                      <Checkbox checked={investorType.indexOf(name) > -1} />
                      <ListItemText primary={name} />
                    </MenuItem>
                  ))}
                </Select>
              </Box>
              <Box
                sx={{
                  width: "300px",
                  flexDirection: "column",
                  display: "flex",
                }}
              >
                Campaign
                <Select
                  value={campaign}
                  onChange={handleCampaignTypeChange}
                  input={<OutlinedInput label="Campaign" />}
                  renderValue={(selected) => selected || "Select a campaign"}
                >
                  {campaigns.map((name) => (
                    <MenuItem key={name} value={name}>
                      <ListItemText primary={name} />
                    </MenuItem>
                  ))}
                </Select>
              </Box>
              <Box
                sx={{
                  width: "300px",
                  flexDirection: "column",
                  display: "flex",
                }}
              >
                Seniority
                <Select
                  value={seniority}
                  onChange={handleSeniorityTypeChange}
                  input={<OutlinedInput label="Seniority" />}
                  renderValue={(selected) => selected || `Select Seniority`}
                >
                  {titles.map((name) => (
                    <MenuItem key={name} value={name}>
                      <ListItemText primary={name} />
                    </MenuItem>
                  ))}
                </Select>
              </Box>
            </Box>
          </Box>
        </>
      ) : (
        <Box
          display={`flex`}
          sx={{
            justifyContent: "stretch",
            gap: "10px",
          }}
        >
          <Box>
            <Box
              sx={{
                width: "300px",
              }}
            >
              Name
              <Input
                type="text"
                onChange={(event) => handleNameChange(event, "company")}
                size="lg"
              />
            </Box>

            <Box
              sx={{
                width: "300px",
              }}
            >
              Company Name
              <Input type="text" onChange={handleFirmNameChange} size="lg" />
            </Box>
            <Box
              sx={{
                width: "300px",
              }}
            >
              Version
              <Input type="text" onChange={handleVersionChange} size="lg" />
            </Box>
          </Box>
          <Box>
            <Box
              sx={{
                width: "300px",
                flexDirection: "column",
                display: "flex",
              }}
            >
              Campaign
              <Select
                value={campaign}
                onChange={handleCampaignTypeChange}
                input={<OutlinedInput label="Campaign" />}
                renderValue={(selected) => selected || "Select a campaign"}
              >
                {campaigns.map((name) => (
                  <MenuItem key={name} value={name}>
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default GenerateURL;
