import React, { useEffect, useState } from "react";
import { useZoomEffect } from "../../../Common/functions";
import Header from "./header";
import Sidebar from "../Components/sidebar";
import Topbar from "../Components/topbar";
import Timeline from "../Components/Timeline";
import Overview from "./overview";
import DealDocuments from "./dealDocuments";
import QnaSection from "./qnaSection";
import { companyState } from "../companyState";
import PassDealModal from "../../../Common/modals/passDealModal";
import { postStageData } from "../../../../endpoints/deal";
import UploadDocumentModal from "../../../Common/modals/uploadDocumentModal";
import Toast from "../../../Common/Toast";
import { getAllDealInteractions } from "../../../../endpoints/deal";

import { useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { ToastContainer } from "react-toastify";
import { useRecoilValue } from "recoil";
import { useLoader } from "../../../Common/LoaderProvider";

const InvestorDetails = () => {
  const [isLoading, setIsLoading] = useState(false);
  const token = localStorage.getItem("token");
  const { activeBtn } = useRecoilValue(companyState);
  const loader = useLoader();
  const location = useLocation();
  const Navigate = useNavigate();
  const { investor, passedStage, dealId, sentBy, investorID } =
    location.state || {};
  const [stage, setStage] = useState(passedStage ? passedStage : "");
  const [openReasonModal, setOpenReasonModal] = useState(false);
  const [SPAModalOpen, setSPAModalOpen] = useState(false);
  const [spaFile, setspaFile] = useState(null);
  const [fileInfo, setFileInfo] = useState({});
  const spaFileChange = (file) => {
    setspaFile(file);
  };
  const handleCancelSPA = () => {
    setSPAModalOpen(false);
    setFileInfo({});
    setspaFile();
  };
  const handleSPASubmit = async () => {
    try {
      if (!spaFile) {
        Toast("Please upload a file", "error", "investorDetails");
        return;
      }
      const formData = new FormData();
      formData.append("DealID", dealId);
      formData.append("Stage", "spaNegotiation");
      formData.append("IsInterested", true);
      formData.append("investorID", investor.investorID);
      formData.append("SPA", spaFile[0]);
      formData.append("sentBy", investor.sentBy);
      loader.start("Uploading SPA...");
      const response = await postStageData(formData, token);
      loader.stop();
      if (response.data !== null) {
        setSPAModalOpen(false);
        setFileInfo({});
        setspaFile();
        Navigate("/company/spaNegotiation", {
          state: {
            stage: "spaNegotiation",
            dealId: dealId,
            investorID: investor.investorID,
            companyName: investor.companyName,
            sentBy: sentBy,
          },
        });
      }
    } catch (error) {
      loader.stop();
      Toast(
        "Failed to upload SPA due to some internal errors",
        "error",
        "investorDetails"
      );
    }
  };
  const handleAccept = async () => {
    let stageAccept;
    if (stage === "requestSent") {
      stageAccept = "requestAccepted";
    } else if (stage === "ioiSubmitted") {
      stageAccept = "ioiAccepted";
    } else if (stage === "loiSubmitted") {
      stageAccept = "loiAccepted";
    }
    const updatedFormData = {
      DealID: dealId,
      investorID: investorID,
      IsInterested: true,
      Stage: stageAccept,
      sentBy: sentBy,
    };
    try {
      loader.start();
      const response = await postStageData(updatedFormData, token);
      loader.stop();
      if (response.data !== null) {
        setStage(stageAccept);
      }
    } catch (error) {
      loader.stop();
    }
  };

  const redirectToSPA = () => {
    Navigate("/company/spaNegotiation", {
      state: {
        stage: "spaNegotiation",
        dealId: dealId,
        investorID: investor.investorID,
        companyName: investor.companyName,
        sentBy: sentBy,
      },
    });
  };

  const redirectToIOIBid = () => {
    Navigate("/company/ioiBid", {
      state: {
        stage: "ioiSubmitted",
        dealId: dealId,
        investorID: investor.investorID,
        companyName: investor.companyName,
      },
    });
  };
  const redirectToLOIAgreement = () => {
    Navigate("/company/loiAgreement", {
      state: {
        stage: "loiSubmitted",
        dealId: dealId,
        investorID: investor.investorID,
        companyName: investor.companyName,
      },
    });
  };
  // const getTeamDetails = async () => {
  //   if (!investor) return;
  //   try {
  //     loader.start("Fetching details...");
  //     const response = await getInvestmentTeamDetails(
  //       investor.investorID,
  //       token
  //     );
  //     if (response.data !== null) {
  //       setTeamDetails(response.data.data);
  //     } else {
  //       loader.stop();
  //     }
  //   } catch (error) {
  //     loader.stop();
  //   }
  // };
  const fetchAllInteractions = async () => {
    try {
      const response = await getAllDealInteractions(
        dealId,
        investor.investorID,
        token
      );
      setStage(response.data[response.data.length - 1].Stage);
      loader.stop();
    } catch (error) {
      loader.stop();
    }
  };
  useEffect(() => {
    fetchAllInteractions();
  }, [dealId, investor]);
  // useEffect(() => {
  //   getTeamDetails();
  // }, [investor]);
  useEffect(() => {
    if (!investor || !passedStage || !dealId) {
      Navigate("/company/fundraising");
    }
  }, [investor, passedStage, dealId]);

  useZoomEffect();
  return (
    <>
      {isLoading ? (
        <>Loading...</>
      ) : (
        <div className="InvestorSide companySide">
          <ToastContainer
            position="top-center"
            containerId="investorDeatails"
          />
          <Sidebar active="fundraising" />
          <Topbar title="Fundraising" isBack={true} />
          <Helmet>
            <title>Company | Fundraising</title>
          </Helmet>

          <PassDealModal
            openReasonModal={openReasonModal}
            setOpenReasonModal={setOpenReasonModal}
            stage={stage}
            isCompany={true}
            DealID={dealId}
            investorID={investorID}
          />
          <UploadDocumentModal
            DocumentUploadModalOpen={SPAModalOpen}
            setDocumentUploadModalOpen={setSPAModalOpen}
            DocumentUploadFile={spaFile}
            DocumentUploadFileChange={spaFileChange}
            handleCancelDocumentUpload={handleCancelSPA}
            handleDocumentUploadSubmit={handleSPASubmit}
          />
          <div
            className="InvestorSide-box"
            style={{
              overflowX: "hidden",
              display: "flex",
              flexDirection: "row-reverse",
              padding: "0px",
              overflowY: "scroll",
            }}
          >
            <Timeline
              passedStage={stage}
              investor={investor}
              dealId={dealId}
              companyName={investor?.companyName}
              sentBy={sentBy}
            />
            <div className="InvestorSide-content" style={{ flex: 5 }}>
              {(stage === "requestSent" || stage === "requestAccepted") && (
                <div className="cdetails-div">
                  <Overview investor={investor} />
                </div>
              )}
              {stage !== "requestSent" && stage !== "requestAccepted" && (
                <div className="cdetails-div">
                  <Header activeBtn={1} stage={stage} />
                  {activeBtn === 1 && <Overview investor={investor} />}
                  {activeBtn === 2 && (
                    <DealDocuments
                      dealId={dealId}
                      investorID={investor.investorID}
                    />
                  )}
                  {activeBtn === 3 && (
                    <QnaSection
                      dealID={dealId}
                      investorID={investor.investorID}
                      token={token}
                    />
                  )}
                </div>
              )}

              {stage === "requestSent" && (
                <div className="showInterestButtons-div">
                  <div className="btns-insideContent">
                    <div
                      className="showInterestButtons-div-2"
                      onClick={() => setOpenReasonModal(true)}
                    >
                      Decline Offer
                    </div>
                    <div className="showInterestButtons-right">
                      <div
                        className="stage-display-btn ask-question"
                        style={{ backgroundColor: "#00A6FB", color: "white" }}
                        onClick={handleAccept}
                      >
                        Accept Request
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {/* {stage === "ioiSubmitted" && (
                <div className="showInterestButtons-div">
                  <div
                    className="showInterestButtons-div-2"
                    onClick={() => setOpenReasonModal(true)}
                  >
                    Pass Deal
                  </div>
                  <div className="showInterestButtons-right">
                    <div
                      className="stage-display-btn ask-question"
                      onClick={redirectToIOIBid}
                    >
                      View IOI Bid
                    </div>
                  </div>
                </div>
              )}
              {stage === "loiSubmitted" && (
                <div className="showInterestButtons-div">
                  <div
                    className="showInterestButtons-div-2"
                    onClick={() => setOpenReasonModal(true)}
                  >
                    Pass Deal
                  </div>
                  <div className="showInterestButtons-right">
                    <div
                      className="stage-display-btn ask-question"
                      onClick={redirectToLOIAgreement}
                    >
                      View LOI Agreement
                    </div>
                  </div>
                </div>
              )}
              {stage === "loiAccepted" && (
                <div className="showInterestButtons-div">
                  <div
                    className="showInterestButtons-div-2"
                    style={{ visibility: "hidden" }}
                  >
                    Random
                  </div>
                  <div className="showInterestButtons-right">
                    <div
                      className="stage-display-btn ask-question"
                      style={{ backgroundColor: "#00A6FB", color: "white" }}
                      onClick={() => setSPAModalOpen(true)}
                    >
                      Attach SPA
                    </div>
                  </div>
                </div>
              )} */}
              {stage !== "requestSent" && (
                <div className="showInterestButtons-div">
                  <div className="btns-insideContent">
                    {stage !== "dealClosed" && stage !== "requestSent" && (
                      <div
                        className="showInterestButtons-div-2"
                        onClick={() => setOpenReasonModal(true)}
                      >
                        Pass Deal
                      </div>
                    )}
                    <div className="showInterestButtons-right">
                      {stage === "requestAccepted" ||
                        stage === "ioiSubmitted" ||
                        stage === "ioiAccepted" ||
                        stage === "loiSubmitted" ||
                        stage === "spaNegotiation" ||
                        stage === "loiAccepted"}

                      {stage === "ioiSubmitted" && (
                        <div
                          className="stage-display-btn ask-question"
                          onClick={redirectToIOIBid}
                        >
                          View IOI Bid
                        </div>
                      )}
                      {stage === "ioiAccepted" && (
                        <div
                          className="stage-display-btn ask-question"
                          onClick={redirectToIOIBid}
                        >
                          View IOI Bid
                        </div>
                      )}
                      {stage === "loiSubmitted" && (
                        <div
                          className="stage-display-btn ask-question"
                          onClick={redirectToLOIAgreement}
                        >
                          View LOI Agreement
                        </div>
                      )}
                      {stage === "loiAccepted" && (
                        <div
                          className="showInterestButtons-div-3"
                          style={{ backgroundColor: "#00A6FB", color: "white" }}
                          onClick={() => setSPAModalOpen(true)}
                        >
                          Attach SPA
                        </div>
                      )}
                      {stage === "spaNegotiation" && (
                        <div
                          className="stage-display-btn ask-question"
                          onClick={redirectToSPA}
                        >
                          View SPA Negotiation
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default InvestorDetails;
