import React from "react";

const CardTags = ({ deal, page, cardTags }) => {
  const parseAndRender = (jsonString) => {
    try {
      const parsedArray = JSON.parse(jsonString);
      return parsedArray.map((field, index) => (
        <div
          key={index}
          className="cdetails-div-8"
          style={{
            backgroundColor: page === "detailsPage" && "#4EF79A1A",
            padding: page === "detailsPage" && "2px 8px",
            fontSize: page === "detailsPage" && "16px",
            color: page === "detailsPage" && "#1A1A1A",
            fontWeight: page === "detailsPage" && "600",
            height: page === "detailsPage" && "34px",
            alignContent: page === "detailsPage" && "center",
          }}
        >
          {field}
        </div>
      ));
    } catch (error) {
      console.error("Parsing error:", error);
      return null;
    }
  };
  return (
    <div
      className="cdetails-div-7"
      style={{
        marginTop: page === "noNameDetailsPage" && "24px",
      }}
    >
      {deal && deal.RoundType.length > 0 && parseAndRender(deal.RoundType)}
      {deal &&
        deal.IndustryVerticals.length > 0 &&
        parseAndRender(deal.IndustryVerticals)}
      {deal &&
        deal.EndMarketFocus.length > 0 &&
        parseAndRender(deal.EndMarketFocus)}
      {deal && deal.FundingType.length > 0 && parseAndRender(deal.FundingType)}
    </div>
  );
};

export default CardTags;
