//Keeping nda signed stage in this page it may be required later
import React, { useEffect, useState } from "react";
import Sidebar from "./Components/sidebar";
import Topbar from "./Components/topbar";
import Message from "../../Common/components/message";
import Thread from "../../Common/components/thread";
import { useZoomEffect, fetchBlobName } from "../../Common/functions";
import { useRecoilValue } from "recoil";
import Toast from "../../Common/Toast";
import { companyState } from "./companyState";
import PassDealModal from "../../Common/modals/passDealModal";
import UploadDocumentModal from "../../Common/modals/uploadDocumentModal";
import {
  addDealInteraction,
  getDealInteraction,
  updateDealInteraction,
  dealInterestByStage,
  getAllDealInteractions,
  postStageData,
} from "../../../endpoints/deal";
import {
  fetchStartupMemberDetailsbyId,
  fetchStartupMemberDetails,
} from "../../../endpoints/startup";
import { getFundrevAnalystDetailsById } from "../../../endpoints/fundrevAnalyst";
import { fetchInvestorMemberDetailsbyId } from "../../../endpoints/investor";
import SPAAcceptanceModal from "../../Common/modals/spaAcceptanceModal";

import { Textarea } from "@mui/joy";
import { ToastContainer } from "react-toastify";
import { ErrorOutlined } from "@mui/icons-material";
import moment from "moment-timezone";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { FileUploader } from "react-drag-drop-files";
import { Modal } from "@mui/material";
import { Box } from "@mui/joy";

import FileImg from "../../../Assets/Images/signup/file.svg";
import download from "../../../Assets/Images/InvestorPage/Dashboard/download.svg";
import file from "../../../Assets/Images/InvestorPage/Dashboard/file.svg";
import sendMessage from "../../../Assets/Images/InvestorPage/Dashboard/addMessage.svg";
import cross from "../../../Assets/Images/signup/cross.svg";
import filter from "../../../Assets/Images/InvestorPage/Dashboard/filter.svg";
import uploadFile from "../../../Assets/Images/signup/uploadFile.svg";
import uploadFileBlue from "../../../Assets/Images/InvestorPage/Dashboard/uploadFile.svg";
import uploadFileSmall from "../../../Assets/Images/signup/uploadFileSmall.svg";
import VisibilityIcon from "../../../Assets/Images/FounderPage/Dashboard/visibllityIcon.svg";
import closeIcon from "../../../Assets/Images/InvestorPage/Dashboard/closeIcon.svg";
import "../../../CSS/InvestorPage/Dashboard/signNDA.css";
import { useLoader } from "../../Common/LoaderProvider";
import AcceptConfirmationModal from "./Components/acceptConfirmationModal";
const MessagesPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { stage, dealId, companyName, investorID, sentBy } = location.state || {
    stage: "requestAccepted",
  };
  const fundrevAnalystID = "FN123456";
  const [latestStage, setLatestStage] = useState("requestAccepted");
  const token = localStorage.getItem("token");
  const { name } = useRecoilValue(companyState);
  const [fileUploads, setFileUploads] = useState([]);
  const [fileInfo, setFileInfo] = useState({});
  const [message, setMessage] = useState("");
  const [threadID, setThreadID] = useState();
  const [Messages, setMessages] = useState([]);
  const [startupMemberID, setStartupMemberID] = useState();
  const [investorMemberID, setInvestorMemberID] = useState();
  const [startupID, setStartupID] = useState();
  const [replyingTo, setReplyingTo] = useState();
  const [editMessageOption, setEditMessageOption] = useState();
  const [filterBoxOpen, setFilterBoxOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("all");
  const [version, setVersion] = useState("1");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [ioiBids, setIoiBids] = useState([]);
  const [spaFile, setspaFile] = useState();
  const [loiAgreements, setLoiAgreements] = useState([]);
  const [SPAs, setSPAs] = useState([]);
  const [NDADocuments, setNDADocuments] = useState([]);
  const [fileUploadTimeStamps, setFileUploadTimeStamps] = useState([]);
  const [ndaApprovalModal, setndaApprovalModal] = useState(false);
  const [SPAModalOpen, setSPAModalOpen] = useState(false);
  const [spaAcceptanceModal, setSpaAcceptanceModal] = useState(false);
  const [openReasonModal, setOpenReasonModal] = useState(false);
  const [spaAcceptedInvestor, setSpaAcceptedInvestor] = useState(false);
  const [spaAcceptedCompany, setSpaAcceptedCompany] = useState(false);
  const uploadImgUrl = windowWidth > 991 ? uploadFile : uploadFileSmall;
  const [NDASignedInvestor, setNDASignedInvestor] = useState(false);
  const [investorSignedNDA, setInvestorSignedNDA] = useState([]);
  const [acceptanceModal, setAcceptanceModal] = useState(false);
  const loader = useLoader();
  const formData = {
    DealID: dealId,
    investorID: investorID,
  };
  const spaFileChange = (file) => {
    setspaFile(file);
  };
  const handleFileUpload = (uploadType, e) => {
    const file = e.target.files[0];
    if (!file) return;
    fileUploads[uploadType] = 1;
    var filename = file.name;
    const fileURL = URL.createObjectURL(file);
    setFileInfo((prevState) => ({
      ...prevState,
      [uploadType]: { file, filename, fileURL },
    }));
  };
  const handleReplyData = (name, threadID) => {
    setReplyingTo(name);
    setThreadID(threadID);
  };
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };
  const handleEnterClick = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      addMessage();
    }
  };
  const fetchDetails = async () => {
    try {
      const response = await fetchStartupMemberDetails(token);
      if (response && response.data !== null) {
        setStartupMemberID(response.data.data.startupMemberID);
        setStartupID(response.data.data.startupID);
      } else {
      }
    } catch (error) {
      loader.stop();
    }
  };
  useEffect(() => {
    loader.start("Fetching details...");
    fetchDetails();
  }, [token]);
  const fetchAnalystDetails = async () => {
    if (!token) return;
    try {
      // If the user is fundrev analyst then fetch the details of the fundrev analyst
      if (fundrevAnalystID) {
        const response = await getFundrevAnalystDetailsById({
          fundrevAnalystID: fundrevAnalystID,
        });
        if (response.data !== null) {
          const responseData = response.data;
          const { data } = responseData;
        }
        return response;
      }
    } catch (error) {
      loader.stop();
    }
  };
  useEffect(() => {
    fetchAnalystDetails();
  }, [fundrevAnalystID]);
  const handleDeleteFile = (e, uploadType) => {
    fileUploads[uploadType] = 0;
    setFileInfo((prevState) => {
      const newState = { ...prevState };
      delete newState[uploadType];
      return newState;
    });
  };
  const addMessage = async () => {
    if (editMessageOption) {
      const formData = new FormData();
      formData.append("investorID", investorID);
      formData.append("DealID", dealId);
      formData.append("messageID", editMessageOption.messageID);
      formData.append("message", editMessageOption.message);
      if (fileUploads["attachFile"]) {
        formData.append("file", fileInfo["attachFile"].file);
      }
      const result = await updateDealInteraction(formData, token);
      if (result.data.status === 200) {
        fetchMessages();
        setMessage("");
        setFileInfo({});
        setReplyingTo("");
        setThreadID();
        setFileUploads([]);
        setEditMessageOption();
        return;
      } else {
        Toast("Failed to update the message", "error", "companyAgreement");
      }
    }
    if (message || fileInfo["attachFile"]) {
      const formData = new FormData();
      formData.append("name", name);
      formData.append("message", message);
      formData.append("DealID", dealId);
      formData.append("startupMemberID", startupMemberID);
      formData.append("RoundType", stage);
      formData.append("investorID", investorID);

      if (threadID) {
        formData.append("threadID", threadID);
      }

      if (fileUploads["attachFile"]) {
        formData.append("file", fileInfo["attachFile"].file);
      }
      const result = await addDealInteraction(formData, token);
      if (result.data.status === 201) {
        fetchMessages();
        setMessage("");
        setFileInfo({});
        setReplyingTo("");
        setThreadID();
        setFileUploads([]);
      } else {
        Toast("Failed to add the message", "error", "companyAgreement");
      }
    }
  };
  const fetchMemberDetails = async (ID, role) => {
    try {
      if (role === "Investor") {
        const data = await fetchInvestorMemberDetailsbyId({
          investorMemberID: ID,
          token: token,
        });
        return data;
      } else if (role === "Company") {
        const data = await fetchStartupMemberDetailsbyId({
          startupMemberID: ID,
          token: token,
        });
        return data;
      } else {
        const data = await fetchAnalystDetails();
        return data;
      }
    } catch (error) {
      loader.stop();
    }
  };
  const fetchMessages = async () => {
    try {
      const data = {
        DealID: dealId,
        investorID: investorID,
        RoundType: stage,
      };
      const response = await getDealInteraction(data, token);
      const fetchedMessages = response.data.data;

      let processedMessagesByThread = {}; // Object to store messages by threadID

      // messgaes can be written by both investor and startup
      for (const message of fetchedMessages) {
        let memberDetailsdata;
        let memberDetails;
        let resolvedByPersonData;
        let resolvedByPersonName;
        let isInvestor;
        let isAnalyst;
        if (message.investorMemberID) {
          memberDetailsdata = await fetchMemberDetails(
            message.investorMemberID,
            "Investor"
          );
          isInvestor = true;
          memberDetails = memberDetailsdata.data.data.data;
        } else if (message.startupMemberID) {
          memberDetailsdata = await fetchMemberDetails(
            message.startupMemberID,
            "Company"
          );
          isInvestor = false;
          memberDetails = memberDetailsdata.data.data.data;
        } else {
          memberDetailsdata = await fetchMemberDetails(
            message.resolvedBy,
            "Analyst"
          );
          isAnalyst = true;
          memberDetails = memberDetailsdata.data.data;
        }
        // We are not saving whether the message is saved ny an investor or statrto hence can be identified by the investorMemberID or startupMemberID (message.resolvedBy)
        if (message.resolvedBy !== undefined) {
          if (message.resolvedBy.slice(0, 3) === "INM") {
            resolvedByPersonData = await fetchMemberDetails(
              message.resolvedBy,
              "Investor"
            );
            resolvedByPersonName = resolvedByPersonData.data.data.data.name;
          } else if (message.resolvedBy.slice(0, 1) === "C") {
            resolvedByPersonData = await fetchMemberDetails(
              message.resolvedBy,
              "Company"
            );
            resolvedByPersonName = resolvedByPersonData.data.data.data.name;
          } else {
            resolvedByPersonData = await fetchMemberDetails(
              message.resolvedBy,
              "Analyst"
            );
            resolvedByPersonName = resolvedByPersonData.data.data.name;
          }
        }
        const newMessage = {
          name: memberDetails.name,
          firmName: memberDetails.companyName
            ? memberDetails.companyName
            : null,
          profilePhoto: isAnalyst
            ? memberDetails.profile
            : memberDetails.memberImg,
          message: message.message,
          messageID: message.messageID,
          threadID: message.threadID,
          isThreadResolved: message.isThreadResolved,
          resolvedBy:
            message.resolvedBy !== undefined ? resolvedByPersonName : null,
          investorMemberID: message.investorMemberID
            ? message.investorMemberID
            : null,
          startupMemberID: message.startupMemberID
            ? message.startupMemberID
            : null,
          fundrevAnalystID: message.fundrevAnalystID
            ? message.fundrevAnalystID
            : null,
          file: message.file ? message.file : null,
          time: moment(message.createdAt)
            .tz("Asia/Kolkata")
            .format("MM/DD/YYYY h:mm A"), // Convert to IST and format
          updatedAt: moment(message.updatedAt)
            .tz("Asia/Kolkata")
            .format("MM/DD/YYYY h:mm A"),
          role: isAnalyst
            ? "Fundrev Analyst"
            : isInvestor
            ? "Investor"
            : "Company",
          stage: stage,
          userRole: "Company",
        };

        if (!processedMessagesByThread[newMessage.threadID]) {
          processedMessagesByThread[newMessage.threadID] = []; // Initialize array if not exists
        }

        processedMessagesByThread[newMessage.threadID].push(newMessage); // Push message to corresponding threadID array
      }

      // Sort messages in each thread based on the 'time' field (timestamp)
      for (const threadID in processedMessagesByThread) {
        processedMessagesByThread[threadID].sort(
          (a, b) => new Date(a.time) - new Date(b.time)
        );
      }

      // Now, sort the threads based on the latest message in each thread
      const sortedThreads = Object.entries(processedMessagesByThread).sort(
        ([, messagesA], [, messagesB]) => {
          const latestTimeA = messagesA[messagesA.length - 1].time;
          const latestTimeB = messagesB[messagesB.length - 1].time;
          return new Date(latestTimeA) - new Date(latestTimeB);
        }
      );

      // Convert sortedThreads back into an object
      const sortedMessagesByThread = Object.fromEntries(sortedThreads);

      setMessages(sortedMessagesByThread);
    } catch (error) {
      console.error("Error fetching messages:", error);
    }
  };
  const acceptSPA = async () => {
    try {
      const formData = new FormData();
      formData.append("DealID", dealId);
      formData.append("investorID", investorID);
      formData.append("IsInterested", true);
      formData.append("SPA_Accepted_Company", true);
      formData.append("Stage", "spaNegotiation");
      formData.append("sentBy", sentBy);
      loader.start("Confirming ...");
      const response = await postStageData(formData, token);
      loader.stop();
      if (response.data !== null) {
        setSpaAcceptanceModal(false);
        await getDealInterestByStage(dealId, investorID, stage, token);
      }
    } catch (error) {
      loader.stop();
      console.error("Error in acceptSPA:", error);
    }
  };

  const closeDeal = async () => {
    try {
      await acceptSPA(); // Ensure acceptSPA completes before continuing

      const formData = new FormData();
      formData.append("DealID", dealId);
      formData.append("investorID", investorID);
      formData.append("Stage", "dealClosed");
      formData.append("IsInterested", true);
      formData.append("sentBy", sentBy);
      loader.start();
      const response = await postStageData(formData, token);
      loader.stop();
      if (response.data !== null) {
        setSpaAcceptanceModal(false);
        await getDealInterestByStage(dealId, investorID, stage, token);
        fetchAllInteractions();
      }
    } catch (error) {
      loader.stop();
      console.error("Error in closeDeal:", error);
    }
  };
  const removeReplyingTo = () => {
    setReplyingTo("");
    setThreadID();
  };
  const handleEditMessgae = (message, messageID, file) => {
    setEditMessageOption({ message, messageID, file });
  };
  const toggleFilterBox = () => {
    setFilterBoxOpen(!filterBoxOpen);
  };
  const handleCancelSPA = () => {
    setSPAModalOpen(false);
    setFileInfo({});
    setspaFile();
  };
  useEffect(() => {
    fetchMessages();
  }, [investorID]);
  const acceptNDA = async () => {
    try {
      const formData = new FormData();
      formData.append("DealID", dealId);
      formData.append("investorID", investorID);
      formData.append("IsInterested", true);
      formData.append("Stage", "ndaSigned");
      formData.append(
        "NDADocument",
        NDADocuments[NDADocuments.length - version]
      );
      formData.append("sentBy", sentBy);
      loader.start();
      const response = await postStageData(formData, token);
      loader.stop();
      if (response.data !== null) {
        setndaApprovalModal(false);
        await getDealInterestByStage(dealId, investorID, stage, token);
        fetchAllInteractions();
      }
    } catch (error) {
      loader.stop();
      Toast(
        "Failed to accept IOI Bid due to some internal errors",
        "error",
        "companyAgreement"
      );
      console.error("Error in acceptIOIBid:", error);
    }
  };

  const handleSPASubmit = async () => {
    try {
      if (!spaFile) {
        Toast("Please upload a file", "error", "companyAgreement");
        return;
      }
      const formData = new FormData();
      formData.append("DealID", dealId);
      formData.append("Stage", "spaNegotiation");
      formData.append("IsInterested", true);
      formData.append("investorID", investorID);
      formData.append("SPA", spaFile[0]);
      formData.append("sentBy", sentBy);
      loader.start();
      const response = await postStageData(formData, token);
      loader.stop();
      if (response.data !== null) {
        setSPAModalOpen(false);
        setFileInfo({});
        setspaFile();
        getDealInterestByStage(dealId, investorID, stage);
        fetchAllInteractions();
      }
    } catch (error) {
      loader.stop();
      Toast(
        "Failed to upload SPA due to some internal errors",
        "error",
        "companyAgreement"
      );
    }
  };

  const getDealInterestByStage = async (dealId, investorID, stage) => {
    try {
      if (token) {
        const response = await dealInterestByStage(
          dealId,
          investorID,
          stage,
          token
        );
        loader.stop();
        if (response) {
          if (stage === "requestAccepted") {
            const filteredData = response.data.filter(
              (item) => item.NDADocument
            );
            const newDocuments = filteredData.map((item) => item.NDADocument);
            const newFileUploadTimeStamps = filteredData.map(
              (item) => item.createdAt
            );
            const investorSignednda = filteredData.map(
              (item) => item.NDA_Signed_Investor
            );
            const ndaSignedI = response.data.some(
              (item) => item.NDA_Signed_Investor
            );
            setInvestorSignedNDA(investorSignednda); // Keep an array whether NDA at that index is signed by investor or not
            setNDASignedInvestor(ndaSignedI);
            setNDADocuments(newDocuments);
            setFileUploadTimeStamps(newFileUploadTimeStamps);
            setVersion(newDocuments.length);
          } else if (stage === "ioiSubmitted") {
            const newIoiBids = response.data.map(
              (item) => item.IOI_Bid_Details
            );
            // For showing time at files
            const newFileUploadTimeStamps = response.data.map(
              (item) => item.createdAt
            );
            setIoiBids(newIoiBids);
            setFileUploadTimeStamps(newFileUploadTimeStamps);
            setVersion(newIoiBids.length);
          } else if (stage === "loiSubmitted") {
            const newloiAgreements = response.data.map(
              (item) => item.LOI_Agreement_Details
            );
            const newFileUploadTimeStamps = response.data.map(
              (item) => item.createdAt
            );
            setLoiAgreements(newloiAgreements);
            setFileUploadTimeStamps(newFileUploadTimeStamps);
            setVersion(newloiAgreements.length);
          } else if (stage === "spaNegotiation") {
            // Filter the data to include only items that have the SPA property
            const filteredData = response.data.filter((item) => item.SPA);

            // Map the filtered data to extract SPA and createdAt
            const newSpas = filteredData.map((item) => item.SPA);
            const newFileUploadTimeStamps = filteredData.map(
              (item) => item.createdAt
            );

            // Check if any item has SPA_Accepted_Investor or SPA_Accepted_Company set to a truthy value
            // We are not using filteredata here because it do not contains those fields.
            const spaAcceptedI = response.data.some(
              (item) => item.SPA_Accepted_Investor
            );
            const spaAcceptedC = response.data.some(
              (item) => item.SPA_Accepted_Company
            );

            // Set state for SPAs and file upload timestamps
            setSPAs(newSpas);
            setFileUploadTimeStamps(newFileUploadTimeStamps);

            // Set additional state for the boolean values
            setSpaAcceptedInvestor(spaAcceptedI);
            setSpaAcceptedCompany(spaAcceptedC);

            // Set version based on the length of newSpas
            setVersion(newSpas.length);
          }
        }
      }
    } catch (error) {
      loader.stop();
    }
  };
  useEffect(() => {
    if (dealId && investorID && stage)
      getDealInterestByStage(dealId, investorID, stage);
  }, [investorID, Messages, stage, token]);
  //For getting the latest stage to ensure that the edit can be done only for the latest stage
  const fetchAllInteractions = async () => {
    try {
      const response = await getAllDealInteractions(dealId, investorID, token);
      setLatestStage(response.data[response.data.length - 1].Stage);
    } catch (error) {
      loader.stop();
    }
  };
  useEffect(() => {
    fetchAllInteractions();
  }, [dealId, investorID]);
  const NavigateToInvestorDetails = () => {
    navigate(-1);
  };
  useZoomEffect();
  useEffect(() => {
    if (!investorID || !stage || !companyName || !dealId) {
      navigate("/company/fundraising");
    }
  }, [stage, dealId, companyName, investorID]);
  useEffect(() => {
    window.addEventListener("resize", () => {
      setWindowWidth(window.innerWidth);
    });
  }, []);
  return (
    <div className="InvestorSide">
      <ToastContainer position="top-center" containerId="companyAgreement" />
      <Sidebar active="fundraising" />
      <Topbar title="Ongoing Deals" />
      <PassDealModal
        openReasonModal={openReasonModal}
        setOpenReasonModal={setOpenReasonModal}
        stage={stage}
        isCompany={true}
        DealID={dealId}
        investorID={investorID}
      />
      <AcceptConfirmationModal
        acceptanceModal={acceptanceModal}
        setAcceptanceModal={setAcceptanceModal}
        stage={stage}
        dealId={dealId}
        investorID={investorID}
        sentBy={sentBy}
        fetchAllInteractions={fetchAllInteractions}
        getDealInterestByStage={getDealInterestByStage}
        postStageData={postStageData}
      />
      <UploadDocumentModal
        DocumentUploadModalOpen={SPAModalOpen}
        setDocumentUploadModalOpen={setSPAModalOpen}
        DocumentUploadFile={spaFile}
        DocumentUploadFileChange={spaFileChange}
        handleCancelDocumentUpload={handleCancelSPA}
        handleDocumentUploadSubmit={handleSPASubmit}
      />
      <Modal
        open={ndaApprovalModal}
        onClose={() => setndaApprovalModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className="modal-box reason-box"
          style={{ width: "600px", height: "220px" }}
        >
          <img
            className="reason-box-closeicon"
            src={closeIcon}
            style={{
              cursor: "pointer",
              position: "fixed",
              right: "20px",
              top: "20px",
            }}
            onClick={() => setndaApprovalModal(false)}
          ></img>
          <div className="text-box">
            <div className="heading">
              <p className="heading">Are you sure?</p>
            </div>
            <div className="subheading">
              <p>
                Please ensure that the NDA is signed by investor before
                approving the request.
              </p>
            </div>
          </div>

          <div className="btn-box">
            <button
              className="reason-submit cancel"
              style={{ height: "40px" }}
              onClick={() => setndaApprovalModal(false)}
            >
              Cancel
            </button>
            <button className="reason-submit" onClick={acceptNDA}>
              Approve NDA
            </button>
          </div>
        </Box>
      </Modal>
      <SPAAcceptanceModal
        spaAcceptanceModal={spaAcceptanceModal}
        setSpaAcceptanceModal={setSpaAcceptanceModal}
        closeDeal={closeDeal}
        acceptSPA={acceptSPA}
        spaAcceptedInvestor={spaAcceptedInvestor}
        spaAcceptedCompany={spaAcceptedCompany}
      />
      <div className="InvestorSide-box">
        <div
          className="InvestorSide-content"
          style={{
            marginBottom: replyingTo
              ? "180px"
              : fileUploads["attachFile"]
              ? "250px"
              : "130px",
          }}
        >
          <div className="cdetails-div" style={{ paddingLeft: "10px" }}>
            <div className="signNDA-first-div">
              {stage === "requestAccepted" ? (
                <div className="MessagePage-first-div">
                  <p className="MessagePage-stage">
                    <Link
                      className="blue-text"
                      onClick={NavigateToInvestorDetails}
                    >
                      {companyName}
                    </Link>
                    &nbsp;- Sign NDA
                  </p>
                  {stage === latestStage && (
                    <div className="messagePage-btns">
                      {NDASignedInvestor ? (
                        <button
                          className="showInterestButtons-div-3"
                          style={{
                            border: "0px",
                            fontWeight: 600,
                            fontSize: "16px",
                          }}
                          onClick={() => setndaApprovalModal(true)}
                        >
                          Approve NDA
                        </button>
                      ) : (
                        <div className="stage-display-btn req-pending">
                          Signed NDA yet to be uploaded
                        </div>
                      )}
                      <div
                        className="view-only-btn"
                        style={{ fontWeight: 600, fontSize: "16px" }}
                      >
                        <img src={VisibilityIcon}></img>
                        View Only
                      </div>
                    </div>
                  )}
                </div>
              ) : stage === "ioiSubmitted" ? (
                <div className="MessagePage-first-div">
                  <p className="MessagePage-stage">
                    <Link
                      className="blue-text"
                      onClick={NavigateToInvestorDetails}
                    >
                      {companyName}
                    </Link>
                    &nbsp;- IOI Bid
                  </p>
                  {stage === latestStage && (
                    <div className="messagePage-btns">
                      <button
                        className="showInterestButtons-div-2"
                        style={{ fontWeight: 600, fontSize: "16px" }}
                        onClick={() => setOpenReasonModal(true)}
                      >
                        Pass Deal
                      </button>
                      <button
                        className="showInterestButtons-div-3"
                        style={{
                          border: "0px",
                          fontWeight: 600,
                          fontSize: "16px",
                        }}
                        onClick={() => setAcceptanceModal(true)}
                      >
                        Accept IOI Bid
                      </button>
                    </div>
                  )}
                </div>
              ) : stage === "loiSubmitted" ? (
                <div className="MessagePage-first-div">
                  <p className="MessagePage-stage">
                    <Link
                      className="blue-text"
                      onClick={NavigateToInvestorDetails}
                    >
                      {companyName}
                    </Link>
                    &nbsp;- LOI Agreement
                  </p>
                  {stage === latestStage && (
                    <div className="messagePage-btns">
                      <button
                        className="showInterestButtons-div-2"
                        style={{ fontWeight: 600, fontSize: "16px" }}
                        onClick={() => setOpenReasonModal(true)}
                      >
                        Pass Deal
                      </button>
                      <button
                        className="showInterestButtons-div-3"
                        style={{
                          border: "0px",
                          fontWeight: 600,
                          fontSize: "16px",
                        }}
                        onClick={() => setAcceptanceModal(true)}
                      >
                        Accept LOI Agreement
                      </button>
                    </div>
                  )}
                </div>
              ) : stage === "spaNegotiation" ? (
                <div className="MessagePage-first-div">
                  <div className="stage-status">
                    <p className="MessagePage-stage">
                      <Link
                        className="blue-text"
                        onClick={NavigateToInvestorDetails}
                      >
                        {companyName && companyName}
                      </Link>
                      &nbsp;- SPA Negotiation
                    </p>
                    {(spaAcceptedCompany || spaAcceptedInvestor) &&
                      stage === latestStage && (
                        <div
                          className="stage-display-btn ask-question"
                          style={{ cursor: "text" }}
                        >
                          {spaAcceptedInvestor
                            ? "SPA Accepted by Investor"
                            : spaAcceptedCompany
                            ? "SPA Accepted by Company"
                            : ""}
                        </div>
                      )}
                  </div>
                  {stage === latestStage && (
                    <div className="messagePage-btns">
                      <button
                        className="showInterestButtons-div-2"
                        style={{ fontWeight: 600, fontSize: "16px" }}
                        onClick={() => setOpenReasonModal(true)}
                      >
                        Pass Deal
                      </button>
                      <button
                        className="showInterestButtons-div-3"
                        style={{
                          border: "0px",
                          fontWeight: 600,
                          fontSize: "16px",
                        }}
                        onClick={() => setSPAModalOpen(true)}
                      >
                        Revise SPA
                      </button>
                      {!spaAcceptedCompany && (
                        <button
                          className="showInterestButtons-div-3"
                          style={{
                            border: "0px",
                            fontWeight: 600,
                            fontSize: "16px",
                            cursor: spaAcceptedCompany
                              ? "not-allowed"
                              : "pointer",
                          }}
                          onClick={() => setSpaAcceptanceModal(true)}
                        >
                          Accept SPA
                        </button>
                      )}
                    </div>
                  )}
                  {latestStage === "dealClosed" && (
                    <div className="messagePage-btns">
                      <button
                        className="showInterestButtons-div-2"
                        style={{
                          fontWeight: 600,
                          fontSize: "16px",
                          cursor: "not-allowed",
                        }}
                      >
                        Deal Closed
                      </button>
                    </div>
                  )}
                </div>
              ) : null}
            </div>
          </div>
          {stage === "requestAccepted" ? (
            <section className="sign-nda-section">
              <div className="MessagePage-first-div">
                <p className="activity-text">Document</p>
                {NDADocuments.length > 0 && (
                  <div className="messagePage-btns">
                    <p className="version-text">Version :</p>
                    <FormControl sx={{ m: 1, minWidth: 154 }}>
                      <Select
                        sx={{ height: "36px", width: "154px" }}
                        value={version}
                        onChange={(e) => setVersion(e.target.value)}
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        {NDADocuments.map((bid, index) => (
                          <MenuItem value={NDADocuments.length - index}>
                            v{NDADocuments.length - index}.0
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                )}
              </div>
              {NDADocuments.length > 0 ? (
                <div className="NDA-document SPA-document">
                  <div className="NDA-document-left">
                    <img src={FileImg} className="FileImg" />
                    <div className="NDA-document-name">
                      <p>
                        {fetchBlobName(
                          NDADocuments.length > 0 &&
                            NDADocuments[NDADocuments.length - version]
                        ).length > 30
                          ? fetchBlobName(
                              NDADocuments.length > 0 &&
                                NDADocuments[NDADocuments.length - version]
                            ).slice(0, 30) + `...`
                          : fetchBlobName(
                              NDADocuments.length > 0 &&
                                NDADocuments[NDADocuments.length - version]
                            )}
                      </p>
                    </div>
                    <p className="file-time-stamp">
                      {moment(
                        fileUploadTimeStamps[
                          fileUploadTimeStamps.length - version
                        ]
                      )
                        .tz("Asia/Kolkata")
                        .format("MM/DD/YYYY h:mm A")}
                    </p>
                    <p>
                      {investorSignedNDA[NDADocuments.length - version] ? (
                        <p className="blue-text">Signed by Investor</p>
                      ) : (
                        ""
                      )}
                    </p>
                  </div>
                  <div className="NDA-document-right">
                    <Link to={NDADocuments[NDADocuments.length - version]}>
                      <img src={download} />
                    </Link>
                  </div>
                </div>
              ) : (
                <div className="NDA-document SPA-document">
                  <div className="NDA-document-left">
                    <div className="NDA-document-name">
                      <p>NDA Document yet to be uploaded</p>
                    </div>
                  </div>
                </div>
              )}
            </section>
          ) : stage === "ioiSubmitted" ? (
            <section className="IOI-bid-details-section">
              <div className="MessagePage-first-div">
                <p className="activity-text">Bid Details</p>
                <div className="messagePage-btns">
                  <p className="version-text">Version :</p>
                  <FormControl sx={{ m: 1, minWidth: 154 }}>
                    <Select
                      sx={{ height: "36px", width: "154px" }}
                      value={version}
                      onChange={(e) => setVersion(e.target.value)}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      {ioiBids.map((bid, index) => (
                        <MenuItem value={ioiBids.length - index}>
                          v{ioiBids.length - index}.0
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div className="IOI-bids-details">
                <div className="IOI-bid-details-left">
                  <div className="IOI-bid-details-row">
                    <p className="IOI-bid-details-row-first">
                      Indicative Post-money Valuation (₹Cr)
                    </p>
                    <p className="IOI-bid-details-row-second">
                      {ioiBids.length > 0 &&
                        ioiBids[ioiBids.length - version].postMoneyValuation}
                    </p>
                  </div>
                  <div className="IOI-bid-details-row">
                    <p className="IOI-bid-details-row-first">
                      Expected Transaction Completion Date
                    </p>
                    <p className="IOI-bid-details-row-second">
                      {ioiBids.length > 0 &&
                        moment(
                          ioiBids[ioiBids.length - version]
                            .transactionCompletionDate
                        )
                          .tz("Asia/Kolkata")
                          .format("Do MMMM, YYYY")}
                    </p>
                  </div>
                  <div className="IOI-bid-details-row">
                    <p className="IOI-bid-details-row-first">Diligence Items</p>
                    <p className="IOI-bid-details-row-second">
                      {ioiBids.length > 0 &&
                        ioiBids[ioiBids.length - version].diligenceItems}
                    </p>
                  </div>
                </div>
                <div className="IOI-bid-details-right">
                  <div className="IOI-bid-details-row">
                    <p className="IOI-bid-details-row-first">
                      Amount of Investment (₹Cr)
                    </p>
                    <p className="IOI-bid-details-row-second">
                      {ioiBids.length > 0 &&
                        ioiBids[ioiBids.length - version].investmentAmount}
                    </p>
                  </div>
                  <div className="IOI-bid-details-row">
                    <p className="IOI-bid-details-row-first">Data Requests</p>
                    <p className="IOI-bid-details-row-second">
                      {ioiBids.length > 0 &&
                        ioiBids[ioiBids.length - version].dataRequests}
                    </p>
                  </div>
                  <div className="IOI-bid-details-row">
                    <p className="IOI-bid-details-row-first">IOI Letter</p>
                    <p className="IOI-bid-details-row-second">
                      <div className="IOI-bid-document">
                        <div className="IOI-bid-doument-left">
                          <img src={FileImg} className="FileImg" />
                          <div>
                            <p className="IOI-bid-document-name">
                              {fetchBlobName(
                                ioiBids.length > 0 &&
                                  ioiBids[ioiBids.length - version].IOILetter
                              ).length > 10
                                ? fetchBlobName(
                                    ioiBids.length > 0 &&
                                      ioiBids[ioiBids.length - version]
                                        .IOILetter
                                  ).slice(0, 10) + `...`
                                : fetchBlobName(
                                    ioiBids.length > 0 &&
                                      ioiBids[ioiBids.length - version]
                                        .IOILetter
                                  )}
                            </p>
                            <p className="IOI-bid-document-upload-time">
                              {moment(
                                fileUploadTimeStamps[ioiBids.length - version]
                              )
                                .tz("Asia/Kolkata")
                                .format("MM/DD/YYYY h:mm A")}
                            </p>
                          </div>
                        </div>
                        <div className="IOI-bid-doument-right">
                          <Link
                            to={
                              ioiBids.length > 0 &&
                              ioiBids[ioiBids.length - version].IOILetter
                            }
                          >
                            <img src={download} />
                          </Link>
                        </div>
                      </div>
                    </p>
                  </div>
                </div>
              </div>
            </section>
          ) : stage === "loiSubmitted" ? (
            <section className="LOI-agreement-section">
              <div className="MessagePage-first-div">
                <p className="activity-text">Agreement Details</p>
                <div className="messagePage-btns">
                  <p className="version-text">Version :</p>
                  <FormControl sx={{ m: 1, minWidth: 154 }}>
                    <Select
                      sx={{ height: "36px", width: "154px" }}
                      value={version}
                      onChange={(e) => setVersion(e.target.value)}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      {loiAgreements.map((bid, index) => (
                        <MenuItem value={loiAgreements.length - index}>
                          v{loiAgreements.length - index}.0
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div className="IOI-bids-details">
                <div className="IOI-bid-details-left">
                  <div className="IOI-bid-details-row">
                    <p className="IOI-bid-details-row-first">
                      Amount of Investment (₹Cr)
                    </p>
                    <p className="IOI-bid-details-row-second">
                      {loiAgreements.length > 0 &&
                        loiAgreements[loiAgreements.length - version]
                          .investmentAmount}
                    </p>
                  </div>
                  <div className="IOI-bid-details-row">
                    <p className="IOI-bid-details-row-first">
                      Valuation Amount (₹Cr)
                    </p>
                    <p className="IOI-bid-details-row-second">
                      {loiAgreements.length > 0 &&
                        loiAgreements[loiAgreements.length - version]
                          .valuationAmount}
                    </p>
                  </div>
                  <div className="IOI-bid-details-row">
                    <p className="IOI-bid-details-row-first">Investment Type</p>
                    <p className="IOI-bid-details-row-second">
                      {loiAgreements.length > 0 &&
                        loiAgreements[loiAgreements.length - version]
                          .investmentType}
                    </p>
                  </div>
                  <div className="IOI-bid-details-row">
                    <p className="IOI-bid-details-row-first">
                      Closing Conditions
                    </p>
                    <p className="IOI-bid-details-row-second">
                      {loiAgreements.length > 0 &&
                        loiAgreements[loiAgreements.length - version]
                          .closingConditions}
                    </p>
                  </div>
                </div>
                <div className="IOI-bid-details-right">
                  <div className="IOI-bid-details-row">
                    <p className="IOI-bid-details-row-first">
                      Expected Transaction Completion Date
                    </p>
                    <p className="IOI-bid-details-row-second">
                      {loiAgreements.length > 0 &&
                        moment(
                          loiAgreements[loiAgreements.length - version]
                            .selectedDate
                        )
                          .tz("Asia/Kolkata")
                          .format("Do MMMM, YYYY")}
                    </p>
                  </div>
                  <div className="IOI-bid-details-row">
                    <p className="IOI-bid-details-row-first">Payment Options</p>
                    <p className="IOI-bid-details-row-second">
                      {loiAgreements.length > 0 &&
                        loiAgreements[loiAgreements.length - version]
                          .paymentOptions}
                    </p>
                  </div>
                  <div className="IOI-bid-details-row">
                    <p className="IOI-bid-details-row-first">
                      Valuation Considerations
                    </p>
                    <p className="IOI-bid-details-row-second">
                      {loiAgreements.length > 0 &&
                        loiAgreements[loiAgreements.length - version]
                          .valuationConsiderations}
                    </p>
                  </div>
                  <div className="IOI-bid-details-row">
                    <p className="IOI-bid-details-row-first">LOI Letter</p>
                    <p className="IOI-bid-details-row-second">
                      <div className="IOI-bid-document">
                        <div className="IOI-bid-doument-left">
                          <img src={FileImg} className="FileImg" />
                          <div className="IOI-bid-document-name"></div>
                          <div>
                            <p className="IOI-bid-document-name">
                              {fetchBlobName(
                                loiAgreements.length > 0 &&
                                  loiAgreements[loiAgreements.length - version]
                                    .LOILetter
                              ).length > 10
                                ? fetchBlobName(
                                    loiAgreements.length > 0 &&
                                      loiAgreements[
                                        loiAgreements.length - version
                                      ].LOILetter
                                  ).slice(0, 10) + `...`
                                : fetchBlobName(
                                    loiAgreements.length > 0 &&
                                      loiAgreements[
                                        loiAgreements.length - version
                                      ].LOILetter
                                  )}
                            </p>
                            <p className="IOI-bid-document-upload-time">
                              {moment(
                                fileUploadTimeStamps[
                                  loiAgreements.length - version
                                ]
                              )
                                .tz("Asia/Kolkata")
                                .format("MM/DD/YYYY h:mm A")}
                            </p>
                          </div>
                        </div>
                        <div className="IOI-bid-doument-right">
                          <Link
                            to={
                              loiAgreements.length > 0 &&
                              loiAgreements[loiAgreements.length - version]
                                .LOILetter
                            }
                          >
                            <img src={download} />
                          </Link>
                        </div>
                      </div>
                    </p>
                  </div>
                </div>
              </div>
            </section>
          ) : stage === "spaNegotiation" ? (
            <section className="Closed-deal-section">
              <div className="MessagePage-first-div">
                <p className="activity-text">Document</p>
                {SPAs.length > 0 ? (
                  <div className="messagePage-btns">
                    <p className="version-text">Version :</p>
                    <FormControl sx={{ m: 1, minWidth: 154 }}>
                      <Select
                        sx={{ height: "36px", width: "154px" }}
                        value={version}
                        onChange={(e) => setVersion(e.target.value)}
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        {SPAs.map((bid, index) => (
                          <MenuItem value={SPAs.length - index}>
                            v{SPAs.length - index}.0
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                ) : null}
              </div>
              {SPAs.length > 0 ? (
                <div className="NDA-document SPA-document">
                  <div className="NDA-document-left">
                    <img src={FileImg} className="FileImg" />
                    <div className="NDA-document-name">
                      <p>
                        {fetchBlobName(
                          SPAs.length > 0 && SPAs[SPAs.length - version]
                        ).length > 10
                          ? fetchBlobName(
                              SPAs.length > 0 && SPAs[SPAs.length - version]
                            ).slice(0, 10) + `...`
                          : fetchBlobName(
                              SPAs.length > 0 && SPAs[SPAs.length - version]
                            )}
                      </p>
                    </div>
                    <p className="file-time-stamp">
                      {moment(
                        fileUploadTimeStamps[
                          fileUploadTimeStamps.length - version
                        ]
                      )
                        .tz("Asia/Kolkata")
                        .format("MM/DD/YYYY h:mm A")}
                    </p>
                  </div>
                  <div className="NDA-document-right">
                    <Link to={SPAs[SPAs.length - version]}>
                      <img src={download} />
                    </Link>
                  </div>
                </div>
              ) : (
                <div>
                  <FileUploader
                    multiple={true}
                    handleChange={spaFileChange}
                    name="file"
                  >
                    <div className="upload-file-div">
                      {spaFile ? (
                        <div className="upload-spa-div">
                          <p>{`File name: ${spaFile[0].name}`}</p>
                        </div>
                      ) : (
                        <div className="upload-spa-div-6">
                          <div className="upload-file-div-2">
                            <img
                              loading="lazy"
                              src={uploadFileBlue}
                              className="upload-file-img"
                            />
                          </div>
                          <div className="upload-file-div-3">
                            <div className="upload-file-div-4">
                              Click to Upload
                            </div>
                            <div className="upload-file-div-5">
                              or drag and drop
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </FileUploader>
                  {spaFile ? (
                    <div className="upload-spa-div">
                      <button
                        className="InvestorSide-content-button-1"
                        onClick={handleSPASubmit}
                      >
                        Upload
                      </button>
                    </div>
                  ) : null}
                </div>
              )}
            </section>
          ) : null}
          <div className="filter-box-div" onClick={toggleFilterBox}>
            <p className="activity-text">Activity</p>
            <button className="InvestorSide-content-button-1">
              <img loading="lazy" src={filter} className="img" />
              Filter
            </button>
            <div
              className="NDA-filter-box"
              style={{ display: filterBoxOpen ? "flex" : "none" }}
            >
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="female"
                name="radio-buttons-group"
                value={selectedOption}
                onChange={handleOptionChange}
              >
                <FormControlLabel
                  value="all"
                  className="filter-box-radio"
                  control={<Radio />}
                  label="Show all threads"
                />
                <FormControlLabel
                  value="unresolved"
                  className="filter-box-radio"
                  control={<Radio />}
                  label="Show unresolved threads"
                />
                <FormControlLabel
                  value="resolved"
                  style={{ width: "100%" }}
                  control={<Radio />}
                  label="Show resolved threads"
                />
              </RadioGroup>
            </div>
          </div>
          {Object.values(Messages).map((threadMessages, threadIndex) =>
            // Check if thread contains more than one member
            threadMessages.length > 1 ? (
              <Thread
                key={threadIndex}
                messages={threadMessages}
                handleReplyData={handleReplyData}
                personalID={startupMemberID}
                fetchMessages={fetchMessages}
                handleEditMessage={handleEditMessgae}
                option={selectedOption}
                latestStage={latestStage}
              />
            ) : (
              // If no, render individual messages
              threadMessages.map((message, index) => (
                <Message
                  key={index}
                  personalID={startupMemberID}
                  userID={
                    message.role === "Fundrev Analyst"
                      ? message.fundrevAnalystID
                      : message.investorMemberID
                      ? message.investorMemberID
                      : message.startupMemberID
                  }
                  name={message.name}
                  firmName={message.firmName}
                  role={message.role}
                  time={message.time}
                  message={message.message}
                  messageID={message.messageID}
                  threadID={message.threadID}
                  isThreadResolved={message.isThreadResolved}
                  resolvedBy={message.resolvedBy}
                  updatedAt={message.updatedAt}
                  profilePhoto={message.profilePhoto}
                  handleReplyData={handleReplyData}
                  file={message.file ? message.file : null}
                  handleEdit={handleEditMessgae}
                  fetchMessages={fetchMessages}
                  option={selectedOption}
                  stage={stage}
                  latestStage={latestStage}
                  userRole={message.userRole}
                />
              ))
            )
          )}
          {stage === latestStage && stage !== "requestAccepted" ? (
            <div className="add-comment-div">
              {fileUploads["attachFile"] ? (
                <div
                  className="NDA-document"
                  style={{ marginTop: "0px", marginBottom: "20px" }}
                >
                  <div className="NDA-document-left">
                    <img src={FileImg} className="FileImg" />
                    <div className="NDA-document-name">
                      <p>{fileInfo["attachFile"].filename}</p>
                    </div>
                  </div>
                  <div className="NDA-document-right">
                    <img
                      className="attch-file-delete"
                      src={cross}
                      style={{ cursor: "pointer" }}
                      onClick={(e) => handleDeleteFile(e, "attachFile")}
                    />
                  </div>
                </div>
              ) : (
                ""
              )}
              {replyingTo ? (
                <div
                  className="replying-to-div"
                  style={{ marginBottom: "10px", marginLeft: "12px" }}
                >
                  <div className="replying-to">Replying to {replyingTo}</div>
                  <div>
                    <img
                      src={cross}
                      className="replying-to-cross"
                      onClick={removeReplyingTo}
                    />
                  </div>
                </div>
              ) : null}
              {editMessageOption ? (
                <div>
                  <img
                    src={cross}
                    className="edit-cross"
                    onClick={() => setEditMessageOption()}
                  ></img>
                  {editMessageOption.file && !fileUploads["attachFile"] ? (
                    <div
                      className="NDA-document"
                      style={{ marginTop: "0px", marginBottom: "20px" }}
                    >
                      <div className="NDA-document-left">
                        <img src={FileImg} className="FileImg" />
                        <div className="NDA-document-name">
                          <p>
                            {fetchBlobName(editMessageOption.file).length > 10
                              ? fetchBlobName(editMessageOption.file).slice(
                                  0,
                                  10
                                ) + `...`
                              : fetchBlobName(editMessageOption.file)}
                          </p>
                        </div>
                      </div>
                      <div className="NDA-document-right">
                        <img
                          className="attch-file-delete"
                          src={cross}
                          style={{ cursor: "pointer" }}
                          onClick={(e) =>
                            setEditMessageOption((prevState) => ({
                              ...prevState,
                              file: null,
                            }))
                          }
                        />
                      </div>
                    </div>
                  ) : null}
                  <Textarea
                    className="add-comment-div-2"
                    value={
                      editMessageOption ? editMessageOption.message : message
                    }
                    onKeyDown={handleEnterClick}
                    onChange={(e) =>
                      setEditMessageOption((prevState) => ({
                        ...prevState,
                        message: e.target.value,
                      }))
                    }
                  />
                </div>
              ) : (
                <Textarea
                  className="add-comment-div-2"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  onKeyDown={handleEnterClick}
                  placeholder="Add any suggestions, changes to the document …"
                />
              )}
              <div className="add-comment-div-3">
                <div class="upload-btn-wrapper attach-file-wrapper">
                  <button
                    className="upload-img-btn"
                    style={{ cursor: "pointer" }}
                  >
                    <img loading="lazy" src={file} alt="Attach File" />
                  </button>
                  <input
                    id="attachFile"
                    type="file"
                    onChange={(e) => handleFileUpload("attachFile", e)}
                  />
                </div>
                <img
                  loading="lazy"
                  src={sendMessage}
                  onClick={addMessage}
                  className="add-comment-img-2"
                />
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default MessagesPage;
